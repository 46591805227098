<template>
  <div class="common-mask" v-show="props.visible">
    <div class="w-[500px] rounded-[30px] bg-white relative px-[50px] pt-[50px] pb-[30px]">
      <p v-if="props.title" class="font-Roboto-Bold font-bold  text-[24px] leading-[28px] text-text-deep">
        {{ props.title }}
      </p>
      <p v-if="props.content" class="text-[14px] leading-[20px] text-text-deep mt-5 mb-10">
        {{ props.content }}
      </p>
      <slot></slot>
      <span class="absolute right-3 top-3 cursor-pointer p-2" @click="onClose">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.89826 1.2874L7.99393 7.37979L14.0248 1.34856C14.4358 0.982462 14.8612 1.20104 15.0312 1.38454C15.1402 1.51743 15.1965 1.68585 15.1892 1.8576C15.1819 2.02935 15.1115 2.19238 14.9916 2.31554L8.95981 8.34587L14.9916 14.3744C15.2596 14.5993 15.2596 15.04 15.0447 15.2865C14.8288 15.5321 14.4331 15.6742 14.0599 15.3827L7.99393 9.31194L1.92075 15.3854C1.65454 15.6238 1.21567 15.5752 0.998933 15.3387C0.781295 15.1012 0.698556 14.691 0.976449 14.4014L7.02805 8.34587L1.00163 2.31554C0.781294 2.07267 0.704851 1.62831 1.00163 1.34856C1.29841 1.06882 1.74088 1.10749 1.89826 1.2874Z"
            fill="#8A8EA1" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  content: {
    type: String,
    default: "",
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close"]);
function onClose() {
  emit("close");
}
</script>
