<template>
  <div class="relative h-full w-full flex items-center justify-center">
    <div
      v-show="originVideo"
      class="relative h-2/3 max-w-[1224px] -translate-y-[20%] w-full shadow-1 bg-white"
    >
      <div
        class="relative h-full  w-full"
      >
        <EnhanceVideoPreview
          ref="originVideoRef"
          :preViewAbled="preViewAbled"
          :isFinish="process === 1"
          @timeupdate="timeupdate"
          @loadedmetadata="loadedmetadata"
          @error="originError"
          @onDownload="onDownload"
        ></EnhanceVideoPreview>
      </div>
    </div>
    <div v-if="!originVideo" class="flex items-center justify-center">
      <FuncTypeUpload
        class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
        :funcType="route.params.funcType"
      ></FuncTypeUpload>
    </div>
    <div
      class="absolute left-1/2 bottom-10 -translate-x-1/2 w-[888px] flex flex-col items-center gap-6"
      v-if="originVideo"
    >
      <EnhanceVideoProcessCtr
        ref="videoProcessCtrRef"
        v-if="preViewAbled && (originVideo || outputVideo)"
        @onPlay="onPlay"
        @onPause="onPause"
        @updateSeed="updateSeed"
        :totalDuration="totalDuration"
        :currentTime="currentTime"
        :enable="!(originVideo || outputVideo)"
      ></EnhanceVideoProcessCtr>
      <div
        class="bg-white shadow-6 h-[80px] rounded-[20px] px-4 flex items-center gap-8"
      >
        <div class="flex items-center gap-3">
          <img
            v-if="process === 1"
            src="/assets/images/enhance/check.svg"
            alt="check"
          />
          <span
            >{{process === 1?$t('enhance-Conversion-complete'): $t("enhance-Compressed") }}</span
          >
          <!-- <span>{{$t('Compressed',{process:(process * 100).toFixed(1)})  }}%</span> -->
        </div>
        <div
          class="h-12 rounded-lg border border-gray-line px-3 flex items-center gap-3 bg-light-gray-bg"
        >
          <img src="/assets/images/enhance/video.svg" alt="video" />
          <span
            class="max-w-[180px] text-ellipsis overflow-hidden whitespace-nowrap Roboto-bold-14"
            >{{ truncateString(originVideo?.name ?? "", 20) }}</span
          >
          <span>{{ formatFileSize(originVideo?.size ?? 0) }}</span>
        </div>
        <img
          src="/assets/images/enhance/to.svg"
          alt="converter"
          width="24"
          height="24"
        />
        <a-select
          class="h-12 rounded-lg border border-gray-line px-3 flex items-center gap-3 bg-light-gray-bg"
          style="
            width: 100px;
            border-radius: 10px;
            border: 1px solid rgb(224, 224, 224);
          "
          placeholder=""
          v-model="targetVideoType"
          :disabled="process > 0 && process < 1"
        >
          <a-option
            :value="item"
            :label="item"
            :key="item"
            v-for="item in videoList"
            :disabled="item == videoType"
          />
        </a-select>
        <EnhanceApply
          class="w-max"
          @click="onApply"
          :enable="applyEnabled"
        ></EnhanceApply>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUploadStore } from "@/store/upload";
import { useMainStore } from "@/store/index";
import { getObjectURL, formatFileSize, truncateString } from "@/utils";
import messageBox from "@/composables/messageBox";
const fullLoading = useFullLoading();
const mainStore = useMainStore();
const uploadStore = useUploadStore();
const {
  public: { MODE },
} = useRuntimeConfig();
const { locale, t } = useI18n();
const { isLogin, isVip, isVipPro } = useUserInfo();
const originVideoRef = ref<HTMLElement>();
const videoPlayer = ref<HTMLVideoElement | null>(null);
const originVideo = ref<File | null>(null);
const outputVideo = ref<File | null>(null);
const process = ref(0);
const totalDuration = ref(0);
const currentTime = ref(0);
const videoList = ref(["mp4", "mov", "avi", "webm"]);
const route = useRoute();
// 支持预览
const preViewAbled = ref(true);
const ffmpegRef = ref();
const outputUrl = ref("");
const videoProcessCtrRef = ref(null);
const videoType = ref("");
const targetVideoType = ref("");
onMounted(async () => {
  const { createFFmpeg } = await import("@ffmpeg/ffmpeg");

  console.log("videos", uploadStore.videos);
  const videos = [...uploadStore.videos];
  uploadStore.setVideos([]);
  if (videos.length > 0) {
    initFileList(videos);
  }
  window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);

  const ffmpeg = createFFmpeg({
    // ffmpeg路径
    corePath: "/ffmpeg/ffmpeg-core.js",
    // 日志
    log: true,
    // 进度
    progress: ({ ratio }) => {
      process.value = ratio;
      const p = Number((ratio * 100).toFixed(2));
      fullLoading.updateText({
          title: `${t("enhance-upload-process")}(${p}%)`,
        });
    },
  });
  ffmpegRef.value = ffmpeg;
  await ffmpeg.load();
});
onUnmounted(() => {
  uploadStore.setVideos([]);
  window.removeEventListener(
    "uploadLocalFilesSuccess",
    uploadLocalFilesSuccess
  );
});
const applyEnabled = computed(() => {
  return originVideo.value && process.value == 0 && !!targetVideoType.value;
});
function uploadLocalFilesSuccess(params: any) {
  console.log(params.detail);
  initFileList(params.detail);
}
function initFileList(fileList: File[]) {
  console.log("fileList", fileList);
  if (fileList.length) {
    process.value = 0;
    originVideo.value = fileList[0];
    outputVideo.value = null;
    outputUrl.value = "";
    currentTime.value = 0;
    totalDuration.value = 0;
    const suffix = originVideo.value?.name.split(".")[1].toLocaleLowerCase();
    videoType.value = suffix;
    targetVideoType.value = "";
    videoProcessCtrRef.value?.setSeed(0);
    preViewAbled.value = ["mp4", "webm"].includes(suffix);
    const videoUrl = getObjectURL(fileList[0]);

    window.dispatchEvent(
      new CustomEvent("updateFileListEvent", {
        detail: [{ file: fileList[0] }],
      })
    );
    window.dispatchEvent(
      new CustomEvent("downloadUrlEvent", {
        detail: "",
      })
    );
    nextTick(() => {
      if (originVideoRef.value) {
        videoPlayer.value = originVideoRef.value.videoPlayer;
        if (videoPlayer.value) {
          videoPlayer.value.src = videoUrl;
        }
      }
    });
  }
}

async function onApply() {
  const { fetchFile } = await import("@ffmpeg/ffmpeg");
  if (!applyEnabled.value || !originVideo.value) {
    return;
  }
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  // pro会员专属的功能
  if (!isVipPro.value) {
    messageBox.error(null, t("errCode-300601"));
    return;
  }
  const name = originVideo.value.name;
  const suffix = name.split(".")[1];
  const tt = new Date().getTime();
  const outputFullName = `${tt}.${targetVideoType.value}`;

  const ffmpeg = ffmpegRef.value;
  fullLoading.show(
      `${t("enhance-upload-loading")}(0%)`,
      t("enhance-process")
    );
  try {
    ffmpeg.FS("writeFile", name, await fetchFile(originVideo.value));
    switch (targetVideoType.value) {
      case "mov":
      case "mp4":
        await ffmpeg.run(
          "-i",
          name,
          "-c:v",
          "libx264",
          "-c:a",
          "aac",
          outputFullName
        );
        break;
      case "webm":
        await ffmpeg.run(
          "-i",
          name,
          "-c:v",
          "libvpx-vp9",
          "-crf",
          "28",
          "-b:v",
          "0",
          "-c:a",
          "libopus",
          outputFullName
        );
        break;
      case "avi":
        await ffmpeg.run("-i", name, outputFullName);
        break;
      default:
        await ffmpeg.run(
          "-i",
          name,
          "-c:v",
          "libx264",
          "-c:a",
          "aac",
          outputFullName
        );
        break;
    }
    console.log("转换完成");
    // 压缩所完成，   读文件  压缩后的文件名称为 put.mp4
    const data = ffmpeg.FS("readFile", outputFullName);
    // 转换压缩后的视频格式  当前为 blob 格式
    outputVideo.value = await transToFile(data, outputFullName, suffix);
    if (outputVideo.value) {
      outputUrl.value = getObjectURL(outputVideo.value);
      console.log(outputUrl.value);
      fullLoading.hide();
      if (
        videoPlayer.value &&
        ["mp4", "webm"].includes(targetVideoType.value)
      ) {
        preViewAbled.value = true;
        videoPlayer.value.src = outputUrl.value;
        videoProcessCtrRef.value?.setSeed(0);
      } else {
        preViewAbled.value = false;
      }

      window.dispatchEvent(
        new CustomEvent("downloadUrlEvent", {
          detail: outputUrl.value,
        })
      );
    }
  } catch (err) {
    console.log("处理异常");
    fullLoading.hide()
    messageBox.error(null, t("enhance-catch"));
  }
}
async function transToFile(
  data: Uint8Array,
  fileFullName: string,
  fileType: string = "mp4"
) {
  console.log(data);

  // 转换bolb类型
  const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  // 这么写是因为文件转换是异步任务
  const blobToFile = async (blob: Blob, fileName: string, type: string) => {
    return new window.File([blob], fileName, { type });
  };
  return blobToFile(blob, fileFullName, getMIME(fileType));
}
function onDownload() {
  if (outputUrl.value) {
    window.dispatchEvent(
      new CustomEvent("downloadImgEvent", {
        detail: {url:outputUrl.value},
      })
    );
  }
}
function onPlay() {
  videoPlayer.value?.play();
}
function onPause() {
  videoPlayer.value?.pause();
}
function updateSeed(val: number) {
  if (videoPlayer.value) {
    videoPlayer.value.currentTime = totalDuration.value * val;
  }
}
function timeupdate() {
  if (videoPlayer.value) {
    currentTime.value = videoPlayer.value.currentTime;
  }
}
function loadedmetadata() {
  if (videoPlayer.value) {
    totalDuration.value = videoPlayer.value.duration; // 获取视频总时长
    console.log(videoPlayer.value.videoWidth, videoPlayer.value.videoHeight);
  }
}
function originError(e: Event) {
  console.log("originError", e);
}
</script>
