<template>
  <div class="w-full h-full m-auto relative select-none overflow-hidden" ref="canvasRef" @mouseleave="mouseleave"
    @mouseenter="isEnter = true">
    <div class=" overflow-hidden flex items-center justify-center relative h-full" @mousedown="onmouseDown2">
      <div ref="img-contain" class="w-full h-full relative  flex justify-center items-center scale-[1] "
        :style="`clip-path: polygon(0 0,${imgLeft}px 0,${imgLeft}px 100%, 0 100%);transform: scale(1);`">
        <div animation class="w-full h-full shine" v-if="!originImgLoad"></div>
        <div v-else class="w-full h-full bg-no-repeat bg-cover bg-center"
          :style="`background-image: url(${origin});transform: scale(${scaleZoom / 100}) translate(${translate.x}px,${translate.y}px)`">
        </div>
        <div v-if="props.labebType == 1"
          class="text-text-white absolute top-5 rounded-2 h-8 leading-8 px-3 text-text-deep rounded-full text-base w-max left-5 border_befoe">
          {{ $t("before") }}</div>

      </div>
      <div class="w-full h-full  absolute left-0 top-0 right-0 bottom-0"
        :style="`clip-path: polygon(${imgLeft}px 0,100% 0,100% 100%, ${imgLeft}px 100%);transform: scale(1);`">
        <div animation class="w-full h-full shine" v-if="!enhanceLoad"></div>
        <div v-else class="w-full h-full bg-no-repeat bg-cover bg-center"
          :style="`background-image: url(${enhance});transform: scale(${scaleZoom / 100}) translate(${translate.x}px,${translate.y}px)`">
        </div>
        <div v-if="props.labebType == 1"
          class="absolute top-5 bg-mainColor-deep rounded-2 h-8 leading-8 px-3 text-text-white rounded-full text-base w-max right-5 border border_after">
          {{ $t("after") }}</div>

      </div>
    </div>
    <div v-show="device.isMobileOrTablet || isDown || isImgDown"
      class=" absolute w-full h-full top-0 left-0 z-[5] cursor-grab " @mousemove="mousemove" @touchmove="touchmove"
      ref="moveRef" @mouseup="onmouseup" />

    <div ref="move" class=" absolute top-0 bottom-0 z-[4] cursor-grab select-none w-[32px] flex flex-col items-center"
      :style="{ left: (pointer - moveHalfWidth) + 'px' }" @mousedown="onmouseDownMove">
      <div class=" relative top-0 left-0 h-full w-[2px] bg-[#F7F7F8]">
        <svg class=" absolute bottom-[5%] -translate-x-1/2" width="32" height="32" viewBox="0 0 32 32" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="white" />
          <path d="M20 12L24 16L20 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M12 12L8 16L12 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <div v-if="props.labebType == 0"
          class=" absolute top-5 bg-[#1f1f1f98] rounded-lg h-8 leading-8 px-3 text-white text-base w-max right-5">{{
            $t("before") }}</div>
        <div v-if="props.labebType == 0"
          class="absolute top-5 bg-[#1f1f1f98] rounded-lg h-8 leading-8 px-3 text-white text-base w-max left-5">{{
            $t("after") }}</div>
      </div>
    </div>

  </div>
</template>
<script setup>

const device = useDevice();
const props = defineProps({
  origin: {
    type: String,
    default: "",
  },
  enhance: {
    type: String,
    default: "",
  },
  minScale: {
    type: Number,
    default: 50,
  },
  maxScale: {
    type: Number,
    default: 250,
  },
  step: {
    type: Number,
    default: 10,
  },
  canScale: {
    type: Boolean,
    default: false,
  },
  canDrap: {
    type: Boolean,
    default: false,
  },
  labebType: {
    type: Number,
    default: 0,
  },
  animation: {
    type: Boolean,
    default: false,
  },
  autoAnimation: {
    type: Boolean,
    default: false,
  },
});
const pointer = ref(0);
const imgLeft = ref(0);
const isDown = ref(false);
const moveHalfWidth = ref(0);
const clientWidth = ref(0);
const translate = ref({ x: 0, y: 0 });
const scaleZoom = ref(100);
const moveX = ref(0);
const moveY = ref(0);
const isImgDown = ref(false);
const lastTouchEnd = ref(0);
const isEnter = ref(false);
const canvasRef = ref(null);
const moveRef = ref(null);
const direction = ref(1)
const animationTimer = ref()
const originImgLoad = ref(false)
const enhanceLoad = ref(false)
const emit = defineEmits(["scaleChange"]);
defineExpose({ setScale })
onMounted(async () => {
  if (props.canScale) {
    canvasRef.value.addEventListener('wheel', handleMouseWheel)
  }
  if (props.canDrap) {
    moveRef.value.addEventListener('mousemove', mousemove)
  }
  init();
  await nextTick()
  // 禁止右键，防止直接复制图片地址
  const canvas = canvasRef.value
  if (!device.isMobile) {
    if (canvas) {
      // console.log('canvas',canvas);
      canvasRef.value.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }

  }
  if (props.animation) {
    // console.log('开始动画');
    moveAnimation()
  }
  loadOrigin()
  loadEnhance()
});
onUnmounted(() => {
  if (props.animation) {
    clearInterval(animationTimer.value)
  }
})
watch(() => props.enhance, () => {
  if (props.animation) {
    clearInterval(animationTimer.value)
    moveAnimation()
  }
  loadEnhance()
})
watch(() => props.autoAnimation, () => {
  if (props.autoAnimation) {
    clearInterval(animationTimer.value)
    moveAnimation()
  }
})
watch(() => props.origin, () => {
  loadOrigin()
})
function loadOrigin() {
  originImgLoad.value = false
  // 创建一个新的Image对象
  var img = new Image();
  // 设置图片的URL
  img.src = props.origin;
  // 添加load事件监听器
  img.addEventListener('load', function () {
    console.log('图片加载完成');
    originImgLoad.value = true
  });

  // 添加error事件监听器
  img.addEventListener('error', function () {
    console.error('图片加载失败');
    // 在这里处理图片加载失败的情况
  });
}
function loadEnhance() {
  enhanceLoad.value = false
  // 创建一个新的Image对象
  var img = new Image();
  // 设置图片的URL
  img.src = props.enhance;
  // 添加load事件监听器
  img.addEventListener('load', function () {
    console.log('图片加载完成');
    enhanceLoad.value = true
  });

  // 添加error事件监听器
  img.addEventListener('error', function () {
    console.error('图片加载失败');
    // 在这里处理图片加载失败的情况
  });
}
function moveAnimation() {
  animationTimer.value = setInterval(() => {
    if (!canvasRef.value) {
      return
    }
    const left = imgLeft.value + direction.value * 5;
    // console.log('left：',left);

    if (direction.value > 0 && (left + moveHalfWidth.value) > canvasRef.value.offsetWidth) {
      direction.value = -1
      return
    }
    if (direction.value < 0 && left < canvasRef.value.offsetWidth / 2) {
      direction.value = 1
      clearInterval(animationTimer.value)
    }
    imgLeft.value = left
    pointer.value = left
  }, 20)
}
function init() {
  clientWidth.value = document.documentElement.clientWidth;
  moveHalfWidth.value = 16;
  imgLeft.value = canvasRef.value.offsetWidth / 2;
  pointer.value = canvasRef.value.offsetWidth / 2;
}
const offset = computed(() => {
  return clientWidth.value - canvasRef.value.offsetWidth;
});
function mousemove(e) {
  if (isDown.value) {
    if (
      e.offsetX < moveHalfWidth.value ||
      e.offsetX + moveHalfWidth.value > canvasRef.value.offsetWidth
    ) {
      console.log(e.offsetX, moveHalfWidth.value);
      isDown.value = false;

      return;
    }
    // canvas.value = e.offsetX - moveHalfWidth.value;
    pointer.value = e.offsetX;
    imgLeft.value = e.offsetX;
  } else if (isImgDown.value) {
    if (!props.canDrap) return;

    const posX = e.offsetX - moveX.value;
    const posY = e.offsetY - moveY.value;
    translate.value = {
      x: posX,
      y: posY,
    };
  }
}
function touchmove(e) {
  e.preventDefault();
  const offsetX = e.touches[0].clientX - offset.value;
  console.log('touchmove', isDown.value, isImgDown.value);
  if (
    offsetX < moveHalfWidth.value ||
    offsetX + moveHalfWidth.value > canvasRef.value.offsetWidth
  ) {
    console.log(e.offsetX, moveHalfWidth.value);
    isDown.value = false;

    return;
  }
  // canvas.value = e.offsetX - moveHalfWidth.value;
  pointer.value = offsetX;
  imgLeft.value = offsetX;

}
function onmouseDownMove(e) {
  e.preventDefault();
  isDown.value = true;
}
function onmouseDown2(e) {
  e.preventDefault();
  isImgDown.value = true;

  moveX.value = e.offsetX;
  moveY.value = e.offsetY;
}
function onmouseup(e) {
  e.preventDefault();
  isDown.value = false;
  isImgDown.value = false;
}


function setScale(scale) {
  scaleZoom.value = scale;
  if (scale == 100) {
    translate.value = {
      x: 0,
      y: 0,
    };
    init();
  }
}
function mouseleave() {
  console.log('mouseleave');

  isDown.value = false;
  isImgDown.value = false;
  isEnter.value = false;
}
function handleMouseWheel(e) {
  e.preventDefault()
  if (props.canScale) {
    if (!isEnter.value) return;
    // 禁止页面滚动
    // e.preventDefault()
    let scale = scaleZoom.value;
    console.log(e.wheelDelta);

    if (e.wheelDelta) {
      // 判断浏览器IE，谷歌滑轮事件
      if (e.wheelDelta > 0) {
        scale += props.step;
        checkScale(scale);
      }
      if (e.wheelDelta < 0) {
        // 当滑轮向下滚动时
        scale -= props.step;
        checkScale(scale);
      }
    } else if (e.detail) {
      // Firefox滑轮事件
      if (e.detail > 0) {
        // 当滑轮向下滚动时
        scale -= props.step;
        checkScale(scale);
      }
      if (e.detail < 0) {
        // 当滑轮向上滚动时
        scale += props.step;
        checkScale(scale);
      }
    }
  }
}
function checkScale(scale) {
  console.log('scale', scale);
  if (scale >= props.minScale && scale <= props.maxScale) {
    scaleZoom.value = scale;
    emit("scaleChange", scaleZoom.value);
  }
}
</script>
<style scoped>
.shine {
  background: #131313;
  background-image: linear-gradient(90deg, #131313 0, #2d2d2d 20%, #131313 40%, #131313);
  background-repeat: no-repeat;
  background-size: 200% 200%;
  display: inline-block;
  position: relative;
  animation: placeholderShimmer 1s forwards linear infinite;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 100% 0
  }

  to {
    background-position: -100% 0
  }
}

.border_after {
  border: 1px solid rgba(199, 199, 199, 0.267);
}

.border_befoe {
  background-color: rgba(0, 0, 0, 0.356);
}
</style>
