<template>
    <div class="relative w-full h-screen-minus-80 flex items-center justify-center overflow-x-auto">
        <div class="h-full relative w-[340px]">
            <div
                class="flex border flex-col justify-between sticky h-screen-minus-80 w-[340px] items-center bg-white overflow-y-auto custom-scrollbar">
                <div class="grid grid-cols-2 gap-2  relative">
                    <div class="mt-8 col-span-2 h-[250px]">
                        <div class="Roboto-Bold-20">{{ $t('enhance-Art-Generator-title') }}</div>
                        <div class="Roboto-Bold-14 mt-4"><span class="required">*</span>
                            {{ $t('enhance-Description') }}</div>
                        <EnhanceTextPrompt v-model="prompt" ref="textPromptRef" class="h-[170px] mt-2" enhance
                            :placeholder="$t('prompt-placeholder')"></EnhanceTextPrompt>
                    </div>
                    <div class="mt-4 col-span-2">
                        <span class="Roboto-Bold-14">
                            {{ $t('enhance-Ratio') }}
                        </span>
                        <div class="grid grid-cols-3 gap-4 mt-2">
                            <div @click="onClickRatio(0)" :class="{ 'bg-mainColor-deep-8': selectedRatioIndex === 0 }"
                                class="p-2 w-20 h-16 bg-light-gray-bg rounded-lg flex items-center justify-center border border-gray-200 grid place-items-center hover:scale-110">
                                <div class="w-5 h-5 bg-white border border-gray-300"></div>
                                <div class="Roboto-12 text-text-deep">1:1</div>
                            </div>
                            <div @click="onClickRatio(2)" :class="{ 'bg-mainColor-deep-8': selectedRatioIndex === 2 }"
                                class="p-2 w-20 h-16 bg-light-gray-bg rounded-lg flex items-center justify-center border border-gray-200 grid place-items-center hover:scale-110">
                                <div class="w-7 h-5 bg-white border border-gray-300"></div>
                                <div class="Roboto-12 text-text-deep">4:3</div>
                            </div>
                            <div @click="onClickRatio(1)" :class="{ 'bg-mainColor-deep-8': selectedRatioIndex === 1 }"
                                class="p-2 w-20 h-16 bg-light-gray-bg rounded-lg flex items-center justify-center border border-gray-200 grid place-items-center hover:scale-110">
                                <div class="w-5 h-7 bg-white border border-gray-300"></div>
                                <div class="Roboto-12 text-text-deep">3:4</div>
                            </div>
                            <div @click="onClickRatio(3)" :class="{ 'bg-mainColor-deep-8': selectedRatioIndex === 3 }"
                                class="w-20 h-16 bg-light-gray-bg rounded-lg flex items-center justify-center border border-gray-200 grid place-items-center hover:scale-110">
                                <div class="w-5 h-10 bg-white border border-gray-300"></div>
                                <div class="Roboto-12 text-text-deep">9:16</div>
                            </div>
                            <div @click="onClickRatio(4)" :class="{ 'bg-mainColor-deep-8': selectedRatioIndex === 4 }"
                                class="p-2 w-20 h-16 bg-light-gray-bg rounded-lg flex items-center justify-center border border-gray-200 grid place-items-center hover:scale-110">
                                <div class="w-10 h-5 bg-white border border-gray-300"></div>
                                <div class="Roboto-12 text-text-deep">16:9</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 col-span-2">
                        <span class="Roboto-Bold-14">
                            {{ $t('enhance-output') }}
                        </span>
                        <a-input-number v-model="picNum" class="Roboto-Bold-12"
                            :style="{ width: '90px', float: 'right', }" mode="button" size="small" :max="4" :min="1" />
                    </div>
                    <div class="mt-4 col-span-2">
                        <div class="Roboto-Bold-14">{{ $t('enhance-style') }}</div>
                        <div class="grid grid-cols-3 gap-4 flex-1 flex mt-4">
                            <div class="cursor-pointer relative inline-block hover:scale-110 h-[84px] w-[82px]"
                                v-for="(item, index) in styleList"
                                :class="{ 'selected': selectedImageIndex === index + 1 }" :key="index"
                                :value="item.name" @click="onClickImg(item.name, index)">
                                <img class="rounded-lg shadow border h-[80px] w-[80px]" :src="item.src"
                                    alt="AI Matching Image">
                                <div style="background: linear-gradient(to bottom, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 100%)"
                                    class="text-xs rounded-lg absolute bottom-0 left-0 text-white text-center h-[30px] w-full grid place-items-center">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-24 col-span-2 flex items-center justify-center">
                        <a-collapse style="width: 280px;border-radius: 20px;" :default-active-key="['1']"
                            expand-icon-position="right">
                            <a-collapse-item header="Claim" key="1">
                                <template #header>
                                    <div class="flex items-center">
                                        <img class="w-5 h-5 mr-4" src="/assets/images/functype/AIGenerator/Frame.png"
                                            alt="">
                                        <div>{{ $t('enhance-Claim') }}</div>
                                    </div>
                                </template>
                                <div class="Roboto-12 text-text-gray">{{ $t('enhance-Claim-ph') }}
                                </div>
                            </a-collapse-item>
                        </a-collapse>
                    </div>

                </div>
            </div>
            <div style="background-color: rgba(255 255 255 / 94%)"
                class="h-[88px] w-[340px] p-5 bottom-0 left-0 right-0 absolute">
                <EnhanceGenerate @click="onApply" :enable="true" :picNum="picNum">
                </EnhanceGenerate>
            </div>
        </div>
        <div v-if="historyList.length > 0"
            class="relative h-4/5 flex-1 flex items-center justify-center -translate-y-6">
            <div class="relative w-[90%] h-full shadow-1 flex items-center justify-center rounded-xl">
                <EnhanceImageView ref="imageViewRef" :minScale="minScale" :maxScale="maxScale" :url="srcImg.enhanceUrl"
                    @scaleChange="imageViewChange"></EnhanceImageView>
                <img class="mt-4 mr-2 w-6 h-6 absolute right-0 top-0 cursor-pointer"
                    src="/assets/images/functype/AIGenerator/download.png" alt="" @click="downloadImage" />
                <img class="mt-4 mr-2 w-6 h-6 absolute right-0 top-10 cursor-pointer"
                    src="/assets/images/functype/AIGenerator/Delete.png" alt="" @click="deleteImage" />
                <EnhanceGiveALike class="absolute right-0 bottom-4"
                    v-show="(fileModel ? fileModel[0]?.isEvaluate : false) && curIndex == 0"
                    :id="historyList[0].enhanceRecordId" @onEvaluate="onEvaluate">
                </EnhanceGiveALike>
                <div
                    class="absolute left-1/2 -bottom-16 -translate-x-1/2 bg-white shadow-6 h-[50px] rounded-[20px] px-4 w-max">
                    <EnhanceBaseControls class="flex-1 flex justify-center" ref="baseControlsRef" :minScale="minScale"
                        :maxScale="maxScale" @change="baseCtlScaleChange" @changeMove="baseCtlChangeMove">
                    </EnhanceBaseControls>
                </div>
            </div>

        </div>
        <div class="w-full h-full flex items-center justify-center" v-else>
            <div class="h-full flex flex-col items-center gap-4 px-10 pb-6">
                <div class="relative flex-1 flex flex-col items-center justify-center">
                    <div class="h-[80%] flex flex-col">
                        <div class="h-full w-full max-h-[430px] max-w-[740px]"
                            style="background-image: url('/assets/images/functype/AIGenerator/Generator.webp');background-size: contain;background-repeat: no-repeat;background-position: center;">
                        </div>
                        <div class="">
                            <div class="text-center Roboto-Bold-32 mt-4">{{ $t('enhance-Art-Generator-text-1') }}</div>
                            <div class="text-center Roboto-16 text-text-gray mt-4">
                                {{ $t('enhance-Art-Generator-text-2') }}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="Roboto-Bold-16 border rounded-3xl bg-white h-[276px] p-3">
                    <div class="w-full mt-4">
                        {{ $t('enhance-Art-Generator-No-inspiration') }}:
                    </div>
                    <div class="flex items-center justify-center mt-4 ">
                        <Vue3Marquee :duration="70" :pauseOnHover="true">
                            <div v-for="(img, index) in imgArray" :key="img" class="relative group">
                                <img :src="img"
                                    class="bg-cover bg-center shrink-0 h-44 w-64 mx-4 border-2 rounded-3xl border-current" />
                                <div style="background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);"
                                    class="group-hover:opacity-100 opacity-0 group-hover:bg-black rounded-3xl  absolute top-0 left-4 w-[256px] h-1/2 flex items-start justify-center text-white px-8 pt-4 Roboto-12 line-clamp-3">
                                    <div
                                        style="-webkit-line-clamp: 3; -webkit-box-orient: vertical; display: -webkit-box; overflow: hidden;">
                                        {{ inspiration[index].des }}
                                    </div>
                                </div>
                                <button @click="autoWriteWord(index)"
                                    class="group-hover:scale-105 shadow-md bg-white rounded-full absolute bottom-8 left-1/2 transform -translate-x-1/2 translate-y-1/2 group-hover:opacity-95 opacity-60 p-2">{{
                                        $t('enhance-Art-Generator-Use-Prompt') }}</button>
                            </div>
                        </Vue3Marquee>
                    </div>
                </div>
            </div>
        </div>
        <EnhanceFold v-if="historyList.length > 0" :isFold="isFold" @change="foldChange">
            <div class=" h-full py-8 flex flex-col overflow-hidden transition-all delay-150"
                :class="isFold ? 'pl-6 pr-2' : 'opacity-0'">
                <div class="flex items-center justify-between w-full mb-6 pr-4">
                    <div class="flex flec-col items-center">
                        <span class="Roboto-Bold-20 text-black w-[140px]">{{ $t('enhance-All-Generate') }}</span>
                        <a-tooltip :contentStyle="{ 'border-radius': '15px', 'background-color': '#1F1F1FC5' }"
                            :content="$t('Enhance-Tips')" position="left">
                            <img class="ml-2 w-5 h-5" src="/assets/images/functype/AIGenerator/tips.png" />
                        </a-tooltip>
                    </div>
                    <div class="flex flec-col">
                        <span class="Roboto-16 text-text-gray mr-16 mt-1">{{ getFilesTotal(historyList) }}</span>
                        <a-dropdown position="bottom">
                            <img class="mr-12 w-6 h-6 absolute -right-0 top-18 cursor-pointer"
                                src="/assets/images/functype/AIGenerator/more.png" alt="">
                            <template #content>
                                <a-doption @click="onDeleteAll" :disabled="historyList.length == 0">
                                    {{ $t("enhance-All-Delete") }}
                                </a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>
                <div class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4"
                    style="max-height: calc(100vh - 196px)">
                    <div v-for="(items, index) in historyList" :key="items.enhanceRecordId"
                        :value="items.enhanceRecordId">
                        <div v-if="items.imgList || items.enhanceUrl" class="Roboto-12 text-text-gray flex ">
                            <div class="flex-1">{{ getHistoryTime(items.createTime) }}</div>
                            <img class="w-4 h-4 mr-2 cursor-pointer opacity-70"
                                src="/assets/images/functype/AIGenerator/download.png" alt=""
                                @click="downloadBatchImage(items)" />
                        </div>
                        <div v-if="items.enhanceUrl == null" class="grid grid-cols-2">
                            <div class="m-2 flex justify-center items-center" v-for="(item, i) in items.imgList"
                                :key="item.recordFaceId" :value="item.recordFaceId">
                                <div
                                    :class="index == curIndex && i == isSelect ? 'border-mainColor-deep border-[2px] p-1 shadow-md rounded-lg' : ''">
                                    <img @click="selectImg(item, index, i)" @error="reloadImage(item.enhanceUrl)"
                                        class="cursor-pointer shadow-md rounded-lg w-full h-3/4" :src="item.enhanceUrl"
                                        alt="">
                                </div>
                            </div>
                        </div>
                        <div v-else class="grid grid-cols-2">
                            <div class="m-2 flex justify-center items-center">
                                <div
                                    :class="index == isSelects ? 'border-mainColor-deep border-[2px] p-1 shadow-md rounded-lg' : ''">
                                    <img @click="selectImg(items, index, null)"
                                        class="cursor-pointer shadow-md rounded-lg w-full h-3/4" :src="items.enhanceUrl"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="historyList.length == 0" class="h-full w-full flex justify-center items-center">
                        <EnhanceNoRecords></EnhanceNoRecords>
                    </div>
                </div>
            </div>
        </EnhanceFold>
        <BaseModal :title="$t('dialog-delete-title')" :content="$t('dialog-delete-all-desc', { maxWidth })"
            :visible="sizeLimitVisible" @close="sizeLimitCancel">
            <div>
                <span
                    class="bg-text-deep inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white mr-3"
                    @click="sizeLimitCancel">{{ $t("cancel") }}</span>
                <span class="bg-red-500 inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white"
                    @click="sizeLimitOk">{{ $t("modal-size-limit-ok") }}</span>
            </div>
        </BaseModal>
        <BaseModal :title="$t('dialog-delete-all-title')" :content="$t('dialog-delete-all-desc', { maxWidth })"
            :visible="visible" @close="onCancel">
            <div>
                <span
                    class="bg-text-deep inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white mr-3"
                    @click="onCancel">{{ $t("cancel") }}</span>
                <span class="bg-red-500 inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white"
                    @click="onOk">{{ $t("modal-size-limit-ok") }}</span>
            </div>
        </BaseModal>
        <BaseModal :title="$t('modal-switch-title')" :content="!isNormalVip ? $t('modal-des-1') : $t('modal-des-2')"
            :visible="visibleModal" @close="visibleModal = false">
            <div>
                <span class="bg-black inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white"
                    @click="okModal">{{ !isNormalVip ? $t("modal-des-btn") : $t("modal-png-ok") }}</span>
            </div>
        </BaseModal>
    </div>
</template>
<script setup>
import {
    minScale,
    maxScale,
    imageAccept,
    maxUploadCount,
} from "@/utils/config";
import { Vue3Marquee } from 'vue3-marquee';
import { useUploadStore } from "@/store/upload";
import { useMainStore } from "@/store/index";
const uploadStore = useUploadStore();
const { $encryptor } = useNuxtApp();
const { isLogin, isVip, isVipPro, isNormalVip } = useUserInfo();
const props = defineProps({
    funcType: {
        type: String,
        default: "",
    },
    fileList: {
        type: Array,
        default: [],
    },
    currIndex: {
        type: Number,
        default: 0,
    },
    isFinish: {
        type: Boolean,
        default: false,
    },
    applyEnabled: {
        type: Boolean,
        default: false,
    },
});
const inspiration = ref([
    { key: 0, des: 'black cat，trendy girl，20 years old，looking at the camera，half-length photo，cyberpunk， black background，fluorescent green，oc rendering，3d，portrait photography' },
    { key: 0, des: 'Creative composition, miniature landscape shooting, very American retro color, a huge pair of colored sunglasses placed in a summer beach miniature scene, miniature people and beach chairs, resort hotels, seaside, realism, color level, depth of field, high quality, ultra-fine details, shot by Hasselblad' },
    { key: 0, des: 'a young female in front of a bunch of flowers withlots of petals, in the style of martine johanna,8k,hyper-realistic representation, blue sky, close-up,dreamlike illustration' },
    { key: 0, des: 'A fresco of a close-up portrait featuring a gigantic colorful Chinese White arctic foxFresco ， in the style of unique yokai illustrations, nightcore, dark gray and orange, detailed skies, floralpunk, low resolution, light cyan and red，artwork by Alphonse Mucha, Arthur Rackham, James Jean, and Victo Ngai' },
    { key: 0, des: 'a motorized bicycle character wearing glasses and a helmet, wearing a dark green hoodie grunge beauty, mixing patterns, text and emoji devices, charming character illustration' },
    { key: 0, des: 'the cat is wearing sunglasses, in the style of octane render, neon realism, hdr, photo-realistic techniques, dark amber, redshift, softbox lighting' },
])
const { locale, t } = useI18n();
const fullLoading = useFullLoading();
const {
    downloadUrlEvent, downloadImgEvent
} = useCustomEvent();
const styleList = ref([
    { id: 1, name: 'General', src: "/assets/images/functype/AIGenerator/General.png" },
    { id: 2, name: 'Photography', src: "/assets/images/functype/AIGenerator/Photography.png" },
    { id: 3, name: 'Anime', src: "/assets/images/functype/AIGenerator/Anime.png" },
    { id: 4, name: 'Abstract', src: "/assets/images/functype/AIGenerator/Abstract.png" },
    { id: 5, name: 'Fantasy', src: "/assets/images/functype/AIGenerator/Fantasy.png" },
    { id: 6, name: 'Retro', src: "/assets/images/functype/AIGenerator/Retro.png" },
    { id: 7, name: 'Cyberpunk', src: "/assets/images/functype/AIGenerator/Cyberpunk.png" },
    { id: 8, name: 'Cartoon', src: "/assets/images/functype/AIGenerator/Cartoon.png" },
    { id: 9, name: '3D', src: "/assets/images/functype/AIGenerator/3D.png" },
    { id: 10, name: 'Illustration', src: "/assets/images/functype/AIGenerator/Illustration.png" },
    { id: 11, name: 'Oilpainting', src: "/assets/images/functype/AIGenerator/OilPainting.png" },
    { id: 12, name: 'ChineseStyle', src: "/assets/images/functype/AIGenerator/Chinese-style.png" }
])
const mainStore = useMainStore();
function showLogin() {
    mainStore.setVisibleLogin(true);
}
function foldChange() {
    isFold.value = !isFold.value;
}
function imageViewChange(scale) {
    baseControlsRef.value.setScale(scale);
}

const isFold = ref(true);
// const loading = ref(false);
const smearRef = ref(null);
const fileModel = ref(null);
const imageViewRef = ref(null);
const selectedRatioIndex = ref(0)
const selectedImageIndex = ref(1)

const textPromptRef = ref(null)
const onClickRatio = (index) => {
    selectedRatioIndex.value = index;
}
const onClickImg = (res, index) => {
    selectedImageIndex.value = index + 1;
    stylePath.value = res
}
function baseCtlChangeMove(isMove) {
    smearRef.value.setMove(scale);
}
const baseControlsRef = ref(null);
function baseCtlScaleChange(scale) {
    if (imageViewRef.value) {
        imageViewRef.value.setScale(scale);
    }
}
watch(
    () => props.fileList, () => {
        if (props.fileList.length) {
            fileModel.value = props.fileList[props.currIndex];
        } else {
            fileModel.value = null;
        }
    },
    { deep: true }
);

function autoWriteWord(index) {
    prompt.value = inspiration.value[index].des
}
async function onEvaluate() {
    fileModel.value[0].isEvaluate = true;
    window.dispatchEvent(
        new CustomEvent("updateFileListEvent", {
            detail: fileModel.value,
        })
    );
    // fileModel.value[0].isEvaluate = false
    // await useApi.imgEvaluate({ enhanceRecordId: historyList.value[0].enhanceRecordId, evaluate: type }).then((res) => {
    // })
}
const curIndex = ref(0)
const isSelect = ref(0)
const isSelects = ref(0)
const srcImg = ref('')
function selectImg(img, index, i) {
    img = img
    srcImg.value = img;
    curIndex.value = index;
    isSelect.value = i;
    isSelects.value = index;
    // if (fileModel.value[0] && index == 0) {
    //     fileModel.value[0].isEvaluate = true
    // } else {
    //     fileModel.value[0].isEvaluate = false
    // }
}
const visible = ref(false)
const sizeLimitVisible = ref(false)
async function deleteImage() {
    sizeLimitVisible.value = true
}
async function sizeLimitOk(params) {
    fullLoading.show("loading...");
    if (historyList.value[curIndex.value].imgList != null) {
        const recordFaceId = historyList.value[curIndex.value].imgList[isSelect.value].recordFaceId;
        await useApi.delOneAIHistory(recordFaceId).then((res) => {
            if (res.code == 0) {
                getHistoryRecord();
                sizeLimitCancel();
            } else {
                fullLoading.hide();
            }
        })
    } else if (historyList.value[curIndex.value].enhanceUrl) {
        const recordFaceId = historyList.value[curIndex.value].enhanceRecordId;
        await useApi.delOneAIHistoryV2(recordFaceId).then((res) => {
            if (res.code == 0) {
                getHistoryRecord();
                sizeLimitCancel();
            } else {
                fullLoading.hide();
            }
        })
    }
    fullLoading.hide();
}
async function onOk(params) {
    fullLoading.show("loading...");
    await useApi.delAllAIHistory(67).then((res) => {
        if (res.code == 0) {
            getHistoryRecord();
            onCancel();
        } else {
            getHistoryRecord();
            onCancel();
            fullLoading.hide();
        }
    }).catch((e) => {
        fullLoading.hide();
        messageBox.error(t("network-err"), t("network-err-try"));
    })
    fullLoading.hide();
}
function sizeLimitCancel(params) {
    sizeLimitVisible.value = false;
}
function onCancel(params) {
    visible.value = false;
}
async function onDeleteAll() {
    visible.value = true
}
const prompt = ref('')  //文字描述
const stylePath = ref("Photography")  //风格 1~12
const picNum = ref(4)  //图片数量1~4

async function onApply() {
    if (isLogin.value) {
        if (prompt.value != '') {
            const params = {
                enhanceCfgIds: [67],
                stylePath: stylePath.value,
                prompt: prompt.value,
                picNum: picNum.value,
                ratio: selectedRatioIndex.value

            }
            fullLoading.show(t("enhance-upload-process"), t("enhance-process"));
            await useApi.enhanceImg(params).then((result) => {
                if (result.code == 0) {
                    getImageListResult(result.data, 0);
                }
                if (result.code == 300601) {
                    fullLoading.hide()
                }
                if (result.code == 300612) {
                    // messageBox.error(t("question-failed"), t("errCode-300612"));
                    // errCode-use-limit-vip
                    if (!isVip.value) {
                        messageBox.error(t('errCode-use-limit'), null);
                        mainStore.setVisibleVipDialog(true);
                    } else {
                        messageBox.error(t('errCode-use-limit-vip'), null);
                    }
                    fullLoading.hide()
                }
            }).catch((e) => {
                messageBox.error(t("network-err"), t("network-err-try"));
                fullLoading.hide()
            });
        } else {
            if (textPromptRef.value.$refs['textareaRef']) {
                textPromptRef.value.$refs['textareaRef'].focus();
            }
        }
    } else {
        showLogin()
    }
}
const visibleModal = ref(false)
function okModal() {
    if (!isNormalVip.value) {
        mainStore.setVisibleVipDialog(true);
    }
    visibleModal.value = false
}
const queryResultTimeout = ref(null);
const imgGeneratorList = ref([]);
const oriImgGenerator = ref(null);
function getImageListResult(id, count) {
    if (count > 200) {
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        return;
    }
    useApi.getResult(id).then((res) => {
        const { imgList } = res.data;
        curIndex.value = 0
        isSelect.value = 0
        let isResult = true
        imgList.map(async (v) => {
            if (v.errorCode) {
                fullLoading.hide();
                isResult = false
                clearTimeout(queryResultTimeout.value);
                messageBox.error(t("enhance-process-fail"), t("network-err-try"));
            } else if (v.imgList) {
                oriImgGenerator.value = res.data
                fileModel.value = res.data.imgList;
                fileModel.value[0].isEvaluate = true
                imgGeneratorList.value = v.imgList
                imgGeneratorList.value.map((e) => {
                    e.enhanceUrl = decryptUrl(e.enhanceUrl)
                })
                await useApi.getAIHistoryToday(67).then((res) => {
                    if (res?.code === 0) {
                        historyList.value = res.data;
                        if (historyList.value[curIndex.value]) {
                            if (historyList.value[curIndex.value].imgList) {
                                srcImg.value = historyList.value[curIndex.value].imgList[isSelect.value];
                            }
                            if (historyList.value[curIndex.value].enhanceUrl) {
                                srcImg.value = historyList.value[curIndex.value];
                            }
                        }
                    }
                });
                isResult = false
                fullLoading.hide();
                clearTimeout(queryResultTimeout.value);
                updataWeekcount()
            } else if (v.enhanceUrl) {
                fileModel.value = res.data.imgList;
                fileModel.value[0].isEvaluate = true
                await useApi.getAIHistoryToday(67).then((res) => {
                    if (res?.code === 0) {
                        historyList.value = res.data;
                        if (historyList.value[curIndex.value]) {
                            if (historyList.value[curIndex.value].imgList) {
                                srcImg.value = historyList.value[curIndex.value].imgList[isSelect.value];
                            }
                            if (historyList.value[curIndex.value].enhanceUrl) {
                                srcImg.value = historyList.value[curIndex.value];
                            }
                        }
                    }
                    isResult = false
                    fullLoading.hide();
                    clearTimeout(queryResultTimeout.value);
                    updataWeekcount()
                });
            }
        })
        if (isResult) {
            queryResultTimeout.value = setTimeout(() => {
                clearTimeout(queryResultTimeout.value);
                getImageListResult(id, count + 1);
            }, 3000);
        }
    }).catch((e) => {
        fullLoading.hide();
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        console.error(e)
    })
}

function decryptUrl(url) {
    let enhanceUrl = "";
    if (url) {
        $encryptor?.setPrivateKey(privateKey);
        let part1 = url.substring(0, url.length / 2);
        let part2 = url.substring(url.length / 2);
        const dec1 = $encryptor?.decrypt(part1);
        const dec2 = $encryptor?.decrypt(part2);
        enhanceUrl = dec1 + dec2;
    }
    return enhanceUrl;
}
const reloadImage = (item) => {
    // 重新设置 imageSrc 为原始 URL，触发图片重新加载
    item = item
};
const imgArray = [
    "/assets/images/functype/AIGenerator/image1.png",
    "/assets/images/functype/AIGenerator/image2.png",
    "/assets/images/functype/AIGenerator/image3.png",
    "/assets/images/functype/AIGenerator/image5.png",
    "/assets/images/functype/AIGenerator/image6.png",
    "/assets/images/functype/AIGenerator/image8.png",
]
async function downloadImage() {
    // if (isVip.value) {
    //     let imageUrl = ""
    if (historyList.value[curIndex.value].imgList != null) {
        downloadImgEvent({ url: historyList.value[curIndex.value].imgList[isSelect.value].enhanceUrl })
    } else {
        downloadImgEvent({ url: historyList.value[curIndex.value].enhanceUrl })
    }
    //     try {
    //         // 使用 fetch 获取图片数据
    //         const response = await fetch(imageUrl);
    //         if (!response.ok) {
    //             messageBox.error(t("network-err"), t("network-err-try"));
    //         }
    //         // 将图片数据转换为 Blob
    //         const blob = await response.blob();

    //         // 创建一个下载链接
    //         const link = document.createElement("a");
    //         link.href = URL.createObjectURL(blob);
    //         if (blob.type == 'image/png') {
    //             link.download = "image.png";
    //         }
    //         if (blob.type == 'image/jpg') {
    //             link.download = "image.jpg";
    //         }
    //         document.body.appendChild(link);
    //         link.click(); // 触发下载
    //         document.body.removeChild(link); // 移除链接

    //         // 释放 Blob URL
    //         URL.revokeObjectURL(link.href);
    //     } catch (error) {
    //         messageBox.error(t("network-err"), t("network-err-try"));
    //     }
    // } else {
    //     mainStore.setVisibleVipDialog(true);
    // }
}
const downloadBatchImage = (items) => {
    if (items.imgList?.length > 1) {
        if (!isVipPro.value) {
            return visibleModal.value = true
        }
    }
    if (items.imgList) {
        fullLoading.show(t("enhance-downloading"));
        items.imgList.forEach(async (e, i) => {
            let url = e.enhanceUrl;
            if (!url) {
                return;
            }
            if (!isLogin.value) {
                uploadStore.setSignUpVisible(true);
                return;
            }
            const freeDownloadCount = mainStore.userInfo.freeDownloadCount
            const isFree = isVip.value || freeDownloadCount > 0;
            if (!isFree) {
                mainStore.setVisibleVipDialog(true);
                return;
            }
            if (freeDownloadCount > 0 && !isVip.value) {
                if (url.includes('@1m')) {
                    url = url.split('@1m')[0]
                }
            }
            if (!(await downloadNetwork(url))) {
                messageBox.error(t("network-err"), t("errCode-down-fail"));
            } else {
                if (freeDownloadCount > 0) {
                    console.log('更新');

                    useApi.freeDownloadCount().then((result) => {
                        if (result.code == 0) {
                            mainStore.updateUserInfo();
                        }
                    });
                }
            }
        });
        setTimeout(() => {
            fullLoading.hide();
        }, 2000);
    } else if (items.enhanceUrl) {
        downloadImgEvent({ url: items.enhanceUrl })
    } else {
        messageBox.error(t("network-err"), t("network-err-try"));
    }
};

const historyList = ref([])
const dayjs = useDayjs();

const getHistoryTime = (record) => {
    return dayjs(record).format("YYYY-MM-DD HH:mm:ss");
};

async function getHistoryRecord() {
    await useApi.getAIHistoryToday(67).then((res) => {
        if (res?.code === 0) {
            historyList.value = res.data;
            curIndex.value = 0
            isSelect.value = 0
            isSelects.value = 0
            if (historyList.value.length) {
                if (historyList.value[curIndex.value].imgList != null) {
                    srcImg.value = historyList.value[curIndex.value].imgList[isSelect.value];
                }
                if (historyList.value[curIndex.value].enhanceUrl) {
                    srcImg.value = historyList.value[curIndex.value];
                }
            }
        }
    }).catch((e) => {
    });
}
onMounted(() => {
    const images = [...uploadStore.images];
    if (images.length) {
        window.dispatchEvent(
            new CustomEvent("downloadUrlEvent", {
                detail: "",
            })
        );
    }
    window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
    if (isLogin.value) {
        setTimeout(() => {
            getHistoryRecord()
        }, 500);
    }

})
onUnmounted(() => {
    window.removeEventListener(
        "uploadLocalFilesSuccess",
        uploadLocalFilesSuccess
    );
});
function uploadLocalFilesSuccess(e) {
    console.log(e.detail);
    const files = e.detail;
    if (files.length) {
        window.dispatchEvent(
            new CustomEvent("downloadUrlEvent", {
                detail: "",
            })
        );
    }
}
function updataWeekcount() {
    mainStore.updateUserInfo();
    useApi.getWeekCount().then((res) => {
        if (res.code == 0) {
            mainStore.setWeekCount(res.data ?? 0);
        }
    });
}
function getFilesTotal(params) {
    if (params) {
        let total = 0
        params.forEach(element => {
            if (element.imgList == null && element.enhanceUrl) {
                total = total + 1
            } else {
                total = total + element.imgList.length
            }
        });
        return total;
    } else {
        return 0
    }
}




</script>

<style scoped>
.h-screen-minus-80 {
    height: calc(100vh - 80px);
}

.arco-textarea {
    background: #f1f1f1;
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #492dff7a, #c02cff73) 1;
    border-radius: 20px !important;
}

.selected {
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px #5F45FF;
    border-radius: 10px;
}

.arco-collapse-item-active>.arco-collapse-item-header {
    background-color: #F7F7F8
}

textarea:focus {
    border: 1px solid #C02CFF;
}

textarea {
    width: 100%;
    /* 16px */
    border: 2px solid #F7F7F8;
    background-color: #F7F7F8;
    /* 默认边框颜色 */
    border-radius: 0.5rem;
    text-align: left;
    line-height: normal;
    outline: none;
    /* 去掉默认的黑色外边框 */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.animate-rotate {
    animation: rotate 3s linear infinite;
}

.required {
    color: red;
    margin-right: 5px;
}
</style>