type downloadUrlEventParam = {
  url?: string;
  suffix?: string;
};
type ECCommonData = {
  origin?:File|null,
  enhance?: any
}
const customEventNames = {
  downloadUrlEvent: "downloadUrlEvent",
  updateFileListEvent: "updateFileListEvent",
  applyEvent: "applyEvent",
  uploadLocalFilesSuccessEvent: "uploadLocalFilesSuccess",
  taskFailRetryEvent: 'taskFailRetryEvent',
  downloadImgEvent: 'downloadImgEvent',
  updateLinkEvent:'updateLinkEvent',
  addFileListEvent: 'addFileListEvent',
  selectImgEvent: 'selectImgEvent',
  delImgEvent:'delImgEvent',
  updateUseInfoEvent:'updateUseInfoEvent',
  updateECommerceCommonEvent:'updateECommerceCommonEvent',
  cancelFuncTimerEvent:'cancelFuncTimerEvent',
  guideLoginEvent:'guideLoginEvent',
};
export function useCustomEvent() {
  // 控制导航栏上方下载按钮使能
  function downloadUrlEvent(detail: downloadUrlEventParam | string) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.downloadUrlEvent, {
        detail: detail,
      })
    );
  }
  function cleanMenuDownload() {
    downloadUrlEvent("");
  }
  // 更新通用文件列表
  function updateFileListEvent(detail: Array<any>) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.updateFileListEvent, {
        detail: detail,
      })
    );
  }
  // 应用、生成按钮
  function applyEvent(detail: Object) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.applyEvent, {
        detail: detail,
      })
    );
  }
  // 上传文件成功后，获取文件
  function uploadLocalFilesSuccessEvent(detail: Array<any>) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.uploadLocalFilesSuccessEvent, {
        detail: detail,
      })
    );
  }
  // 上传文件成功后，获取文件
  function taskFailRetryEvent(detail: Object) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.taskFailRetryEvent, {
        detail: detail,
      })
    );
  }
  // 下载图片
  function downloadImgEvent(detail: downloadUrlEventParam | string) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.downloadImgEvent, {
        detail: detail,
      })
    );
  }
  //本地连接被释放了，需要重新生成新链接，不然windows系统会有异常，mac正常
  function updateLinkEvent(detail: Object) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.updateLinkEvent, {
        detail: detail,
      })
    );
  }
  // 上传文件成功后，获取文件
  function addFileListEvent(detail: Array<File>) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.addFileListEvent, {
        detail: detail,
      })
    );
  }
  // 选择图片
  function selectImgEvent(detail: number) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.selectImgEvent, {
        detail: detail,
      })
    );
  }
  // 删除图片
  function delImgEvent(detail: number) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.delImgEvent, {
        detail: detail,
      })
    );
  }
  // 更新相关用户信息
  function updateUseInfoEvent(detail: any) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.updateUseInfoEvent, {
        detail: detail,
      })
    );
  }
  // 更新电商模块，通用的相关数据
  function updateECommerceCommonEvent(detail: ECCommonData) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.updateECommerceCommonEvent, {
        detail: detail,
      })
    );
  }
  // 取消通用处理这边的相关定时器,如任务查询等
  function cancelFuncTimerEvent() {
    window.dispatchEvent(
      new CustomEvent(customEventNames.cancelFuncTimerEvent, {
        detail: '',
      })
    );
  }
  // 首页右上角弹窗
  function guideLoginEvent(isShow: boolean = true) {
    window.dispatchEvent(
      new CustomEvent(customEventNames.guideLoginEvent, {
        detail: isShow,
      })
    );
  }
  return {
    customEventNames,
    downloadUrlEvent,
    cleanMenuDownload,
    updateFileListEvent,
    applyEvent,
    uploadLocalFilesSuccessEvent,
    taskFailRetryEvent,
    downloadImgEvent,
    updateLinkEvent,
    addFileListEvent,
    selectImgEvent,
    delImgEvent,
    updateUseInfoEvent,
    updateECommerceCommonEvent,
    cancelFuncTimerEvent,
    guideLoginEvent
  };
}
