<template>
  <div class="common-mask">
    <DialogLayout @close="onClose" :title="$t('sign-up')">
      <div>
        <div class="mb-3">
          <IvInput
            v-model="form.email"
            :placeholder="$t('email')"
            :errMsg="error.email"
          ></IvInput>
        </div>
        <div class="mb-3">
          <IvInput
            v-model="form.pwd"
            :placeholder="$t('password')"
            type="password"
            :errMsg="error.pwd"
            :tip="$t('sign-up-tip-pw-format')"
          ></IvInput>
        </div>
        <!-- <div class="mb-3">
          <IvInput
            v-model="form.rePwd"
            :placeholder="$t('sign-up-ph-confirm-password')"
            type="password"
            :errMsg="error.rePwd"
            :tip="$t('sign-up-tip-input-repw')"
          ></IvInput>
        </div> -->
        <div class="mb-3">
          <IvInput
            v-model="form.verifyCode"
            :placeholder="$t('verify-code')"
            type="text"
            :maxLength="8"
          >
          <template v-slot:suffix>
            <ASpin :loading="sendLoading">
              <div class=" border-l border-gray-line text-center px-4 cursor-pointer text-sm"  @click="onSendCode" :class="[isValidEmail && !isSend?'text-mainColor-deep':'text-text-gray']" >
                {{ isSend ? time + $t("seconds") : $t("send-code") }}
              </div>
            </ASpin>
          </template>

        </IvInput>
        </div>
        <div
          class="mb-3 h-10 rounded-full w-full font-Roboto-Bold font-bold text-base leading-10 text-white text-center"
          :class="
            disable
              ? ' bg-btn-gray cursor-not-allowed'
              : 'bg-gradient cursor-pointer'
          "
          @click="submit"
        >
          <icon-loading v-show="loading" class="mr-3" />{{ $t("login-sign-up") }}
        </div>
        <p class="text-text-deep text-xs mt-3 text-center">
        {{ $t('modal-sign-account') }}
            <span @click="onClose" class="text-mainColor cursor-pointer underline"
              >{{ $t('modal-sign-signIn') }}</span
            >
          </p>
      </div>
    </DialogLayout>
  </div>
</template>
<script setup lang="ts">
import { publicKey } from "@/utils/config";
import { verifyEmail, verifyPw } from "@/utils/verify";
import messageBox from "@/composables/messageBox";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const { t } = useI18n();
const { $encryptor } = useNuxtApp()
interface Form {
  email: string;
  pwd: string;
  // rePwd: string;
  verifyCode: string;
}
interface Error {
  email: string;
  pwd: string;
  // rePwd: string;
  verifyCode: string;
}
const sendTime = 60;
const time = ref(sendTime);
const form = ref<Form>({
  email: "",
  pwd: "",
  // rePwd: "",
  verifyCode: "",
});
const error = ref<Error>({
  email: "",
  pwd: "",
  // rePwd: "",
  verifyCode: "",
});
const loading = ref(false);
const isSend = ref(false);
const codeTimer = ref();
const sendLoading = ref(false);
const visibleSignUp = computed(() => mainStore.visibleSignUp);
const vipToLogin = computed(() => mainStore.vipToLogin);
watch(visibleSignUp, (n, o) => {
  if (n) {
    form.value.email = "";
    form.value.pwd = "";
    // form.value.rePwd = "";
    form.value.verifyCode = "";
    error.value.email = "";
    error.value.pwd = "";
    // error.value.rePwd = "";
    error.value.verifyCode = "";
    loading.value = false;
        isSend.value = false;
        time.value = sendTime;
  }
});
const isValidEmail = computed(() => !verifyEmail(form.value.email));

const disable = computed(() => {
  // if (!form.value.pwd.trim() || !form.value.email.trim()|| !form.value.rePwd.trim()|| !form.value.verifyCode.trim()) {
    if (!form.value.pwd.trim() || !form.value.email.trim()|| !form.value.verifyCode.trim()) {
    return true;
  }
  return false;
});
onMounted(()=>{
    // $encryptor?.setPublicKey(publicKey); // 设置公钥
    window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})
const handleKeyDown = (event:any) => {
  if (event.key === 'Enter') {
    console.log('Enter 键在 div 上被按下')
    submit()
    // 业务逻辑
  }
}
function onClose() {
      loading.value = false;
      mainStore.setVisibleSignUp(false);
      mainStore.setVisibleLogin(true)
      clearInterval(codeTimer.value);
    }
    function submit() {
      if (disable.value) {
        return;
      }
      switch (verifyEmail(form.value.email)) {
        case -1:
        // error.value.email = t("login-tip-empty-pw");
        // return;
        case -2:
          error.value.email = t("login-tip-invalid-email");
          return;
        default:
          error.value.email = "";
          break;
      }
      switch (verifyPw(form.value.pwd.trim())) {
        case -1:
        // error.value.email = t("login-tip-empty-pw");
        // return;
        case -2:
          error.value.pwd = t("sign-up-tip-pw-format");
          return;
        default:
          error.value.pwd = "";
          break;
      }
      // if (!form.value.pwd.trim()) {
      //   error.value.pwd = t("login-tip-invalid-email");
      //   return;
      // }
      // error.value.pwd = "";
      // if (form.value.pwd.trim() != form.value.rePwd.trim()) {
      //   error.value.rePwd = t("sign-up-tip-diff-pw");
      //   return;
      // }
      // error.value.rePwd = "";
      // if (!form.value.verifyCode.trim()) {
      //   error.value.verifyCode = t("login-tip-code-empty");
      //   return;
      // }
      // error.value.pwd = "";
      if (loading.value) {
        return;
      }
      // console.log(values);
      loading.value = true;
      $encryptor?.setPublicKey(publicKey);
      const pwd = $encryptor?.encrypt(form.value.pwd);
      useApi.register({
        email: form.value.email,
        pwd: pwd,
        rePwd: pwd,
        verifyCode: form.value.verifyCode,
      })
        .then((res: any) => {
          const { data, code } = res;

          if (code === 0) {
            useLogEvent({ eventName: "sign-up",fbEvent: false });
            useFBLogEvent({ eventName: "CompleteRegistration",needPrefix: false });
            // messageBox.success(t("login-success"));
            messageBox.success(
              t("sign-up"),
              t("sign-up-success")
            );
            console.log("data", data);
            localStorage.setItem("token", data.token);
            localStorage.setItem("userInfo", JSON.stringify(data.user));
            mainStore.setUserInfo(data.user)
            mainStore.setVisibleSignUp(false);
            mainStore.setVisibleLogin(false)
            //如果会员页过来登录的，登录完，非会员返回会员页
            if(vipToLogin.value && data.user.memberType==0){
              mainStore.setVisibleVipDialog(true)
            }
            mainStore.setVipToLogin(false)
          }
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
          console.log("注册异常:", e);
          useLogEvent({ eventName: "sign-fail" });
          messageBox.error(t("login-sign-up"), t("sign-err"));
        });
    }
    function onSendCode() {
      switch (verifyEmail(form.value.email)) {
        case -1:
        // error.value.email = t("login-tip-empty-pw");
        // return;
        case -2:
          error.value.email = t("login-tip-invalid-email");
          return;
        default:
          error.value.email = "";
          break;
      }
      if (isSend.value || sendLoading.value) return;
      sendLoading.value =true;
      useApi.sendCode({ emailType: 1, email: form.value.email })
        .then((res: any) => {
          const { data, code } = res;
          if (code === 0) {
            isSend.value = true;
            codeTimer.value = setInterval(() => {
              time.value--;
              if (time.value <= 0) {
                isSend.value = false;
                clearInterval(codeTimer.value);
                time.value = sendTime;
              }
            }, 1000);
          } else {
            isSend.value = false;
          }
          sendLoading.value =false;
        })
        .catch((e) => {
          isSend.value = false;
          sendLoading.value =false;
        });
    }
</script>


<style scoped lang="scss">
@import '~/assets/css/iv-form.scss';

@media screen and (max-width: 767px) {
}
.spinning{
  border-radius: 100px;
  overflow: hidden;
}

</style>