<template>
    <div class="background-tool h-full w-full p-16 flex flex-col items-center xl:p-32">
        <div class="w-full h-[405px] bg-white max-w-[1300px] shadow-2xl rounded-3xl">
            <div class="flex">
                <div class="flex-1 ml-12 mt-8  xl:ml-24 xl:mt-16">
                    <div class="text-4xl font-bold h-9 text-left">{{ $t("AI_Enhance") }}</div>
                    <div style="font-size: 18px;" class="leading-8 text-left text-sm text-text-gray mt-8">
                        {{ $t("funcType-enhance-desc") }}
                    </div>
                    <div @click="onTryNow('AI_Enhancer')">
                        <GradientsBtn class="h-14 mt-8 w-fit Roboto-Bold-20 xl:mt-16" :text="$t('tryNow')"
                            @mouseenter="floatFunction(0)" @mouseleave="floatFunction(1)"
                            :to="localePath({ params: { funcType: 'enhance_pro' }, name: 'picma_web-funcType' })">
                            <template #suffix>
                                <icon-arrow-right :class="{ 'arrow-move': isMoving }" size="25" />
                            </template>
                        </GradientsBtn>
                    </div>
                </div>
                <div class="relative w-[433px]">
                    <NuxtImg src="/assets/images/home/Home_AI_Enhance.webp" :alt="$t('AI_Enhance')" width="400"
                        height="420" loading="lazy" class="transform -translate-y-[30px]" />
                    <NuxtImg src="/assets/images/home/Home_AI_Enhance-arrow.webp" :alt="$t('AI_Enhance')"
                        class="floating absolute right-[45%] top-1/2" width="70" height="70" loading="lazy" />
                    <NuxtImg src="/assets/images/home/Home_AI_Enhance-star.webp" :alt="$t('AI_Enhance')"
                        class="floating absolute right-6 -top-10" width="50" height="80" loading="lazy" />
                </div>
            </div>
        </div>
        <div class="w-full mt-10 h-[405px] bg-white max-w-[1300px] shadow-2xl rounded-3xl">
            <div class=" flex ">
                <div class="relative w-[483px]">
                    <div class="transform -translate-y-[12px] -translate-x-[25px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Removal.webp" :alt="$t('AI_Removal')" width="563"
                            height="451" loading="lazy" />
                    </div>
                    <div class="transform -translate-y-[260px] translate-x-[80px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Removal-arrow.webp" :alt="$t('AI_Removal')"
                            class="floating" width="60" height="60" loading="lazy" />
                    </div>
                    <div class="transform -translate-y-[110px] translate-x-[90px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Removal-bottom.webp" :alt="$t('AI_Removal')"
                            class="floating" width="250" height="75" loading="lazy" />
                    </div>
                </div>
                <div class="flex-1 ml-14 mt-10">
                    <div class="text-4xl font-bold h-9 text-left xl:mt-8">
                        {{ $t("AI_Removal") }}
                    </div>
                    <div style="font-size: 18px;width: 90%;" class="leading-8 text-left text-sm text-text-gray mt-8">
                        {{ $t("funcType-removal-desc") }}
                    </div>
                    <gradientsBtn class="h-14 mt-8 w-fit Roboto-Bold-20 xl:mt-16" :text="$t('tryNow')"
                        @mouseenter="floatFunction2(0)" @mouseleave="floatFunction2(1)"
                        :to="localePath({ params: { funcType: 'object_remover' }, name: 'picma_web-funcType' })"
                        @click="onTryNow('AI_Removal')">
                        <template #suffix>
                            <icon-arrow-right :class="{ 'arrow-move': isMoving2 }" size="25" />
                        </template>
                    </gradientsBtn>
                </div>
            </div>
        </div>
        <div class="w-full mt-10 h-[405px] bg-white max-w-[1300px] shadow-2xl rounded-3xl">
            <div class="flex">
                <div class="flex-1 ml-12 mt-8 xl:ml-24 xl:mt-16">
                    <div class="text-4xl font-bold h-9 text-left">
                        {{ $t("AI_Generation") }}
                    </div>
                    <div style="font-size: 18px;width: 90%;"
                        class="leading-8 text-left text-sm text-text-gray mt-8 pr-4">
                        {{ $t("funcType-generation-desc") }}
                    </div>
                    <gradientsBtn class="h-14 mt-8 xl:mt-16 w-fit Roboto-Bold-20" :text="$t('tryNow')"
                        @mouseenter="floatFunction3(0)" @mouseleave="floatFunction3(1)"
                        :to="localePath({ params: { funcType: 'ai_art_generator' }, name: 'picma_web-enhance-funcType' })"
                        @click="onTryNow('AI_Art_Generator')">
                        <template #suffix>
                            <icon-arrow-right :class="{ 'arrow-move': isMoving3 }" size="25" />
                        </template>
                    </gradientsBtn>
                </div>
                <div class=" relative w-[433px]">
                    <div class="transform translate-x-[35px] -translate-y-[30px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Generation.webp" :alt="$t('AI_Generation')"
                            width="469" height="475" loading="lazy" />
                    </div>
                    <div class="transform -translate-x-[100px] -translate-y-[405px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Generation-top.webp" :alt="$t('AI_Generation')"
                            class="floating" width="240" height="140" loading="lazy" />
                    </div>
                    <div class="transform translate-x-[10px] -translate-y-[340px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Generation-left.webp" :alt="$t('AI_Generation')"
                            class="floating" width="50" height="50" loading="lazy" />
                    </div>
                    <div class="transform w-[220px] translate-x-[120px] -translate-y-[325px]">
                        <NuxtImg src="/assets/images/home/Home_AI_Generation-bottom.webp" :alt="$t('AI_Generation')"
                            class="floating" width="220" height="85" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const { locale, t } = useI18n();
const localePath = useLocalePath();
function onTryNow(params) {
    useLogEvent({ eventName: `index-trynow-${params}` });
}
const isMoving = ref(false);
const isMoving2 = ref(false);
const isMoving3 = ref(false);



function floatFunction(type) {
    if (type == 0) {

        isMoving.value = true
    } else if (type == 1) {
        isMoving.value = false
    }
}

function floatFunction2(type) {
    if (type == 0) {
        isMoving2.value = true
    } else if (type == 1) {
        isMoving2.value = false
    }
}

function floatFunction3(type) {
    if (type == 0) {
        isMoving3.value = true
    } else if (type == 1) {
        isMoving3.value = false
    }
}
</script>

<style scoped>
.floating {
    animation: float 2s ease-in-out infinite;
}

@keyframes float {

    0%,
    100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateY(-15px);
        /* 调整浮动的幅度 */
    }
}
</style>