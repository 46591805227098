<template>
  <div
    class="rounded-lg border border-transparent flex items-center relative"
    :class="[focus ? 'bg-gradient' : '']"
  >
    <input
      :type="passwordType"
      :placeholder="placeholder"
      @blur="onBlur"
      @focus="onFocus"
      :value="props.modelValue"
      @input="onInput"
      :maxlength="props.maxLength"
      class="outline-none bg-light-gray-bg h-[50px] text-sm text-text-deep rounded-lg px-4 flex-1"
      :class="props.type === InputType.password || hasSuffixSlot?'pr-12':''"
    />
    <div v-if="props.type === InputType.password" class=" absolute right-0 top-0 z-[1] h-full px-3 flex items-center">
      <img
        :src="`/assets/images/${passwordType==='text'?'visible':'unvisible'}.svg`"
        alt="visible"
        height="24"
        width="24"
        class=" cursor-pointer"
        @click="onSuffix"
      />
    </div>
    <div v-if="hasSuffixSlot" class=" absolute right-0 top-0 z-[1] h-full px-3 flex items-center bg-light-gray-bg rounded-lg">
      <slot name="suffix"></slot>
    </div>
  </div>
  <div class="text-[#FF7726] Roboto-12 leading-5" v-if="errMsg">{{ errMsg }}</div>
  <div class="text-text-gray Roboto-12 leading-5" v-else-if="tip">{{ tip }}</div>
</template>

<script setup lang="ts">
enum InputType {
  text = 'text',
  password = 'password',
  code = 'code',
}
const slots = useSlots()
const hasSuffixSlot = !!slots.suffix
const focus = ref(false);
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: InputType.text,
  },
  errMsg: {
    type: String,
    default: "",
  },
  tip: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  maxLength: {
    type: Number,
    default: 999999,
  },
});
const emit = defineEmits(["update:modelValue","onSuffix"]);

const passwordType = ref('text')
onMounted(()=>{
  passwordType.value = props.type
})
function onBlur() {
  focus.value = false;
}
function onFocus() {
  focus.value = true;
}
const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement; // 类型断言
  // if (target.value.length > props.maxLength) {
  //   const str = target.value.slice(0, props.maxLength);
  //   emit("update:modelValue", str);
  //   return;
  // }
  emit("update:modelValue", target.value);
};
function onSuffix() {
  // emit('onSuffix')
  onChangeType()
}
function onChangeType() {
  if(passwordType.value === 'text'){
    passwordType.value = 'password'
  } else {
    passwordType.value = 'text'
  }
}
</script>

<style scoped lang="scss"></style>
