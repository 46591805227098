<template>
  <div class="w-full  overflow-y-auto bg-[#1A1A1A] select-none min-h-dvh" style="background-color: #1A1A1A;">
    <div class="w-full flex flex-col items-center">
      <NuxtImg src="/assets/images/index/bg.png" alt="bg" width="750" height="1018" class="w-full" format="webp"/>
      <p class="w-[250px] text-white text-center min-h-10">
        {{ $t("index-only-web") }}
      </p>
    </div>
    <div class="flex flex-col py-10 items-center">
      <GradientsBtn  @click="onCopy" text="picma.magictiger.ai" class="h-14">
              <template #suffix>
                <NuxtImg src="/assets/images/home/copy.svg" alt="copy" width="24" height="24" />
              </template>
            </GradientsBtn>
      <div class="flex flex-col items-center">
        <div class="mt-[30px] flex gap-2">
          <a href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406" target="_blank" @click="addEvent('mobile-down-appStore')">
            <NuxtImg src="/assets/images/index/appStore.png" alt="app store" width="142" height="40"/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma" target="_blank"  @click="addEvent('mobile-down-googlePlay')">
            <NuxtImg src="/assets/images/index/googlePlay.png" alt="google play" width="142" height="40"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const { t } = useI18n();
function onCopy() {
  addEvent('mobile-url-copy')
  // 使用 Clipboard API 进行复制
  navigator.clipboard.writeText("picma.magictiger.ai").then(() => {
    alert(t("index-copy-dialog-title"));
  });
}
function addEvent(eventName) {
  useLogEvent({ eventName});
}
</script>
<style scoped lang="scss"></style>
