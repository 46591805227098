<template>

    <ClientOnly>
      <DialogGuide v-if="mainStore.visibleGuide"></DialogGuide>
      <DialogLogin v-if="mainStore.visibleLogin"></DialogLogin>
      <DialogSignUp v-if="mainStore.visibleSignUp"></DialogSignUp>
      <DialogForgot v-if="mainStore.visibleForgot"></DialogForgot>
      <DialogEmailLogin v-if="mainStore.visibleEmailLogin"></DialogEmailLogin>
      <DialogVipDialog v-if="mainStore.visibleVipDialog"></DialogVipDialog>
      <DialogPay v-if="mainStore.visiblePay" />
      <Cookie />
      <div
        v-show="loading"
        class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <Loading />
      </div>
    </ClientOnly>

</template>
<script setup lang="ts">
import { useMainStore } from "@/store/index";
const {
  public: { MODE },
} = useRuntimeConfig();
const mainStore = useMainStore();
// qa和正式环境做上报
if (process.client && MODE !== "dev") {
  let env = "production";
  switch (MODE) {
    case "dev":
      env = "development";
      break;
    case "pre":
      env = "pre-production";
      break;
    default:
      env = "production";
      break;
  }
  if(window.apmPlus) {
    window.apmPlus("init", {
      aid: 589973,
      token: "4f25c48804b747d98423a685b8db3f6d",
      release: `${config.version}.${config.build}`,
      env: env,
      plugins: {
        pageview: {
          routeMode: "history",
          extractPid: (url: string) => {
            return new URL(url).pathname;
          },
        },
      },
    });
    window.apmPlus("start");
  }

}
const nuxtApp = useNuxtApp();
// console.log("client", process.client);

const loading = ref(false);
nuxtApp.hook("page:start", () => {
  loading.value = true;
  // console.log('page:start');
});

nuxtApp.hook("page:finish", () => {
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  /* filter: blur(0.5rem); */
}
</style>
