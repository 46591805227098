export const uploadStatus = {
  none: 0, // 未上传
  imageLoadFail: 1, // 图片加载失败
  uploading: 2, // 上传中
  uploadSuccess: 3, // 上传成功
  uploadFail: 4, // 上传失败
  enhanceErr: 5, // 增强异常
  handle: 6, // 增强中
  fail: 7, // 增强失败
  lodingImg: 8, // 加载图片
  success: 9, // 增强成功
};
export enum EnhanceFuncs {
  Enhance,
  WildFocus, //动物毛发
  Landscape, //风景超分
  StableMotion, //运动去模糊
  Eraser, // 涂抹
  word_hd, // 涂抹
}
export enum FileType {
  image,
  video,
}

export enum MemberType  {
  None,
  Week,
  YearPro,
  WeekPro,
  Year,
  Month,
  MonthPro
}
export const MemberTypePrice = {
  0:0,
  1:6.99,
  2:99.99,
  3:9.99,
  4:69.99,
  5:14.99,
  6:24.99,
  7:5.59,
  8: 11.99,
  9:55.99
}
