<template>
  <div class="relative z-[999] bg-white border-b border-b-gray-line">
    <div class="h-header px-10 flex items-center justify-between">
      <div class="flex items-center gap-x-12 h-full xl:gap-x-24">
        <!-- logo -->
        <nuxt-link :to="localePath({ name: isLogin ? 'master_center' : 'index' })" :prefetch="false">
          <img src="/assets/images/logoDark.webp" alt="logo" class="min-w-[160px] lg:w-[226px]" width="226" height="50" />
        </nuxt-link>
        <!-- 菜单 -->
        <ul class="flex items-center gap-2 text-lg font-bold font-Roboto-Bold h-full">
          <li class="cursor-pointer text-white-75 flex items-center  h-full" @mouseenter="mouseenter"
            @mouseleave="mouseleave">
            <div class=" rounded-full hover:bg-light-gray-bg flex items-center px-3 py-2">
              <h1 class="my-0 mr-2 Roboto-Bold-16 text-black">{{funcTypeName}}</h1>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 6L8 11L13 6" stroke="#1f1f1f" stroke-width="2" stroke-linecap="square" />
              </svg>
            </div>
          </li>
          <EnhanceTips></EnhanceTips>
        </ul>
      </div>

      <div class="h-full flex items-center gap-x-4">
        <EnhanceDownload v-show="!hiddenDownload" :enable="downloadEnabled" @click="onDownload"></EnhanceDownload>
        <EnhanceUpload v-if="!hiddenUpload" :acceptList="acceptList" :acceptCount="acceptCount"
          class="hover:-translate-y-1 duration-500"></EnhanceUpload>
        <div class="h-full flex items-center gap-4">
          <div v-if="!isLogin"
            class="h-9 leading-9 text-center rounded-full text-deep px-6 Roboto-Bold-14 border border-btn-gray cursor-pointer hover:-translate-y-1 duration-500"
            @click="showLogin">
            {{ $t("header-login") }}
          </div>
          <GradientsBtn v-if="!isLogin" class="h-9" :shadow="false" :text="$t('login-sign-up')" @click="showSignUp">
          </GradientsBtn>
          <!-- 登录用户，非会员和普通会员显示 -->
          <HeaderCreditsBtn v-if="isLogin && (isNormalVip || !isVipPro)"></HeaderCreditsBtn>
          <!-- <GradientsBtn
            class="h-9 "
            :text="$t('login-sign-up')"
            textClass="Roboto-Bold-18"
            showArrow
            @click="onClick"
          >
            <template v-slot:prefix>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.0356 20.4561C21.4581 18.4384 23 15.3992 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23H20.1528C20.3638 23 20.4636 22.6592 20.3013 22.5244C19.8003 22.1082 19.188 21.4187 19.0356 20.4561ZM10 11C10 12.1046 9.32843 13 8.5 13C7.67157 13 7 12.1046 7 11C7 9.89543 7.67157 9 8.5 9C9.32843 9 10 9.89543 10 11ZM14.5 13C15.3284 13 16 12.1046 16 11C16 9.89543 15.3284 9 14.5 9C13.6716 9 13 9.89543 13 11C13 12.1046 13.6716 13 14.5 13Z"
                fill="white"
              />
            </svg>
          </template>
<template v-slot:suffix>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.0356 20.4561C21.4581 18.4384 23 15.3992 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23H20.1528C20.3638 23 20.4636 22.6592 20.3013 22.5244C19.8003 22.1082 19.188 21.4187 19.0356 20.4561ZM10 11C10 12.1046 9.32843 13 8.5 13C7.67157 13 7 12.1046 7 11C7 9.89543 7.67157 9 8.5 9C9.32843 9 10 9.89543 10 11ZM14.5 13C15.3284 13 16 12.1046 16 11C16 9.89543 15.3284 9 14.5 9C13.6716 9 13 9.89543 13 11C13 12.1046 13.6716 13 14.5 13Z"
                fill="white"
              />
            </svg>
          </template>
</GradientsBtn> -->
          <div v-if="isLogin" class="h-full flex items-center" @mouseenter="enterBaseInfo" @mouseleave="leaveBaseInfo">
            <HeaderAvatar></HeaderAvatar>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed top-header right-[20px]" v-show="showBaseInfo" @mouseenter="enterBaseInfo"
      @mouseleave="leaveBaseInfo">
      <HeaderBaseInfo> </HeaderBaseInfo>
    </div>
    <!-- 展开菜单 -->
    <div class="fixed top-header left-0 right-0" v-show="showMenus" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <HeaderExpandMenus></HeaderExpandMenus>
    </div>
  </div>
</template>
<script setup>
import { videoAccept, imageAccept, maxUploadCount } from "@/utils/config";
import { funcTypeAcceptList } from "@/utils/index";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const localePath = useLocalePath();
const { isNormalVip, isVip, isVipPro, isLogin } = useUserInfo();
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const route = useRoute();
const showMenus = ref(false);
const showBaseInfo = ref(false);
const timer = ref()
// const funcTypeName = ref("");
// const downloadEnabled = ref(false);
const downloadUrl = ref("");
const suffix = ref('')
// const acceptList = ref(funcTypeAcceptList(route.params.funcType));

const funcTypeName = computed(() => {
  let name = "";
  Object.keys(FuncType.value).map((k) => {
    if (k === route.params.funcType?.toLocaleLowerCase()) {
      name = FuncType.value[k].name;
    }
  });
  return name;
});
const acceptList = computed(() => {
  return funcTypeAcceptList(route.params.funcType?.toLocaleLowerCase())
})
const hiddenUpload = computed(() => {
  if ([FuncType.value.ai_art_generator.path].includes(route.params.funcType?.toLocaleLowerCase())) {
    return true
  }
  if ([FuncType.value.ai_e_commerce.path].includes(route.params?.funcType?.toLocaleLowerCase()) && ['ai_poster', 'ai_logo'].includes(route.query.id)) {
    return true
  }
  return false
})
const hiddenDownload = computed(() => {
  return [FuncType.value.ai_art_generator.path].includes(route.params?.funcType?.toLocaleLowerCase())
})
// const fileList = ref([]);
onMounted(() => {
  // window.addEventListener("fileListEvent", onDownloadEnabled);
  window.addEventListener("downloadUrlEvent", downloadUrlEvent);
  window.addEventListener("logoutEvent", logoutEvent);
});
onUnmounted(() => {
  // window.removeEventListener("fileListEvent", onDownloadEnabled);
  window.removeEventListener("downloadUrlEvent", downloadUrlEvent);
  window.removeEventListener("logoutEvent", logoutEvent);
});

const downloadEnabled = computed(() => {
  // 多张处理的时候，有压缩包链接，或者本地连接

  return !!downloadUrl.value;

  // // 单张处理的，只有增强结果链接
  // const len = fileList.value.length;

  // if (len && len === 1 && fileList.value[0].enhanceUrl) {
  //   return true;
  // }
  // return false;
});
const acceptCount = computed(() => {
  if (
    [
      FuncType.value.enhance_pro.path,
      FuncType.value.stable_motion_ai.path,
      FuncType.value.color_master.path,
      FuncType.value.descratch.path,
      FuncType.value.anime_enhance.path,
      FuncType.value.word_hd.path,
    ].includes(route.params.funcType?.toLocaleLowerCase()) &&
    isVipPro.value
  ) {
    return maxUploadCount;
  }
  return 1;
});
// function onDownloadEnabled(event) {
//   fileList.value = event.detail || [];
// }
function downloadUrlEvent(event) {
  if (typeof event.detail === 'string') {
    downloadUrl.value = event.detail
    suffix.value = ''
  } else {
    downloadUrl.value = event.detail.url
    suffix.value = event.detail.suffix
  }


}
function onDownload() {
  if (!downloadEnabled.value) {
    return;
  }
  window.dispatchEvent(
    new CustomEvent("downloadImgEvent", {
      detail: { url: downloadUrl.value, suffix: suffix.value },
    })
  );
}
function showLogin() {
  mainStore.setVisibleLogin(true);
}
function showSignUp() {
  mainStore.setVisibleSignUp(true);
}
function mouseenter() {
  if (timer.value) {
    clearTimeout(timer.value)
  }
  showMenus.value = true;
}
function mouseleave() {
  timer.value = setTimeout(() => {
    showMenus.value = false;
  }, 200)
}

function enterBaseInfo() {
  if (isLogin.value) showBaseInfo.value = true;
}
function leaveBaseInfo() {
  showBaseInfo.value = false;
}
function logoutEvent(params) {
  showBaseInfo.value = false;
}
</script>

<style scoped lang="scss"></style>
