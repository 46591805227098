<template>
  <div style="z-index: 1002" class="common-mask" @wheel.prevent @touchmove.stop>
    <div
      class="rounded-[30px] bg-white pb-6 flex flex-col items-center relative w-[704px] guide"
      :style="{ paddingTop: paddingTop + 'px' }"
    >
      <div class="absolute -top-[127px] left-0 right-0">
        <div
          class="relative h-[367px]"
          style="
            background-image: url('/assets/images/dialog/guide/guide-bg.webp');
            background-size: contain;
            background-repeat: no-repeat;
          "
        >
          <div ref="descRef" class="ml-6 pt-11 w-[420px] tracking-normal">
            <p class="Roboto-Bold-20 text-gradient inline-block">PicMa Studio AI</p>
            <p class="font-semibold text-white text-2xl mb-5 mt-2 Roboto-Bold-32">
              {{ $t('index-guide-title') }}
            </p>
            <p class="Roboto-12 text-[#FFFFFFCC] leading-5">
              <span
                >{{ $t('index-guide-desc1') }}
              </span>
              <span class="font-bold text-[#FFE032]"
                >{{ $t('index-guide-desc2') }}</span
              >
              <span>{{ $t('index-guide-desc3') }}</span>
            </p>
          </div>
          <div
            class="absolute top-7 right-5 flex items-center justify-center rounded-full w-8 h-8 bg-[#FFFFFF4D] cursor-pointer"
            @click="onClose"
          >
            <NuxtImg
              src="/assets/images/dialog/close.svg"
              :alt="$t('AI_Enhance')"
              width="16"
              height="16"
            />
          </div>
        </div>
      </div>
      <div
        class="rounded-[20px] bg-white px-4 py-6 flex flex-col items-center relative gap-4 mb-4 mx-8 shadow-[0px_4px_16px_0px_#4E2DFF1A]"
      >
        <div class="flex items-center">
          <NuxtImg src="/assets/images/dialog/guide/1.webp" class="mr-2" width="24" height="24" :alt="$t('AI_Enhance')"/><span>{{ $t('index-guide-content-1') }}</span>
        </div>
        <div class="flex items-stretch gap-x-7">
          <div
            class="bg-[#FFF1EE] rounded-[20px] overflow-hidden text-center w-[184px] flex flex-col gap-y-4"
          >
            <NuxtImg
              src="/assets/images/dialog/guide/guide-1.webp"
              :alt="$t('AI_Enhance')"
              width="184"
              height="130"
            />
            <p class="px-4 pb-6 Roboto-12 text-text-deep">
              {{ $t('index-guide-content-2') }}
            </p>
          </div>
          <div
            class="bg-[#EDFDFF] rounded-[20px] overflow-hidden text-center w-[184px] flex flex-col gap-y-4"
          >
            <NuxtImg
              src="/assets/images/dialog/guide/guide-2.webp"
              :alt="$t('AI_Enhance')"
              width="184"
              height="130"
            />
            <p class="px-4 pb-6 Roboto-12 text-text-deep">
              {{ $t('index-guide-content-3') }}
            </p>
          </div>
          <div
            class="bg-[#FDF1FF] rounded-[20px] overflow-hidden text-center w-[184px] flex flex-col gap-y-4"
          >
            <NuxtImg
              src="/assets/images/dialog/guide/guide-3.webp"
              :alt="$t('AI_Enhance')"
              width="184"
              height="130"
            />
            <p class="px-4 pb-6 Roboto-12 text-text-deep flex-1">
              {{ $t('index-guide-content-4') }}
            </p>
          </div>
        </div>
        <p class="Roboto-12 font-medium text-text-deep text-center mt-4">
          {{ $t('index-guide-content-5') }}
        </p>
        <div class="flex items-center gap-x-5 w-full">
          <div
            class="flex-1 px-2 bg-[#F4F2FF] rounded-[10px] text-center flex flex-col items-center py-2"
          >
            <NuxtImg
              src="/assets/images/enhance/icons/Image Background Remover.svg"
              :alt="$t('background_remover')"
              width="32"
              height="32"
              class="mt-2"
            />
            <p class="Roboto-12 font-medium text-text-deep text-center mt-2">
              {{ FuncType.background_remover.name }}
            </p>
          </div>
          <div
            class="flex-1 px-2 bg-[#F4F2FF] rounded-[10px] text-center flex flex-col items-center py-2"
          >
            <NuxtImg
              src="/assets/images/enhance/icons/Object Remover.svg"
              :alt="$t('object_remover')"
              width="32"
              height="32"
              class="mt-2"
            />
            <p class="Roboto-12 font-medium text-text-deep text-center mt-2">
              {{ FuncType.object_remover.name }}
            </p>
          </div>
          <div
            class="flex-1 px-2 bg-[#F4F2FF] rounded-[10px] text-center flex flex-col items-center py-2"
          >
            <NuxtImg
              src="/assets/images/enhance/icons/Color Master.svg"
              :alt="$t('color_master')"
              width="32"
              height="32"
              class="mt-2"
            />
            <p class="Roboto-12 font-medium text-text-deep text-center mt-2">
              {{ FuncType.color_master.name }}
            </p>
          </div>
          <div
            class="flex-1 px-2 bg-[#F4F2FF] rounded-[10px] text-center flex flex-col items-center py-2"
          >
            <NuxtImg
              src="/assets/images/enhance/icons/Image Compressor.svg"
              :alt="$t('image_compressor')"
              width="32"
              height="32"
              class="mt-2"
            />
            <p class="Roboto-12 font-medium text-text-deep text-center mt-2">
              {{ FuncType.image_compressor.name }}
            </p>
          </div>
        </div>
      </div>
      <p class="Roboto-12 text-text-gray mb-6">
        {{ $t('index-guide-content-6') }}
      </p>
      <div class="flex items-center gap-x-5">
        <div
          class="h-[50px] w-[260px] rounded-[44px] text-center text-base leading-[50px] bg-gray-line cursor-pointer"
          @click="onClose"
        >
          {{ $t('index-guide-content-cancel') }}
        </div>
        <GradientsBtn
          class="h-[50px] w-[260px]"
          style="font-size: 16px;"
          @click="toLogin"
          :text="$t('index-guide-content-start')"
          showArrow
        >
        </GradientsBtn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const {
  guideLoginEvent,
} = useCustomEvent();
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const { t } = useI18n();
const { getFuncType } = useFuncType();
const FuncType = reactive(getFuncType());
const { isLogin, isVip } = useUserInfo();
const descRef = ref<HTMLDivElement | null>(null);
const paddingTop = ref(82);
onMounted(() => {
  if (descRef.value) {
    console.log(descRef.value.offsetHeight);
    paddingTop.value += descRef.value.offsetHeight - 179;
  }
});

function onClose() {
  localStorage.setItem('guideDialog','1')
  useLogEvent({ eventName: "guide-close" });
  // useLogEvent({ eventName: "vip-close" });
  // useFBLogEvent({ eventName: "vip-close" });
  mainStore.setVisibleGuide(false);
  guideLoginEvent()
  // mainStore.setVisibleVipDialog(false);
}

function toLogin() {
  useLogEvent({ eventName: "guide-start" });
  mainStore.setVisibleGuide(false);
  localStorage.setItem('guideDialog','1')
  if (!isLogin.value) {
    // mainStore.setVisibleVipDialog(false);
    mainStore.setVisibleLogin(true);
  }
}
</script>
<style scoped lang="scss">
.text-gradient {
  background: linear-gradient(90deg, #ffd529 0%, #00ffdb 100%);
  /* 背景仅作用于文字区域 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 文字颜色透明以显示背景 */
  color: transparent;
  /* 兼容旧版WebKit浏览器 */
  -webkit-text-fill-color: transparent;
}
@media (max-height: 650px) {
  .guide {
    transform: scale(0.7) translateY(20px);
  }
}
@media (min-height: 650px) {
  .guide {
    transform: scale(0.8) translateY(20px);
  }
}
@media (min-height: 750px) {
  .guide {
    transform: scale(1) translateY(40px);
  }
}
</style>
