<template>
    <div class="mt-8">
        <div class="flex items-center">
            <div class="Roboto-Bold-24 mr-2">
                {{ $t("AI_Enhance") }}
            </div>
            <span class="tag free px-2">FREE</span>
        </div>
        <div class="grid grid-cols-2 gap-5 mt-6 text-center text-base 2xl:grid-cols-5 xl:grid-cols-3 ">
            <div class="group hover:scale-105" v-for="(item, index) in EnhanceList" :key="index"
                @click="clickLogEvent(item.to.params.funcType)">
                <NuxtLink :to="localePath(item.to)">
                    <div
                        class="bg-mainColor-white5 rounded-3xl h-[240px] relative clip-container p-2 hover:border-indigo-500 group-hover:border-2">
                        <img :src="item.src" class="rounded-2xl object-cover h-[165px]" />
                        <div class="w-full my-5 mx-2 flex items-center justify-left Roboto-Bold-18 text-left">
                            <img class="h-6 w-6 mr-1" :src="item.icon" alt="" />
                            {{ item.content }}
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
    <div class="Roboto-Bold-24 mt-8">
        {{ $t("AI_Removal") }}
        <div class="grid grid-cols-2 gap-5 mt-6 text-center text-base 2xl:grid-cols-5 xl:grid-cols-3">
            <div class="group hover:scale-105" v-for="(item, index) in RemovalList" :key="index"
                @click="clickLogEvent(item.to.params.funcType)">
                <NuxtLink :to="localePath(item.to)">
                    <div
                        class="bg-mainColor-white5 rounded-3xl h-[240px] relative clip-container p-2 hover:border-indigo-500 group-hover:border-2">
                        <img :src="item.src" class="rounded-2xl object-cover h-[165px]" />
                        <div class="w-full my-5 mx-2 flex items-center justify-left Roboto-Bold-18 text-left">
                            <img class="h-6 w-6 mr-1" :src="item.icon" alt="" />
                            {{ item.content }}
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
    <div class="Roboto-Bold-24 mt-8">
        {{ $t("AI_Editing") }}
        <div class="grid grid-cols-2 gap-5 mt-6 text-center text-base 2xl:grid-cols-5 xl:grid-cols-3">
            <div class="group hover:scale-105" v-for="(item, index) in EditingList" :key="index"
                @click="clickLogEvent(item.to.params.funcType)">
                <NuxtLink :to="localePath(item.to)">
                    <div
                        class="bg-mainColor-white5 rounded-3xl h-[240px] relative clip-container p-2 hover:border-indigo-500 group-hover:border-2">
                        <img :src="item.src" class="rounded-2xl object-cover h-[165px]" />
                        <div class="w-full my-5 mx-2 flex items-center justify-left Roboto-Bold-18 text-left">
                            <img class="h-6 w-6 mr-1" :src="item.icon" alt="" />
                            {{ item.content }}
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
    <div class="Roboto-Bold-24 mt-8">
        {{ $t("AI_Generation") }}
        <div class="grid grid-cols-2 gap-5 mt-6 text-center text-base 2xl:grid-cols-5 xl:grid-cols-3">
            <div class="group hover:scale-105" v-for="(item, index) in GeneratorList" :key="index"
                @click="clickLogEvent(item.to.query ? item.to.query.id : item.to.params.funcType)">
                <NuxtLink :to="localePath(item.to)">
                    <div
                        class="bg-mainColor-white5 rounded-3xl h-[240px] relative clip-container p-2 hover:border-indigo-500 group-hover:border-2">
                        <img :src="item.src" class="rounded-2xl object-cover h-[165px]" />
                        <div class="w-full my-5 mx-2 flex items-center justify-left Roboto-Bold-18 text-left">
                            <img class="h-6 w-6 mr-1" :src="item.icon" alt="" />
                            {{ item.content }}
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n();
const localePath = useLocalePath();
const EnhanceList = ref([
    { to: { params: { funcType: 'enhance_pro' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Photo_Enhance.webp", icon: "/assets/images/enhance/icons/Photo Enhancer-2.svg", content: t("header-hot-tools-1") },
    { to: { params: { funcType: 'word_hd' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Text_Enhancer.webp", icon: "/assets/images/enhance/icons/Word HD-2.svg", content: t("word_hd") },
    { to: { params: { funcType: 'color_master' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Color_Master.webp", icon: "/assets/images/enhance/icons/Color Master-2.svg", content: t("color_master") },
    { to: { params: { funcType: 'stable_motion_ai' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Stable_Motion_Al.webp", icon: "/assets/images/enhance/icons/Stable Motion AI-2.svg", content: t("stable_motion_ai") },
    { to: { params: { funcType: 'descratch' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Descratch.webp", icon: "/assets/images/enhance/icons/Descratch-2.svg", content: t("descratch") },
])
const RemovalList = ref([
    { to: { params: { funcType: 'image_background_remover' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Image_Background_Remover.webp", icon: "/assets/images/enhance/icons/Image Background Remover-2.svg", content: t("image_background_remover") },
    { to: { params: { funcType: 'object_remover' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Object_Remover.webp", icon: "/assets/images/enhance/icons/Object Remover-2.svg", content: t("object_remover") },
    { to: { params: { funcType: 'face_cutout' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Face_Cutout.webp", icon: "/assets/images/enhance/icons/Face Cutout-2.svg", content: t("face_cutout") },
])
const EditingList = ref([
    { to: { params: { funcType: 'image_compressor' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Image_Compressor.webp", icon: "/assets/images/enhance/icons/Image Compressor-2.svg", content: t("image_compressor") },
    { to: { params: { funcType: 'image_converter' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/Image_Converter.webp", icon: "/assets/images/enhance/icons/Image Converter-2.svg", content: t("image_converter") },
    { to: { params: { funcType: 'ai_e_commerce' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/AI_E_Commerce.webp", icon: "/assets/images/enhance/icons/AI E-Commerce-2.svg", content: t("ai_e_commerce") },
])
const GeneratorList = ref([
    { to: { params: { funcType: 'ai_background_generator' }, name: 'picma_web-funcType' }, src: "/assets/images/index/home/AI_Background_Generator.webp", icon: "/assets/images/enhance/icons/Al Background Generator-2.svg", content: t("ai_background_generator") },
    { to: { params: { funcType: 'ai_art_generator' }, name: 'picma_web-enhance-funcType' }, src: "/assets/images/index/home/AI_Art_Generator.webp", icon: "/assets/images/enhance/icons/Al Art Generator-2.svg", content: t("ai_art_generator") },
    { to: { params: { funcType: 'ai_e_commerce' }, query: { id: 'ai_poster' }, name: 'picma_web-enhance-funcType' }, src: "/assets/images/index/home/AI Poster.webp", icon: "/assets/images/enhance/icons/AI Poster-2.svg", content: t("ai_poster") },
    { to: { params: { funcType: 'ai_e_commerce' }, query: { id: 'ai_logo' }, name: 'picma_web-enhance-funcType' }, src: "/assets/images/index/home/AI logo.webp", icon: "/assets/images/enhance/icons/AI Logo-2.svg", content: t("ai_logo") },
])
function clickLogEvent(params) {
    useLogEvent({ eventName: `center-${params}` });
}
</script>

<style scoped>
.clip-container {
    position: relative;
    width: fit-content;
    /* 让容器宽度与视频宽度一致 */
    overflow: hidden;
}

.tag {
    display: inline-block;
    border: 1px solid rgba(106, 234, 94, 0.4);
    height: 17px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 10px;
    line-height: 16px;
    text-align: center;
}

.free {
    background-color: rgba(106, 234, 94, 0.1);
    color: #39D201;
}
</style>