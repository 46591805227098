<template>
  <nuxt-link
    v-if="to"
    :to="to"
    class="group relative flex items-center justify-center text-center rounded-full text-white font-Roboto-Bold text-sm cursor-pointer  overflow-hidden hover:-translate-y-1 duration-500"
    :class="[props.shadow?'shadow':'',props.enable?'transition-gradient-enable-1':'transition-gradient-unenable-1']"
  >
    <div class="flex items-center justify-center gap-4 px-6">
      <slot name="prefix"></slot>
      <span :class="props.textClass">{{ text }}</span>
      <NuxtImg v-if="props.showArrow" src="/assets/images/arrow1.svg" :alt="$t('AI_Generation')" width="20" height="20" loading="lazy"/>
      <slot name="suffix"></slot>
    </div>
    <div
      class="transition-[left] h-full w-full absolute top-0 -left-full duration-150 ease-in-out group-hover:left-0"
      :class="[props.enable?'transition-gradient-enable-2':'transition-gradient-unenable-2']"
    ></div>

    <div
      class="absolute h-full w-full top-0 left-0 z-[1] flex items-center justify-center gap-4 px-6 "
      :class="[props.enable?'cursor-pointer':' cursor-not-allowed']"
    >
      <slot name="prefix"></slot>
      <span class="select-none" :class="props.textClass">{{ text }}</span>
      <NuxtImg v-if="props.showArrow" src="/assets/images/arrow1.svg" :alt="$t('AI_Generation')" width="20" height="20" loading="lazy"/>
      <slot name="suffix"></slot>
    </div>
  </nuxt-link>
  <div
    v-else
    :to="to"
    class="group relative flex items-center justify-center text-center rounded-full text-white font-Roboto-Bold text-sm cursor-pointer  overflow-hidden hover:-translate-y-1 duration-500"
    :class="[props.shadow?'shadow':'',props.enable?'transition-gradient-enable-1':'transition-gradient-unenable-1']"
  >
    <div class="flex items-center justify-center gap-4 px-6">
      <slot name="prefix"></slot>
      <span :class="props.textClass">{{ text }}</span>
      <NuxtImg v-if="props.showArrow" src="/assets/images/arrow1.svg" :alt="$t('AI_Generation')" width="20" height="20" loading="lazy"/>
      <slot name="suffix"></slot>
    </div>
    <div
      class="transition-[left] h-full w-full absolute top-0 -left-full duration-150 ease-in-out group-hover:left-0"
      :class="[props.enable?'transition-gradient-enable-2':'transition-gradient-unenable-2']"
    ></div>

    <div
      class="absolute h-full w-full top-0 left-0 z-[1] flex items-center justify-center gap-4 px-6 "
      :class="[props.enable?'cursor-pointer':' cursor-not-allowed']"
    >
      <slot name="prefix"></slot>
      <span class="select-none" :class="props.textClass">{{ text }}</span>
      <NuxtImg v-if="props.showArrow" src="/assets/images/arrow1.svg" :alt="$t('AI_Generation')" width="20" height="20" loading="lazy"/>
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  textClass: {
    type: String,
    default: "",
  },
  to: {
    type: String,
    default: "",
  },
  showArrow: {
    type: Boolean,
    default: false,
  },
  shadow: {
    type: Boolean,
    default: true,
  },
  enable: {
    type: Boolean,
    default: true,
  },
});
const localePath = useLocalePath();
// const emit = defineEmits(["onClick"]);
// function onClick() {
//   emit("onClick");
// }
</script>
<style lang="scss" scoped>
.transition-gradient-enable-1 {
  background-image: linear-gradient(to right, #c02cff, #6650f4);
}
.transition-gradient-enable-2 {
  background-image: linear-gradient(to right, #4a2dff, #c02cff);
}
.transition-gradient-unenable-1 {
  // background-image: linear-gradient(to right, #c02cff, #6650f4);
  background: linear-gradient(90deg, rgba(79, 43, 255, 0.3) 0%, rgba(209, 13, 255, 0.3) 100%);

}
.transition-gradient-unenable-2 {
  // background-image: linear-gradient(to right, #4a2dff, #c02cff);
  background: linear-gradient(90deg,rgba(209, 13, 255, 0.3) 100%, rgba(79, 43, 255, 0.3) 0%);
}
.shadow {
  box-shadow: 0px 8px 16px 0px #5f45ff40;
}
</style>
