<template>
  <div v-show="show" class="relative group">
    <img :src="tipIcon" alt="icon" class="w-[18px]" />
    <div ref="tipsRef" id="tip"
      class="group-hover:block hidden absolute top-5 left-0 rounded-2xl p-5 z-20 bg-white shadow-md">
      <div class="w-[210px] ">
        <video class="rounded-xl overflow-hidden" v-if="url.endsWith('.mp4')" :src="url" style="width: 100%" autoplay
          loop muted></video>
        <img v-else class="rounded-xl overflow-hidden" :src="url" alt="image" />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const route = useRoute();
const { t } = useI18n();
const tipIcon = ref(
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAlCAYAAAAqXEs9AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANjSURBVHgB7VhBdtMwEB0b9k0ukJgTNOzY1d3BinCCNCdIe4I2J2h6ApoTUFY8VjUnID1BnazY4Vwg4X97ZJS82JYdw2PBf8+RHUlfXyPNaGyR/yiHJw0QACjCzWYz8Dyvj/uOViXb7Xbp+/4C91EMSE04C4KGDgRMICCkGMduC7S/qyPOSRDETDDzG7EsgYEeUD7xHles/7M+wHWG9qHVPkb7KTTdV43lVQgJQPxR1CIgjVCQOBK3iVygGKk4I+y8zFpeCdkARJ8kmzGJxq5CDgkD17XF9QFcC2dBKuYRtx1dGopJ5AioI9yCdyjZkp8fEuUd6qhiWM5Xq9WFtIher3cPMSMpWD5/v4MRQ8u0LYYgp1rd7E8pFKSbMJDMa67kz2GsY4QY89Ku8CwxgWWdsYuLWrFpIOri4IgRGKdVcQd9Qx2P++mV2aO2hULJrBM5iuEEvoPsBo9D7R/imR71jOpRWX96rIYRTiq3Ui4IJBOWaDQXBxhr4nqiG3OD6jXX+hktWEEz1THPzB+eNdtn3CbL5bJbQWKbm57yej8koP6RwRB1V6iblXH1+/2fkoUXLltsLBSq0m/iCLSl+94VxCfDU2Uh8ny2NbzUh4GWkThAI3ZhW80C0qZSDQbHEfvkgvAQ1CAohHrdNbje61+RQ7fMu3w/yAXh4QRrnlfWBfZB2pkcOjHeT7EfY4fusbY/yQW1CZMRQEwkDWCWbK0zC+QIQESTDDTd+Bh/zTL1MixZbFf+ZQT8gVFilmbJTBpwKg3Ao0YaAkIYr0Tz8FxQxB/NVWqTuxw1RbCidMQfXwljc64wCktNsI9etZZcswv2WZjD2D5c0+iqqWYd0jRLMBmm1EN6AOubiewL4pnDOBTWtRKtqxZ2jmOaZ4eSxaEo59prdIlGt1JwaLaFstxrJ2PkyawzPZhetgWddJANuesQ/oH2Jr0cIiFvXZRy0pvTJH+/vvI1CNeD5jWxHAF6oFq99DXoxaHOSZL86Ha7X3H7FtcbkuB5jf8X0kwME7ovykXLvKv1omgRBVaqStCb5q6BUIUwjITpYNn+HDd6ld4jtl+FiUTFMXbF8tvdWc9lPtWob3+cmFals86CVFQg2Uz5JWTg0kctQtEz1xBy1AcryVLfnQ9WuJaSHdbRsY7wT+AXS0TZiZ9hQuUAAAAASUVORK5CYII="
);

const url = ref("");
const text = ref("");

const show = computed(()=>{
  if (
    [
      FuncType.value.enhance_pro.path,
      FuncType.value.stable_motion_ai.path,
      FuncType.value.word_hd.path,
      FuncType.value.descratch.path,
      FuncType.value.color_master.path,
    ].includes(route.params.funcType?.toLocaleLowerCase())
  ) {
    getInfo(route.params.funcType?.toLocaleLowerCase())
    return true
  }
  return false
})
const getInfo = (funcType) => {
  switch (funcType) {
    case FuncType.value.enhance_pro.path:
      url.value = "/assets/video/Enhance.mp4";
      text.value = t("enhance-func-enhance-detail");
      break;
    case FuncType.value.stable_motion_ai.path:
      url.value = "/assets/images/enhance/Stable.webp";
      text.value = t("enhance-func-stableMotion-detail");
      break;
    case FuncType.value.word_hd.path:
      url.value = "/assets/images/enhance/word.jpg";
      text.value = t("enhance-func-word_hd-detail");
      break;
    case FuncType.value.descratch.path:
      url.value = "/assets/images/index/home/Descratch.webp";
      text.value = t("enhance-func-enhance-detail");
      break;
    case FuncType.value.color_master.path:
      url.value = "/assets/images/index/home/Color_Master.webp";
      text.value = t("enhance-func-enhance-detail");
      break;
    default:
      break;
  }
};

</script>
