<template>
    <div class="relative w-full h-full flex">
        <div class="h-full w-80 relative">
            <div class="h-full sticky flex flex-col items-center overflow-y-auto bg-white custom-scrollbar">
                <div class="grid grid-cols-2 gap-2 p-4">
                    <div class="mt-2 col-span-2">
                        <div class="Roboto-Bold-20">{{ $t('enhance-poster-info') }}</div>
                        <div class="Roboto-Bold-14 my-4"><span class="required">*</span>{{ $t('enhance-poster-name') }}
                        </div>
                        <input ref="inputRef" maxlength="100" v-model="picDesc"
                            class="w-full h-[40px] rounded-lg text-text-deep Roboto-12 px-4" type="text"
                            :placeholder="$t('enhance-poster-name-ph')">
                        <div class="Roboto-Bold-14 my-4">
                            <span class="required">*</span>
                            {{ $t('enhance-poster-Description') }}
                        </div>
                        <EnhanceTextPrompt ref="textPromptRef" v-model="prompt" class="h-[170px] mt-2" :maxLength="1000"
                            :placeholder="$t('enhance-poster-Description-ph')"></EnhanceTextPrompt>

                        <div>
                            <div class="Roboto-Bold-12 text-text-gray my-4">
                                {{ $t('enhance-Art-Generator-No-inspiration') }}
                            </div>
                            <div class="flex items-center">
                                <img v-for="item in inspirationList" :value="item.key" :key="item.key"
                                    @click="onInspirationClick(item)"
                                    class="w-[40px] h-[60px] rounded-lg mr-4 cursor-pointer hover:scale-110"
                                    :class="{ 'selected-ins': inspIndex === item.key }" :src="item.src" alt="img" />
                            </div>
                            <div :title="inspText"
                                class="mt-5 Roboto-12 w-full h-[153px] border rounded-lg limited-lines px-4 pt-2"
                                style="font-size: 12px; font-weight: 400;line-height: 20px;background-color: white;color:#8A8EA1">
                                {{ inspText }}
                            </div>
                            <div class="w-[250px] h-4 transform -translate-y-[22px] translate-x-[4px]"
                                style="background-color: white;">
                                <a-tooltip :contentStyle="{ 'border-radius': '15px', 'background-color': '#1F1F1FE5' }"
                                    :content="t('enhance-Art-Generator-Use-Prompt')" position="top">
                                    <img class="float-right w-4 h-4 cursor-pointer hover:scale-110"
                                        src="/assets/images/perCenter/edit.svg" alt="img" @click="onTipClick">
                                </a-tooltip>
                            </div>
                        </div>

                        <div class="Roboto-Bold-14 my-4">{{ $t('enhance-poster-image') }}</div>
                        <a-upload image-preview ref="uploadRef" name="file" action="" @before-upload="onBeforeUpload"
                            accept=".bmp, .jpeg,.jpg,.png" :show-file-list="false"
                            class="border rounded-lg w-full h-20 bg-white hover:bg-gray-bg">
                            <template #upload-button>
                                <div class="w-full h-full relative" v-if="imgSrc" @mouseenter="isShow = true"
                                    @mouseleave="isShow = false">
                                    <img class="w-full h-full object-contain" :src="imgSrc" alt="">
                                    <div v-show="isShow" style="background-color: #00000066;"
                                        class="absolute w-fit px-2 py-1 rounded-lg flex items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <img class="w-4 h-4" src="/assets/images/functype/AIGenerator/ReSelect.png"
                                            alt="">
                                        <div class="ml-1 Roboto-10 text-white">{{ $t("enhance-AI-Re-select") }}</div>
                                    </div>
                                    <div v-show="isShow" class="absolute top-0 right-0">
                                        <img @click.stop="deleteImg" class="h-6 w-6"
                                            src="/assets/images/perCenter/delete.png" alt="" />
                                    </div>
                                </div>
                                <div v-else class="h-full flex flex-col items-center justify-center">
                                    <icon-plus style="color: gary; width: 25px; height: 25px;" />
                                    <span class="text-text-deep Roboto-Bold-12 mt-4">
                                        {{ $t("enhance-poster-image-ph") }}
                                    </span>
                                </div>
                            </template>
                        </a-upload>
                    </div>
                    <div class="mt-8 col-span-2">
                        <div class="Roboto-Bold-14">{{ $t('enhance-style') }}</div>
                        <div class="grid grid-cols-3 gap-4 flex-1 flex mt-4">
                            <div :class="{ 'selected': selectedImageIndex === index }"
                                class="relative inline-block hover:scale-110" v-for="(item, index) in styleList"
                                :key="index" :value="item.id" @click="onClickImg(item, index)">
                                <img class="cursor-pointer rounded-lg border h-full w-[82px]" :src="item.src"
                                    alt="AI Matching Image">
                                <div style="background: linear-gradient(to bottom, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 100%)"
                                    class="text-xs rounded-lg absolute bottom-0 left-0 right-0 text-white h-[30px] w-full grid place-items-center">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-28 col-span-2">
                        <span class="Roboto-Bold-14">
                            {{ $t('enhance-output') }}
                        </span>
                        <a-input-number v-model="picNum" class="Roboto-Bold-12"
                            :style="{ width: '90px', float: 'right', }" mode="button" size="small" :max="4" :min="1" />
                    </div>
                    <div style="background-color: rgba(255 255 255 / 94%)"
                        class="h-[88px] min-w-[290px] bottom-0 left-[140px] p-5 fixed">
                        <EnhanceGenerate @click="onApply" :enable="true" :picNum="picNum"></EnhanceGenerate>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="imgGeneratorList.length > 0"
            class="relative h-3/4 mx-20 m-10 flex-1 flex items-center justify-center ">
            <div class="relative h-full w-full max-w-[554px] shadow-1 rounded-xl overflow-hidden bg-white">
                <EnhanceImageView ref="imageViewRef" :minScale="minScale" :maxScale="maxScale"
                    :url="imgGeneratorList[curIndex].enhanceUrl" @scaleChange="imageViewChange"></EnhanceImageView>
                <img class="mt-4 mr-2 w-6 h-6 absolute right-0 top-0 cursor-pointer"
                    src="/assets/images/functype/AIGenerator/download.png" alt="" @click="downloadImage">
                <EnhanceGiveALike class="absolute right-0 bottom-0" v-show="isEvaluate" :id="enhanceRecordId"
                    @onEvaluate="onEvaluate">
                </EnhanceGiveALike>
            </div>

            <div v-if="imgGeneratorList[curIndex]"
                class="absolute left-1/2 -bottom-36 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max">
                <EnhanceBaseControls class="flex-1 flex justify-center mt-4" ref="baseControlsRef" :minScale="minScale"
                    :maxScale="maxScale" @change="baseCtlScaleChange" @changeMove="baseCtlChangeMove">
                </EnhanceBaseControls>
            </div>
        </div>
        <div class="relative h-3/4 m-10 flex-1 flex items-center justify-center" v-else>
            <div
                class="h-full w-full flex flex-col items-center justify-center  max-w-[554px] min-w-[200px] shadow-1 text-text-gray rounded-xl bg-white">
                <img class="w-52 h-52 mb-10" src="/assets/images/functype/AILogo/Empty.png" alt="">
                <div class="px-6 text-center">{{ $t('enhance-AI-empty-tip') }}</div>
            </div>
        </div>
        <EnhanceFold :isFold="isFold" @change="foldChange">
            <div class="w-full h-full py-8 flex flex-col overflow-hidden transition-all delay-150"
                :class="isFold ? 'pl-6 pr-2' : 'opacity-0'">
                <div class="flex items-center justify-between w-full mb-6 pr-4">
                    <span class="Roboto-Bold-20 text-black">{{ $t('enhance-All-Generate') }}</span>
                    <span class="text-gray">{{ imgGeneratorList.length != 0 ? imgGeneratorList.length : 0 }}</span>
                </div>
                <div class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4"
                    style="max-height: calc(100vh - 196px)">
                    <EnhanceImgItem class="mb-5 last:mb-0" v-for="(item, index) in imgGeneratorList" :key="item"
                        :imgInfo="item" @click="selectImg(index)" @downloadImg="() => downloadImg(index)"
                        :isFinish="props.isFinish">
                    </EnhanceImgItem>
                    <div v-if="imgGeneratorList.length == 0" class="h-full w-full flex justify-center items-center">
                        <EnhanceNoRecords></EnhanceNoRecords>
                    </div>
                </div>
            </div>
        </EnhanceFold>
    </div>
</template>
<script setup>
import { minScale, maxScale } from "@/utils/config";
import { uploadStatus } from "@/utils/type";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
import { useUploadStore } from "@/store/upload";
import { IconEdit, IconPlus } from '@arco-design/web-vue/es/icon';
import {
    maxImageSize, imageAccept
} from "@/utils/config";
const uploadStore = useUploadStore();
const { $encryptor } = useNuxtApp();
const props = defineProps({
    acceptCount: {
        type: Number,
        default: 1,
    },
    fileList: {
        type: Array,
        default: [],
    },
    applyEnabled: {
        type: Boolean,
        default: false,
    },
    isFinish: {
        type: Boolean,
        default: false,
    },
});
const { t } = useI18n();
const picNum = ref(4)
const prompt = ref('')
const picDesc = ref('')
const stylePath = ref("General_poster")
const fileModel = ref(null)
const { isLogin, isVip, isVipPro, checkWeekCount } = useUserInfo()

const route = useRoute();
const { downloadUrlEvent, applyEvent, downloadImgEvent, uploadLocalFilesSuccessEvent, updateFileListEvent } = useCustomEvent();
const styleList = ref([
    { id: 1, value: 'General_poster', name: 'General', src: "/assets/images/functype/AIGenerator/General.png" },
    { id: 2, value: 'Photography_poster', name: 'Photography', src: "/assets/images/functype/AIGenerator/Photography.png" },
    { id: 3, value: 'Anime_poster', name: 'Anime', src: "/assets/images/functype/AIGenerator/Anime.png" },
    { id: 4, value: 'Abstract_poster', name: 'Abstract', src: "/assets/images/functype/AIGenerator/Abstract.png" },
    { id: 5, value: 'Fantasy_poster', name: 'Fantasy', src: "/assets/images/functype/AIGenerator/Fantasy.png" },
    { id: 6, value: 'Retro_poster', name: 'Retro', src: "/assets/images/functype/AIGenerator/Retro.png" },
    { id: 7, value: 'Cyberpunk_poster', name: 'Cyberpunk', src: "/assets/images/functype/AIGenerator/Cyberpunk.png" },
    { id: 8, value: 'Cartoon_poster', name: 'Cartoon', src: "/assets/images/functype/AIGenerator/Cartoon.png" },
    { id: 9, value: '3D_poster', name: '3D', src: "/assets/images/functype/AIGenerator/3D.png" },
    { id: 10, value: 'Illustration_poster', name: 'Illustration', src: "/assets/images/functype/AIGenerator/Illustration.png" },
    { id: 11, value: 'Oilpainting_poster', name: 'Oilpainting', src: "/assets/images/functype/AIGenerator/OilPainting.png" },
    { id: 12, value: 'ChineseStyle_poster', name: 'ChineseStyle', src: "/assets/images/functype/AIGenerator/Chinese-style.png" }
])
const inspirationList = ref([
    {
        key: 1,
        title: "Dynamic concert-style poster",
        src: "/assets/images/functype/AIPoster/inspiration_1.webp",
        text: "Dynamic concert-style poster, neon cyberpunk aesthetic. Central vortex effect draws attention to /AI SUMMIT 2024/ in glowing English hybrid typography. Surround with blockchain nodes floating in chromatic aberration effect. Background: rainy night cityscape with holographic billboards. Color scheme: neon pink and cyan gradients."
    },
    {
        key: 2,
        title: "A city landscape Poster",
        src: "/assets/images/functype/AIPoster/inspiration_2.webp",
        text: "This is a poster depicting a city landscape. The Poster is titled /Poster/ and is displayed in large blue font at the top. A plane is flying in the sky. There is a large yellow sun in the background, just above the horizon, symbolizing dusk. The sky appeared in shades of orange and pink, interspersed by clouds. The city skyline in the distance is made up of multiple tall buildings, including a very tall skyscraper whose shape is similar to Taipei 101. Other buildings vary in height, forming a modern cityscape. In the foreground is a train with a silver body and red stripes. The Windows of the train reflect the surrounding scenery, adding layers to the picture. The train travels on a viaduct, a simple but stable structure that supports the passage of the train. The poster shows a busy and beautiful city scene through rich colors and fine details, combining modern traffic elements and natural beauty."
    },
    {
        key: 3,
        title: "WESTLIFE Poster",
        src: "/assets/images/functype/AIPoster/inspiration_3.webp",
        text: "This is an artistic poster with the words /WESTLIFE/ emblazoned on the bottom. In the center is the silhouette of a man in a wide-brimmed hat against a desolate desert scene in the background. In the setting sun, a rider was driving a horse across the land, and some shadowy figures could be seen in the distance. The whole picture is shrouded in a nostalgic atmosphere, as if telling a story about the West."
    },
    {
        key: 4,
        title: "Smartwatch",
        src: "/assets/images/functype/AIPoster/inspiration_4.webp",
        text: "Minimalist product posters, surreal 3D renderings. The poster says /Smartwatch/ and the smartwatch floats in zero gravity space against a background of heartbeat light waves, battery percentage holograms and gradient segments metallic style Style Reference: The visuals of the Apple product launch come with dribbble style pops of color."
    }
])
const inspText = ref("Dynamic concert-style poster, neon cyberpunk aesthetic. Central vortex effect draws attention to /AI SUMMIT 2024/ in glowing English hybrid typography. Surround with blockchain nodes floating in chromatic aberration effect. Background: rainy night cityscape with holographic billboards. Color scheme: neon pink and cyan gradients.")
const inspIndex = ref(1)
const insPicDesc = ref("Dynamic concert-style poster")
function onTipClick() {
    prompt.value = inspText.value
    picDesc.value = insPicDesc.value
}
function onInspirationClick(params) {
    inspIndex.value = params.key;
    inspText.value = inspirationList.value[params.key - 1].text;
    insPicDesc.value = inspirationList.value[params.key - 1].title;
}
const isEvaluate = ref(false);
const isFold = ref(true);
function foldChange() {
    isFold.value = !isFold.value;
}

const selectedImageIndex = ref(0)
const onClickImg = (res, index) => {
    selectedImageIndex.value = index;
    stylePath.value = res.value
}
const enhanceRecordId = ref('')
const queryZIPTimer = ref(null);
watch(
    () => props.fileList, () => {
        if (props.fileList.length) {
            if (props.fileList[0].enhanceUrl) {
                if (imgGeneratorList.value.length == 0) {
                    imgGeneratorList.value = []
                    imgGeneratorList.value.push({ enhanceUrl: props.fileList[0].enhanceUrl })
                    fileModel.value = []
                    fileModel.value.push(props.fileList[0]);
                    fileModel.value[0].isEvaluate = true
                    enhanceRecordId.value = props.fileList[0].enhanceRecordId;
                    deleteImg()
                    isEvaluate.value = true
                    fullLoading.hide();
                }
            } else if (props.fileList[0].imgList && props.fileList[0].imgList.length) {
                if (imgGeneratorList.value.length == 0) {
                    fileModel.value = props.fileList[0].imgList;
                    fileModel.value[0].isEvaluate = true
                    imgGeneratorList.value = []
                    imgGeneratorList.value = props.fileList[0].imgList;
                    enhanceRecordId.value = props.fileList[0].enhanceRecordId;
                    imgGeneratorList.value.map((e) => {
                        e.enhanceUrl = decryptUrl(e.enhanceUrl)
                    })
                    deleteImg()
                    isEvaluate.value = true
                    fullLoading.hide();
                }
            } else if (props.fileList[0].status == 5) {
                fullLoading.hide();
            }
        } else {
            fileModel.value = null;
            fullLoading.hide();
        }
    },
    { deep: true }
);
const inputRef = ref(null);
const textPromptRef = ref(null);
const tryCount = ref(0);
const isShow = ref(false);
const smearRef = ref(null);
const tryFlag = ref(true);
const baseControlsRef = ref(null);
const imageViewRef = ref(null);
const imgSrc = ref(null);
onMounted(() => {
    // updateECommerceCommonEvent({origin: null, enhance: '' })
})
onUnmounted(() => {
    uploadStore.setImages([]);
    updateFileListEvent([])
    clearInterval(queryZIPTimer.value);
});

function baseCtlScaleChange(scale) {
    if (imageViewRef.value) {
        imageViewRef.value.setScale(scale);
    }
}
function baseCtlChangeMove(isMove) {
    smearRef.value.setMove(scale);
}
const fullLoading = useFullLoading();
function imageViewChange(scale) {
    baseControlsRef.value.setScale(scale);
}
async function onApply() {
    if (isLogin.value) {
        if (prompt.value != '' && picDesc.value != '') {
            imgGeneratorList.value = []
            clearInterval(queryZIPTimer.value);
            if (!imgSrc.value) {
                fullLoading.show(t("enhance-upload-process"), t("enhance-process"));
                const params = {
                    enhanceCfgIds: [68],
                    stylePath: stylePath.value,
                    prompt: prompt.value,
                    picDesc: picDesc.value,
                    picNum: picNum.value,
                    resourceUrls: []
                }
                await useApi.enhanceImg(params).then((result) => {
                    if (result.code == 0) {
                        getImageListResult(result.data, 0);
                    }
                    if (result.code == 300601) {
                        messageBox.error(null, t("errCode-300601"));
                        fullLoading.hide()
                    }
                    if (result.code == 300612) {
                        if (!isVip.value) {
                            messageBox.error(t('errCode-use-limit'), null);
                            mainStore.setVisibleVipDialog(true);
                        } else {
                            messageBox.error(t('errCode-use-limit-vip'), null);
                        }
                        fullLoading.hide()
                    }
                }).catch((e) => {
                    messageBox.error(t("network-err"), t("network-err-try"));
                    fullLoading.hide()
                });
            } else {
                if (checkWeekCount()) {
                    fullLoading.show(t("enhance-upload-process"), t("enhance-process"));
                }
                applyEvent({
                    enhanceCfgIds: [68],
                    stylePath: stylePath.value,
                    prompt: prompt.value,
                    picDesc: picDesc.value,
                    picNum: picNum.value
                });
            }
        } else {
            if (picDesc.value == '') {
                return inputRef.value.focus();
            }
            if (prompt.value == '') {
                if (textPromptRef.value.$refs['textareaRef']) {
                    textPromptRef.value.$refs['textareaRef'].focus();
                }
                // return textareaRef.value.focus();
            }
        }
    } else {
        showLogin()
    }
}
const queryResultTimeout = ref(null);
const imgGeneratorList = ref([]);
function showLogin() {
    mainStore.setVisibleLogin(true);
}
function enhanceError(error) {
    if (tryCount.value < 3) {
        tryCount.value = tryCount.value + 1;
        console.log("加载失败，重试", tryCount.value);
        tryFlag.value = false;
        setTimeout(() => {
            tryFlag.value = true;
        }, 1000);
    }
}
function downloadImg(index) {
    downloadImgEvent({ url: imgGeneratorList.value[index].enhanceUrl });
}

const curIndex = ref(0)
function selectImg(index) {
    curIndex.value = index
}

function onEvaluate() {
    updateFileListEvent(fileModel.value)
    isEvaluate.value = false
}
const formatFailFiles = ref([]);
const sizeFailFiles = ref([]);
const fileList = ref([]);
const timeout = ref();
function debounce(fun, time = 500) {
    if (timeout.value) {
        clearTimeout(timeout.value); // 规定时间内若定时器存在则清除
    }
    timeout.value = setTimeout(() => {
        fun(); // 调用接口
        clearTimeout(timeout.value);
    }, time);
}
function deleteImg() {
    imgSrc.value = ""
}

function onBeforeUpload(file) {
    const suffix = getFileSuffix(file.name).toLowerCase();
    fileList.value = []
    if (imageAccept.includes(suffix)) {
        if (file.size < maxImageSize) {
            fileList.value.push(file);
        } else {
            sizeFailFiles.value.push(file);
        }
    } else {
        formatFailFiles.value.push(file);
    }
    debounce(() => {
        if (fileList.value.length > props.acceptCount) {
            if (isVipPro.value) {
                messageBox.error(t("index-upload"), t("index-upload-limit"));
            } else {
                messageBox.error(
                    t("index-upload-batch"),
                    t("index-upload-batch-limit")
                );
            }
            return;
        }
        imageHandle();
    });
    return false;
}
function imageHandle() {
    if (formatFailFiles.value.length > 0) {
        messageBox.error(
            t("index-upload"),
            t("index-upload-limit-format", { format: imageAccept.join("、") })
        );
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    } else if (sizeFailFiles.value.length > 0) {
        messageBox.error(t("index-upload"), t("index-upload-limit-size"));
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    } else if (fileList.value.length > props.acceptCount) {
        messageBox.error(t("index-upload"), t("index-upload-limit"));
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    }

    let count = 0;
    let hasSizeFail = false;
    fileList.value.map((v) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            let image = new Image();
            image.onload = function () {
                count++;
                if (
                    image.width < minWidth ||
                    image.height > maxWidth ||
                    image.width > maxWidth ||
                    image.height < minWidth
                ) {
                    hasSizeFail = true;
                }
                if (count == fileList.value.length) {

                    if (hasSizeFail) {
                        messageBox.error(t("index-upload"), t("index-upload-limit-width"));
                        imgSrc.value = null
                        return;
                    }
                    onSuccess(fileList.value);
                }
            };
            image.onerror = function () {

                count++;
                if (count == fileList.value.length) {
                    if (hasSizeFail) {
                        messageBox.error(t("index-upload"), t("index-upload-limit-width"));
                        return;
                    }
                    onSuccess(fileList.value);
                }
            };
            image.src = e.target.result;
            imgSrc.value = e.target.result;
        };
        reader.readAsDataURL(v);
    });
}

async function downloadImage() {
    downloadImgEvent({ url: imgGeneratorList.value[curIndex.value].enhanceUrl });
}
function decryptUrl(url) {
    let enhanceUrl = "";
    if (url) {
        $encryptor?.setPrivateKey(privateKey);
        let part1 = url.substring(0, url.length / 2);
        let part2 = url.substring(url.length / 2);
        const dec1 = $encryptor?.decrypt(part1);
        const dec2 = $encryptor?.decrypt(part2);
        enhanceUrl = dec1 + dec2;
    }
    return enhanceUrl;
}
function getImageListResult(id, count) {
    if (count > 200) {
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        return;
    }
    useApi.getResult(id).then((res) => {
        const { imgList, downloadUrl, downloadUrlT, orderStatus } = res.data;
        let isResult = true
        imgList.map((v) => {
            if (v.errorCode) {
                fullLoading.hide();
                isResult = false
                clearTimeout(queryResultTimeout.value);
                messageBox.error(t("enhance-process-fail"), t("network-err-try"));
            } else if (v.imgList) {
                fileModel.value = res.data.imgList;
                fileModel.value[0].isEvaluate = true
                imgGeneratorList.value = []
                imgGeneratorList.value = v.imgList;
                enhanceRecordId.value = v.enhanceRecordId;
                imgGeneratorList.value.map((e) => {
                    e.enhanceUrl = decryptUrl(e.enhanceUrl)
                })
                downloadUrlEvent(downloadUrl ?? "");
                fullLoading.hide();
                isResult = false
                isEvaluate.value = true
                clearTimeout(queryResultTimeout.value);
                mainStore.updateUserInfo();
                if (isLogin.value && isVip.value) {
                    useApi.getWeekCount().then((res) => {
                        if (res.code == 0) {
                            mainStore.setWeekCount(res.data ?? 0);
                        }
                    });
                }
            } else if ((v.enhanceUrl && v.oriHeight) || (v.enhanceUrl && v.tarHeight)) {
                imgGeneratorList.value = []
                imgGeneratorList.value.push({ enhanceUrl: v.enhanceUrl })
                fileModel.value = []
                fileModel.value.push(v);
                fileModel.value[0].isEvaluate = true
                enhanceRecordId.value = v.enhanceRecordId;
                imgGeneratorList.value.map((e) => {
                    e.enhanceUrl = decryptUrl(e.enhanceUrl)
                })
                isResult = false
                isEvaluate.value = true
                downloadUrlEvent(imgGeneratorList.value[0].enhanceUrl ?? "");
                fullLoading.hide();
                clearTimeout(queryResultTimeout.value);
                mainStore.updateUserInfo();
                if (isLogin.value && isVip.value) {
                    useApi.getWeekCount().then((res) => {
                        if (res.code == 0) {
                            mainStore.setWeekCount(res.data ?? 0);
                        }
                    });
                }
            }
        })
        if (isResult) {
            clearTimeout(queryResultTimeout.value);
            queryResultTimeout.value = setTimeout(() => {
                clearTimeout(queryResultTimeout.value);
                getImageListResult(id, count + 1);
            }, 3000);
        } else {
            if (
                !downloadUrl &&
                !downloadUrlT &&
                orderStatus == 0 &&
                (imgGeneratorList.value && imgGeneratorList.value.length > 1)
            ) {
                console.log('检查压缩包');
                queryZIP(id);
            }
        }
    }).catch((e) => {
        fullLoading.hide();
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        console.error(e)
    })
}

function onSuccess(fileList) {
    uploadLocalFilesSuccessEvent(fileList)
}
function queryZIP(id) {
    queryZIPTimer.value = setInterval(() => {
        useApi.getResult(id).then((res) => {
            if (res.code == 0) {
                const { downloadUrl, downloadUrlT } = res.data;
                if (downloadUrl || downloadUrlT) {
                    downloadUrlEvent(downloadUrl || downloadUrlT)
                    clearInterval(queryZIPTimer.value);
                }
            }
        });
    }, 2000);
}
</script>

<style scoped>
.required {
    color: red;
    margin-right: 5px;
}

input,
textarea {
    width: 100%;
    /* 16px */
    border: 2px solid #F7F7F8;
    background-color: #F7F7F8;
    /* 默认边框颜色 */
    border-radius: 0.5rem;
    text-align: left;
    line-height: normal;
    outline: none;
    /* 去掉默认的黑色外边框 */
}

input:focus {
    border: 1px solid #C02CFF;
}

textarea:focus {
    border: 1px solid #C02CFF;
}

.arco-icon {
    color: gary !important
}

.selected {
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px #5F45FF;
    border-radius: 10px;
}

.selected-ins {
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px #5F45FF;
    border-radius: 5px
}

.limited-lines {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    width: auto;
    word-break: break-all;
}
</style>