<template>
    <div class="Roboto-Bold-24 col-span-2">
        {{ $t("Master-Title-1") }}
        <div class="grid grid-cols-2 gap-5 mt-6 text-center Roboto-Bold-16 2xl:grid-cols-5 xl:grid-cols-3">
            <NuxtLink @click="onEvent('Photo_Enhancer')" @mouseover="isHovered1 = true" @mouseout="isHovered1 = false"
                :to="localePath({
                    params: { funcType: 'enhance_pro' },
                    name: 'picma_web-funcType',
                })
                    ">
                <div style="border-color:#4A2DFF15;"
                    class="bg-mainColor-deep-8 hover:bg-mainColor-deep hover:text-white rounded-xl h-20 flex items-center justify-left text-left border hover:shadow-[0_4px_8px_#5E48E650]">
                    <svg v-if="!isHovered1" class="mx-3" width="32" height="32" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_277_7739)">
                            <path
                                d="M7.16766 1.82641C7.22256 1.44209 7.77744 1.44209 7.83234 1.82641C8.22799 4.59595 10.4041 6.77201 13.1736 7.16766C13.5579 7.22256 13.5579 7.77744 13.1736 7.83234C10.4041 8.22799 8.22799 10.4041 7.83234 13.1736C7.77744 13.5579 7.22256 13.5579 7.16766 13.1736C6.77201 10.4041 4.59595 8.22799 1.82641 7.83234C1.44209 7.77744 1.44209 7.22256 1.82641 7.16766C4.59595 6.77201 6.77201 4.59595 7.16766 1.82641Z"
                                fill="#1F1F1F"
                                style="fill:#1F1F1F;fill:color(display-p3 0.1216 0.1216 0.1216);fill-opacity:1;" />
                            <path
                                d="M13.0539 10.8728C13.0863 10.6461 13.4137 10.6461 13.4461 10.8728C13.5706 11.7445 14.2555 12.4294 15.1272 12.5539C15.3539 12.5863 15.3539 12.9137 15.1272 12.9461C14.2555 13.0706 13.5706 13.7555 13.4461 14.6272C13.4137 14.8539 13.0863 14.8539 13.0539 14.6272C12.9294 13.7555 12.2445 13.0706 11.3728 12.9461C11.1461 12.9137 11.1461 12.5863 11.3728 12.5539C12.2445 12.4294 12.9294 11.7445 13.0539 10.8728Z"
                                fill="#1F1F1F"
                                style="fill:#1F1F1F;fill:color(display-p3 0.1216 0.1216 0.1216);fill-opacity:1;" />
                        </g>
                        <defs>
                            <clipPath id="clip0_277_7739">
                                <rect width="16" height="16" fill="white" style="fill:white;fill-opacity:1;" />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg v-if="isHovered1" class="mx-3" width="32" height="32" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_161_6331)">
                            <path
                                d="M7.16766 1.82641C7.22256 1.44209 7.77744 1.44209 7.83234 1.82641C8.22799 4.59595 10.4041 6.77201 13.1736 7.16766C13.5579 7.22256 13.5579 7.77744 13.1736 7.83234C10.4041 8.22799 8.22799 10.4041 7.83234 13.1736C7.77744 13.5579 7.22256 13.5579 7.16766 13.1736C6.77201 10.4041 4.59595 8.22799 1.82641 7.83234C1.44209 7.77744 1.44209 7.22256 1.82641 7.16766C4.59595 6.77201 6.77201 4.59595 7.16766 1.82641Z"
                                fill="white" style="fill:white;fill-opacity:1;" />
                            <path
                                d="M13.0539 10.8728C13.0863 10.6461 13.4137 10.6461 13.4461 10.8728C13.5706 11.7445 14.2555 12.4294 15.1272 12.5539C15.3539 12.5863 15.3539 12.9137 15.1272 12.9461C14.2555 13.0706 13.5706 13.7555 13.4461 14.6272C13.4137 14.8539 13.0863 14.8539 13.0539 14.6272C12.9294 13.7555 12.2445 13.0706 11.3728 12.9461C11.1461 12.9137 11.1461 12.5863 11.3728 12.5539C12.2445 12.4294 12.9294 11.7445 13.0539 10.8728Z"
                                fill="white" style="fill:white;fill-opacity:1;" />
                        </g>
                        <defs>
                            <clipPath id="clip0_161_6331">
                                <rect width="16" height="16" fill="white" style="fill:white;fill-opacity:1;" />
                            </clipPath>
                        </defs>
                    </svg>
                    {{ $t("header-hot-tools-1") }}
                </div>
            </NuxtLink>
            <NuxtLink @click="onEvent('Object_Remover')" @mouseover="isHovered4 = true" @mouseout="isHovered4 = false"
                :to="localePath({
                    params: { funcType: 'object_remover' },
                    name: 'picma_web-funcType',
                })
                    ">
                <div style="border-color:#4A2DFF15;"
                    class="bg-mainColor-deep-8 hover:bg-mainColor-deep hover:text-white rounded-xl h-20 flex items-center justify-left text-left border hover:shadow-[0_4px_8px_#5E48E650]">
                    <img v-if="!isHovered4" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Object Remover-2.svg"
                        alt="" />
                    <img v-if="isHovered4" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Object Remover-1.svg"
                        alt="" />
                    {{ $t("object_remover") }}
                </div>
            </NuxtLink>
            <NuxtLink @click="onEvent('Descratch')" @mouseover="isHovered5 = true" @mouseout="isHovered5 = false" :to="localePath({
                params: { funcType: 'descratch' },
                name: 'picma_web-funcType',
            })
                ">
                <div style="border-color:#4A2DFF15;"
                    class="bg-mainColor-deep-8 hover:bg-mainColor-deep hover:text-white rounded-xl h-20 flex items-center justify-left text-left border hover:shadow-[0_4px_8px_#5E48E650]">
                    <img v-if="!isHovered5" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Descratch-2.svg"
                        alt="" />
                    <img v-if="isHovered5" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Descratch-1.svg"
                        alt="" />
                    {{ $t("descratch") }}
                </div>
            </NuxtLink>
            <NuxtLink @click="onEvent('Background_Remover')" @mouseover="isHovered6 = true"
                @mouseout="isHovered6 = false" :to="localePath({
                    params: { funcType: 'image_background_remover' },
                    name: 'picma_web-funcType',
                })
                    ">
                <div style="border-color:#4A2DFF15;"
                    class="bg-mainColor-deep-8 hover:bg-mainColor-deep hover:text-white rounded-xl h-20 flex items-center justify-left text-left border hover:shadow-[0_4px_8px_#5E48E650]">
                    <img v-if="!isHovered6" class="h-8 w-8 mx-3"
                        src="/assets/images/enhance/icons/Image Background Remover-2.svg" alt="" />
                    <img v-if="isHovered6" class="h-8 w-8 mx-3"
                        src="/assets/images/enhance/icons/Image Background Remover-1.svg" alt="" />
                    {{ $t("image_background_remover") }}
                </div>
            </NuxtLink>
            <NuxtLink @click="onEvent('Color_Master')" @mouseover="isHovered7 = true" @mouseout="isHovered7 = false"
                :to="localePath({
                    params: { funcType: 'color_master' },
                    name: 'picma_web-funcType',
                })
                    ">
                <div style="border-color:#4A2DFF15;"
                    class="bg-mainColor-deep-8 hover:bg-mainColor-deep hover:text-white rounded-xl h-20 flex items-center justify-left text-left border hover:shadow-[0_4px_8px_#5E48E650]">
                    <img v-if="!isHovered7" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Color Master-2.svg"
                        alt="" />
                    <img v-if="isHovered7" class="h-8 w-8 mx-3" src="/assets/images/enhance/icons/Color Master-1.svg"
                        alt="" />
                    {{ $t("color_master") }}
                </div>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n();
const localePath = useLocalePath();
const isHovered1 = ref(false);
const isHovered4 = ref(false);
const isHovered5 = ref(false);
const isHovered6 = ref(false);
const isHovered7 = ref(false);
function onEvent(params) {
    useLogEvent({ eventName: `center-home-${params}` });
}
</script>