<template>
  <div class=" z-[999] hover:bg-black sticky top-0 border-b border-[#FFFFFF33]"
    :class="[showMenus || sticky ? 'bg-black' : 'backdrop-blur-xl bg-[#00000033]', !visabled ? 'opacity-0' : '']">
    <div class="h-header m-auto max-w-[1400px] flex items-center justify-center sm:px-4">
      <!-- logo -->
      <nuxt-link :to="localePath({ name: 'index' })" :prefetch="false">
        <img src="/assets/images/logolight.webp" alt="logo" class="w-[100px] lg:w-[226px]" width="226" height="50" />
      </nuxt-link>
      <!-- 菜单 -->
      <ul class="flex-1 flex items-center justify-center gap-16 text-lg font-Roboto-Bold h-full font-bold ">
        <li class="cursor-pointer text-white-75 flex items-center hover:text-white h-full" @mouseenter="mouseenter"
          @mouseleave="mouseleave">
          <span class="mr-2">{{ $t("header-AI-Tools") }}</span>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6L8 11L13 6" stroke="white" stroke-width="2" stroke-linecap="square" />
          </svg>
        </li>
        <li class="cursor-pointer text-white-75 flex items-center hover:text-white">
          <nuxt-link @click="onEvent('blog')" :class="{ 'text-white': route?.path.endsWith('/blog') }"
            :to="localePath({ name: 'blog' })" :prefetch="false">
            {{ $t("header-blog") }}
          </nuxt-link>
        </li>
        <li class="cursor-pointer text-white-75 flex items-center hover:text-white">
          <nuxt-link @click="onEvent('apiServices')" :class="{
            'text-white': route?.path.endsWith('/apiServices'),
          }" :to="localePath({ name: 'apiServices' })" :prefetch="false">
            {{ $t("header-apiServices") }}
          </nuxt-link>
        </li>
        <li class="cursor-pointer text-white-75 flex items-center hover:text-white">
          <span @click="onPrice">
            {{ $t("header-price") }}
          </span>
        </li>
        <li class="cursor-pointer text-white-75 flex items-center hover:text-white">
          <nuxt-link :class="{ 'text-white': route?.path.endsWith('/support') }" :to="localePath({ name: 'support' })"
            :prefetch="false">
            {{ $t("header-helpCenter") }}
          </nuxt-link>
        </li>
      </ul>
      <div class="h-full flex items-center gap-4">
        <div v-show="!isLogin"
          class="h-9 leading-9 text-center rounded-full text-white px-6 font-Roboto-Bold text-sm border border-white-line hover:border-white cursor-pointer"
          @click="showLogin">
          {{ $t("header-login") }}
        </div>
        <GradientsBtn id="sign-up" v-if="!isLogin" class="h-9" :text="$t('login-sign-up')" @click="showSignUp"
          :shadow="false">
        </GradientsBtn>
        <!-- 登录用户，非会员和普通会员显示 -->
        <HeaderCreditsBtn v-if="isLogin && (isNormalVip || !isVipPro)"></HeaderCreditsBtn>
        <div v-if="isLogin" class="h-full flex items-center absolute right-[5%]" @mouseenter="enterBaseInfo"
          @mouseleave="leaveBaseInfo">
          <HeaderAvatar></HeaderAvatar>
        </div>
      </div>
    </div>
    <div class="fixed top-[80px] right-[20px]" v-if="isLogin" v-show="showBaseInfo" @mouseenter="enterBaseInfo"
      @mouseleave="leaveBaseInfo">
      <HeaderBaseInfo> </HeaderBaseInfo>
    </div>
    <!-- 展开菜单 -->
    <div class="fixed top-header left-0 right-0" v-show="showMenus" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <HeaderExpandMenus @onToPath="mouseleave"></HeaderExpandMenus>
    </div>
  </div>
</template>
<script setup>
import { useMainStore } from "@/store/index";
const { locale, t } = useI18n();
const {
  guideLoginEvent,
} = useCustomEvent();
const mainStore = useMainStore();
const route = useRoute();
const localePath = useLocalePath();
const { isNormalVip, isVip, isVipPro, isLogin } = useUserInfo();

const visabled = ref(false);
const sticky = ref(false);
const opacity = ref(1);
const scrollY = ref(0);

const showMenus = ref(false);
const showBaseInfo = ref(false);
const timer = ref()
onMounted(() => {
  document.addEventListener("scroll", onScroll, { passive: true });
  window.addEventListener("logoutEvent", logoutEvent);
  setTimeout(() => {
    visabled.value = true
  }, 100);
});
onUnmounted(() => {
  document.removeEventListener("scroll", onScroll);
  window.removeEventListener("logoutEvent", logoutEvent);
});

function onScroll(e) {

  // console.log(window.scrollY);

  if (window.scrollY > 100) {
    sticky.value = true;
    // if (window.scrollY - scrollY.value > 0) {
    //   sticky.value = true;
    // } else {
    //   sticky.value = false;
    // }

  } else {
    // opacity.value = 1 - window.scrollY / 100;
    sticky.value = false;
  }
  scrollY.value = window.scrollY;
}

function showLogin() {
  guideLoginEvent(false);
  mainStore.setVisibleLogin(true);
}

function onPrice() {
  mainStore.setVipFrom("header_vip");
  mainStore.setVisibleVipDialog(true);
  useLogEvent({ eventName: 'header-onPrice' });
}
function onEvent(record) {
  useLogEvent({ eventName: `header-${record}` });
}
function mouseenter() {
  if (timer.value) {
    clearTimeout(timer.value)
  }
  showMenus.value = true;
}
function mouseleave() {
  timer.value = setTimeout(() => {
    showMenus.value = false;
  }, 200)
}
function showSignUp() {
  guideLoginEvent(false);
  mainStore.setVisibleSignUp(true);
}
function onHideMenus() {
  showMenus.value = false;
}
function enterBaseInfo() {
  // if(isLogin.value)
  showBaseInfo.value = true;
}
function leaveBaseInfo() {
  showBaseInfo.value = false;
}
function logoutEvent(params) {
  showBaseInfo.value = false;
}
</script>
<style scoped lang="scss">
.bg {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
