<template>
    <div v-if="getView(props.showfun)"
        class="xl:w-[1300px] w-[900px] h-[120px] grid grid-cols-8 rounded-3xl bg-white text-center flex items-center justify-center shadow mt-10">
        <div class="grid gap-2 col-span-2">
            <div class="mb-2 Roboto-Bold-28"> 100M+ </div>
            <div class="Roboto-14 px-3" style="color:#818181">{{ $t('Image-Processed') }} </div>
        </div>
        <div class="grid gap-2 col-span-2">
            <div class="mb-2 Roboto-Bold-28"> 10M+ </div>
            <div class="Roboto-14 px-3" style="color:#818181"> {{ $t('Individual-Users') }} </div>
            <a-divider direction="vertical" class="absolute top-1/2 h-10" />
        </div>
        <div class="grid gap-2 col-span-2">
            <div class="mb-2 Roboto-Bold-28"> 100+ </div>
            <div class="Roboto-14 px-3" style="color:#818181"> {{ $t('Enterprise-Users') }}</div>
            <a-divider direction="vertical" class="absolute top-1/2 h-10" />
        </div>
        <div class="grid gap-2 col-span-2">
            <div class="mb-2 Roboto-Bold-28">3 Secs</div>
            <div class="Roboto-14 px-3" style="color:#818181">{{ $t('Processing-Speed') }}</div>
            <a-divider direction="vertical" class="absolute top-1/2 h-10" />
        </div>
    </div>
    <div v-else class="w-[1300px] h-fit text-center">
        <div class="flex items-center justify-center">
            <div
                class="w-[160px] h-[241px] lg:w-[300px] lg:h-[363px] md:w-[240px] md:h-[343px] rounded-[40px] shadow flex flex-col items-center bg-white">
                <NuxtImg width="60" height="58" alt="01" loading="lazy" class="md:w-[60px] w-[30px] mt-0 md:mt-6"
                    src="/assets/images/functype/allTools/newImgs/01.webp">
                </NuxtImg>
                <NuxtImg width="200" height="150" alt="tools-upload" loading="lazy" class="md:w-[200px] w-[120px]"
                    src="/assets/images/functype/allTools/newImgs/tools-upload.webp"></NuxtImg>
                <div class="Roboto-Bold-18 my-1 md:my-4 md:Roboto-Bold-24">{{ $t("tools-content-1") }}</div>
                <div class="Roboto-12 text-text-gray px-2 md:Roboto-16">{{ $t("tools-desc-1") }}</div>
            </div>
            <NuxtImg class="mx-2 md:w-[84px] w-[42px]" width="84" height="84" alt="tools-arrow" loading="lazy"
                src="/assets/images/functype/allTools/newImgs/tools-arrow.webp"></NuxtImg>
            <div
                class="w-[160px] h-[241px] lg:w-[300px] lg:h-[363px] md:w-[240px] md:h-[343px] rounded-[40px] shadow flex flex-col items-center bg-white">
                <NuxtImg width="60" height="58" alt="02" loading="lazy" class="md:w-[60px] w-[30px] mt-0 md:mt-6"
                    src="/assets/images/functype/allTools/newImgs/02.webp">
                </NuxtImg>
                <NuxtImg width="200" height="150" alt="tools-processing" loading="lazy" class="md:w-[200px] w-[120px]"
                    src="/assets/images/functype/allTools/newImgs/tools-processing.webp"></NuxtImg>
                <div class="Roboto-Bold-18 my-1 md:my-4 md:Roboto-Bold-24">{{ $t("tools-content-2") }}</div>
                <div class="Roboto-12 text-text-gray px-2 md:Roboto-16">{{ $t("tools-desc-2") }}</div>
            </div>
            <NuxtImg class="mx-2 md:w-[84px] w-[42px]" width="84" height="84" alt="tools-arrow" loading="lazy"
                src="/assets/images/functype/allTools/newImgs/tools-arrow.webp"></NuxtImg>
            <div
                class="w-[160px] h-[241px] lg:w-[300px] lg:h-[363px] md:w-[240px] md:h-[343px] rounded-[40px] shadow flex flex-col items-center bg-white">
                <NuxtImg width="60" height="58" alt="03" loading="lazy" class="md:w-[60px] w-[30px] mt-0 md:mt-6"
                    src="/assets/images/functype/allTools/newImgs/03.webp">
                </NuxtImg>
                <NuxtImg v-if="props.resultIcon" width="200" height="150" alt="tools-result" loading="lazy"
                    class="md:w-[200px] w-[120px]" src="/assets/images/functype/allTools/newImgs/tools-result.webp">
                </NuxtImg>
                <NuxtImg v-else width="200" height="150" alt="tools-result" loading="lazy"
                    class="md:w-[200px] w-[120px]" src="/assets/images/functype/allTools/newImgs/tools-result-2.webp">
                </NuxtImg>
                <div class="Roboto-Bold-18 my-1 md:my-4 md:Roboto-Bold-24">{{ $t("tools-content-3") }} </div>
                <div class="Roboto-12 text-text-gray px-2 md:Roboto-16">{{ $t("tools-desc-3") }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n();
const props = defineProps({
    showfun: {
        type: String,
        default: "",
    },
    resultIcon: {
        type: Boolean,
        default: false,
    }
});
function getView(params) {
    const res1 = [t("enhance_pro"), t("word_hd"), t("color_master"), t("stable_motion_ai"), t("descratch"), t("video_converter"), t("video_compressor")];
    const res2 = [t("image_compressor"), t("image_converter"), t("face_cutout"), t("ai_background_generator"), t("video_enhance"), t("video_background_remover"), t("watermark_remover")];
    if (res1.includes(params)) {
        return true;
    }
    if (res2.includes(params)) {
        return false;
    }
}
</script>
<style scoped>
.shadow {
    box-shadow: 0px 8px 30px 0px #C8C8E840;
}
</style>