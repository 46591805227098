<template>
  <div v-if="!isClose" class="cookie-container">
    <div class="border" />

    <div class="main">
      <p class="tip">
        <span>{{ $t("cookiePolicy-tip1") }}</span>
        <a :href="cookiePolicy" target="_blank">{{
          $t("cookiePolicy-tip2")
        }}</a>
        <span>{{ $t("cookiePolicy-tip3") }}</span>
      </p>
      <div class="btns">
        <div class="reject" @click="reject">{{ $t("reject") }}</div>
        <div class="accept" @click="accept">{{ $t("accept") }}</div>
      </div>

    </div>
  </div>
</template>
<script setup>
import { cookiePolicy } from "@/utils/config";
const isClose = ref(true);
onMounted(() => {
  isClose.value = !!localStorage.isCloseCookie;
});
function reject() {
  isClose.value = true;
  localStorage.isCloseCookie = 1;
  useLogEvent({ eventName: "cookies-reject"});
}
function accept() {
  isClose.value = true;
  localStorage.isCloseCookie = 1;
  useLogEvent({ eventName: "cookies-accept"});
}
</script>

<style scoped lang="scss">
.cookie-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // height: 104px;
  background: #fff;
  text-align: center;
  z-index: 100;

  .border {
    height: 1px;
    background: $linear-gradient-vip;
  }

  .main {
    margin: 0 auto;
    max-width: 1200px;
    // height: 103px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 15px 0;
    .tip {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $text-deep;
      flex: 1;
      a {
        color: $mainColor;
      }
    }

    img {
      padding: 20px;
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
    .btns {
      display: flex;
      .reject,
      .accept {
        height: 34px;
        padding: 0 20px;
        line-height: 34px;
        color: $text-deep;
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        border-radius: 16px;
        cursor: pointer;
      }
      .reject {
        border: 1px solid $gray-middle;
      }
      .accept {
        color: #fff;
        border: 1px solid $gray-middle;
        background-color: $mainColor;
        margin-left: 16px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .cookie-container {
    .main {
      width: 100%;
      flex-direction: column;
      padding: 15px;
      .btns {
        margin-top: 10px;
      }
    }
  }
}
</style>
