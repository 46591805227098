interface eventParams {
  track?: string;
  eventName: string;
  eventParams?: Record<string, any>;
  needPrefix?: boolean;
  fbEvent?: boolean;
}
interface fbEventParams {
  track?: string;
  eventName: string;
  eventParams?: Record<string, any>;
  needPrefix?: boolean;
}
export const useLogEvent = ({
  track = "trackCustom",
  eventName = "",
  eventParams = {},
  needPrefix = true,
  fbEvent = true
}: eventParams) => {
  let name = eventName;
  if (needPrefix) {
    name = "web-" + name;
  }
  useTrackEvent(name,eventParams);
  // fb事件
  if(fbEvent && window.fbq ){
    fbq(track,name,eventParams)
  }
};
export const useFBLogEvent = ({
  track = "trackCustom",
  eventName = "",
  eventParams = {},
  needPrefix = true
}: eventParams) => {
  let name = eventName;
  if (needPrefix) {
    name = "web-" + name;
  }
  if(window.fbq){
    fbq(track,name,eventParams)
  }
};
