<template>
    <div>
        <div v-for="(item, index) in list?.info" :value="index" :key="index" class="flex justify-center items-center">
            <div class="xl:w-[1280px] mx-4 mb-20 h-[421px] rounded-[60px] text-center px-14 relative flex items-center"
                :class="{ 'absolute -translate-y-[100px]': list.path == $t('image_background_remover'), 'shadow': autoAnimation1, 'absolute -translate-y-[50px]': list.path == $t('ai_e_commerce') || list.path == $t('object_remover') }"
                v-if="item.imgPosition == 'left' && item.key == 1" @mouseenter="onAnimation1(item.key, index + 1)"
                @mouseleave="onAnimation1(item.key)">
                <div class="min-w-[482px] max-w-[482px] h-full py-[30px] flex items-center justify-center">
                    <div v-if="item.imgAfter == ''" class="relative">
                        <NuxtImg width="482" height="320" :alt="list.path" loading="lazy"
                            class="rounded-[30px] overflow-hidden w-full h-full" :src="item.imgBefore">
                        </NuxtImg>
                        <div v-if="item.imgs">
                            <NuxtImg v-for="(i, t) in item.imgs" :value="t" :key="t" :src="i.img.arrowIcon"
                                :width="i.img.width" :height="i.img.height" alt="arrow"
                                :class="i.img.class1 + (autoAnimation1 ? i.img.class2 : '')" loading="lazy">
                            </NuxtImg>
                        </div>
                    </div>
                    <CompareImg v-else class="rounded-[30px] overflow-hidden" :origin="item.imgBefore"
                        :enhance="item.imgAfter" :labebType="1" :autoAnimation="autoAnimation1"></CompareImg>
                </div>
                <div class="h-full w-full flex flex-col text-left gap-4 p-[30px] justify-center">
                    <div style="line-height: 36px;" class="Roboto-Bold-32 line-clamp-[2]">{{ item.title }}</div>
                    <div style="line-height: 26px;letter-spacing: 0px;"
                        class="mt-[20px] Roboto-16 text-text-gray line-clamp-[8]">
                        {{ item.desc }}
                    </div>
                    <nuxt-link v-if="list.path == t('ai_e_commerce')" :to="localePath({
                        name: 'picma_web-enhance-funcType',
                        params: { funcType: 'ai_e_commerce' },
                        query: { id: item.path == 1 ? 'ai_product_image' : 'ai_logo' },
                    })">
                        <button @mouseenter="buttonMove = true" @mouseleave="buttonMove = false"
                            class="max-w-fit h-[48px] bg-white rounded-full Roboto-Bold-18 content-center items-center px-4 border border-black mt-4">
                            <div class="text-button Roboto-Bold-20 flex items-center justify-center px-4">
                                {{ $t("useNow") }} <img class="w-[16px] h-[16px] ml-2"
                                    :class="{ 'arrow-move-lr': buttonMove }"
                                    src="/assets/images/functype/allTools/newImgs/black-arrow-right.webp" alt="useNow">
                            </div>
                        </button>
                    </nuxt-link>
                </div>
            </div>
            <div class="xl:w-[1280px] mx-4 mb-20 mb-20 h-[421px] rounded-[60px] text-center px-14 relative shadow  flex items-center"
                :class="{ 'absolute -translate-y-[100px]': list.path == $t('image_background_remover'), 'absolute -translate-y-[50px]': list.path == $t('ai_e_commerce') || list.path == $t('object_remover') }"
                v-else-if="item.imgPosition == 'left' && item.key == 3" @mouseenter="onAnimation3(item.key, index + 1)"
                @mouseleave="onAnimation3(item.key)">
                <div class="min-w-[482px] max-w-[482px] h-full py-[30px] flex items-center justify-center">
                    <div v-if="item.imgAfter == ''" class="relative">
                        <NuxtImg v-if="list.path != t('image_background_remover')" width="482" height="320"
                            :alt="list.path" loading="lazy" class="rounded-[30px] overflow-hidden w-full h-full"
                            :src="item.imgBefore">
                        </NuxtImg>
                        <div class="w-[482px] " v-else>
                            <ImgBackgroundRemoverReview></ImgBackgroundRemoverReview>
                        </div>
                        <div v-if="item.imgs">
                            <NuxtImg v-for="(i, t) in item.imgs" :value="t" :key="t" :src="i.img.arrowIcon"
                                :width="i.img.width" :height="i.img.height" alt="arrow"
                                :class="i.img.class1 + (autoAnimation3 ? i.img.class2 : '')" loading="lazy">
                            </NuxtImg>
                        </div>
                    </div>
                    <CompareImg v-else class="rounded-[30px] overflow-hidden" :origin="item.imgBefore"
                        :enhance="item.imgAfter" :labebType="1" :autoAnimation="autoAnimation3"></CompareImg>
                </div>
                <div class="h-full w-fit flex flex-col justify-center text-left gap-4 p-[30px]">
                    <div style="line-height: 36px;" class="Roboto-Bold-32 line-clamp-[2]">{{ item.title }}</div>
                    <div style="line-height: 26px;letter-spacing: 0px;"
                        class="mt-[20px] Roboto-16 text-text-gray line-clamp-[8]">
                        {{ item.desc }}
                    </div>
                    <nuxt-link v-if="list.path == t('ai_e_commerce')" :to="localePath({
                        name: 'picma_web-enhance-funcType',
                        params: { funcType: 'ai_e_commerce' },
                        query: { id: item.path == 1 ? 'ai_logo' : 'ai_product_image' },
                    })">
                        <button @mouseenter="buttonMove3 = true" @mouseleave="buttonMove3 = false"
                            class="max-w-fit h-[48px] bg-white rounded-full Roboto-Bold-18 content-center items-center px-4 border border-black mt-4">
                            <div class="text-button Roboto-Bold-20 flex items-center justify-center px-4">
                                {{ $t("useNow") }} <img class="w-[16px] h-[16px] ml-2"
                                    :class="{ 'arrow-move-lr': buttonMove3 }"
                                    src="/assets/images/functype/allTools/newImgs/black-arrow-right.webp" alt="useNow">
                            </div>
                        </button>
                    </nuxt-link>
                </div>
            </div>
            <div class="xl:w-[1280px] mx-4 mb-20 h-[421px] rounded-[60px] text-center px-14 relative shadow flex items-center"
                :class="{ 'absolute -translate-y-[100px]': list.path == $t('image_background_remover'), 'absolute -translate-y-[50px]': list.path == $t('ai_e_commerce') || list.path == $t('object_remover') }"
                v-else @mouseenter="onAnimation2(item.key, index + 1)" @mouseleave="onAnimation2(item.key)">
                <div class="h-full w-full flex flex-col justify-center text-left gap-4 p-[30px]">
                    <div style="line-height: 36px;" class="Roboto-Bold-32 line-clamp-[2]">{{ item.title }}</div>
                    <div style="line-height: 26px;letter-spacing: 0px;"
                        class="mt-[20px] Roboto-16 text-text-gray line-clamp-[8]">
                        {{ item.desc }}
                    </div>
                    <nuxt-link v-if="list.path == t('ai_e_commerce')" :to="localePath({
                        name: 'picma_web-enhance-funcType',
                        params: { funcType: 'ai_e_commerce' },
                        query: { id: 'partial_redraw' },
                    })">
                        <button @mouseenter="buttonMove1 = true" @mouseleave="buttonMove1 = false"
                            class="max-w-fit h-[48px] bg-white rounded-full Roboto-Bold-18 content-center items-center px-4 border border-black mt-4">
                            <div class="text-button Roboto-Bold-20 flex items-center justify-center px-4">
                                {{ $t("useNow") }}
                                <img class="w-[16px] h-[16px] ml-2" :class="{ 'arrow-move-lr': buttonMove1 }"
                                    src="/assets/images/functype/allTools/newImgs/black-arrow-right.webp" alt="useNow">
                            </div>
                        </button>
                    </nuxt-link>
                </div>
                <div class="h-full min-w-[482px] max-w-[482px] py-[30px] flex items-center justify-center">
                    <div v-if="item.imgAfter == ''" class="relative">
                        <NuxtImg width="482" height="320" :alt="list.path" loading="lazy"
                            class="rounded-[30px] overflow-hidden w-full h-full" :src="item.imgBefore">
                        </NuxtImg>
                        <div v-if="item.imgs">
                            <NuxtImg v-for="(i, t) in item.imgs" :value="t" :key="t" :src="i.img.arrowIcon"
                                :width="i.img.width" :height="i.img.height" alt="arrow"
                                :class="i.img.class1 + (autoAnimation2 ? i.img.class2 : '')" loading="lazy">
                            </NuxtImg>
                        </div>
                    </div>
                    <CompareImg v-else class="rounded-[30px] overflow-hidden" :origin="item.imgBefore"
                        :enhance="item.imgAfter" :labebType="1" :autoAnimation="autoAnimation2"></CompareImg>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ImgBackgroundRemoverReview from '../index/ImgBackgroundRemoverReview.vue';

const { t } = useI18n();
const localePath = useLocalePath();
const props = defineProps({
    showfun: {
        type: String,
        default: "",
    },
});
const autoAnimation1 = ref(false)
const autoAnimation2 = ref(false)
const autoAnimation3 = ref(false)
const buttonMove = ref(false)
const buttonMove1 = ref(false)
const buttonMove3 = ref(false)
const list = ref({})
function onAnimation1(params, index) {
    autoAnimation1.value = (params == index ? true : false)
}
function onAnimation2(params, index) {
    autoAnimation2.value = (params == index ? true : false)
}
function onAnimation3(params, index) {
    autoAnimation3.value = (params == index ? true : false)
}
const infoList = ref([
    {
        path: t('enhance_pro'),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/enhance-before.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/enhance-after.webp",
                title: t("Enhancer-tools-title-1"),
                desc: t("Enhancer-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/enhance-before_1.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/enhance-after_2.webp",
                title: t("Enhancer-tools-title-2"),
                desc: t("Enhancer-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/enhance-static.webp",
                imgAfter: "",
                title: t("Enhancer-tools-title-3"),
                desc: t("Enhancer-tools-desc-3"),
            },
        ]
    },
    {
        path: t('word_hd'),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/text-before.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/text-after.webp",
                title: t("Text-tools-title-1"),
                desc: t("Text-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/text-enhance.webp",
                imgAfter: "",
                title: t("Text-tools-title-2"),
                desc: t("Text-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/text-before_1.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/text-after_2.webp",
                title: t("Text-tools-title-3"),
                desc: t("Text-tools-desc-3"),
            },
        ]
    },
    {
        path: t('color_master'),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/color-before.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/color-after.webp",
                title: t("Color-tools-title-1"),
                desc: t("Color-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/color-before_1.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/color-after_1.webp",
                title: t("Color-tools-title-2"),
                desc: t("Color-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/color-before_2.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/color-after_2.webp",
                title: t("Color-tools-title-3"),
                desc: t("Color-tools-desc-3"),
            },
        ]
    },
    {
        path: t("stable_motion_ai"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/Stable_static.webp",
                imgAfter: "",
                title: t("StableMotion-tools-title-1"),
                desc: t("StableMotion-tools-desc-1"),
                imgs: [{
                    img: {
                        width: 65, height: 60, class1: 'absolute top-[60%] left-[48%] ', class2: " arrow-move-lr", arrowIcon: "/assets/images/functype/allTools/newImgs/stablemotion-arrow.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/Stable-before_1.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/Stable-after_1.webp",
                title: t("StableMotion-tools-title-2"),
                desc: t("StableMotion-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/Stable-before_2.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/Stable-after_2.webp",
                title: t("StableMotion-tools-title-3"),
                desc: t("StableMotion-tools-desc-3"),
            },
        ]
    },
    {
        path: t("descratch"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/Descratch-static_1.webp",
                imgAfter: "",
                title: t("Descratch-tools-title-1"),
                desc: t("Descratch-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/Descratch-before_2.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/Descratch-after_2.webp",
                title: t("Descratch-tools-title-2"),
                desc: t("Descratch-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/Descratch-static_2.webp",
                imgAfter: "",
                title: t("Descratch-tools-title-3"),
                desc: t("Descratch-tools-desc-3"),
            },
        ]
    },
    {
        path: t("image_compressor"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/compressor-static_1.webp",
                imgAfter: "",
                title: t("ImageCompressor-tools-title-1"),
                desc: t("ImageCompressor-tools-desc-1"),
                imgs: [{
                    img: {
                        width: "100%", height: 51, class1: 'px-10 absolute top-1/2 inset-0 flex items-center justify-center ', class2: " cursor-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/cursor.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/compressor-static_2.webp",
                imgAfter: "",
                title: t("ImageCompressor-tools-title-2"),
                desc: t("ImageCompressor-tools-desc-2"),
                imgs: [{
                    img: {
                        width: "100%", height: 51, class1: 'absolute top-1/2 inset-0 ml-[30%] lg:h-[51px] h-[30px]', class2: " arrow-move-lrd", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right-down.webp",
                    },
                }]
            },
        ]
    },
    {
        path: t("image_converter"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/converter-static_1.webp",
                imgAfter: "",
                title: t("ImageConverter-tools-title-1"),
                desc: t("ImageConverter-tools-desc-1"),
                imgs: [{
                    img: {
                        width: "100%", height: 50, class1: 'absolute top-1/3 inset-1/2 ', class2: " arrow-move-lr", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/converter-static_2.webp",
                imgAfter: "",
                title: t("ImageConverter-tools-title-2"),
                desc: t("ImageConverter-tools-desc-2"),
                imgs: [{
                    img: {
                        width: "100%", height: 158, class1: 'absolute top-[50%] inset-[50%] ', class2: "commerce-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/converter-up-down.webp",
                    },
                }]
            },
        ]
    },
    {
        path: t("ai_e_commerce"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/commerce-static_1.webp",
                imgAfter: "",
                title: t("AlCommerce-tools-title-1"),
                desc: t("AlCommerce-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/commerce-static_2.webp",
                imgAfter: "",
                title: t("AlCommerce-tools-title-2"),
                desc: t("AlCommerce-tools-desc-2"),
                imgs: [{
                    img: {
                        width: "100%", height: 50, class1: 'absolute top-[20%] inset-[35%] ', class2: " arrow-move-lr2", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right-down_1.webp",
                    },
                }]
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/commerce-static_3.webp",
                imgAfter: "",
                title: t("AlCommerce-tools-title-3"),
                desc: t("AlCommerce-tools-desc-3"),
                imgs: [
                    {
                        img: {
                            width: "100%", height: 256, class1: 'absolute top-10 inset-[30%] w-[40%] ', class2: " commerce-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/commerce-updown.webp",
                        }
                    },
                    {
                        img: {
                            width: "100%", height: 50, class1: 'absolute top-[45%] inset-[30%] flex items-center justify-center w-[40%] ', class2: " cursor-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/commerce-cursor.webp",
                        }
                    }
                ]
            },
        ]
    },
    {
        path: t("image_background_remover"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/backgroundremover-static_1.webp",
                imgAfter: "",
                title: t("BackgroundRemover-tools-title-1"),
                desc: t("BackgroundRemover-tools-desc-1"),
                imgs: [{
                    img: {
                        width: "100%", height: 50, class1: 'absolute top-[45%] inset-[40%] lg:w-[50px] w-[30px] ', class2: " arrow-move-lrd", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right-down.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/backgroundremover-static_2.webp",
                imgAfter: "",
                title: t("BackgroundRemover-tools-title-2"),
                desc: t("BackgroundRemover-tools-desc-2"),
                imgs: [
                    {
                        img: {
                            width: "100%", height: 73, class1: 'absolute top-1/2 left-[25%] ', class2: " arrow-move-lr", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right.webp",
                        }
                    },
                    {
                        img: {
                            width: "100%", height: 43, class1: 'absolute top-[55%] right-[40%] ', class2: " arrow-move-tbScale", arrowIcon: "/assets/images/functype/allTools/newImgs/pop.webp",
                        }
                    },
                    {
                        img: {
                            width: "100%", height: 95, class1: 'absolute top-0 right-0 ', class2: " arrow-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/tip.webp",
                        }
                    }
                ],
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/backgroundremover-static_3.webp",
                imgAfter: "",
                title: t("BackgroundRemover-tools-title-3"),
                desc: t("BackgroundRemover-tools-desc-3"),
            },
        ]
    },
    {
        path: t("object_remover"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/object-static_1.webp",
                imgAfter: "",
                title: t("ObjectRemover-tools-title-1"),
                desc: t("ObjectRemover-tools-desc-1"),
                imgs: [{
                    img: {
                        width: "100%", height: 73, class1: 'absolute top-1/2 inset-[45%] ', class2: " arrow-move-lr", arrowIcon: "/assets/images/functype/allTools/newImgs/arrow-right.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/object-static_2.webp",
                imgAfter: "",
                title: t("ObjectRemover-tools-title-2"),
                desc: t("ObjectRemover-tools-desc-2"),
            },
            {
                key: 3,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/object-static_3.webp",
                imgAfter: "",
                title: t("ObjectRemover-tools-title-3"),
                desc: t("ObjectRemover-tools-desc-3"),
            },
        ]
    },
    {
        path: t("face_cutout"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/facecut-static_1.webp",
                imgAfter: "",
                title: t("FaceCut-tools-title-1"),
                desc: t("FaceCut-tools-desc-1"),
                imgs: [{
                    img: {
                        width: "100%", height: 230, class1: 'absolute top-[4.5%] inset-[51%] ', class2: " animate-rotate-left-right", arrowIcon: "/assets/images/functype/allTools/newImgs/child.webp",
                    },
                }]
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/facecut-static_2.webp",
                imgAfter: "",
                title: t("FaceCut-tools-title-2"),
                desc: t("FaceCut-tools-desc-2"),
                imgs: [
                    {
                        img: {
                            width: "100%", height: 42, class1: 'px-10 absolute top-[40%] inset-0 w-1/2', class2: " cursor-move-tb", arrowIcon: "/assets/images/functype/allTools/newImgs/commerce-cursor.webp",
                        }
                    }
                ]
            }
        ]
    },
    {
        path: t("ai_background_generator"),
        info: [
            {
                key: 1,
                imgPosition: 'left',
                imgBefore: "/assets/images/functype/allTools/newImgs/bg_generator-before_1.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/bg_generator-after_1.webp",
                title: t("BackgroundGenerator-tools-title-1"),
                desc: t("BackgroundGenerator-tools-desc-1"),
            },
            {
                key: 2,
                imgPosition: 'right',
                imgBefore: "/assets/images/functype/allTools/newImgs/bg_generator-before_2.webp",
                imgAfter: "/assets/images/functype/allTools/newImgs/bg_generator-after_2.webp",
                title: t("BackgroundGenerator-tools-title-2"),
                desc: t("BackgroundGenerator-tools-desc-2"),
            }
        ]
    },
])
onMounted(() => {
    infoList.value.map(obj => {
        if (obj.path == props.showfun.title) {
            list.value = obj
        }
    })
})

</script>
<style scoped>
.shadow {
    background-color: #ffffff;
    transition: box-shadow 0.5s ease;
    box-shadow: 0 -12px 40px #ffffff, 0 12px 40px #ffffff;
    /* 添加过渡效果，让阴影显示更平滑 */
}

.shadow:hover {
    box-shadow: 0 -12px 40px #DABCFF66, 0 12px 40px #53DAFF66;
}

.arrow-move-lr {
    animation: lr-move 3s infinite;
}

@keyframes lr-move {

    0%,
    100% {
        transform: translateX(0%);
        animation-timing-function: linear;
    }

    50% {
        transform: translateX(40%);
        animation-timing-function: linear;
    }
}

.arrow-move-lr2 {
    animation: lr2-move 1.5s infinite;
}

@keyframes lr2-move {

    0%,
    100% {
        transform: translateX(0%);
        animation-timing-function: linear;
    }

    50% {
        transform: translateX(25%);
        animation-timing-function: linear;
    }
}

.arrow-move-lrd {
    animation: lrd-move 2s infinite;
}

@keyframes lrd-move {

    0%,
    100% {
        transform: translateX(0%) translateY(0%);
        animation-timing-function: linear;
    }

    50% {
        transform: translateX(25%) translateY(25%);
        animation-timing-function: linear;
    }
}

.arrow-move-tb {
    animation: tb-move 1.5s infinite;
}

@keyframes tb-move {

    0%,
    100% {
        transform: scale(1);
        animation-timing-function: linear;
    }

    50% {
        transform: scale(1.3);
        animation-timing-function: linear;
    }
}

.arrow-move-tbScale {
    animation: tbScale-move 3s infinite;
}

@keyframes tbScale-move {

    0%,
    100% {
        transform: translateY(0%) scale(1);
        animation-timing-function: linear;
    }

    50% {
        transform: translateY(-33%) scale(1.5);
        animation-timing-function: linear;
    }
}

.cursor-move-tb {
    animation: cursor-tb-move 4s infinite;
}

@keyframes cursor-tb-move {

    0%,
    100% {
        transform: translateY(-70px);
        animation-timing-function: linear;
    }

    50% {
        transform: translateY(70px);
        animation-timing-function: linear;
    }
}

.commerce-move-tb {
    animation: commerce-tb-move 3s infinite;
}

@keyframes commerce-tb-move {

    0%,
    100% {
        transform: translateY(-5px);
        animation-timing-function: linear;
    }

    50% {
        transform: translateY(5px);
        animation-timing-function: linear;
    }
}

@keyframes rotate-left-right {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(5deg);
    }
}

.animate-rotate-left-right {
    animation: rotate-left-right 2s ease-in-out infinite alternate;
    transform-origin: center bottom;
    /* 设置旋转中心为底部中间 */
}
</style>