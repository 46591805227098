<template>
  <div class="relative w-full h-full flex items-center justify-center">
    <div class="h-full flex-1 flex items-center justify-center relative px-6">
      <div
        class="h-4/5 w-full max-w-[1224px] relative overflow-hidden -translate-y-[10%] shadow-1 bg-white rounded-xl"
        v-if="fileModel"
      >
        <div class="w-full h-full overflow-hidden">
          <EnhancePreLoad
            v-if="
              fileModel.status == uploadStatus.handle ||
              fileModel.status == uploadStatus.lodingImg
            "
            :fileModel="fileModel"
            @load="onImgLoad"
          ></EnhancePreLoad>
          <div
            v-else-if="fileModel.status == uploadStatus.success"
            class="w-full h-full relative"
          >
            <!-- <EnhanceImageView
              ref="imageViewRef"
              :minScale="minScale"
              :maxScale="maxScale"
              :url="
                isVip
                  ? fileModel.enhanceUrl
                  : fileModel.watermark || fileModel.enhanceUrl
              "
              @scaleChange="imageViewChange"
            ></EnhanceImageView> -->
            <div class="mosaic relative h-full w-full bg-auto select-none">
              <img
                class="absolute object-contain w-full h-full"
                :src="fileModel.enhanceUrl"
                alt="enhance"
              />
            </div>
            <EnhanceGiveALike
              class="absolute right-4 bottom-4"
              v-if="isLogin"
              v-show="fileModel.enhanceUrl && !fileModel.isEvaluate"
              :id="fileModel.enhanceRecordId"
              @onEvaluate="onEvaluate"
            ></EnhanceGiveALike>
            <div
              class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white"
            >
              {{ $t("after") }}
            </div>
          </div>
          <EnhanceImageView
            ref="imageViewRef"
            v-else
            :minScale="minScale"
            :maxScale="maxScale"
            :url="fileModel.preImage"
            @scaleChange="imageViewChange"
          ></EnhanceImageView>
        </div>
      </div>
      <div v-else class="flex items-center justify-center">
        <FuncTypeUpload
          class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
          :funcType="route.params.funcType"
        ></FuncTypeUpload>
      </div>
      <div
        v-if="fileModel"
        v-show="!(fileModel.status >= uploadStatus.lodingImg)"
        class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max"
      >
        <div class="relative h-full flex items-center justify-between gap-x-8">
          <EnhanceBaseControls
            class="flex-1 flex justify-center"
            ref="baseControlsRef"
            :minScale="minScale"
            :maxScale="maxScale"
            @change="baseCtlScaleChange"
          ></EnhanceBaseControls>
          <EnhanceApply
            @click="onApply"
            :enable="applyEnabled && fileModel.status !== uploadStatus.success"
          ></EnhanceApply>
        </div>
        <!-- 遮罩，部分流程禁止用户操作 -->
        <div
          class="absolute left-0 top-0 bottom-0 right-0 z-[2] cursor-not-allowed"
          v-if="
            !(
              fileModel.status === uploadStatus.success ||
              fileModel.status === uploadStatus.none ||
              fileModel.status === uploadStatus.fail ||
              fileModel.status === uploadStatus.uploadFail ||
              fileModel.status === uploadStatus.enhanceErr
            )
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { uploadStatus } from "@/utils/type";
import { useMainStore } from "@/store/index";
const {
  customEventNames,
  cleanMenuDownload,
  downloadImgEvent,
  addFileListEvent,
  downloadUrlEvent,
  applyEvent,
  selectImgEvent,
  delImgEvent,
  updateFileListEvent,
} = useCustomEvent();
const route = useRoute();
const mainStore = useMainStore();
const props = defineProps({
  fileList: {
    type: Array,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
});

const fileModel = ref(null);
const { isLogin, isVip, isVipPro } = useUserInfo();
onMounted(() => {
  window.addEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
});
onUnmounted(() => {
  window.removeEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
});
watch(
  () => props.fileList,
  () => {
    console.log("数据");
    if (props.fileList.length) {
      fileModel.value = props.fileList[0];
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);

const baseControlsRef = ref(null);
const imageViewRef = ref(null);

function baseCtlScaleChange(scale) {
  imageViewRef.value?.setScale(scale);
}

function imageViewChange(scale) {
  baseControlsRef.value.setScale(scale);
}
function onApply() {
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  if (fileModel.value.status == uploadStatus.success) {
    return;
  }
  applyEvent({
    enhanceCfgIds: [63],
  });
}

function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList);
}
function onImgLoad() {
  console.log("加载成功");

  const fileList = [...props.fileList];
  fileList[0].status = uploadStatus.success;
  updateFileListEvent(fileList);
}
function taskFailRetryEvent(e) {
  // 如果任务失败，重置数据
  setTimeout(() => {
    updateFileListEvent([{ ...e.detail, status: uploadStatus.enhanceErr }]);
  }, 100);
}
</script>
