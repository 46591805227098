<template>
  <nuxt-link class="grid grid-cols-1 block cursor-pointer p-4 border rounded-3xl bg-white h-full" :to="localePath({
    name: 'blog-slug',
    params: { slug: article.urlPath },
  })
    ">
    <div class="md:hover:text-mainColor-deep">
      <div>
        <NuxtImg :src="article.articleCover" alt="logo" height="260" width="390" format="webp"
          class="object-cover w-full aspect-[3/2] rounded-2xl" loading="lazy" />
      </div>
      <!-- <div class="py-[16px] flex gap-x-6 text-text-gray text-sm border-b border-solid border-t-gray-line">
      <p v-for="tag in article.articleTagNameList" :key="tag">{{ tag }}</p>
    </div> -->
      <p class="mt-[16px] text-xl font-Roboto-Bold font-bold text-ellipsis line-clamp-[3]">
        {{ article.articleTitle }}
      </p>
      <p class="mt-[20px] text-sm text-text-deep font-Roboto text-ellipsis line-clamp-[5]">
        {{ article.seoDetail }}
      </p>
    </div>
    <!-- <div class="text-sm text-text-gray mt-4">
      BY {{ article.updateBy }}-{{ getTime(article.deployTime) }}
    </div> -->
  </nuxt-link>
</template>

<script setup>
const localePath = useLocalePath();
const { article } = defineProps({
  article: Object,
});
function getTime(params) {
  const timestamp = params; // 这是2022年7月28日的午夜时间戳

  // 创建一个Date对象
  const date = new Date(timestamp);

  // 获取月份、日期和年份
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const month = monthNames[date.getMonth()]; // 注意：getMonth()返回的是0-11，所以需要映射到月份名称数组中
  const day = String(date.getDate()).padStart(2, '0'); // 确保日期是两位数
  const year = date.getFullYear();

  // 组合成所需的格式
  return `${month} ${day}, ${year}`;
}

</script>
