<template>
  <div class="bg-[#000000CC] px-6 py-10 flex justify-center backdrop-blur-3xl">
    <div class="max-w-[1400px] flex justify-center gap-10">
      <div class="min-w-[204px]">
        <div class="font-Roboto-Bold text-xl text-white h-12">
          {{ $t("header-hot-tools") }}
        </div>

        <nuxt-link
          class="flex flex-col justify-end rounded-xl p-2 bg-[url('/assets/images/index/home/Photo_Enhance.webp')] h-[125px] w-[230px] bg-cover cursor-pointer"
          :to="localePath({
            name: 'picma_web-funcType',
            params: { funcType: FuncType.enhance_pro.path },
          })
            " @click="onToPath(FuncType.enhance_pro.path + '_img')">
          <div style="z-index: 10" class="flex items-center">
            <p>
              <NuxtImg src="/assets/images/enhance/icons/tool-1.svg" width="24" height="24" :alt="$t('enhance_pro')"
                loading="lazy" />
            </p>
            <p class="ml-2 Roboto-Bold-14 text-white">
              {{ $t("header-hot-tools-1") }}
            </p>
          </div>
          <div class="w-[232px] absolute h-14 bottom-auto transform translate-y-[9px] -translate-x-[9px] rounded-xl"
            style=" background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 00%, rgba(0, 0, 0, 1) 120%);z-index: 1">
          </div>
        </nuxt-link>
        <nuxt-link
          class="flex flex-col justify-end rounded-xl p-2 bg-[url('/assets/images/index/home/AI_E_Commerce.webp')] h-[125px] w-[230px] bg-cover mt-3 cursor-pointer"
          :to="localePath({
            name: 'picma_web-funcType',
            params: { funcType: FuncType.ai_e_commerce.path },
          })
            " @click="onToPath(FuncType.ai_e_commerce.path + '_img')">
          <div style="z-index: 10" class="flex items-center">
            <p>
              <NuxtImg src="/assets/images/enhance/icons/tool-2.svg" width="24" height="24" :alt="$t('ai_e_commerce')"
                loading="lazy" />
            </p>
            <p class="ml-2 Roboto-Bold-14 text-white">
              {{ $t("ai_e_commerce") }}
            </p>
          </div>
          <div class="w-[232px] absolute h-14 bottom-1/5 transform translate-y-[9px] -translate-x-[9px] rounded-xl"
            style=" background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 120%);z-index: 1">
          </div>
        </nuxt-link>
      </div>
      <div class="flex flex-col">
        <div class="h-12"></div>
        <div class="flex-1 bg-white-line w-px"></div>
      </div>
      <div class="flex-1">
        <div class="font-Roboto-Bold text-xl text-white h-12 ">
          <span>{{ $t("header-AI-Tools") }}</span>
        </div>
        <div class="flex gap-4">
          <div>
            <div class="Roboto-16 text-white h-9 flex items-center gap-3">
              <span class="opacity-70">{{ $t("AI_Enhance") }}</span>
              <span class="tag free">FREE</span>
            </div>
            <ul>
              <li class="min-h-10 group hover:bg-gradient p-px rounded-3xl overflow-hidden -translate-x-2"
                v-for="func in AIEnhance" :key="func.name" @click="onToPath(func.funcType)">
                <nuxt-link :to="localePath({
                  name: 'picma_web-funcType',
                  params: { funcType: func.funcType },
                })
                  "
                  class="group pl-2 pr-1 rounded-3xl overflow-hidden py-3 flex items-center gap-2 border border-solid border-transparent group-hover:bg-black">
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon1}.svg`" width="16" height="16"
                    class="group-hover:hidden" :alt="func.name" loading="lazy" />
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon2}.svg`" width="16" height="16"
                    class="hidden group-hover:inline-block" :alt="func.name" loading="lazy" />
                  <div class="flex-1 Roboto-14 text-white inline-block max-w-[150px] break-words">
                    <span class="leading-5">{{ func.name }}</span><span v-if="func.new" class="tag new ml-1">NEW</span>
                  </div>
                  <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                    height="12" :alt="func.name" loading="lazy" />
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div>
            <div class="Roboto-16 text-white h-9 flex items-center opacity-70">
              <span>{{ $t("AI_Editing") }}</span>
            </div>
            <ul>
              <li class="min-h-10 group hover:bg-gradient p-px rounded-3xl overflow-hidden -translate-x-2"
                v-for="func in AIEditing" :key="func.name" @click="onToPath(func.funcType)">
                <nuxt-link :to="localePath({
                  name: 'picma_web-funcType',
                  params: { funcType: func.funcType },
                })
                  "
                  class="pl-2 pr-2 rounded-3xl overflow-hidden py-3 flex items-center gap-2 border border-solid border-transparent group-hover:bg-black">
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon1}.svg`" width="16" height="16"
                    class="group-hover:hidden" :alt="func.name" loading="lazy" />
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon2}.svg`" width="16" height="16"
                    class="hidden group-hover:inline-block" :alt="func.name" loading="lazy" />
                  <div class="flex-1 Roboto-14 text-white max-w-[150px] break-words">
                    <span class="leading-5">{{ func.name }}</span><span v-if="func.new" class="tag new ml-1">NEW</span>
                  </div>
                  <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                    height="12" :alt="func.name" loading="lazy" />
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div>
            <div class="Roboto-16 text-white h-9 flex items-center opacity-70">
              <span>{{ $t("AI_Removal") }}</span>
            </div>
            <ul>
              <li class="min-h-10 group hover:bg-gradient p-px rounded-3xl overflow-hidden -translate-x-2"
                v-for="func in AIRemoval" :key="func.name" @click="onToPath(func.funcType)">
                <nuxt-link :to="localePath({
                  name: 'picma_web-funcType',
                  params: { funcType: func.funcType },
                })
                  "
                  class="pl-2 pr-2 rounded-3xl overflow-hidden py-3 flex items-center gap-2 border border-solid border-transparent group-hover:bg-black">
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon1}.svg`" width="16" height="16"
                    class="group-hover:hidden" :alt="func.name" loading="lazy" />
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon2}.svg`" width="16" height="16"
                    class="hidden group-hover:inline-block" :alt="func.name" loading="lazy" />
                  <div class="flex-1 Roboto-14 text-white max-w-[150px] break-words">
                    <span class="leading-5">{{ func.name }}</span><span v-if="func.new" class="tag new ml-1">NEW</span>
                  </div>
                  <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                    height="12" :alt="func.name" loading="lazy" />
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div>
            <div class="Roboto-16 text-white h-9 flex items-center opacity-70">
              <span>{{ $t("AI_Generation") }}</span>
            </div>
            <ul>
              <li class="min-h-10 group hover:bg-gradient p-px rounded-3xl overflow-hidden -translate-x-2"
                v-for="func in AIGeneration" :key="func.name"
                @click="onToPath(func.query ? FuncType.ai_logo.path : func.funcType)">
                <nuxt-link :to="localePath({
                  name: func.enhance ? 'picma_web-enhance-funcType' : 'picma_web-funcType',
                  params: { funcType: func.funcType },
                  query: func.query
                })" class=" pl-2 pr-2 rounded-3xl overflow-hidden py-3 flex items-center gap-2 border border-solid
                  border-transparent group-hover:bg-black">
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon1}.svg`" width="16" height="16"
                    class="group-hover:hidden" :alt="func.name" loading="lazy" />
                  <NuxtImg :src="`/assets/images/enhance/icons/${func.icon2}.svg`" width="16" height="16"
                    class="hidden group-hover:inline-block" :alt="func.name" loading="lazy" />
                  <div class="flex-1 Roboto-14 text-white inline-block max-w-[150px] break-words">
                    <span class="leading-5">{{ func.name }}</span><span v-if="func.new" class="tag new ml-1">NEW</span>
                  </div>
                  <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                    height="12" :alt="func.name" loading="lazy" />
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="h-12"></div>
        <div class="flex-1 bg-white-line w-px"></div>
      </div>
      <div>
        <div class="font-Roboto-Bold text-xl text-white h-12">
          {{ $t("Mobile") }}
        </div>
        <ul>
          <li class="min-h-10 group hover:bg-gradient p-px rounded-full overflow-hidden -translate-x-2"
            @click="onEvent('Ios')">
            <nuxt-link :href="ios" target="_blank"
              class="pl-2 pr-2 rounded-full overflow-hidden py-3 flex items-center gap-3 border border-solid border-transparent group-hover:bg-black">
              <NuxtImg src="/assets/images/header/ios.svg" width="16" height="16" alt="arrow" />
              <div class="flex-1 Roboto-14 text-white inline-block max-w-[150px] break-words">
                iOS
              </div>
              <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                height="12" alt="arrow" />
            </nuxt-link>
          </li>
          <li class="min-h-10 group hover:bg-gradient p-px rounded-full overflow-hidden -translate-x-2"
            @click="onEvent('Android')">
            <nuxt-link :href="Android" target="_blank"
              class="pl-2 pr-2 rounded-full overflow-hidden py-3 flex items-center gap-3 border border-solid border-transparent group-hover:bg-black">
              <NuxtImg src="/assets/images/header/Android.svg" width="16" height="16" alt="arrow" />
              <div class="flex-1 Roboto-14 text-white inline-block max-w-[150px] break-words">
                Android
              </div>
              <NuxtImg class="invisible group-hover:visible" src="/assets/images/header/arrow.svg" width="12"
                height="12" alt="arrow" />
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useMainStore } from "@/store/index";
import { ios, Android } from "@/utils/config";
const { locale, locales, setLocale, t } = useI18n();
const mainStore = useMainStore();
const localePath = useLocalePath();
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const AIEditing = ref(getEditor());
const AIEnhance = ref(getAIEnhance());
const AIRemoval = ref(getAIRemoval());
const AIGeneration = ref(getAIGeneration());
const emit = defineEmits(["onToPath"]);
watch(
  () => locale.value,
  () => {
    FuncType.value = getFuncType();
    AIEditing.value = getEditor();
    AIEnhance.value = getAIEnhance();
    AIRemoval.value = getAIRemoval();
    AIGeneration.value = getAIGeneration();
  }
);
function getEditor() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.image_compressor.name,
      funcType: FuncType.value.image_compressor.path,
      icon1: "Image Compressor-1",
      icon2: "Image Compressor",
    },
    // {
    //   name: FuncType.value.video_compressor.name,
    //   funcType: FuncType.value.video_compressor.path,
    //   icon1: "Video Compressor-1",
    //   icon2: "Video Compressor",
    // },
    {
      name: FuncType.value.image_converter.name,
      funcType: FuncType.value.image_converter.path,
      icon1: "Image Converter-1",
      icon2: "Image Converter",
    },
    // {
    //   name: FuncType.value.video_converter.name,
    //   funcType: FuncType.value.video_converter.path,
    //   icon1: "Video Converter-1",
    //   icon2: "Video Converter",
    // },
    {
      name: FuncType.value.ai_e_commerce.name,
      funcType: FuncType.value.ai_e_commerce.path,
      icon1: "AI E-Commerce-1",
      icon2: "AI E-Commerce",
      new: true,
    },
    // {
    //   name: FuncType.value.ai_uncrop.name,
    //   funcType: FuncType.value.ai_uncrop.path,
    // new: true
    // },
  ];
}
function getAIEnhance() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.enhance_pro.name,
      funcType: FuncType.value.enhance_pro.path,
      icon1: "Photo Enhancer-1",
      icon2: "Photo Enhancer",
    },
    {
      name: FuncType.value.word_hd.name,
      funcType: FuncType.value.word_hd.path,
      icon1: "Word HD-1",
      icon2: "Word HD",
    },
    // {
    //   name: FuncType.value.video_enhance.name,
    //   funcType: FuncType.value.video_enhance.path,
    //   icon1: "Video Enhancer-1",
    //   icon2: "Video Enhancer",
    // },
    {
      name: FuncType.value.color_master.name,
      funcType: FuncType.value.color_master.path,
      icon1: "Color Master-1",
      icon2: "Color Master",
    },
    {
      name: FuncType.value.stable_motion_ai.name,
      funcType: FuncType.value.stable_motion_ai.path,
      icon1: "Stable Motion AI-1",
      icon2: "Stable Motion AI",
    },
    {
      name: FuncType.value.descratch.name,
      funcType: FuncType.value.descratch.path,
      icon1: "Descratch-1",
      icon2: "Descratch",
    },
    // {
    //   name: FuncType.value.anime_enhance.name,
    //   funcType: FuncType.value.anime_enhance.path,
    //   icon1: "Anime Enhancer-1",
    //   icon2: "Anime Enhancer",
    //   new: true,
    // },
  ];
}
function getAIRemoval() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.image_background_remover.name,
      funcType: FuncType.value.image_background_remover.path,
      icon1: "Image Background Remover-1",
      icon2: "Image Background Remover",
      new: true,
    },
    // {
    //   name: FuncType.value.video_background_remover.name,
    //   funcType: FuncType.value.video_background_remover.path,
    //   icon1: "Video Background Remover-1",
    //   icon2: "Video Background Remover",
    // },
    // {
    //   name: FuncType.value.watermark_remover.name,
    //   funcType: FuncType.value.watermark_remover.path,
    //   icon1: 'Watermark Remover-1',
    //   icon2: 'Watermark Remover',
    // },
    {
      name: FuncType.value.object_remover.name,
      funcType: FuncType.value.object_remover.path,
      icon1: "Object Remover-1",
      icon2: "Object Remover",
    },
    {
      name: FuncType.value.face_cutout.name,
      funcType: FuncType.value.face_cutout.path,
      icon1: "Face Cutout-1",
      icon2: "Face Cutout",
      new: true,
    },
  ];
}
function getAIGeneration() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.ai_background_generator.name,
      funcType: FuncType.value.ai_background_generator.path,
      icon1: "Al Background Generator-1",
      icon2: "Al Background Generator",
      new: true
    },
    {
      name: FuncType.value.ai_art_generator.name,
      funcType: FuncType.value.ai_art_generator.path,
      icon1: "Al Art Generator-1",
      icon2: "Al Art Generator",
      new: true,
      enhance: true
    },
    {
      name: FuncType.value.ai_poster.name,
      funcType: FuncType.value.ai_e_commerce.path,
      icon1: "AI Poster-1",
      icon2: "AI Poster",
      new: true,
      query: { id: FuncType.value.ai_poster.path },
      enhance: true
    },
    {
      name: FuncType.value.ai_logo.name,
      funcType: FuncType.value.ai_e_commerce.path,
      icon1: "AI Logo-1",
      icon2: "AI Logo",
      query: { id: FuncType.value.ai_logo.path },
      new: true,
      enhance: true
    },
  ];
}
function onToPath(record) {
  emit("onToPath");
  useLogEvent({ eventName: `header-${record}` });
}
function onEvent(record) {
  useLogEvent({ eventName: `header-${record}` });
}
</script>
<style lang="scss" scoped>
.tag {
  display: inline-block;
  border: 1px solid rgba(106, 234, 94, 0.4);
  height: 17px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 10px;
  line-height: 16px;
  width: 35px;
  text-align: center;
}

.free {
  // background: linear-gradient(90deg, #006bfb 0%, #00e8c8 100%);
  background-color: rgba(106, 234, 94, 0.1);
  color: #39D201;
}

.new {
  background: linear-gradient(90deg, #4f2bff 0%, #d10dff 100%);
}
</style>
