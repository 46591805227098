<template>
  <div class="flex gap-x-[60px]">
    <!-- 链接 -->
    <div class="">
      <ul class="flex flex-col gap-y-3">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("AI_Enhance") }}
        </li>
        <li @click="onEvent(item.funcType)" v-for="item in AIEnhance" :key="item.name" class="Roboto-14 text-text-gray">
          <nuxt-link :to="localePath({
            name: 'picma_web-funcType',
            params: { funcType: item.funcType },
          })
            " :prefetch="false">{{ item.name }}</nuxt-link>
        </li>
      </ul>
      <ul class="flex flex-col gap-y-3 mt-10">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("AI_Editing") }}
        </li>
        <li @click="onEvent(item.funcType)" v-for="item in AIEditing" :key="item.name" class="Roboto-14 text-text-gray">
          <nuxt-link :to="localePath({
            name: 'picma_web-funcType',
            params: { funcType: item.funcType },
          })
            " :prefetch="false">{{ item.name }}</nuxt-link>
        </li>
      </ul>
    </div>
    <div class="">
      <ul class="flex flex-col gap-y-3">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("AI_Removal") }}
        </li>
        <li @click="onEvent(item.funcType)" v-for="item in AIRemoval" :key="item.name" class="Roboto-14 text-text-gray">
          <nuxt-link :to="localePath({
            name: 'picma_web-funcType',
            params: { funcType: item.funcType },
          })
            " :prefetch="false">{{ item.name }}</nuxt-link>
        </li>
      </ul>
      <ul class="flex flex-col gap-y-3 mt-10">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("AI_Generation") }}
        </li>
        <li @click="onEvent(func.funcType)" v-for="func in AIGeneration" :key="func.name"
          class="Roboto-14 text-text-gray">
          <nuxt-link
            :to="!func.id ?
              localePath({ name: func.enhance ? 'picma_web-enhance-funcType' : 'picma_web-funcType', params: { funcType: func.funcType } }) :
              localePath({ name: func.enhance ? 'picma_web-enhance-funcType' : 'picma_web-funcType', params: { funcType: func.funcType }, query: { id: func.name == FuncType.ai_poster.name ? 'ai_poster' : func.name == FuncType.ai_logo.name ? 'ai_logo' : '' } })"
            :prefetch="false">{{ func.name }}
          </nuxt-link>
        </li>
      </ul>
    </div>
    <div>
      <ul class="flex flex-col gap-y-3">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          API
        </li>
        <li @click="onEvent(item.path)" v-for="item in apiLinks" :key="item.name" class="Roboto-14 text-text-gray">
          <nuxt-link :to="localePath({
            name: 'apiServices',
            params: { funcType: item.funcType },
          })
            " :prefetch="false">{{ item.name }}</nuxt-link>
        </li>
      </ul>
      <ul class="flex flex-col gap-y-3 mt-10">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("footer-suport") }}
        </li>
        <li @click="onEvent(item.path)" v-for="item in support" :key="item.name" class="Roboto-14 text-text-gray">
          <nuxt-link :to="localePath({
            name: item.path,
          })
            " :prefetch="false">{{ item.name }}</nuxt-link>
        </li>
      </ul>
    </div>
    <div>
      <ul class="flex flex-col gap-y-3">
        <li class=" mb-1  Roboto-Bold-14 text-white">
          {{ $t("footer-legal") }}
        </li>
        <li class="Roboto-14 text-text-gray">
          <a @click="onEvent('termsOfService')" :href="config.termsofService" target="_blank">{{
            $t("termsOfService")
          }}</a>
        </li>
        <li class="Roboto-14 text-text-gray">
          <a @click="onEvent('privacyPolicy')" :href="config.privacyPolicy" target="_blank">{{
            $t("privacyPolicy")
          }}</a>
        </li>
        <li class="Roboto-14 text-text-gray">
          <a @click="onEvent('cookiePolicy')" :href="config.cookiePolicy" target="_blank">{{
            $t("cookiePolicy")
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import config from "@/utils/config";

const {
  public: { MODE },
} = useRuntimeConfig();
const localePath = useLocalePath();

const { locale, locales, setLocale, t } = useI18n();
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const AIEditing = ref(getEditor());
const AIEnhance = ref(getAIEnhance());
const AIRemoval = ref(getAIRemoval());
const AIGeneration = ref(getAIGeneration());
const apiLinks = ref([
  { name: t("footer-image"), tag: "1", path: "For_Image" },
  { name: t("footer-video"), tag: "2", path: "For_Video" },
]);
const support = ref([
  { name: "Q&A", tag: "1", path: "support" },
  { name: t("footer-about"), tag: "2", path: "apiServices" },
]);

watch(
  () => locale.value,
  () => {
    support.value = [
      { name: "Q&A", tag: "1", path: "support" },
      { name: t("footer-about"), tag: "2", path: "apiServices" },
    ];
    apiLinks.value = [
      { name: t("footer-image"), tag: "1", path: "For_Image" },
      { name: t("footer-video"), tag: "2", path: "For_Video" },
    ];
    FuncType.value = getFuncType();
    AIEditing.value = getEditor();
    AIEnhance.value = getAIEnhance();
    AIRemoval.value = getAIRemoval();
    AIGeneration.value = getAIGeneration();
  }
);

function getEditor() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.image_compressor.name,
      funcType: FuncType.value.image_compressor.path,
    },
    // {
    //   name: FuncType.value.video_compressor.name,
    //   funcType: FuncType.value.video_compressor.path,
    // },
    {
      name: FuncType.value.image_converter.name,
      funcType: FuncType.value.image_converter.path,
    },
    // {
    //   name: FuncType.value.video_converter.name,
    //   funcType: FuncType.value.video_converter.path,
    // },
    {
      name: FuncType.value.ai_e_commerce.name,
      funcType: FuncType.value.ai_e_commerce.path,
    },
    // {
    //   name: FuncType.value.ai_uncrop.name,
    //   funcType: FuncType.value.ai_uncrop.path,
    // },
  ];
}
function getAIEnhance() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.enhance_pro.name,
      funcType: FuncType.value.enhance_pro.path,
    },
    {
      name: FuncType.value.word_hd.name,
      funcType: FuncType.value.word_hd.path,
    },
    // {
    //   name: FuncType.value.video_enhance.name,
    //   funcType: FuncType.value.video_enhance.path,
    // },
    {
      name: FuncType.value.color_master.name,
      funcType: FuncType.value.color_master.path,
    },
    {
      name: FuncType.value.stable_motion_ai.name,
      funcType: FuncType.value.stable_motion_ai.path,
    },
    {
      name: FuncType.value.descratch.name,
      funcType: FuncType.value.descratch.path,
    },
  ];
}
function getAIRemoval() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.image_background_remover.name,
      funcType: FuncType.value.image_background_remover.path,
    },
    // {
    //   name: FuncType.value.video_background_remover.name,
    //   funcType: FuncType.value.video_background_remover.path,
    // },
    // {
    //   name: FuncType.value.watermark_remover.name,
    //   funcType: FuncType.value.watermark_remover.path,
    // },
    {
      name: FuncType.value.object_remover.name,
      funcType: FuncType.value.object_remover.path,
    },
    {
      name: FuncType.value.face_cutout.name,
      funcType: FuncType.value.face_cutout.path,
    },
  ];
}
function getAIGeneration() {
  FuncType.value = getFuncType();
  return [
    {
      name: FuncType.value.ai_background_generator.name,
      funcType: FuncType.value.ai_background_generator.path,
    },
    {
      name: FuncType.value.ai_art_generator.name,
      funcType: FuncType.value.ai_art_generator.path,
      enhance: true
    },
    {
      name: FuncType.value.ai_poster.name,
      funcType: FuncType.value.ai_e_commerce.path,
      id: 'ai_poster',
      enhance: true
    },
    {
      name: FuncType.value.ai_logo.name,
      funcType: FuncType.value.ai_e_commerce.path,
      id: 'ai_logo',
      enhance: true
    },
  ];
}

function onEvent(params) {
  console.log(`footer-${params}`);
  useLogEvent({ eventName: `footer-${params}` });
}
</script>
