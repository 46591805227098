<template>
    <div class="text-5xl mt-10 font-Montserrat font-bold px-16 xl:px-0">
        {{ $t("Index-Title1-Des1") }}
        <span class="text-button">
            {{ $t("Index-Title1-Des2") }}
        </span>
        {{ $t("Index-Title1-Des3") }}
        <NuxtImg class="h-16 mt-6 inline floating" src="/assets/images/home/star.png" :alt="$t('AI_Enhance')" width="64"
            height="64" />
    </div>
    <div
        class="grid grid-cols-4 flex items-center gap-2 mt-10 text-base font-bold border border-gray-line rounded-full h-14 w-min-fit px-1">
        <button class="h-12 min-w-fit px-10" @click="onTabSwitch('enhance')"
            :class="tabType == 'enhance' ? swicthTabStyle : 'text-base font-bold'">
            {{ $t("AI_Enhance") }}
        </button>
        <button class="h-12 min-w-fit px-10" @click="onTabSwitch('editing')"
            :class="tabType == 'editing' ? swicthTabStyle : 'text-base font-bold'">
            {{ $t("AI_Editing") }}
        </button>
        <button class="h-12 min-w-fit px-10" @click="onTabSwitch('removal')"
            :class="tabType == 'removal' ? swicthTabStyle : 'text-base font-bold'">
            {{ $t("AI_Removal") }}
        </button>
        <button class="h-12 min-w-fit px-10" @click="onTabSwitch('')"
            :class="tabType == '' ? swicthTabStyle : 'text-base font-bold'">
            {{ $t("AI_Generation") }}
        </button>
    </div>
    <div class="px-16 xl:px-32 2xl:px-0">
        <div v-if="tabType === 'enhance'" class="grid grid-cols-2 gap-4 mt-10 h-42 max-w-[1300px] xl:grid-cols-3 "
        :class="{ 'opacity-60': transitioning }">
        <div v-for="(item, index) in enhanceTabs" :key="index"
            class="hover:border-indigo-500 hover:border-2 border p-4 hover:p-0 rounded-3xl h-[480px] relative clip-container group flex flex-col items-center"
            @click="onEvent(item.params)">
            <video @mouseleave="resetVideo" loop muted :src="item.src" loading="lazy"
                class="video hover:rounded rounded-3xl object-cover w-full h-full transition-all duration-300"></video>
            <div
                class="mt-8 button-container absolute flex justify-center items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <NuxtLink :to="localePath({ params: { funcType: `${item.params}` }, name: `${item.name}` })">
                    <button
                        class="z-15 min-w-64 px-4 h-14 rounded-full bg-gradient text-xl flex justify-center items-center">
                        <div class="text-white flex justify-center items-center gap-2 Roboto-Bold-20">
                            {{ $t("Index_Btn_StartFree") }} <icon-arrow-right />
                        </div>
                    </button>
                </NuxtLink>
            </div>
            <div class="group-hover:hidden absolute top-[340px] left-3 flex items-center justify-left Roboto-Bold-24">
                <NuxtImg class="mr-2 w-7 h-7" :src="item.icon" loading="lazy" height="28" :alt="$t(`${item.params}`)">
                </NuxtImg>
                {{ $t(`${item.params}`) }}
            </div>
            <div
                class="leading-6 group-hover:hidden text-sm Roboto-16 text-left text-text-gray absolute top-[375px] py-2  px-4">
                {{ item.content }}
            </div>
        </div>
    </div>
    <div v-else-if="tabType === 'editing'" class="grid grid-cols-2 gap-4 mt-10 h-42 max-w-[1300px] xl:grid-cols-3 "
        :class="{ 'opacity-60': transitioning }">
        <div v-for="(item, index) in editingTabs" :key="index" @click="onEvent(item.params)"
            class="hover:border-indigo-500 hover:border-2 border p-4 hover:p-0 rounded-3xl h-[480px] relative clip-container group flex flex-col items-center">
            <video @mouseleave="resetVideo" loop muted :src="item.src" loading="lazy"
                class="video hover:rounded rounded-3xl object-cover w-full h-full transition-all duration-300"></video>
            <div
                class="mt-8 button-container absolute flex justify-center items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <NuxtLink :to="localePath({ params: { funcType: `${item.params}` }, name: `${item.name}` })">
                    <button
                        class="z-15 min-w-64 px-4 h-14 rounded-full bg-gradient text-xl flex justify-center items-center">
                        <div class="text-white flex justify-center Roboto-Bold-20 items-center gap-2">
                            {{ $t("Index_Btn_StartFree") }} <icon-arrow-right />
                        </div>
                    </button>
                </NuxtLink>
            </div>
            <div class="group-hover:hidden Roboto-Bold-24 absolute top-[340px] left-3 flex items-center justify-left">
                <NuxtImg class="mr-2 w-7 h-7" :src="item.icon" loading="lazy" height="28" :alt="$t(`${item.params}`)">
                </NuxtImg>
                {{ $t(`${item.params}`) }}
            </div>
            <div
                class="leading-6 group-hover:hidden text-sm Roboto-16 text-left text-text-gray absolute top-[375px] py-2 px-4">
                {{ item.content }}
            </div>
        </div>
    </div>
    <div v-else-if="tabType === 'removal'" class="grid grid-cols-2 gap-4 mt-10 h-42 max-w-[1300px] xl:grid-cols-3 "
        :class="{ 'opacity-60': transitioning }">
        <div v-for="(item, index) in removalTabs" :key="index" @click="onEvent(item.params)"
            class="hover:border-indigo-500 hover:border-2 border p-4 hover:p-0 rounded-3xl h-[480px] relative clip-container group flex flex-col items-center">
            <video @mouseleave="resetVideo" loop muted :src="item.src" loading="lazy"
                class="video hover:rounded rounded-3xl object-cover w-full h-full transition-all duration-300"></video>
            <div
                class="mt-8 button-container absolute flex justify-center items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <NuxtLink :to="localePath({ params: { funcType: `${item.params}` }, name: `${item.name}` })">
                    <button
                        class="z-15 min-w-64 px-4 h-14 rounded-full bg-gradient text-xl flex justify-center items-center">
                        <div class="text-white Roboto-Bold-20 flex justify-center items-center gap-2">
                            {{ $t("Index_Btn_StartFree") }} <icon-arrow-right />
                        </div>
                    </button>
                </NuxtLink>
            </div>
            <div class="group-hover:hidden Roboto-Bold-24 absolute top-[340px] left-3 flex items-center justify-left">
                <NuxtImg class="mr-2 w-7 h-7" :src="item.icon" loading="lazy" height="28" :alt="$t(`${item.params}`)">
                </NuxtImg>
                {{ $t(`${item.params}`) }}
            </div>
            <div
                class="leading-6 group-hover:hidden text-sm Roboto-16 text-left text-text-gray absolute top-[375px] py-2 px-4">
                {{ item.content }}
            </div>
        </div>
    </div>
    <div v-else class="grid grid-cols-2 gap-4 mt-10 h-42 max-w-[1300px] xl:grid-cols-3 " :class="{ 'opacity-60': transitioning }">
        <div v-for="(item, index) in generationTabs" :key="index" @click="onEvent(item.id ? item.id : item.params)"
            class="hover:border-indigo-500 hover:border-2 border p-4 hover:p-0 rounded-3xl h-[480px] relative clip-container group flex flex-col items-center">
            <video @mouseleave="resetVideo" loop muted :src="item.src" loading="lazy"
                class="video hover:rounded rounded-3xl object-cover w-full h-full transition-all duration-300"></video>
            <div
                class="mt-8 button-container absolute flex justify-center items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <NuxtLink
                    :to="item.id ? localePath({ params: { funcType: `${item.params}` }, name: `${item.name}`, query: { id: `${item.id}` }, }) : localePath({ params: { funcType: `${item.params}` }, name: `${item.name}` })">
                    <button
                        class="z-15 min-w-64 px-4 h-14 rounded-full bg-gradient text-xl flex justify-center items-center">
                        <div class="text-white Roboto-Bold-20 flex justify-center items-center gap-2">
                            {{ $t("Index_Btn_StartFree") }} <icon-arrow-right />
                        </div>
                    </button>
                </NuxtLink>
            </div>
            <div class="group-hover:hidden Roboto-Bold-24 absolute top-[340px] left-3 flex items-center justify-left">
                <NuxtImg class="mr-2 w-7 h-7" :src="item.icon" loading="lazy" height="28"
                    :alt="$t(`${item.id ? item.id : item.params}`)"></NuxtImg>
                {{ $t(`${item.id ? item.id : item.params}`) }}
            </div>
            <div
                class="leading-6 group-hover:hidden text-sm Roboto-16 text-left text-text-gray absolute top-[375px] py-2 px-4">
                {{ item.content }}
            </div>
        </div>
    </div>
    </div>

</template>
<script setup>
const tabType = ref("enhance");
const { t } = useI18n();
const localePath = useLocalePath();
const transitioning = ref(false);
const swicthTabStyle = ref(
    "bg-mainColor-deep rounded-full transition-all duration-500 ease-in-out text-white text-base font-bold shadow-2xl"
);
const enhanceTabs = [
    { src: "/assets/images/index/home/Photo_Enhancer.mp4", icon: "/assets/images/enhance/icons/Photo Enhancer-2.svg", params: 'enhance_pro', name: 'picma_web-funcType', content: t('Index-content-Des-1') },
    { src: "/assets/images/index/home/Text_Enhancer.mp4", icon: "/assets/images/enhance/icons/Word HD-2.svg", params: 'word_hd', name: 'picma_web-funcType', content: t('Index-content-Des-23') },
    { src: "/assets/images/index/home/Color_Master.mp4", icon: "/assets/images/enhance/icons/Color Master-2.svg", params: 'color_master', name: 'picma_web-funcType', content: t('Index-content-Des-4') },
    { src: "/assets/images/index/home/Stable_Motion_Al.mp4", icon: "/assets/images/enhance/icons/Stable Motion AI-2.svg", params: 'stable_motion_ai', name: 'picma_web-funcType', content: t('Index-content-Des-5') },
    { src: "/assets/images/index/home/Descratch.mp4", icon: "/assets/images/enhance/icons/Descratch-2.svg", params: 'descratch', name: 'picma_web-funcType', content: t('Index-content-Des-6') },
    // { src: "/assets/images/index/home/Anime_Enhancer.mp4", icon: "/assets/images/enhance/icons/Anime Enhancer-2.svg", params: 'anime_enhance', name: 'picma_web-funcType', content: t('Index-content-Des-3') },
]
const removalTabs = [
    { src: "/assets/images/index/home/Image_Background_Remover.mp4", icon: "/assets/images/enhance/icons/Image Background Remover-2.svg", params: 'image_background_remover', name: 'picma_web-funcType', content: t('Index-content-Des-7') },
    { src: "/assets/images/index/home/Object_Remover.mp4", icon: "/assets/images/enhance/icons/Object Remover-2.svg", params: 'object_remover', name: 'picma_web-funcType', content: t('Index-content-Des-9') },
    { src: "/assets/images/index/home/Face_Cut.mp4", icon: "/assets/images/enhance/icons/Face Cutout-2.svg", params: 'face_cutout', name: 'picma_web-funcType', content: t('Index-content-Des-10') },
    // { src: "/assets/images/index/home/Watermark_Remover.mp4", icon: "/assets/images/enhance/icons/Watermark Remover-2.svg", params: 'watermark_remover', name: 'picma_web-funcType', content: t('Index-content-Des-11') },
    // { src: "/assets/images/index/home/Video_BG_Remover.mp4", icon: "/assets/images/enhance/icons/Video Background Remover-2.svg", params: 'video_background_remover', name: 'picma_web-funcType', content: t('Index-content-Des-8') },
]
const editingTabs = [
    { src: "/assets/images/index/home/Image_Compressor.mp4", icon: "/assets/images/enhance/icons/Image Compressor-2.svg", params: 'image_compressor', name: 'picma_web-funcType', content: t('Index-content-Des-12') },
    { src: "/assets/images/index/home/Image_Converter.mp4", icon: "/assets/images/enhance/icons/Image Converter-2.svg", params: 'image_converter', name: 'picma_web-funcType', content: t('Index-content-Des-14') },
    { src: "/assets/images/index/home/AI_E_Commerce.mp4", icon: "/assets/images/enhance/icons/AI E-Commerce-2.svg", params: 'ai_e_commerce', name: 'picma_web-funcType', content: t('Index-content-Des-16') },
    // { src: "/assets/images/index/home/video_converter.mp4", icon: "/assets/images/enhance/icons/Video Converter-2.svg", params: 'video_converter', name: 'picma_web-funcType', content: t('Index-content-Des-15') },
    // { src: "/assets/images/index/home/Video_Compressor.mp4", icon: "/assets/images/enhance/icons/Video Compressor-2.svg", params: 'video_compressor', name: 'picma_web-funcType', content: t('Index-content-Des-13') },
    // { src: "/assets/images/index/home/Id_Photo_Maker.mp4", icon: "/assets/images/enhance/icons/Id Photo Maker-2.svg", params: 'id_photo_maker', name: 'picma_web-funcType', content: t('Index-content-Des-24') },
]
const generationTabs = [
    { src: "/assets/images/index/home/Al_Background_Generator.mp4", icon: "/assets/images/enhance/icons/Al Background Generator-2.svg", params: 'ai_background_generator', name: 'picma_web-funcType', content: t('Index-content-Des-18') },
    { src: "/assets/images/index/home/AI_Art_Generator.mp4", icon: "/assets/images/enhance/icons/Al Art Generator-2.svg", params: 'ai_art_generator', name: 'picma_web-enhance-funcType', content: t('Index-content-Des-19') },
    { id: "ai_logo", src: "/assets/images/index/home/AI_LOGO.mp4", icon: "/assets/images/enhance/icons/AI Logo-2.svg", params: 'ai_e_commerce', name: 'picma_web-enhance-funcType', content: t('Index-content-Des-20') },
    { id: "ai_poster", src: "/assets/images/index/home/AI_Poster.mp4", icon: "/assets/images/enhance/icons/AI Poster-2.svg", params: 'ai_e_commerce', name: 'picma_web-enhance-funcType', content: t('Index-content-Des-22') },
]
const resetVideo = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
        video.currentTime = 0;
        video.pause();
    });
};

function onTabSwitch(params) {
    transitioning.value = true;
    tabType.value = params;
    setTimeout(() => {
        addEventListenersToVideos();
        transitioning.value = false;
    }, 200);
}
const playVideo = (videoElement) => {
    videoElement.play();
};
const pauseVideo = (videoElement) => {
    videoElement.pause();
};
const addEventListenersToVideos = () => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((videoElement) => {
        videoElement.addEventListener("mouseenter", () => playVideo(videoElement));
        videoElement.addEventListener("mouseleave", () => pauseVideo(videoElement));
    });
};

function onEvent(record) {
    useLogEvent({ eventName: `index-tool-${record}` });
}
onMounted(() => {
    addEventListenersToVideos();
});
</script>

<style scoped>
.clip-container {
    position: relative;
    width: fit-content;
    overflow: hidden;
}

.clip-container video.video {
    object-fit: cover;
    /* 图片/视频将保持比例并填满容器，超出部分裁剪 */
    width: 800px;
    height: 70%;
    transition: all 0.3s ease;
    /* 添加过渡效果 */
}

.clip-container:hover video.video {
    height: 100%;
    height: 100%;
    object-fit: cover;
    /* 悬停时仍然保持比例 */
}

.clip-container .button-container {
    opacity: 0;
    /* 按钮默认隐藏 */
    transition: opacity 0.3s ease;
    /* 添加过渡效果 */
}

.clip-container:hover .button-container {
    opacity: 1;
    /* 悬停时显示按钮 */
}

/* 按钮容器样式 */
.button-container {
    position: absolute;
    /* 绝对定位 */
    top: 280px;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 15;
    /* 高于图片和文字 */
    pointer-events: none;
    /* 避免按钮容器捕获鼠标事件 */
}

/* 当图片被悬停时，显示按钮 */
.border:hover .button-container {
    opacity: 1;
    /* 显示按钮 */
    pointer-events: auto;
    /* 恢复按钮的鼠标事件 */
}

.floating {
    animation: float 2s ease-in-out infinite;
}

@keyframes float {

    0%,
    100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateY(-15px);
        /* 调整浮动的幅度 */
    }
}
</style>