<template>
  <div class="relative w-full h-full flex items-center justify-center">
    <div class="h-full flex-1 flex items-center justify-center relative px-6">
      <div class="h-4/5 w-full max-w-[1224px] relative overflow-hidden -translate-y-[10%]" v-if="fileModel">
        <div class="h-full w-full relative flex flex-wrap">
          <div class="h-full w-full flex relative items-center justify-center" id="main-area">
            <canvas id="origin-canvas-area" oncontextmenu="return false" width="300" height="300"
              :style="leftBackgStr">This function is not supported by the current Tourer. Try another
              one!</canvas>
            <canvas @mousedown="rightCanvasDown" id="top-canvas-area" oncontextmenu="return false" width="300"
              height="300" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 cursor-crosshair">This
              function is not supported by the current Tourer. Try another
              one!</canvas>
            <!-- <div
              class="absolute bg-[#FF711899] rounded-full -translate-y-1/2 -translate-x-1/2 pointer-events-none"
              :style="{
                width: penSize + 'px',
                height: penSize + 'px',
                left: arcXy.x + 'px',
                top: arcXy.y + 'px',
                display: arcXy.display,
              }"
            ></div> -->
          </div>
          <div v-if="
            fileModel.status == uploadStatus.handle ||
            fileModel.status == uploadStatus.lodingImg
          " class="absolute top-0 w-full h-full flex flex-col items-center justify-center bg-black-60">
            <Loading />
            <p v-if="fileModel.status == uploadStatus.handle"
              class="mt-10 max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center" v-html="$t('enhance-process')"></p>
          </div>
        </div>
        <div class="w-full h-full absolute left-0 top-0 flex items-center justify-center flex-col bg-[#00000088]"
          v-if="loadImgFail">
          <p class="mt-10 max-w-[50%] text-center Roboto-16 text-[#FFFFFF99]">
            {{ $t("loading-err")
            }}<a @click="reloadImg" class="cursor-pointer underline ml-1 text-text-gray">
              {{ $t("reload") }}?</a>
          </p>
          <div class="absolute right-2 top-2 close-btn" @click.stop="loadImgFail = false" />
        </div>
        <EnhanceGiveALike class="absolute right-4 bottom-4" v-if="isLogin"
          v-show="fileModel.enhanceUrl && !fileModel.isEvaluate" :id="fileModel.enhanceRecordId"
          @onEvaluate="onEvaluate"></EnhanceGiveALike>
      </div>
      <div v-else class="flex items-center justify-center">
        <FuncTypeUpload class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
          :funcType="route.params.funcType"></FuncTypeUpload>
      </div>
      <template v-if="fileModel">
        <div v-if="points.length == 0 && 0" class="absolute left-1/2 bottom-11 -translate-x-1/2 flex items-center">
          <img src="/assets/images/enhance/tip.svg" alt="close" />
          <span class="Roboto-Bold-20 text-black ml-2">{{
            $t("enhance-Partial-Redraw-opt-tip")
          }}</span>
        </div>
        <div class="absolute left-1/2 bottom-16 -translate-x-1/2" v-else-if="points.length == 0">
          <div class="flex items-center">
            <img class="mr-4 w-5 h-5" src="/assets/images/functype/AIGenerator/tips.png" />
            <div class="Roboto-Bold-20">
              {{ $t("enhance-Partial-Redraw-opt-tip") }}
            </div>
          </div>
        </div>
        <div v-else
          class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-6 w-max">
          <div class="relative h-full flex items-center">
            <textarea style="resize:none" v-model="prompt" @input="onInput"
              class="w-[530px] bg-light-gray-bg p-2 border Roboto-14 border-gray-line placeholder:text-gray outline-none min-h-10 max-h-24 rounded-lg"
              :placeholder="$t('enhance-Partial-Redraw-Description-ph')" :maxLength="100" :rows="1"
              :disabled="keepEnable"></textarea>
            <EnhanceGenerate class="ml-[32px] h-12" @click="onApply" :enable="!!prompt && !keepEnable" :picNum="1">
            </EnhanceGenerate>
            <div
              class="ml-[16px] h-12 border border-mainColor-deep rounded-full px-6 flex items-center gap-x-2 justify-center text-mainColor-deep"
              id="draw-save" @click="onKeep" :class="keepEnable
                ? 'hover:bg-mainColor-deep-10 cursor-pointer '
                : ' opacity-30 cursor-not-allowed'
                ">
              <img class="h-6" src="/assets/images/enhance/check 2.svg" alt="check" height="24" />
              {{ $t("enhance-keep") }}
            </div>
            <div
              class="ml-[32px] h-12 border border-gray-line rounded-full w-[72px] flex items-center justify-center cursor-pointer"
              @click="onClear">
              <img src="/assets/images/enhance/close.svg" alt="close" />
            </div>
          </div>

          <!-- 遮罩，部分流程禁止用户操作 -->
          <div class="absolute left-0 top-0 bottom-0 right-0 z-[2] cursor-not-allowed" v-if="
            !(
              fileModel.status === uploadStatus.success ||
              fileModel.status === uploadStatus.none ||
              fileModel.status === uploadStatus.fail ||
              fileModel.status === uploadStatus.uploadFail ||
              fileModel.status === uploadStatus.enhanceErr
            )
          "></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { uploadStatus } from "@/utils/type";
import type { EnhanceModel } from "~/types";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
const {
  customEventNames,
  cleanMenuDownload,
  applyEvent,
  updateFileListEvent,
  uploadLocalFilesSuccessEvent,
  updateECommerceCommonEvent,
} = useCustomEvent();
const mainStore = useMainStore();
const { t } = useI18n();
const { isLogin, isVip, isVipPro } = useUserInfo();
const fullLoading = useFullLoading();
const uploadStore = useUploadStore();
const props = defineProps({
  fileList: {
    type: Array<EnhanceModel>,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
  // commonData: {
  //   type: Object,
  //   default: { origin: null, enhance: null },
  // },
});

const fileModel = ref<EnhanceModel | null>(null);
// const historyList = ref<any>([]);

const leftBackgStr = ref("cursor: none;");
const hideCan = ref();
const hideCanTxt = ref();
const isSmear = ref(true);
const scale = ref(100);
const originalScale = ref(0);
const rightCansTxt = ref();

const originalObj = ref();
const rightCans = ref();
const topCans = ref();
const topCansTxt = ref();
const mouseupTime = ref(0);
const loadImgFail = ref(false);
const lastX = ref(0);
const lastY = ref(0);
const prompt = ref("");
// 重试图片地址
const loadUrl = ref("");
const isInit = ref(true);
const points = ref<Array<any>>([]);
const drawing = ref(false);
const dashOffset = ref(0);
const requestID = ref();
const route = useRoute();
const keepEnable = ref(false);
const lastResult = ref("");
watch(
  () => props.fileList,
  () => {
    console.log("数据");
    if (props.fileList.length) {
      fileModel.value = props.fileList[0];
      if (fileModel.value.status === uploadStatus.none) {
        if (isInit.value) {
          isInit.value = false;
          loadUrl.value = fileModel.value.localUrl;
          lastResult.value = fileModel.value.localUrl;
          init(fileModel.value.localUrl);
        }
      } else if (fileModel.value.status == uploadStatus.lodingImg) {
        isSmear.value = false;
        loadUrl.value = fileModel.value.enhanceUrl!;
        initEnhance(fileModel.value.enhanceUrl!);
        keepEnable.value = true;
      }
      // else if (fileModel.value.status === uploadStatus.uploadSuccess) {
      //   if (isInit.value) {
      //     isInit.value = false;
      //     loadUrl.value = fileModel.value.originUrl!;
      //     init(loadUrl.value);
      //   }
      // }
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);

onMounted(() => {
  // 生命周期原因，加nextTick
  nextTick(() => {
    // 其他功能处理好的图
    if (props.fileList.length && props.fileList[0].enhanceUrl) {
      isInit.value = true;
      initData();
      updateFileListEvent([
        {
          status: uploadStatus.none,
          originUrl: props.fileList[0].enhanceUrl,
          preImage: props.fileList[0].enhanceUrl,
          localUrl: props.fileList[0].enhanceUrl,
          enhanceUrl: props.fileList[0].enhanceUrl,
          isEvaluate: true,
        },
      ]);
    } else if (props.fileList.length && props.fileList[0].file) {
      // uploadLocalFilesSuccessEvent([props.commonData.origin]);
      isInit.value = true;
      initData();
      updateFileListEvent([
        {
          ...props.fileList[0],
          status: uploadStatus.none,
          isEvaluate: true,
        },
      ]);
    }
  });
  window.addEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
});
onUnmounted(() => {
  //   document.removeEventListener("mousemove", leftCanvasMouseMove);
  window.removeEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
  uploadStore.setImages([]);

  // if (fileModel.value?.enhanceUrl) {
  //   updateFileListEvent([
  //       {
  //         status: uploadStatus.uploadSuccess,
  //         originUrl: fileModel.value?.enhanceUrl,
  //         preImage: fileModel.value?.enhanceUrl,
  //         isEvaluate: false,
  //       },
  //     ]);
  // }

});
function uploadLocalFilesSuccess(e: any) {
  console.log(e.detail);
  const files = e.detail as File[];
  if (files.length) {
    isInit.value = true;
    initData();
  }
}
function initData() {
  isSmear.value = true;
  // historyList.value = [];
  loadImgFail.value = false;
  points.value = [];
  prompt.value = "";
  keepEnable.value = false;
  if (requestID.value) {
    cancelAnimationFrame(requestID.value);
  }
}

const count = ref(0)
function onApply() {
  if (count.value !== 0) {
    return
  }
  count.value++

  if (!prompt.value.trim()) {
    return;
  }
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  if (points.value.length <= 1) {
    return;
  }
  if (keepEnable.value) {
    checkSave()
    return;
  }
  const tempCans = document.createElement("canvas") as HTMLCanvasElement;
  const tempCtx = tempCans.getContext("2d");
  try {
    hideCan.value.toBlob((blob: Blob) => {
      const link = URL.createObjectURL(blob);
      const img = new Image();
      img.src = link;
      img.onload = () => {
        const hideCan2 = document.createElement("canvas") as HTMLCanvasElement;
        const hideCanTxt2 = hideCan2.getContext("2d");
        hideCan2.width = originalObj.value.width;
        hideCan2.height = originalObj.value.height;

        hideCanTxt2?.drawImage(
          img,
          0,
          0,
          originalObj.value.width,
          originalObj.value.height
        );

        tempCans.width = 2 * originalObj.value.width;
        tempCans.height = originalObj.value.height;
        tempCtx?.drawImage(originalObj.value, 0, 0);
        tempCtx?.drawImage(hideCan2, originalObj.value.width, 0);
        tempCans.toBlob((blob2) => {
          // emit("apply",URL.createObjectURL(n))
          const fileName = Math.random() + ".png";
          if (blob2) {
            // var a = document.createElement("a");
            // a.href = URL.createObjectURL(blob2);
            // a.download = fileName; // 自定义保存的文件名及类型
            // a.click();
            const file = new File([blob2], fileName, {
              type: blob2.type,
              lastModified: Date.now(),
            });
            const list = [...props.fileList];
            list[0].file = file;
            list[0].status = uploadStatus.none;
            list[0].enhanceUrl = "";
            list[0].originUrl = "";
            list[0].isEvaluate = false;
            list[0].enhanceRecordId = null;
            updateFileListEvent(list);
            cleanMenuDownload();

            nextTick(() => {
              applyEvent({
                enhanceCfgIds: [66],
                prompt: prompt.value,
              });
              count.value = 0
            });
          }
        }, "image/png");
      };
    }, "image/png");

  } catch (e) {
    count.value = 0
  }

}

function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList);
}
function initEnhance(url: string) {
  setTimeout(() => {
    console.log(123);
    rightCans.value = document.getElementById("origin-canvas-area");
    rightCansTxt.value = rightCans.value.getContext("2d");

    const e = document.getElementById("main-area")?.clientWidth || 0;
    const n = document.getElementById("main-area")?.clientHeight || 0;
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      fullLoading.hide();
      loadImgFail.value = false;
      originalObj.value = img;
      let w = e;
      let h = Math.floor((w * img.height) / img.width);
      h > n && ((h = n), (w = Math.floor((h * img.width) / img.height)));
      originalScale.value = img.width / w;
      rightCans.value.width = w;
      rightCans.value.height = h;

      rightCansTxt.value.clearRect(
        0,
        0,
        rightCans.value.width,
        rightCans.value.height
      );

      rightCansTxt.value.drawImage(
        img,
        0,
        0,
        rightCans.value.width,
        rightCans.value.height
      );
      // addToHistory();

      if (fileModel.value?.status === uploadStatus.lodingImg) {
        const list = [...props.fileList];
        list[0].status = uploadStatus.success;
        updateFileListEvent(list);
      }
    };
    img.onerror = function () {
      // messageBox.error(t("network-err"), t("loading-err"));
      fullLoading.hide();
      console.log("img load err");
      loadImgFail.value = true;
    };
  }, 0);
}
function init(url: string) {
  console.log("初始化");
  setTimeout(() => {
    console.log(123);
    rightCans.value = document.getElementById("origin-canvas-area");
    rightCansTxt.value = rightCans.value.getContext("2d");
    topCans.value = document.getElementById("top-canvas-area");
    topCansTxt.value = topCans.value.getContext("2d");
    const e = document.getElementById("main-area")?.clientWidth || 0;
    const n = document.getElementById("main-area")?.clientHeight || 0;
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      fullLoading.hide();
      loadImgFail.value = false;
      originalObj.value = img;
      let w = e;
      let h = Math.floor((w * img.height) / img.width);
      h > n && ((h = n), (w = Math.floor((h * img.width) / img.height)));
      originalScale.value = img.width / w;
      rightCans.value.width = w;
      rightCans.value.height = h;

      topCans.value.width = w;
      topCans.value.height = h;

      hideCan.value = document.createElement("canvas");
      hideCanTxt.value = hideCan.value.getContext("2d");
      hideCan.value.width = w;
      hideCan.value.height = h;
      hideCanTxt.value.fillStyle = "#000";
      hideCanTxt.value.fillRect(
        0,
        0,
        hideCan.value.width,
        hideCan.value.height
      );

      rightCansTxt.value.clearRect(
        0,
        0,
        rightCans.value.width,
        rightCans.value.height
      );
      rightCansTxt.value.drawImage(
        img,
        0,
        0,
        rightCans.value.width,
        rightCans.value.height
      );
      // addToHistory();

      if (fileModel.value?.status === uploadStatus.lodingImg) {
        const list = [...props.fileList];
        list[0].status = uploadStatus.success;
        updateFileListEvent(list);
      }
    };
    img.onerror = function () {
      // messageBox.error(t("network-err"), t("loading-err"));
      fullLoading.hide();
      console.log("img load err");
      loadImgFail.value = true;
    };
  }, 0);
}

function rightCanvasDown(t: MouseEvent) {
  isSmear.value ? canvasSmear(t) : checkSave();
}

function canvasSmear(t: MouseEvent) {
  // rightRepairCanvas 用于绘制每次的点
  // rightOriginCans 用于绘制最终显示的图像，显示在rightcans

  let n = document
    .getElementById("top-canvas-area")
    ?.getBoundingClientRect() as DOMRect;

  let x = (100 * (t.clientX - n.left)) / scale.value;
  let y = (100 * (t.clientY - n.top)) / scale.value;
  lastX.value = x;
  lastY.value = y;

  drawing.value = true;
  points.value = [];
  points.value.push({ x, y });

  topCansTxt.value.setLineDash([5, 5]); // 设置虚线样式
  topCansTxt.value.beginPath();
  topCansTxt.value.moveTo(lastX.value, lastY.value);
  topCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // 设置线条颜色
  topCansTxt.value.lineWidth = 2; // 设置线条宽度
  topCansTxt.value.stroke(); // 绘制虚线
  topCansTxt.value.setLineDash([]); // 重置为实线

  // 绘制虚线
  hideCanTxt.value.setLineDash([5, 5]); // 设置虚线样式
  hideCanTxt.value.beginPath();
  hideCanTxt.value.moveTo(lastX.value, lastY.value);
  hideCanTxt.value.strokeStyle = "black"; // 设置线条颜色
  hideCanTxt.value.lineWidth = 2; // 设置线条宽度
  hideCanTxt.value.stroke(); // 绘制虚线
  hideCanTxt.value.setLineDash([]); // 重置为实线

  document.getElementById("top-canvas-area")!.onmousemove = function (t) {
    if (!drawing.value) return;

    x = (100 * (t.clientX - n.left)) / scale.value;

    y = (100 * (t.clientY - n.top)) / scale.value;
    points.value.push({ x, y });

    lastX.value = x;
    lastY.value = y;
    drawCanvas();
  };
  document.onmouseup = function () {
    document.getElementById("top-canvas-area")!.onmousemove = null;
    document.onmouseup = null;

    // 防止个别鼠标异常触发两次抬起事件
    let time = new Date().getTime();
    // console.log("鼠标抬起", time);

    if (mouseupTime.value == 0 || time - mouseupTime.value > 500) {
      mouseupTime.value = time;
      drawing.value = false;
      if (points.value.length > 1) {
        animateDashedLine();
      }
    }
  };
}
function animateDashedLine() {
  dashOffset.value += 1; // 增加偏移量
  if (dashOffset.value > 10) dashOffset.value = 0;
  if (points.value.length == 0) {
    return;
  }
  topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
  // 绘制虚线
  topCansTxt.value.setLineDash([5, 5]); // 设置虚线样式
  topCansTxt.value.lineDashOffset = -dashOffset.value; // 设置虚线偏移
  topCansTxt.value.beginPath();
  topCansTxt.value.moveTo(points.value[0].x, points.value[0].y);
  for (let i = 1; i < points.value.length; i++) {
    topCansTxt.value.lineTo(points.value[i].x, points.value[i].y);
  }
  topCansTxt.value.lineTo(points.value[0].x, points.value[0].y);
  topCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // 设置线条颜色
  topCansTxt.value.lineWidth = 2; // 设置线条宽度
  topCansTxt.value.stroke(); // 绘制虚线
  topCansTxt.value.setLineDash([]); // 重置为实线
  requestID.value = requestAnimationFrame(animateDashedLine);
}
function drawCanvas() {
  topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
  // 绘制虚线
  topCansTxt.value.setLineDash([5, 5]); // 设置虚线样式
  topCansTxt.value.beginPath();
  topCansTxt.value.moveTo(points.value[0].x, points.value[0].y);
  for (let i = 1; i < points.value.length; i++) {
    topCansTxt.value.lineTo(points.value[i].x, points.value[i].y);
  }
  topCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // 设置线条颜色
  topCansTxt.value.lineWidth = 2; // 设置线条宽度
  topCansTxt.value.stroke(); // 绘制虚线
  topCansTxt.value.setLineDash([]); // 重置为实线

  hideCanTxt.value.fillStyle = "#000";
  hideCanTxt.value.fillRect(0, 0, hideCan.value.width, hideCan.value.height);
  //   hideCanTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
  // 绘制虚线
  hideCanTxt.value.setLineDash([5, 5]); // 设置虚线样式
  hideCanTxt.value.beginPath();
  hideCanTxt.value.moveTo(points.value[0].x, points.value[0].y);
  for (let i = 1; i < points.value.length; i++) {
    hideCanTxt.value.lineTo(points.value[i].x, points.value[i].y);
  }
  hideCanTxt.value.strokeStyle = "black"; // 设置线条颜色
  hideCanTxt.value.lineWidth = 2; // 设置线条宽度
  hideCanTxt.value.stroke(); // 绘制虚线
  hideCanTxt.value.setLineDash([]); // 重置为实线
  fillArea();
}
function fillArea() {
  if (points.value.length > 0) {
    hideCanTxt.value.beginPath();
    hideCanTxt.value.moveTo(points.value[0].x, points.value[0].y);
    for (let i = 1; i < points.value.length; i++) {
      hideCanTxt.value.lineTo(points.value[i].x, points.value[i].y);
    }
    hideCanTxt.value.closePath();
    hideCanTxt.value.fillStyle = "white"; // 设置填充颜色
    hideCanTxt.value.fill(); // 填充区域
  }
}
// function addToHistory() {
//   const topImageData = topCansTxt.value.getImageData(
//     0,
//     0,
//     topCans.value.width,
//     topCans.value.height
//   );
//   const hideImageData = hideCanTxt.value.getImageData(
//     0,
//     0,
//     hideCan.value.width,
//     hideCan.value.height
//   );
//   historyList.value.push({
//     topImageData,
//     hideImageData,
//   });
// }

function reloadImg() {
  console.log("reloadImg");

  loadImgFail.value = false;
  fullLoading.show("", null);
  if (fileModel.value) {
    initEnhance(loadUrl.value);
  }
}
function checkSave() {
  if (keepEnable.value) {
    const dom: HTMLElement | null = document.getElementById("draw-save");
    if (dom) {
      if (!dom.classList.contains('shake')) {
        dom.classList.add("shake");
        setTimeout(() => {
          dom.classList.remove("shake");
        }, 500)
      }
    }
  }
}
function onClear() {
  if (keepEnable.value) {
    // 恢复上一次结果
    initData();
    dashOffset.value = 0;
    const fileList = [...props.fileList];
    fileList[0].isEvaluate = false;
    fileList[0].status = uploadStatus.none;
    fileList[0].enhanceRecordId = null;
    fileList[0].enhanceUrl = null;
    updateFileListEvent(fileList);
    cleanMenuDownload();
    topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
    hideCanTxt.value.clearRect(0, 0, hideCan.value.width, hideCan.value.height);
    init(lastResult.value);
  } else {
    // 清除当前操作
    initData();
    dashOffset.value = 0;
    topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
    hideCanTxt.value.clearRect(0, 0, hideCan.value.width, hideCan.value.height);
  }

}
// 保留当前操作
function onKeep() {
  if (!keepEnable.value) {
    return;
  }
  initData()
  dashOffset.value = 0;
  topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
  hideCanTxt.value.clearRect(0, 0, hideCan.value.width, hideCan.value.height);
  if (fileModel.value?.enhanceUrl) {
    lastResult.value = fileModel.value?.enhanceUrl;
  }
}
const onInput = () => {
  if (prompt.value) {
    prompt.value = prompt.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '');
  }
};
</script>
<style lang="scss" scoped>
.shake {
  animation: shake 0.5s infinite;
  /* 应用动画 */
}

@keyframes shake {
  0% {
    transform: translateX(0);
    /* 起始位置 */
  }

  25% {
    transform: translateX(-10px);
    /* 向左移动 */
  }

  50% {
    transform: translateX(10px);
    /* 向右移动 */
  }

  75% {
    transform: translateX(-10px);
    /* 向左移动 */
  }

  100% {
    transform: translateX(0);
    /* 回到起始位置 */
  }
}
</style>
