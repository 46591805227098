<template>
  <div class="grid grid-cols-1 gap-4 gap-[10px] md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
    <BlogItem v-for="article in props.dataList" :key="article.articleId" :article="article">
    </BlogItem>
  </div>
</template>
<script setup>

const props = defineProps({
  dataList: {
    type: Array,
    default: () => [],
  },
});

</script>

<style scoped lang="scss"></style>
