<template>
  <div
    class="h-8 w-14 relative  p-[2px]  shadow-[inset_0_2px_3px_0_rgba(0,0,0,0.2)] rounded-[30px] transition-all duration-500 cursor-pointer"
    :style="{backgroundColor: modelValue ? checkedColor : uncheckedColor}"
    @click="toggleSwitch"
  >
    <div class="switch-handle transition-all duration-500 ease-in-out" :class="modelValue ? 'left-[26px]' : 'left-[2px]'"></div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: true,
  },
  checkedColor: {
    type: String,
    default: "#5F45FF",
  },
  uncheckedColor: {
    type: String,
    default: "#F7F7F8",
  },
});
const emit = defineEmits(["update:modelValue"]);
const toggleSwitch = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>
<style scoped>
.switch-handle {
  position: absolute;
  border: 1px solid;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffffff;
  border: #c9c9c9;
  box-shadow: 0px 2px 4px 0px #00000033;
}
</style>
