<template>
  <div class=" h-10 border border-mainColor-deep-10 rounded-full flex items-center justify-center cursor-pointer ">
    <a-upload ref="uploadRef" name="file" :multiple="props.acceptCount > 1" action="" @before-upload="onBeforeUpload"
      :accept="props.acceptList.map((v) => '.' + v).join()" :show-file-list="false"
      class="w-full h-full flex items-center justify-center">
      <template #upload-button>
        <div class="h-full flex items-center justify-center px-10">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.97308 14.1094V2.63786M4.72119 6.99483L9.97308 2.13445L14.8894 6.99483" stroke="#1F1F1F" style="
                stroke: #1f1f1f;
                stroke: color(display-p3 0.1216 0.1216 0.1216);
                stroke-opacity: 1;
              " stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.91895 13.5215V18.0801H18.0271V13.5215" stroke="#1F1F1F" style="
                stroke: #1f1f1f;
                stroke: color(display-p3 0.1216 0.1216 0.1216);
                stroke-opacity: 1;
              " stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span class="ml-3 text-text-deep Roboto-Bold-12">{{
            $t("enhance-reupload")
          }}</span>
        </div>
      </template>
    </a-upload>
    <div v-if="props.drop" class="absolute top-0 left-0 w-full h-full z-[2] cursor-pointer" @click="onUpload"
      @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover"></div>
  </div>
</template>
<script setup>
import { getFileSuffix } from "@/utils/index";
import { FileType } from "@/utils/type";
import {
  imageAccept,
  maxImageSize,
  maxUploadCount,
  minWidth,
  maxWidth,
  maxVideoSize,
  videoAccept,
} from "@/utils/config";
import messageBox from "@/composables/messageBox";

const props = defineProps({
  acceptCount: {
    type: Number,
    default: 1,
  },
  maxUploadCount: {
    type: Number,
    default: maxUploadCount,
  },
  acceptList: {
    type: Array,
    default: imageAccept,
  },
  fileType: {
    type: Number,
    default: FileType.image,
  },
  drop: {
    type: Boolean,
    default: false,
  },
  eventEnable: {
    type: Boolean,
    default: true,
  },
});
const { t } = useI18n();
const { isVip, isVipPro } = useUserInfo();
const timeout = ref();
const formatFailFiles = ref([]);
const sizeFailFiles = ref([]);
const fileList = ref([]);
const count = ref(0);
const isFirstUpload = ref(false);
const uploadRef = ref();
// const acceptCount = computed(() => {
//   return isVip.value ? maxUploadCount : 1;
// });
const emit = defineEmits(["onSuccess"]);
function onBeforeUpload(file) {
  if (isFirstUpload.value) {
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    isFirstUpload.value = false;
    count.value = 0;
  }
  count.value++;
  const suffix = getFileSuffix(file.name).toLowerCase();
  if (props.acceptList.includes(suffix)) {
    if (props.fileType === FileType.video) {
      // 检查是否符合视频大小
      if (file.size < maxVideoSize) {
        fileList.value.push(file);
      } else {
        sizeFailFiles.value.push(file);
      }
    } else {
      // 检查是否符合图片大小
      if (file.size < maxImageSize) {
        fileList.value.push(file);
      } else {
        sizeFailFiles.value.push(file);
      }
    }
  } else {
    formatFailFiles.value.push(file);
  }

  debounce(() => {
    isFirstUpload.value = true;
    if (fileList.value.length > props.acceptCount) {
      if (isVipPro.value) {
        messageBox.error(t("index-upload"), t("index-upload-limit"));
      } else {
        messageBox.error(
          t("index-upload-batch"),
          t("index-upload-batch-limit")
        );
      }

      return;
    }
    if (props.fileType === FileType.video) {
      videoHandle();
    } else {
      imageHandle();
    }
  });
  return false;
}

function debounce(fun, time = 500) {
  if (timeout.value) {
    clearTimeout(timeout.value); // 规定时间内若定时器存在则清除
  }
  timeout.value = setTimeout(() => {
    fun(); // 调用接口
    clearTimeout(timeout.value);
  }, time);
}

function videoHandle() {
  if (formatFailFiles.value.length > 0) {
    messageBox.error(
      t("index-upload"),
      t("index-upload-limit-format", { format: props.acceptList.join("、") })
    );
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];

    return;
  } else if (sizeFailFiles.value.length > 0) {
    messageBox.error(t("index-upload"), t("index-upload-video-limit-size"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  } else if (fileList.value.length > props.acceptCount) {
    messageBox.error(t("index-upload"), t("index-upload-video-limit-number"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  }
  onSuccess(fileList.value);
}
function imageHandle() {
  if (formatFailFiles.value.length > 0) {
    messageBox.error(
      t("index-upload"),
      t("index-upload-limit-format", { format: props.acceptList.join("、") })
    );
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];

    return;
  } else if (sizeFailFiles.value.length > 0) {
    messageBox.error(t("index-upload"), t("index-upload-limit-size"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  } else if (fileList.value.length > props.acceptCount) {
    messageBox.error(t("index-upload"), t("index-upload-limit"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  }
  let count = 0;
  let hasSizeFail = false;
  fileList.value.map((v) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      let image = new Image();
      image.onload = function () {
        count++;
        // 检查图片尺寸大小
        if (
          image.width < minWidth ||
          image.height > maxWidth ||
          image.width > maxWidth ||
          image.height < minWidth
        ) {
          hasSizeFail = true;
        }
        if (count == fileList.value.length) {
          if (hasSizeFail) {
            messageBox.error(t("index-upload"), t("index-upload-limit-width"));
            return;
          }
          onSuccess(fileList.value);
        }
      };
      image.onerror = function () {
        count++;
        // hasSizeFail = true;

        if (count == fileList.value.length) {
          if (hasSizeFail) {
            messageBox.error(t("index-upload"), t("index-upload-limit-width"));
            return;
          }
          onSuccess(fileList.value);
        }
        // reject(new Error("error"));
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(v);
  });
}
defineExpose({ onUpload });
/**
 * 外部调用上传
 */
function onUpload() {
  const inputElement = uploadRef.value.$el.children[0];
  var clickEvent = new MouseEvent("click", {
    bubbles: false,
    cancelable: true,
  });
  inputElement.dispatchEvent(clickEvent);
}
function onSuccess(fileList) {
  emit("onSuccess", fileList);
  if (props.eventEnable) {
    window.dispatchEvent(
      new CustomEvent("uploadLocalFilesSuccess", {
        detail: fileList,
      })
    );
  }
}
// 拖拽上传
async function handleDrop(e) {
  console.log("handleDrop", e);
  e.stopPropagation();
  e.preventDefault();
  // useLogEvent({ eventName: "index-upload" });
  // useFBLogEvent({ eventName: "index-upload" });
  // if (!this.isLogin) {
  //   this.$store.commit("SET_VISIBLELOGIN", true);
  //   return false;
  // }

  fileList.value = [];
  formatFailFiles.value = [];
  sizeFailFiles.value = [];
  count.value = 0;
  let { items } = e.dataTransfer; // 注意这里，是读取items属性
  if (items && items.length && items[0].webkitGetAsEntry != null) {
    if (items.length > props.acceptCount) {
      // messageBox.error(
      //   t("index-upload"),
      //   t("index-upload-limit-number")
      // );
      if (isVipPro.value) {
        messageBox.error(t("index-upload"), t("index-upload-video-limit-number"));
      } else {
        messageBox.error(
          t("index-upload-batch"),
          t("index-upload-batch-limit")
        );
        mainStore.setVisibleVipDialog(true);
        mainStore.setVipFrom("funcType_batch");
      }
      return;
    }
    addFilesItems(items);
  }
  return false;
}

function handleDragover(e) {
  e.stopPropagation();
  e.preventDefault();
  e.dataTransfer.dropEffect = "copy";
}
// 把读取到的文件加入一个对象内，这里是我的逻辑，可自行根据自己逻辑修改
function getFile(file, total) {
  console.log("总的", total);
  console.log(count.value);
  const suffix = getFileSuffix(file.name);
  console.log("suffix", suffix);
  if (props.acceptList.includes(suffix)) {
    if (props.fileType === FileType.video) {
      if (file.size < maxVideoSize) {
        console.log("file.size", file.size, maxVideoSize);
        fileList.value.push(file);
      } else {
        sizeFailFiles.value.push(file);
      }
    } else {
      if (file.size < maxImageSize) {
        console.log("file.size", file.size, maxImageSize);
        fileList.value.push(file);
      } else {
        sizeFailFiles.value.push(file);
      }
    }
    count.value++;
    console.log("count:%d,total:%d", count.value, total);

    if (count.value == total) {
      if (props.fileType === FileType.video) {
        videoHandle();
      } else {
        imageHandle();
      }
    }
    return;
  } else {
    if (suffix != "ds_store") {
      formatFailFiles.value.push(file);
    }
  }
  count.value++;
  console.log("name:", file.name, total, count.value);
  if (count.value == total) {
    if (props.fileType === FileType.video) {
      videoHandle();
    } else {
      imageHandle();
    }
    return;
  }
}
// 循环判断拖拽来的是文件还是文件夹
function addFilesItems(items) {
  let diff = "";
  let dirCount = 0;
  console.log("addFilesItems", items.length);
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var entry;

    if (item.webkitGetAsEntry && (entry = item.webkitGetAsEntry())) {
      if (entry.isFile) {
        console.log("文件", entry);
        if (diff == "dir") {
          messageBox.error(t("index-upload"), t("index-upload-limit-number"));
          return;
        }
        diff = "file";
      } else if (entry.isDirectory) {
        if (diff == "file") {
          messageBox.error(t("index-upload"), t("index-upload-limit-number"));
          return;
        }
        diff = "dir";
        dirCount += 1;
      }
    }
  }
  if (dirCount > 1) {
    messageBox.error(t("index-upload"), t("index-upload-limit-number"));
    return;
  }
  const count = items.length;
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var entry;
    if (item.webkitGetAsEntry && (entry = item.webkitGetAsEntry())) {
      if (entry.isFile) {
        entry.file(function (file) {
          console.log("文件2222items:%d", count);

          getFile(file, count);
          return file;
        });
        // // 如果是文件，直接加进去
      } else if (entry.isDirectory) {
        console.log("文件夹");
        // 递归文件夹
        addFilesFormDirectory(entry, entry.name, 0);
      }
    }
  }
}
// 读取文件夹下的文件
function addFilesFormDirectory(directory, path, deep) {
  if (deep == 1) {
    return;
  }
  const dirReader = directory.createReader();

  dirReader.readEntries(function (entries) {
    console.log("文件夹文件数:", entries.length);
    if (entries.length > props.acceptCount) {
      if (isVipPro.value) {
        messageBox.error(t("index-upload"), t("index-upload-limit"));
      } else {
        messageBox.error(
          t("index-upload-batch"),
          t("index-upload-batch-limit")
        );
        mainStore.setVipFrom("funcType_batch");
        mainStore.setVisibleVipDialog(true);
      }

      return;
    }
    for (let i = 0; i < entries.length; i++) {
      let entry = entries[i];
      if (entry.isFile) {
        // 如果是文件
        entry.file(function (file) {
          // 如果是点击上传文件夹 文件会携带webkitRelativePath  ，拖拽则没有！
          // webkitRelativePath 没有set方法，需要添加个fullPath属性，才能得到递归的目录
          file.fullPath = path + "/" + file.name;
          console.log("共", entries.length);
          getFile(file, entries.length); // 我的getFile 是接收数组，方便点击多传的时候一起传过去

          return file;
        });
      } else if (entry.isDirectory) {
        count.value++;
        // 递归处理
        // that.addFilesFormDirectory(entry, path + '/' + entry.name, deep + 1)
      }
    }
  });
}
</script>

<style scoped lang="scss"></style>
