<template>
  <div
    class=" relative bg-white w-full shadow-6 h-16 rounded-[20px] flex items-center px-4 gap-3"
  >
    <img
      v-if="isPlay"
      @click="pause"
      src="/assets/images/enhance/pause.svg"
      alt="pause"
    />
    <img
      v-else
      @click="play"
      src="/assets/images/enhance/play.svg"
      alt="play"
    />
    <span class="Roboto-12 leading-3">{{ formatTime(currentTime) }}</span>
    <div
      class="flex-1 rounded-full bg-gray-line relative h-3 cursor-pointer mx-2"
      @mousedown="startDragging"
      @touchstart="startDragging"
      ref="progressRef"
    >
      <div
        class="absolute top-0 bottom-0 left-0 bg-[#BBBDCA] rounded-full"
        :style="`width: ${progress}%;`"
      ></div>
      <div
        :style="`left: ${progress}%;`"
        class="absolute -translate-x-1/2 -translate-y-1/4 border-[2px] border-[#DFDFDF] bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.078)] h-7 w-7 rounded-full"
      ></div>
    </div>
    <span class="Roboto-12 leading-3">{{ formatTime(totalDuration) }}</span>
    <div v-show="!enable" class=" absolute inset-0"></div>
  </div>
</template>
<script setup lang="ts">
const progress = ref(0);
const progressRef = ref<HTMLElement | null>(null);
const isDragging = ref(false); // 是否正在拖动
const isPlay = ref(false);
const emit = defineEmits([
  "onPlay",
  "onPause",
  "setSeed",
  "getSeed",
  "updateSeed",
]);
const props = defineProps({
  currentTime: {
    type: Number,
    default: 0,
  },
  totalDuration: {
    type: Number,
    default: 0,
  },
  enable: {
    type: Boolean,
    default: true,
  },
});

watch(
  () => props.currentTime,
  () => {
    console.log(props.currentTime, props.totalDuration);

    if (props.totalDuration > 0) {
      progress.value = (props.currentTime / props.totalDuration) * 100;
      if (progress.value >= 100) {
        isPlay.value = false;
        progress.value = 100;
      }
      console.log(progress.value);
    } else {
      progress.value = 0;
    }
  }
);
const startDragging = (event: MouseEvent | TouchEvent) => {
  isDragging.value = true;

  updateProgress(event);

  window.addEventListener("mousemove", updateProgress);
  window.addEventListener("mouseup", stopDragging);
  window.addEventListener("touchmove", updateProgress);
  window.addEventListener("touchend", stopDragging);
};

const updateProgress = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value) return;
  const rect = progressRef.value!.getBoundingClientRect(); // 获取进度条的位置
  const offsetX =
    (event instanceof MouseEvent ? event.clientX : event.touches[0].clientX) -
    rect.left; // 获取鼠标或触摸相对于进度条的横坐标
  const width = rect.width; // 进度条的宽度
  const progress = Math.min(Math.max((offsetX / width) * 100, 0), 100); // 计算进度
  updateSeed(progress);
};

const stopDragging = () => {
  isDragging.value = false;
  window.removeEventListener("mousemove", updateProgress);
  window.removeEventListener("mouseup", stopDragging);
  window.removeEventListener("touchmove", updateProgress);
  window.removeEventListener("touchend", stopDragging);
};
const play = () => {
  isPlay.value = true;
  emit("onPlay");
};
const pause = () => {
  isPlay.value = false;
  emit("onPause");
};
const getSeed = () => {
  emit("getSeed", progress.value);
  return progress.value;
};
const updateSeed = (val: number) => {
  console.log(val);

  progress.value = val;
  emit("updateSeed", progress.value / 100);
};
const setSeed = (val: number) => {
  progress.value = val;
};
function formatTime(seconds: number): string {
  // 计算小时、分钟和秒
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  // 格式化输出，确保每个部分都是两位数字
  const formattedTime = [
    String(hours).padStart(2, "0"), // 小时
    String(minutes).padStart(2, "0"), // 分钟
    String(secs).padStart(2, "0"), // 秒
  ].join(":");

  return formattedTime;
}
defineExpose({
  play,
  pause,
  getSeed,
  setSeed,
});
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.078);
}
</style>
