<template>
    <div class="relative h-full w-full flex items-center justify-center">
        <div v-show="files" class="relative h-4/5 w-[1224px] -translate-y-[10%] shadow-md rounded-xl bg-white">
            <!-- <img ref="ImgRef" object-fill="contain" style="height: 600px; width: 100%" :src="imgSrc" /> -->
            <div ref="ImgRef" class="image-container" object-fill="contain"
                :style="{ backgroundImage: `url(${imgSrc})` }"></div>
            <div v-show="process === 1"
                class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white">
                {{ $t("after") }}
            </div>
        </div>
        <div v-show="!files" class="flex items-center justify-center">
            <FuncTypeUpload class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
                :funcType="route.params.funcType"></FuncTypeUpload>
        </div>
        <canvas ref="canvasRef" style="display: none"></canvas>
        <div v-if="files" class="absolute left-1/2 bottom-10 -translate-x-1/2 w-[888px] flex flex-col items-center">
            <div class="bg-white shadow-6 h-[80px] rounded-[20px] flex items-center pl-6">
                <div class="flex items-center gap-3 text-xl mr-4">
                    <img v-if="process > 1" src="/assets/images/enhance/check.svg" alt="check" />
                    <span class="Roboto-Bold-16" v-if="oldFileSize != 0 && fileSize != 0 && process > 1">
                        {{ $t('enhance-Compressed') }} {{ (100 - fileSize / oldFileSize * 100).toFixed(1) }}%
                    </span>
                </div>
                <div
                    class="h-12 rounded-lg border border-gray-line ml-3 px-3 flex items-center gap-3 bg-light-gray-bg ">
                    <img class="h-6" src="/assets/images/enhance/ImageIcon.png" alt="img" />
                    <span class="max-w-[140px] text-ellipsis overflow-hidden whitespace-nowrap Roboto-Bold-14">
                        {{ truncateString(fileName, 20) }}
                    </span>
                    <span class="text-text-gray Roboto-14" v-if="oldFileSize != 0 && fileSize != 0 && process > 1">
                        {{ formatFileSize(oldFileSize) }}
                    </span>
                    <img v-if="oldFileSize != 0 && fileSize != 0 && process > 1" class="w-6 h-6"
                        src="/assets/images/enhance/to.png" alt="check" />
                    <span class="Roboto-14 " :class="oldFileSize != 0 ? 'text-mainColor-deep' : 'text-text-gray'">
                        {{ formatFileSize(fileSize) }}
                    </span>
                </div>
                <EnhanceApply class="w-max ml-8 mr-6" @click="onApply" :enable="applyEnabled"></EnhanceApply>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useUploadStore } from "@/store/upload";
import Compressor from 'compressorjs';
import { useMainStore } from "@/store/index";
const uploadStore = useUploadStore();
const process = ref(0);
const imgSrc: any = ref(null);
const fileName = ref('')
const fileSize = ref(0)
const oldFileSize = ref(0)
const canvasRef: any = ref(null);
const compressedImage: any = ref(null)
const files: any = ref(null)
const compressedResult: any = ref('')
const route = useRoute();
const { t } = useI18n();
const fullLoading = useFullLoading();
const compressorResult = ref();
const { isLogin, isNormalVip, isVip, userInfo, checkWeekCount } = useUserInfo();
const applyEnabled = computed(() => {
    return process.value == 0 && imgSrc.value
});
onMounted(async () => {
    const images = [...uploadStore.images];
    uploadStore.setImages([]);
    if (images.length > 0) {
        handFileChange(images);
    }
    window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
    window.addEventListener("updateLinkEvent", updateLinkEvent);
});
onUnmounted(() => {
    window.removeEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
    window.removeEventListener("updateLinkEvent", updateLinkEvent);
});
function uploadLocalFilesSuccess(params: any) {
    handFileChange(params.detail);
}
const typeFile = ref('')
const h = ref(0)
const w = ref(0)
function handFileChange(imgFile: any) {
    process.value = 0;
    const file = imgFile[0];
    files.value = file;
    fileName.value = file.name;
    fileSize.value = file.size
    const regex = /\/([^/]+)$/;
    const match = file.type.match(regex);
    if (match) {
        typeFile.value = match[1];
    }
    if (!file) return;
    const reader: any = new FileReader();
    reader.onload = (e: any) => {
        const img: any = new Image();
        img.onload = () => {
            const canvas: any = canvasRef.value;
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            w.value = img.width;
            canvas.height = img.height;
            h.value = img.height;
            ctx.drawImage(img, 0, 0);
            // updateDownloadLink()
        };
        img.src = e.target.result;
        imgSrc.value = e.target.result;
        window.dispatchEvent(
            new CustomEvent("updateFileListEvent", {
                detail: [file],
            })
        );
    };
    reader.readAsDataURL(file);

}
const mainStore = useMainStore();
function onApply() {
    if (process.value == 0) {
        if (files.value) {
            if (!isLogin.value) {
                mainStore.setVisibleLogin(true);
                return;
            }
            if (!checkWeekCount()) {
                return
            }
            if (['image/tiff', 'image/webp', 'image/jpeg', 'image/jpg', 'image/png', "image/bmp"].includes(files.value.type)) {
                fullLoading.show(`${t("enhance-upload-process")}(0%)`, t("enhance-process"));
                new Compressor(files.value, {
                    quality: 0.8, // 压缩质量
                    maxWidth: w.value - 50, // 最大宽度
                    maxHeight: h.value - 50, // 最大高度
                    success: (res) => {
                        if (process.value < 1) {
                            compressorResult.value = res
                            compressedImage.value = URL.createObjectURL(res)
                            compressedResult.value = res.type

                            oldFileSize.value = fileSize.value
                            fileSize.value = res.size
                            imgSrc.value = compressedImage.value
                            const interval = setInterval(() => {
                                process.value += 0.02;
                                const percent = Number((process.value * 100).toFixed(2));
                                fullLoading.updateText({
                                    title: `${t("enhance-upload-process")}(${percent}%)`,
                                });
                                if (process.value > 1) {
                                    clearInterval(interval)
                                    // 更新用户信息，同步点数
                                    window.dispatchEvent(
                                        new CustomEvent("updateUseInfoEvent", {
                                            // 提交相关处理参数，如相关表单
                                            detail: '',
                                        })
                                    );
                                    fullLoading.hide()
                                }
                            }, 50);
                        }
                        window.dispatchEvent(
                            new CustomEvent("downloadUrlEvent", {
                                // 提交相关处理参数，如相关表单
                                detail: { url: compressedImage.value, suffix: typeFile.value },
                            })
                        );
                    },
                    error: (err) => {
                        console.error('Image compression failed:', err);
                        fullLoading.hide()
                    },
                });
            }
        }
    }

}
const updateLinkEvent = () => {
    if (compressorResult.value) {
        compressedImage.value = URL.createObjectURL(compressorResult.value)
        // console.log('updateLinkEvent',compressedImage.value);
        window.dispatchEvent(
            new CustomEvent("downloadUrlEvent", {
                // 提交相关处理参数，如相关表单
                detail: compressedImage.value,
            })
        );
    }
}
</script>
<style>
.image-container {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>