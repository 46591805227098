<template>
  <div @click="hide">
    <div v-if="!mainStore.isMobile" :class="['dialog', type === 'success' ? '' : 'error']">
      <div class="web">
        <div class="title">
          <img
            v-if="type == 'success'"
            :src="imgBase64Obj.success"
            height="24"
            width="24"
            alt="success"
          />
          <img
            v-else
            :src="imgBase64Obj.error"
            height="24"
            width="24"
            alt="error"
          />
          <span>{{ title }}</span>
        </div>
        <p class="text">{{ text }}</p>
        <img class="close" :src="imgBase64Obj.close" alt="close" />
      </div>
    </div>
    <div
      v-else
      :class="['m-dialog',type == 'success' ? '' : 'error']"
    >
      <div class="title">
        <img
          v-if="type == 'success'"
          :src="imgBase64Obj.success"
          height="24"
          width="24"
          alt="success"
        />
        <img
          v-else
          :src="imgBase64Obj.error"
          height="24"
          width="24"
          alt="error"
        />
        <span>{{ title }}</span>
      </div>
      <p class="text">{{ text }}</p>
    </div>
  </div>
</template>
<script setup>
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const imgBase64Obj = {
  success:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAMAAACP+FljAAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMA34Ag73CfYEAwEL9QkM+wjz1kD0IAAADESURBVDjLhZRZEoQgDESbgIyAqLn/ZWemShYjmPelLE3oJKDHbgvRslkMiWT4wpzPNdbzDS+WLI4FbkEH8QBC5eQhp9g/1Vh5yoo/hqcY/PjwC5+ZgLtGExCG0hZ7iYLG89aUi6SmWr8CYvk5agguWtMi29s96rYqS0CXOTwOpru16EKLCI6zsL63YY8IXjhncHAjAdKYdNfzgHXcQyKXxyacD0DiF4yeTb0e9IrSaxLIPCRrfaF1ltqbEptbd1NEQX0fvveMMHUKgFEvAAAAAElFTkSuQmCC",
  error:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAMAAACP+FljAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAgN8gcEAwv7Dvn5CPYFAQWNmi0QAAAK9JREFUOMuFlOEOhSAIhYFMLa3O+z/tXRu7Ypl8P9qKE4MjSJblDMzhXGjIxQJF6luz7OjYH5Kw4cEWyMAYwC1eMaTO/rc5Vnyy0o3gE7njByYcmuCTRBRbvqjVliimCrZq1tqzaSR1XbE+/2QS9Io+DiFY+O0bPZx5+eZm8GvI8y6SecljH1bjZNWPxslIlDBB3NP05sGdKHcmlYIhxd8Lf7P83bSS0rabL2o498MP9NItGGH1W80AAAAASUVORK5CYII=",
  close:
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuODk4NTEgMS4yODY0Mkw3Ljk5NDE3IDcuMzc4ODFMMTQuMDI1MSAxLjM0NzU5QzE0LjQzNjEgMC45ODE0ODYgMTQuODYxNSAxLjIwMDA3IDE1LjAzMTQgMS4zODM1N0MxNS4xNDA1IDEuNTE2NDYgMTUuMTk2NyAxLjY4NDg3IDE1LjE4OTQgMS44NTY2MkMxNS4xODIxIDIuMDI4MzcgMTUuMTExOCAyLjE5MTQxIDE0Ljk5MTkgMi4zMTQ1Nkw4Ljk2MDA1IDguMzQ0ODlMMTQuOTkxOSAxNC4zNzM0QzE1LjI1OTkgMTQuNTk4MyAxNS4yNTk5IDE1LjAzOTEgMTUuMDQ0OSAxNS4yODU1QzE0LjgyOTEgMTUuNTMxMSAxNC40MzM0IDE1LjY3MzIgMTQuMDYwMiAxNS4zODE4TDcuOTk0MTcgOS4zMTA5NkwxLjkyMDk5IDE1LjM4NDVDMS42NTQ3OSAxNS42MjI4IDEuMjE1OTIgMTUuNTc0MyAwLjk5OTE3NyAxNS4zMzc3QzAuNzgxNTM5IDE1LjEwMDIgMC42OTg4IDE0LjY5IDAuOTc2NjkzIDE0LjQwMDRMNy4wMjgyOSA4LjM0NDg5TDEuMDAxODcgMi4zMTQ1NkMwLjc4MTUzOSAyLjA3MTY5IDAuNzA1MDk1IDEuNjI3MzQgMS4wMDE4NyAxLjM0NzU5QzEuMjk4NjUgMS4wNjc4NCAxLjc0MTEyIDEuMTA2NTIgMS44OTg1MSAxLjI4NjQyWiIgZmlsbD0id2hpdGUiIHN0eWxlPSJmaWxsOndoaXRlO2ZpbGwtb3BhY2l0eToxOyIvPgo8L3N2Zz4K",
};
const {title,text,type,delay,node} = defineProps({
  title: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "success",
  },
  delay: {
    type: Number,
    default: 3000,
  },
  node: {
    type: Object,
    default: null,
  },
});
const t = ref();
t.value = setTimeout(() => {
  node?.remove();
}, delay);
function hide() {
  if (t.value) {
    clearTimeout(t.value);
  }
  node?.remove();
}
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $mainColor;
  font-family: "Roboto";
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  .web {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    .title {
      color: #fff;
      display: flex;

      font-family: "Roboto-Bold";
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      align-items: center;
      img {
        margin-right: 12px;
      }
    }
    .text {
      color: #fff;

      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      max-width: 750px;
      padding: 0 50px;
      line-height: 16px;
    }
    .close {
      padding: 10px;
      cursor: pointer;
      width: 16px;
      box-sizing: content-box;
    }
  }
}
.m-dialog {
  // display: none;
  position: fixed;
  top: 20px;
  left: 16px;
  right: 16px;
  // width: 100%;
  padding: 20px 16px;
  z-index: 1000;
  background-color: $mainColor;
  border-radius: 16px;
  .title {
    color: #fff;
    display: flex;
    align-items: center;
    font-family: "Roboto-Bold";
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    img {
      margin-right: 12px;
    }
  }
  .text {
    color: #fff;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
  }
}
.error {
  background-color: $auxiliary-color;
}
// @media screen and (max-width: 767px) {
//   .dialog {
//     display: none;
//   }
//   .m-dialog {
//     display: block;
//   }
// }
</style>
