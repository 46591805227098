<template>
  <!-- 图片编辑区域 -->
  <div
    class="relative mx-auto border-gray-300 bg-white shadow-1"
    :style="containerStyle"
  >
    <div v-if="selectedImage" class="w-full h-full relative">
      <div class="w-full h-full relative overflow-hidden">
        <div
          class="absolute cursor-move select-none"
          :style="{
            transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)`,
            width: `${width}px`,
            height: `${height}px`,
          }"
        >
          <img :src="selectedImage" class="w-full h-full pointer-events-none" />
        </div>
      </div>
      <div class="absolute top-0 left-0 bg-red-500">
        <div
          class="absolute cursor-move select-none"
          :style="{
            transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)`,
            width: `${width}px`,
            height: `${height}px`,
          }"
          @mousedown="startDrag"
          @click.stop="handleImageClick"
        >
          <!-- 图片轮廓 -->
          <div
            v-if="isSelected"
            class="absolute inset-0 border-2 border-[#BFCCFF] pointer-events-none"
          ></div>
          <template v-if="isSelected">
            <!-- 控制点 -->
            <div
              class="absolute w-5 h-5 shadow-1 -top-[5px] -left-[5px] bg-white rounded-full cursor-pointer z-10"
              @mousedown.stop="startResize('top-left')"
            ></div>
            <div
              class="absolute w-5 h-5 shadow-1 -top-[5px] -right-[5px] bg-white rounded-full cursor-pointer z-10"
              @mousedown.stop="startResize('top-right')"
            ></div>
            <div
              class="absolute w-5 h-5 shadow-1 -bottom-[5px] -left-[5px] bg-white rounded-full cursor-pointer z-10"
              @mousedown.stop="startResize('bottom-left')"
            ></div>
            <div
              class="absolute w-5 h-5 shadow-1 -bottom-[5px] -right-[5px] bg-white rounded-full cursor-pointer z-10"
              @mousedown.stop="startResize('bottom-right')"
            ></div>

            <!-- 旋转手柄和角度显示 -->
            <div class="relative">
              <!-- 角度显示 -->
              <div
                v-if="rotating"
                class="absolute w-12 h-6 -top-[20px] left-1/2 -translate-x-1/2 bg-black bg-opacity-75 rounded text-white text-xs flex items-center justify-center"
                style="pointer-events: none"
              >
                {{ Math.round(rotation) }}°
              </div>
              <!-- 旋转手柄 -->
              <div
                class="shadow-1 absolute w-10 h-10 -top-[60px] left-1/2 -translate-x-1/2 bg-white rounded-full cursor-pointer z-10 flex items-center justify-center select-none"
                @mousedown.stop="startRotate"
              >
                <img
                  src="@/assets/images/enhance/旋转.svg"
                  alt="旋转"
                  width="24"
                  height="24"
                  class="select-none drag"
                  draggable="false"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// 类型定义
interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}
interface CanvasToFileOptions {
  fileName?: string;
  fileType?: string;
  quality?: number;
}
type ResizeCorner = "top-left" | "top-right" | "bottom-left" | "bottom-right";
// 状态定义
const selectedImage = ref<string | null>(null);
const isSelected = ref<boolean>(false);
const width = ref<number>(300);
const height = ref<number>(300);
const rotation = ref<number>(0);
const position = ref<Position>({ x: 0, y: 0 });
const initialSize = ref<Size>({ width: 0, height: 0 });
const resizing = ref<boolean>(false);
const rotating = ref<boolean>(false);
const dragging = ref<boolean>(false);
const startPoint = ref<Position>({ x: 0, y: 0 });
const startSize = ref<Size>({ width: 0, height: 0 });
const startPosition = ref<Position>({ x: 0, y: 0 });
const startRotation = ref<number>(0);
const isInit = ref(true);
const props = defineProps({
  url: {
    type: String,
    require: true,
    default: "",
  },
  originWidth: {
    type: Number,
    default: 944,
  },
  originHeight: {
    type: Number,
    default: 1440,
  },
  limitContainerWidth: {
    type: Number,
    default: 472,
  },
  limitContainerHeight: {
    type: Number,
    default: 720,
  },
  bgImgUrl: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["load"]);

// 添加容器尺寸的响应式变量
const containerWidth = ref<number>(0);
const containerHeight = ref<number>(0);

// 添加背景图相关的响应式变量
const bgImgWidth = ref(0);
const bgImgHeight = ref(0);
const bgImgLoaded = ref(false);

// 添加加载背景图的方法
const loadBgImg = () => {
  if (!props.bgImgUrl) {
    initImg(props.url);
    return;
  }

  const bgImg = new Image();
  bgImg.src = props.bgImgUrl;
  bgImg.onload = () => {
    bgImgWidth.value = bgImg.naturalWidth;
    bgImgHeight.value = bgImg.naturalHeight;
    bgImgLoaded.value = true;
    initImg(props.url);
  };
};

// 添加 watch
watch(
  () => [props.originWidth, props.originHeight],
  () => {
    if (props.url) {
      initImg(props.url);
    }
  }
);

onMounted(() => {
  document.addEventListener("click", (e: MouseEvent) => {
    if (!(e.target as HTMLElement).closest(".cursor-move")) {
      isSelected.value = false;
    }
  });
  if (props.url) {
    initImg(props.url);
  }
});

const initImg = (url: string) => {
  selectedImage.value = url;
  const img = new Image();
  img.src = url;
  img.onload = () => {
    // 使用背景图尺寸或原始 props 尺寸
    const effectiveWidth = props.bgImgUrl ? bgImgWidth.value : props.originWidth;
    const effectiveHeight = props.bgImgUrl ? bgImgHeight.value : props.originHeight;
    const containerRatio = effectiveWidth / effectiveHeight;
    
    // 计算容器尺寸
    if (effectiveWidth > props.limitContainerWidth || effectiveHeight > props.limitContainerHeight) {
      if (props.limitContainerWidth / props.limitContainerHeight > containerRatio) {
        containerHeight.value = props.limitContainerHeight;
        containerWidth.value = props.limitContainerHeight * containerRatio;
      } else {
        containerWidth.value = props.limitContainerWidth;
        containerHeight.value = props.limitContainerWidth / containerRatio;
      }
    } else {
      containerWidth.value = effectiveWidth;
      containerHeight.value = effectiveHeight;
    }
    if(isInit.value){
      // 计算图片尺寸（保持在容器内且保持比例）
      const imageRatio = img.width / img.height;
      if (
        img.width > containerWidth.value ||
        img.height > containerHeight.value
      ) {
        if (containerRatio > 1) {
          // 以高度为基准
          height.value = containerHeight.value * 0.8;
          width.value = height.value * imageRatio;
        } else if (containerRatio == 1) {
          if (imageRatio > 1) {
            // 以宽度为基准
            width.value = containerWidth.value * 0.8;
            height.value = width.value / imageRatio;
          } else {
            // 以高度为基准
            height.value = containerHeight.value * 0.8;
            width.value = height.value * imageRatio;
          }
        } else {
          // 以宽度为基准
          width.value = containerWidth.value * 0.8;
          height.value = width.value / imageRatio;
          // 缩放后如果还是太大，则缩小
          if(height.value > containerHeight.value){
            const tmpRotio = height.value / containerHeight.value
            height.value = containerHeight.value
            width.value = width.value / tmpRotio
          }
        }
      } else {
        width.value = img.width;
        height.value = img.height;
      }
      // 居中定位图片
      position.value = {
        x: (containerWidth.value - width.value) / 2,
        y: (containerHeight.value - height.value) / 2,
      };
      initialSize.value = {
        width: width.value,
        height: height.value,
      };
      rotation.value = 0;

    }

    isSelected.value = true;
    isInit.value = false;
    emit("load");
  };
};

// 监听 url 和 bgImgUrl 的变化
watch(
  () => [props.url, props.bgImgUrl],
  () => {
    if (props.url) {
      loadBgImg();
    }
  },
  { immediate: true }
);

const handleImageClick = (event: MouseEvent): void => {
  event.stopPropagation();
  isSelected.value = true;
};

const startDrag = (event: MouseEvent): void => {
  if (
    (event.target as HTMLElement).classList.contains("control-point") ||
    (event.target as HTMLElement).classList.contains("rotate-handle")
  ) {
    return;
  }

  dragging.value = true;
  startPoint.value = {
    x: event.clientX,
    y: event.clientY,
  };
  startPosition.value = {
    x: position.value.x,
    y: position.value.y,
  };

  const handleMouseMove = (e: MouseEvent): void => {
    if (!dragging.value) return;

    const dx = e.clientX - startPoint.value.x;
    const dy = e.clientY - startPoint.value.y;

    position.value = {
      x: startPosition.value.x + dx,
      y: startPosition.value.y + dy,
    };
  };

  const handleMouseUp = (): void => {
    dragging.value = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};

const startRotate = (event: MouseEvent): void => {
  event.stopPropagation();
  rotating.value = true;

  const element = (event.target as HTMLElement).closest(".cursor-move");
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;

  const startAngle =
    (Math.atan2(event.clientY - centerY, event.clientX - centerX) * 180) /
    Math.PI;

  startRotation.value = rotation.value;

  const handleMouseMove = (e: MouseEvent): void => {
    if (!rotating.value) return;

    const currentAngle =
      (Math.atan2(e.clientY - centerY, e.clientX - centerX) * 180) / Math.PI;

    const deltaAngle = currentAngle - startAngle;
    rotation.value = startRotation.value + deltaAngle;
  };

  const handleMouseUp = (): void => {
    rotating.value = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};

const startResize = (corner: ResizeCorner): void => {
  event?.stopPropagation();
  resizing.value = true;
  if (!event) return;

  const mouseEvent = event as MouseEvent;
  startPoint.value = {
    x: mouseEvent.clientX,
    y: mouseEvent.clientY,
  };
  startSize.value = {
    width: width.value,
    height: height.value,
  };
  startPosition.value = {
    x: position.value.x,
    y: position.value.y,
  };

  const handleMouseMove = (e: MouseEvent): void => {
    if (!resizing.value) return;

    const dx = e.clientX - startPoint.value.x;
    const dy = e.clientY - startPoint.value.y;

    const angle = (rotation.value * Math.PI) / 180;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);

    const rotatedDx = dx * cos + dy * sin;
    const ratio = startSize.value.width / startSize.value.height;

    let newWidth: number;
    let newHeight: number;
    let newX = startPosition.value.x;
    let newY = startPosition.value.y;

    switch (corner) {
      case "bottom-right":
        newWidth = Math.max(50, startSize.value.width + rotatedDx);
        newHeight = newWidth / ratio;
        break;
      case "top-left":
        newWidth = Math.max(50, startSize.value.width - rotatedDx);
        newHeight = newWidth / ratio;
        newX = startPosition.value.x + (startSize.value.width - newWidth) * cos;
        newY = startPosition.value.y + (startSize.value.width - newWidth) * sin;
        break;
      case "top-right":
        newWidth = Math.max(50, startSize.value.width + rotatedDx);
        newHeight = newWidth / ratio;
        newY =
          startPosition.value.y - (newHeight - startSize.value.height) * cos;
        break;
      case "bottom-left":
        newWidth = Math.max(50, startSize.value.width - rotatedDx);
        newHeight = newWidth / ratio;
        newX = startPosition.value.x + (startSize.value.width - newWidth) * cos;
        break;
    }

    width.value = newWidth;
    height.value = newHeight;
    position.value = { x: newX, y: newY };
  };

  const handleMouseUp = (): void => {
    resizing.value = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};
// 新增 Canvas 转 File 的方法
const canvasToFile = async (
  canvas: HTMLCanvasElement,
  options: CanvasToFileOptions = {}
): Promise<File> => {
  const {
    fileName = "image.png",
    fileType = "image/png",
    quality = 1.0,
  } = options;

  // 将 canvas 转换为 blob
  const blob = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas to Blob conversion failed"));
        }
      },
      fileType,
      quality
    );
  });

  // 创建 File 对象
  return new File([blob], fileName, {
    type: fileType,
    lastModified: Date.now(),
  });
};
const handleExport = (): Promise<File | undefined> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  return new Promise<File>((resolve, reject) => {
    if (!ctx) {
      reject(null);
      return;
    }
    const effectiveWidth = props.bgImgUrl ? bgImgWidth.value : props.originWidth;
    const effectiveHeight = props.bgImgUrl ? bgImgHeight.value : props.originHeight;
    const ratio = effectiveWidth / containerWidth.value;
    // const ratio = props.originHeight / containerHeight.value;
    // 使用实际容器尺寸
    canvas.width = effectiveWidth;
    canvas.height = effectiveHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (selectedImage.value) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = selectedImage.value;

      img.onload = async () => {
        ctx.save();

        const centerX = position.value.x * ratio + (width.value * ratio) / 2;
        const centerY = position.value.y * ratio + (height.value * ratio) / 2;

        ctx.translate(centerX, centerY);
        ctx.rotate((rotation.value * Math.PI) / 180);

        ctx.drawImage(
          img,
          -(width.value * ratio) / 2,
          -(height.value * ratio) / 2,
          width.value * ratio,
          height.value * ratio
        );

        ctx.restore();

        // const link = document.createElement('a');
        // link.download = 'edited-image.png';
        // link.href = canvas.toDataURL('image/png', 1.0);
        // link.click();
        //   emit("getCanvas", canvas);
        const file = await canvasToFile(canvas);
        resolve(file);
        // return file;
      };
    }
  });
};
defineExpose({ handleExport });

// 修改模板中容器的样式
const containerStyle = computed(() => ({
  width: `${containerWidth.value}px`,
  height: `${containerHeight.value}px`,
  ...(props.bgImgUrl 
    ? { background: `url(${props.bgImgUrl}) center/100% 100% no-repeat` }
    : { 
        backgroundImage: 'linear-gradient(45deg, #f0f0f0 25%, transparent 25%), linear-gradient(-45deg, #f0f0f0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #f0f0f0 75%), linear-gradient(-45deg, transparent 75%, #f0f0f0 75%)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
      }
  ),
}));
</script>

<style lang="scss"></style>
