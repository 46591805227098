<template>
    <div class="relative w-full h-full flex">
        <div class="h-full w-80  relative">
            <div class="h-full px-4 flex sticky flex-col items-center overflow-y-auto bg-white custom-scrollbar">
                <div class="grid grid-cols-2 gap-2">
                    <div class="mt-8 col-span-2">
                        <div
                            class="grid grid-cols-2 gap-2 border bg-mainColor-deep-10 border-mainColor-deep-8 shadow rounded-lg w-72 h-10 Roboto-Bold-16">
                            <button :class="{ 'Roboto-Bold-12': btnStyle, 'bg-bg-mainColor-deep': btnStyle }"
                                class="w-full h-10 rounded-lg Roboto-12" @click="onClick(true)">
                                {{ $t('enhance-Template') }}
                            </button>
                            <button :class="{ 'Roboto-Bold-12': !btnStyle, 'bg-bg-mainColor-deep': !btnStyle }"
                                class="w-full h-10 rounded-lg Roboto-12" @click="onClick(false)">
                                {{ $t('enhance-Custom') }}
                            </button>
                        </div>
                        <div>
                            <div class="Roboto-Bold-14 my-4"><span class=" required">*</span>
                                {{ $t('enhance-AI-logo-Description') }}
                            </div>
                            <EnhanceTextPrompt ref="textPromptRef" v-model="prompt" class="h-[170px] w-[285px] mt-2"
                                :maxLength="1000" :placeholder="$t('enhance-AI-logo-Description-ph')">
                            </EnhanceTextPrompt>
                        </div>
                        <div>
                            <div class="Roboto-Bold-12 text-text-gray my-4">
                                {{ $t('enhance-Art-Generator-No-inspiration') }}
                            </div>
                            <div class="flex items-center">
                                <img v-for="item in inspirationList" :value="item.key" :key="item.key"
                                    @click="onInspirationClick(item)"
                                    class="w-[40px] h-[40px] rounded-full mr-4 cursor-pointer hover:scale-110"
                                    :class="{ 'selected-ins': inspIndex === item.key }" :src="item.src" alt="img" />
                            </div>
                            <div :title="inspText"
                                class="mt-5 Roboto-12 w-[285px] h-[120px] border rounded-lg px-4 py-2"
                                style="font-size: 12px; font-weight: 400;line-height: 20px;background-color: white;color:#8A8EA1">
                                {{ inspText }}
                            </div>
                            <a-tooltip :contentStyle="{ 'border-radius': '15px', 'background-color': '#1F1F1FE5' }"
                                :content="t('enhance-Art-Generator-Use-Prompt')" position="top">
                                <img class="transform translate-x-[255px] -translate-y-[24px] w-4 h-4 cursor-pointer hover:scale-110"
                                    src="/assets/images/perCenter/edit.svg" alt="img" @click="onTipClick">
                            </a-tooltip>
                        </div>
                        <div v-if="!btnStyle">
                            <div class="Roboto-Bold-14 my-4"><span class="required">*</span>
                                {{ $t('enhance-Reference-Image') }}</div>
                            <a-upload image-preview ref="uploadRef" name="file" action=""
                                @before-upload="onBeforeUpload" accept=".bmp, .jpeg,.jpg,.png" :show-file-list="false"
                                class="border rounded-lg w-full h-20 bg-white hover:bg-gray-bg"
                                :class="uploadColor && !imgSrc ? 'border-red-500' : ''">
                                <template #upload-button>
                                    <div class="w-full h-full relative" v-if="imgSrc" @mouseenter="isShow = true"
                                        @mouseleave="isShow = false">
                                        <img class="w-full h-full object-contain" :src="imgSrc" alt="">
                                        <div v-show="isShow" style="background-color: #00000066;"
                                            class="absolute w-fit px-2 py-1 rounded-lg flex items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                            <img class="w-4 h-4" src="/assets/images/functype/AIGenerator/ReSelect.png"
                                                alt="">
                                            <div class="ml-1 Roboto-10 text-white">{{ $t("enhance-AI-Re-select") }}
                                            </div>
                                        </div>
                                        <div v-show="isShow" class="absolute top-0 right-0">
                                            <img @click.stop="deleteImg" class="h-6 w-6"
                                                src="/assets/images/perCenter/delete.png" alt="" />
                                        </div>
                                    </div>
                                    <div v-else class="h-full flex flex-col items-center justify-center">
                                        <icon-plus style="color: gary; width: 25px; height: 25px;" />
                                        <span class="text-text-deep Roboto-Bold-12 mt-4">
                                            {{ $t("enhance-logo-image-ph") }}
                                        </span>
                                    </div>
                                </template>
                            </a-upload>
                        </div>
                    </div>
                    <div class="h-[320px] mt-4 col-span-2" v-if="btnStyle">
                        <div class="Roboto-Bold-14">{{ $t('enhance-type') }}</div>
                        <div class="grid grid-cols-3 gap-4 flex-1 flex mt-4">
                            <div class="cursor-pointer relative inline-block hover:scale-110 h-[84px] w-[82px]"
                                v-for="(item, index) in styleList" :class="{ 'selected': selectedImageIndex === index }"
                                @click="onClickImg(item, index)" :key="index" :value="item.name">
                                <img class="rounded-lg shadow border h-[80px] w-full" :src="item.icons"
                                    alt="AI Matching Image">
                                <div style="background: linear-gradient(to bottom, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 100%)"
                                    class="text-xs rounded-lg absolute bottom-0 left-0 right-0 text-white h-[30px] w-full grid place-items-center">
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-28 col-span-2">
                        <span class="Roboto-Bold-14">
                            {{ $t('enhance-output') }}
                        </span>
                        <a-input-number v-model="picNum" class="Roboto-Bold-12"
                            :style="{ width: '90px', float: 'right', }" mode="button" size="small" :max="4" :min="1" />
                    </div>

                </div>
            </div>
            <div style="background-color: rgba(255 255 255 / 90%)"
                class="h-[88px] min-w-[295px] bottom-0 left-0 right-0 p-5 absolute">
                <EnhanceGenerate @click="onApply" :enable="true" :picNum="picNum">
                </EnhanceGenerate>
            </div>
        </div>
        <div v-if="imgGeneratorList.length > 0"
            class="relative h-3/4 mx-20 m-10 flex-1 flex items-center justify-center">
            <div class="relative h-full w-full max-w-[554px] shadow-1 rounded-xl overflow-hidden bg-white">
                <EnhanceImageView ref="imageViewRef" :minScale="minScale" :maxScale="maxScale"
                    :url="imgGeneratorList[curIndex].enhanceUrl" @scaleChange="imageViewChange"></EnhanceImageView>
                <img class="mt-4 mr-2 w-6 h-6 absolute right-0 top-0 cursor-pointer"
                    src="/assets/images/functype/AIGenerator/download.png" alt="" @click="downloadImage">
                <EnhanceGiveALike class="absolute right-0 bottom-4" v-show="isEvaluate" :id="enhanceRecordId"
                    @onEvaluate="onEvaluate">
                </EnhanceGiveALike>
            </div>
            <div v-if="imgGeneratorList[curIndex]"
                class="absolute left-1/2 -bottom-36 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max">
                <EnhanceBaseControls class="flex-1 flex justify-center mt-4" ref="baseControlsRef" :minScale="minScale"
                    :maxScale="maxScale" @change="baseCtlScaleChange" @changeMove="baseCtlChangeMove">
                </EnhanceBaseControls>
            </div>
        </div>
        <div class="relative m-10 h-3/4 flex-1 flex items-center justify-center " v-else>
            <div
                class="h-full w-full flex flex-col items-center justify-center  max-w-[554px] min-w-[200px] shadow-1 text-text-gray rounded-xl bg-white">
                <img class="w-52 h-52 mb-10" src="/assets/images/functype/AILogo/Empty.png" alt="">
                <div class="px-6 text-center">{{ $t('enhance-AI-empty-tip') }}</div>
            </div>
        </div>
        <EnhanceFold :isFold="isFold" @change="foldChange">
            <div class="w-full h-full py-8 flex flex-col overflow-hidden transition-all delay-150"
                :class="isFold ? 'pl-6 pr-2' : 'opacity-0'">
                <div class="flex items-center justify-between w-full mb-6 pr-4">
                    <span class="Roboto-Bold-20 text-black">{{ $t('enhance-All-Generate') }}</span>
                    <span class="text-gray"> {{ imgGeneratorList.length != 0 ? imgGeneratorList.length : 0 }}</span>
                </div>
                <div class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4"
                    style="max-height: calc(100vh - 196px)">
                    <EnhanceImgItem class="mb-5 last:mb-0" v-for="(item, index) in imgGeneratorList" :key="item.id"
                        :imgInfo="item" @click="selectImg(index)" @downloadImg="() => downloadImg(index)"
                        :isFinish="props.isFinish"></EnhanceImgItem>
                    <div v-if="imgGeneratorList.length == 0" class="h-full w-full flex justify-center items-center">
                        <EnhanceNoRecords></EnhanceNoRecords>
                    </div>
                </div>
            </div>
        </EnhanceFold>
    </div>
</template>
<script setup>
import { minScale, maxScale } from "@/utils/config";
import { uploadStatus } from "@/utils/type";
import { IconEdit, IconPlus } from '@arco-design/web-vue/es/icon';
import { maxImageSize, imageAccept } from "@/utils/config";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
const { $encryptor } = useNuxtApp();
const uploadStore = useUploadStore();
const { downloadUrlEvent, applyEvent, uploadLocalFilesSuccessEvent, downloadImgEvent, updateFileListEvent } = useCustomEvent();
const props = defineProps({
    isFinish: {
        type: Boolean,
        default: false,
    },
    acceptCount: {
        type: Number,
        default: 1,
    },
    fileList: {
        type: Array,
        default: [],
    },
    applyEnabled: {
        type: Boolean,
        default: true,
    },
});
const { t } = useI18n();
const picNum = ref(4)
const prompt = ref('')
const stylePath = ref('Abstract_logo')
const fileModel = ref(null)
const { isLogin, isVip, isVipPro, checkWeekCount } = useUserInfo()
const styleList = ref([
    // { id: 1, title: "Random", value: 'Random_logo', icons: "/assets/images/functype/AILogo/Random.png" },
    { id: 2, title: "Abstract", value: 'Abstract_logo', icons: "/assets/images/functype/AILogo/Abstract.png " },
    { id: 3, title: "Cartoon", value: 'Cartoon_logo', icons: "/assets/images/functype/AILogo/Cartoon.png" },
    { id: 4, title: "WordMark", value: 'Text_logo', icons: "/assets/images/functype/AILogo/WordMark.png" },
    { id: 5, title: "Combination", value: 'Combination_logo', icons: "/assets/images/functype/AILogo/Combination.png" },
    { id: 6, title: "Handwriting", value: 'Handwriting_logo', icons: "/assets/images/functype/AILogo/Hand-drawn.png" },
    { id: 7, title: "Minimalist", value: 'Minimalist_logo', icons: "/assets/images/functype/AILogo/Minimalist.png" },
    { id: 8, title: "Retro", value: 'Retro_logo', icons: "/assets/images/functype/AILogo/Vintafe.png" },
])
const inspirationList = ref([
    {
        key: 1,
        src: "/assets/images/functype/AILogo/inspiration_1.webp",
        text: "Negative art,vector art,minimalistic,Stylized snake logo for basketball team, with text /PicMa/"
    },
    {
        key: 2,
        src: "/assets/images/functype/AILogo/inspiration_2.webp",
        text: "Negative art,vector art,minimalistic,Stylized girl wears a helmet logo for moto club, with text /PicMa/"
    },
    {
        key: 3,
        src: "/assets/images/functype/AILogo/inspiration_3.webp",
        text: "Negative art,vector art,minimalistic,a bear with a work hat,Bold title word /DECORATION BEAR/,sharp and powerful,side face,Text and logo in one"
    },
])
const inspText = ref("Negative art,vector art,minimalistic,Stylized snake logo for basketball team, with text /PicMa/")
const inspIndex = ref(1)
const isFold = ref(true);
function foldChange() {
    isFold.value = !isFold.value;
}
const queryZIPTimer = ref(null);
const mainStore = useMainStore();
const isShow = ref(false);
const isEvaluate = ref(false);
const selectedImageIndex = ref(0)
const onClickImg = (res, index) => {
    selectedImageIndex.value = index;
    stylePath.value = res.value;
}
function onInspirationClick(params) {
    inspIndex.value = params.key;
    inspText.value = inspirationList.value[params.key - 1].text;
}
function onTipClick() {
    prompt.value = inspText.value
}
onMounted(() => {
    // updateECommerceCommonEvent({origin: null, enhance: '' })
})
onUnmounted(() => {
    uploadStore.setImages([]);
    updateFileListEvent([])
    clearInterval(queryZIPTimer.value);
});
watch(
    () => props.fileList, () => {
        if (props.fileList.length) {
            if (props.fileList[0].enhanceUrl) {
                if (imgGeneratorList.value.length == 0) {
                    imgGeneratorList.value = []
                    imgGeneratorList.value.push({ enhanceUrl: props.fileList[0].enhanceUrl })
                    fileModel.value = []
                    fileModel.value.push(props.fileList[0]);
                    fileModel.value[0].isEvaluate = true
                    enhanceRecordId.value = props.fileList[0].enhanceRecordId;
                    deleteImg()
                    isEvaluate.value = true
                    fullLoading.hide();
                }
            } else if (props.fileList[0].imgList && props.fileList[0].imgList.length) {
                if (counts.value == 0) {
                    if (imgGeneratorList.value.length == 0) {
                        fileModel.value = props.fileList[0].imgList;
                        fileModel.value[0].isEvaluate = true
                        imgGeneratorList.value = props.fileList[0].imgList;
                        enhanceRecordId.value = props.fileList[0].enhanceRecordId;
                        imgGeneratorList.value.map((e) => {
                            e.enhanceUrl = decryptUrl(e.enhanceUrl)
                        })
                        deleteImg()
                        isEvaluate.value = true
                        fullLoading.hide();
                    }
                    counts.value++
                }
            } else if (props.fileList[0].status == 5) {
                fullLoading.hide();
            }
        }
    },
    { deep: true }
);
const enhanceRecordId = ref('')
const compareImgRef = ref();
const tryCount = ref(0);
const counts = ref(0);
const tryFlag = ref(true);
const baseControlsRef = ref(null);
const imageViewRef = ref(null);
const imgSrc = ref(null);
function baseCtlScaleChange(scale) {
    if (imageViewRef.value) {
        imageViewRef.value.setScale(scale);
    }
}
const btnStyle = ref(true)
// const textareaRef = ref(null)
const textPromptRef = ref(null)

const onClick = (rec) => {
    btnStyle.value = rec;
}
const fullLoading = useFullLoading();

function imageViewChange(scale) {
    baseControlsRef.value.setScale(scale);
}
function showLogin() {
    mainStore.setVisibleLogin(true);
}
const uploadColor = ref(false)
async function onApply() {
    if (isLogin.value) {
        if (prompt.value == '') {
            if (textPromptRef.value.$refs['textareaRef']) {
                textPromptRef.value.$refs['textareaRef'].focus();
            }
            return
        }
        clearInterval(queryZIPTimer.value);
        if (btnStyle.value) {
            const params = {
                enhanceCfgIds: [69],
                stylePath: stylePath.value,
                prompt: prompt.value,
                picNum: picNum.value
            }
            fullLoading.show(t("enhance-upload-process"), t("enhance-process"));
            await useApi.enhanceImg(params).then((result) => {
                if (result.code == 0) {
                    getImageListResult(result.data, 0);
                }
                if (result.code == 300601) {
                    fullLoading.hide()
                }
                if (result.code == 300612) {
                    if (!isVip.value) {
                        messageBox.error(t('errCode-use-limit'), null);
                        mainStore.setVisibleVipDialog(true);
                    } else {
                        messageBox.error(t('errCode-use-limit-vip'), null);
                    }
                    fullLoading.hide()
                }
            }).catch((e) => {
                messageBox.error(t("network-err"), t("network-err-try"));
                fullLoading.hide()
            });
        } else {
            if (!imgSrc.value) {
                return uploadColor.value = true
            } else {
                uploadColor.value = false
            }
            if (checkWeekCount()) {
                fullLoading.show(t("enhance-upload-process"), t("enhance-process"));
            }
            imgGeneratorList.value = []
            counts.value = 0
            applyEvent({
                enhanceCfgIds: [69],
                prompt: prompt.value,
                stylePath: "reference_logo",
                picNum: picNum.value,
            })
        }
    } else {
        showLogin()
    }
}
function deleteImg() {
    imgSrc.value = null
    uploadLocalFilesSuccessEvent([])
}
const queryResultTimeout = ref(null);
const imgGeneratorList = ref([]);
function getImageListResult(id, count) {
    if (count > 200) {
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        return;
    }
    useApi.getResult(id).then((res) => {
        const { imgList, downloadUrl, downloadUrlT, orderStatus } = res.data;
        let isResult = true
        imgList.map((v) => {
            if (v.errorCode) {
                fullLoading.hide();
                isResult = false
                clearTimeout(queryResultTimeout.value);
                messageBox.error(t("enhance-process-fail"), t("network-err-try"));
            } else if (v.imgList) {
                fileModel.value = res.data.imgList;
                fileModel.value[0].isEvaluate = true
                imgGeneratorList.value = []
                imgGeneratorList.value = v.imgList;
                enhanceRecordId.value = v.enhanceRecordId;
                imgGeneratorList.value.map((e) => {
                    e.enhanceUrl = decryptUrl(e.enhanceUrl)
                })
                downloadUrlEvent(downloadUrl ?? "");
                fullLoading.hide();
                isResult = false
                isEvaluate.value = true
                clearTimeout(queryResultTimeout.value);
                mainStore.updateUserInfo();
                if (isLogin.value && isVip.value) {
                    useApi.getWeekCount().then((res) => {
                        if (res.code == 0) {
                            mainStore.setWeekCount(res.data ?? 0);
                        }
                    }).catch((e) => {
                        messageBox.error(t("network-err"), t("network-err-try"));
                        // resultError();
                    });
                }
            } else if ((v.enhanceUrl && v.oriHeight) || (v.enhanceUrl && v.tarHeight)) {
                imgGeneratorList.value = []
                imgGeneratorList.value.push({ enhanceUrl: v.enhanceUrl })
                fileModel.value = []
                fileModel.value.push(v);
                fileModel.value[0].isEvaluate = true
                enhanceRecordId.value = v.enhanceRecordId;
                imgGeneratorList.value.map((e) => {
                    e.enhanceUrl = decryptUrl(e.enhanceUrl)
                })
                downloadUrlEvent(imgGeneratorList.value[0].enhanceUrl ?? "");
                isResult = false
                isEvaluate.value = true
                fullLoading.hide();
                clearTimeout(queryResultTimeout.value);
                mainStore.updateUserInfo();
                if (isLogin.value && isVip.value) {
                    useApi.getWeekCount().then((res) => {
                        if (res.code == 0) {
                            mainStore.setWeekCount(res.data ?? 0);
                        }
                    });
                }
            }
        })
        if (isResult) {
            clearTimeout(queryResultTimeout.value);
            queryResultTimeout.value = setTimeout(() => {
                clearTimeout(queryResultTimeout.value);
                getImageListResult(id, count + 1);
            }, 3000);
        } else {
            if (
                !downloadUrl &&
                !downloadUrlT &&
                orderStatus == 0 &&
                (imgGeneratorList.value && imgGeneratorList.value.length > 1)
            ) {
                console.log('检查压缩包');
                queryZIP(id);
            }
        }
    }).catch((e) => {
        fullLoading.hide();
        messageBox.error(t("network-err"), t("network-err-try"));
        // resultError();
        console.error(e)
    })
}
function baseCtlChangeMove(isMove) {
    smearRef.value.setMove(scale);
}
function enhanceError(error) {
    // console.log("加载错误", error);
    if (tryCount.value < 3) {
        tryCount.value = tryCount.value + 1;
        console.log("加载失败，重试", tryCount.value);
        tryFlag.value = false;
        setTimeout(() => {
            tryFlag.value = true;
        }, 1000);
    }
}
function downloadImg(index) {
    downloadImgEvent({ url: props.fileList[index].enhanceUrl });
}

const curIndex = ref(0)
function selectImg(index) {
    curIndex.value = index
}

function onEvaluate() {
    // debugger
    // window.dispatchEvent(
    //     new CustomEvent("updateFileListEvent", {
    //         detail: fileModel.value,
    //     })
    // );
    updateFileListEvent(fileModel.value)
    isEvaluate.value = false
}
const formatFailFiles = ref([]);
const sizeFailFiles = ref([]);
const fileList = ref([]);
const timeout = ref();
function debounce(fun, time = 500) {
    if (timeout.value) {
        clearTimeout(timeout.value); // 规定时间内若定时器存在则清除
    }
    timeout.value = setTimeout(() => {
        fun(); // 调用接口
        clearTimeout(timeout.value);
    }, time);
}
function onBeforeUpload(file) {
    const suffix = getFileSuffix(file.name).toLowerCase();
    fileList.value = []
    if (imageAccept.includes(suffix)) {
        if (file.size < maxImageSize) {
            fileList.value.push(file);
        } else {
            sizeFailFiles.value.push(file);
        }
    } else {
        formatFailFiles.value.push(file);
    }
    debounce(() => {
        if (fileList.value.length > props.acceptCount) {
            if (isVipPro.value) {
                messageBox.error(t("index-upload"), t("index-upload-limit"));
            } else {
                messageBox.error(
                    t("index-upload-batch"),
                    t("index-upload-batch-limit")
                );
            }
            return;
        }
        imageHandle();
    });
    return false;
}
function imageHandle() {
    if (formatFailFiles.value.length > 0) {
        messageBox.error(
            t("index-upload"),
            t("index-upload-limit-format", { format: imageAccept.join("、") })
        );
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    } else if (sizeFailFiles.value.length > 0) {
        messageBox.error(t("index-upload"), t("index-upload-limit-size"));
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    } else if (fileList.value.length > props.acceptCount) {
        messageBox.error(t("index-upload"), t("index-upload-limit"));
        formatFailFiles.value = [];
        fileList.value = [];
        sizeFailFiles.value = [];
        return;
    }

    let count = 0;
    let hasSizeFail = false;
    fileList.value.map((v) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            let image = new Image();
            image.onload = function () {
                count++;
                if (
                    image.width < minWidth ||
                    image.height > maxWidth ||
                    image.width > maxWidth ||
                    image.height < minWidth
                ) {
                    hasSizeFail = true;
                }
                if (count == fileList.value.length) {

                    if (hasSizeFail) {
                        messageBox.error(t("index-upload"), t("index-upload-limit-width"));
                        imgSrc.value = null
                        return;
                    }
                    onSuccess(fileList.value);
                }
            };
            image.onerror = function () {

                count++;
                if (count == fileList.value.length) {
                    if (hasSizeFail) {
                        messageBox.error(t("index-upload"), t("index-upload-limit-width"));
                        return;
                    }
                    onSuccess(fileList.value);
                }
            };
            image.src = e.target.result;
            imgSrc.value = e.target.result;
        };
        reader.readAsDataURL(v);
    });
}
function decryptUrl(url) {
    let enhanceUrl = "";
    if (url) {
        $encryptor?.setPrivateKey(privateKey);
        let part1 = url.substring(0, url.length / 2);
        let part2 = url.substring(url.length / 2);
        const dec1 = $encryptor?.decrypt(part1);
        const dec2 = $encryptor?.decrypt(part2);
        console.log("decryptedMessage", dec1 + dec2);
        enhanceUrl = dec1 + dec2;
    }
    return enhanceUrl;
}
function downloadImage() {
    downloadImgEvent({ url: imgGeneratorList.value[curIndex.value].enhanceUrl });
}
function onSuccess(fileList) {
    uploadLocalFilesSuccessEvent(fileList)
}
function queryZIP(id) {
    queryZIPTimer.value = setInterval(() => {
        useApi.getResult(id).then((res) => {
            if (res.code == 0) {
                const { downloadUrl, downloadUrlT } = res.data;
                if (downloadUrl || downloadUrlT) {
                    downloadUrlEvent(downloadUrl || downloadUrlT)
                    clearInterval(queryZIPTimer.value);
                }
            }
        });
    }, 2000);
}
</script>

<style scoped>
.required {
    color: red;
    margin-right: 5px;
}

input,
textarea {
    width: 100%;
    /* 16px */
    border: 2px solid #F7F7F8;
    background-color: #F7F7F8;
    /* 默认边框颜色 */
    border-radius: 0.5rem;
    text-align: left;
    line-height: normal;
    outline: none;
    /* 去掉默认的黑色外边框 */
}


input:focus {
    border: 1px solid #C02CFF;
}

textarea:focus {
    border: 1px solid #C02CFF;
}

.arco-icon {
    color: gary !important
}

.selected {
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px #5F45FF;
    border-radius: 10px;
}

.selected-ins {
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px #5F45FF;
    border-radius: 25px
}
</style>