<template>
  <div class="relative w-full h-full flex">
    <div class="h-full w-80 relative">
      <div class="h-full flex flex-col items-center bg-white overflow-y-auto custom-scrollbar">
        <div
          class="grid grid-cols-2 gap-2 border bg-mainColor-deep-10 border-mainColor-deep-8 shadow rounded-lg w-[280px] h-10 mt-8 Roboto-Bold-16">
          <button :class="{
            'Roboto-Bold-12': !isCustom,
            'bg-bg-mainColor-deep': !isCustom,
          }" class="w-full h-10 rounded-lg Roboto-12" @click="onTabChange(false)">
            {{ $t("enhance-Template") }}
          </button>
          <button :class="{
            'Roboto-Bold-12': isCustom,
            'bg-bg-mainColor-deep': isCustom,
          }" class="w-full h-10 rounded-lg Roboto-12" @click="onTabChange(true)">
            {{ $t("enhance-Custom") }}
          </button>

          <div class="relative mt-4 col-span-2" v-if="isCustom">
            <div class="Roboto-Bold-14"><span class="required">*</span>{{ $t("enhance-Description") }}</div>
            <div class="relative">
              <EnhanceTextPrompt v-model="prompt" class="h-[170px] mt-2" enhance
                :placeholder="$t('enhance-Description-hp')"></EnhanceTextPrompt>
            </div>
            <div>
              <div class="Roboto-Bold-12 text-text-gray my-4">
                {{ $t('enhance-Art-Generator-No-inspiration') }}
              </div>
              <div class="flex items-center">
                <img v-for="item in inspirationList" :value="item.key" :key="item.key" @click="onInspirationClick(item)"
                  class="w-[40px] h-[60px] rounded-lg mr-4 cursor-pointer hover:scale-110"
                  :class="{ 'selected-ins': inspIndex === item.key }" :src="item.src" alt="img" />
              </div>
              <div :title="inspText" class="mt-5 Roboto-12 w-full h-[153px] border rounded-lg limited-lines px-4 pt-2"
                style="font-size: 12px; font-weight: 400;line-height: 20px;background-color: white;color:#8A8EA1">
                {{ inspText }}
              </div>
              <div class="w-[270px] h-5 transform -translate-y-[22px] translate-x-[4px]"
                style="background-color: white;">
                <a-tooltip :contentStyle="{ 'border-radius': '15px', 'background-color': '#1F1F1FE5' }"
                  :content="t('enhance-Art-Generator-Use-Prompt')" position="top">
                  <img class="float-right w-4 h-4 cursor-pointer hover:scale-110"
                    src="/assets/images/perCenter/edit.svg" alt="img" @click="onTipClick">
                </a-tooltip>
              </div>
            </div>
            <div class="my-5 col-span-2">
              <span class="Roboto-Bold-14">
                {{ $t("enhance-output") }}
              </span>
              <a-input-number v-model="picNum" class="Roboto-Bold-12" :style="{ width: '90px', float: 'right' }"
                mode="button" size="small" :max="4" :min="1" />
            </div>
          </div>
          <div class="col-span-2 mt-2 px-2" v-if="!isCustom">
            <div class="Roboto-Bold-14 my-2"><span class="required">*</span>{{ $t("enhance-style") }}</div>
            <div class="grid grid-cols-3 gap-4 flex-1 mt-4 mb-28">
              <div class="relative inline-block hover:scale-110" v-for="(item, index) in styleList" :key="index">
                <img class="cursor-pointer rounded-lg shadow border h-[82px] w-[82px] object-cover"
                  :class="{ selected: selectedImageIndex.id === item.id }" :src="item.demo"
                  @click="onClickImg(item, index)" alt="AI Matching Image" />
                <div style="background: linear-gradient(to bottom, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 100%)"
                  class="text-xs rounded-lg absolute bottom-0 left-0 right-0 text-white text-center w-[82px]">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div @click="onDownload">导出</div> -->
        </div>
      </div>
      <div style="background-color: rgba(255 255 255 / 94%)" class="h-[88px] bottom-0 absolute min-w-[300px] p-5">
        <EnhanceGenerate @click="onApply" :enable="enable" :picNum="picNum"> </EnhanceGenerate>
      </div>
    </div>
    <div class="relative h-full flex-1 flex items-center justify-center">
      <div class="relative h-2/3 max-w-[624px] flex-1 flex items-center justify-center">
        <template v-if="step === StepStatus.none">
          <EnhancePreLoad v-if="
            step1Data &&
            (step1Data.status == uploadStatus.handle ||
              step1Data.status == uploadStatus.lodingImg)
          " :fileModel="step1Data" @load="onLoad"></EnhancePreLoad>
          <FuncTypeUpload v-else class="h-[240px] mx-auto z-[2] hidden sm:block lg:h-[280px]">
          </FuncTypeUpload>
        </template>
        <template v-else-if="step === StepStatus.edit">
          <!-- <EnhanceImageBgEdit ref="imageBgEditRef" v-if="isCustom && step1Data && step1Data.enhanceUrl" :url="step1Data.enhanceUrl"
            :bgImgUrl="selectedImageIndex.demo" hiddenBg></EnhanceImageBgEdit> -->
          <EnhanceImageBgEdit ref="imageBgEditRef" v-if="isCustom && step1Data.enhanceUrl"
            :url="isCustom && step1Data.enhanceUrl" @load="onBgLoad"></EnhanceImageBgEdit>
          <EnhanceImageBgEdit ref="imageBgEditRef" v-else-if="!isCustom && step1Data.enhanceUrl"
            :url="step1Data.enhanceUrl" :bgImgUrl="selectedImageIndex.demo" @load="onBgLoad"></EnhanceImageBgEdit>
          <div v-if="fileModel && fileModel.status == uploadStatus.handle"
            class="w-[472px] h-[720px] absolute flex items-center justify-center flex-col bg-[#00000088] ">
            <Loading />
            <p class="mt-10 text-white max-w-[50%] text-center text-xs" v-html="$t('enhance-process')"></p>
          </div>
        </template>
        <template v-else-if="step === StepStatus.success">
          <EnhancePreLoad v-if="fileModel.status == uploadStatus.handle" :fileModel="fileModel"></EnhancePreLoad>
          <EnhancePreLoad v-else-if="
            imgGeneratorList[indexImages].status == uploadStatus.lodingImg
          " :fileModel="imgGeneratorList[indexImages]"></EnhancePreLoad>
          <div v-else-if="
            imgGeneratorList[indexImages].status == uploadStatus.success
          " class="w-[95%] max-w-[600px] h-full shadow-1 rounded-xl -translate-y-20">
            <EnhanceImageView ref="imageViewRef" :minScale="minScale" :maxScale="maxScale"
              :url="imgGeneratorList[indexImages].enhanceUrl" @scaleChange="imageViewChange"></EnhanceImageView>
            <EnhanceGiveALike class="absolute right-4 bottom-4" v-if="isLogin" v-show="imgGeneratorList[indexImages].enhanceUrl &&
              !fileModel.isEvaluate
              " :id="fileModel.enhanceRecordId" @onEvaluate="onEvaluate">
            </EnhanceGiveALike>
          </div>
        </template>
      </div>
      <div v-if="fileModel && step === StepStatus.success"
        class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max ">
        <EnhanceBaseControls class="flex-1 flex justify-center mt-4" ref="baseControlsRef" :minScale="minScale"
          :maxScale="maxScale" @change="baseCtlScaleChange">
        </EnhanceBaseControls>
      </div>
    </div>

    <EnhanceFold :isFold="isFold" @change="foldChange">
      <div class="w-full h-full pt-8 flex flex-col overflow-hidden transition-all delay-150"
        :class="isFold ? 'pl-6 pr-2' : 'opacity-0'">
        <div class="flex items-center justify-between w-full mb-6 pr-4">
          <span class="Roboto-Bold-20 text-black">{{
            $t("enhance-All-Generate")
          }}</span>
          <span class="text-gray">{{ imgGeneratorList.length }}</span>
        </div>
        <div class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4" style="max-height: calc(100vh - 196px)">
          <EnhanceImgItem class="mb-5 last:mb-0" v-for="(item, index) in imgGeneratorList" :key="item" :imgInfo="item"
            :isSelect="indexImages === index" @click="selectImg(index)" @delImg="() => delImg(index)"
            @downloadImg="() => downloadImg(index)" @load="() => enhanceLoad(index)" :isFinish="false">
          </EnhanceImgItem>
          <div v-if="imgGeneratorList.length == 0" class="h-full w-full flex justify-center items-center">
            <EnhanceNoRecords></EnhanceNoRecords>
          </div>
        </div>
      </div>
    </EnhanceFold>
  </div>
</template>
<script setup>
import { uploadStatus } from "@/utils/type";
import {
  minScale,
  maxScale,
  imageAccept,
  maxUploadCount,
} from "@/utils/config";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
import messageBox from "@/composables/messageBox";

const StepStatus = {
  none: 0,
  edit: 1,
  success: 2,
};
const {
  customEventNames,
  cleanMenuDownload,
  applyEvent,
  updateFileListEvent,
  downloadImgEvent,
  updateECommerceCommonEvent,
  uploadLocalFilesSuccessEvent
} = useCustomEvent();
const { $encryptor } = useNuxtApp();
const { isLogin, isVip, isVipPro } = useUserInfo();
const { locale, t } = useI18n();
const uploadStore = useUploadStore();
const mainStore = useMainStore();
const step = ref(StepStatus.none);
const step1Data = ref();
const imageBgEditRef = ref();
// const isApply = ref(true);
const loading = ref(false);
const isFold = ref(true);
const isCustom = ref(false);
const fileModel = ref(null);
const imgGeneratorList = ref([]);
const imageViewRef = ref(null);
// const textareaRef = ref(null);

const prompt = ref(""); //文字描述
const picNum = ref(1); //图片数量1~4
const indexImages = ref(0);
const count = ref(0)
// const counts = ref(0);
const props = defineProps({
  fileList: {
    type: Array,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
  funcIndex: {
    type: String,
    default: '',
  },
  // commonData: {
  //   type: Object,
  //   default: { origin: null, enhance: null },
  // },
});

const styleList = ref([
  {
    id: 0,
    thumb: "/assets/images/functype/AIProductImage/室内01.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内1.jpg",
  },
  {
    id: 1,
    thumb: "/assets/images/functype/AIProductImage/室内02.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内2.jpg",
  },
  {
    id: 2,
    thumb: "/assets/images/functype/AIProductImage/室内03.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内3.jpg",
  },
  {
    id: 3,
    thumb: "/assets/images/functype/AIProductImage/室内04.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内4.jpg",
  },
  {
    id: 4,
    thumb: "/assets/images/functype/AIProductImage/室内05.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内5.jpg",
  },
  {
    id: 5,
    thumb: "/assets/images/functype/AIProductImage/室内06.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内6.jpg",
  },
  {
    id: 6,
    thumb: "/assets/images/functype/AIProductImage/室内07.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内7.jpg",
  },
  {
    id: 7,
    thumb: "/assets/images/functype/AIProductImage/室内08.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内8.jpg",
  },
  {
    id: 8,
    thumb: "/assets/images/functype/AIProductImage/室内09.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内9.jpg",
  },
  {
    id: 9,
    thumb: "/assets/images/functype/AIProductImage/室内10.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内10.jpg",
  },
  {
    id: 10,
    thumb: "/assets/images/functype/AIProductImage/室内11.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内11.jpg",
  },
  {
    id: 11,
    thumb: "/assets/images/functype/AIProductImage/室内12.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内12.jpg",
  },
  {
    id: 12,
    thumb: "/assets/images/functype/AIProductImage/室内13.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内13.jpg",


  },
  {
    id: 13,
    thumb: "/assets/images/functype/AIProductImage/室内14.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内14.jpg",


  },
  {
    id: 14,
    thumb: "/assets/images/functype/AIProductImage/室内15.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内15.jpg",


  },
  {
    id: 15,
    thumb: "/assets/images/functype/AIProductImage/室内16.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内16.jpg",


  },
  {
    id: 16,
    thumb: "/assets/images/functype/AIProductImage/室内17.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内17.jpg",


  },
  {
    id: 17,
    thumb: "/assets/images/functype/AIProductImage/室内18.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内18.jpg",


  },
  {
    id: 18,
    thumb: "/assets/images/functype/AIProductImage/室内19.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内19.jpg",


  },
  {
    id: 19,
    thumb: "/assets/images/functype/AIProductImage/室内20.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内20.jpg",


  },
  {
    id: 20,
    thumb: "/assets/images/functype/AIProductImage/室内21.png",
    demo: "/assets/images/functype/AIProductImage/origin/室内21.jpg",


  },
  {
    id: 21,
    thumb: "/assets/images/functype/AIProductImage/室外01.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外1.jpg",


  },
  {
    id: 22,
    thumb: "/assets/images/functype/AIProductImage/室外02.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外2.jpg",


  },
  {
    id: 23,
    thumb: "/assets/images/functype/AIProductImage/室外03.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外3.jpg",


  },
  {
    id: 24,
    thumb: "/assets/images/functype/AIProductImage/室外04.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外4.jpg",


  },
  {
    id: 25,
    thumb: "/assets/images/functype/AIProductImage/室外05.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外5.jpg",


  },
  {
    id: 26,
    thumb: "/assets/images/functype/AIProductImage/室外06.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外6.jpg",


  },
  {
    id: 27,
    thumb: "/assets/images/functype/AIProductImage/室外07.png",
    demo: "/assets/images/functype/AIProductImage/origin/室外7.jpg",


  },
]);
const inspirationList = ref([
  {
    key: 1,
    src: "/assets/images/functype/AIProductImage/inspiration_1.webp",
    text: "placed on a cylindrical stand in the center of the composition. The stand has a gradient color transitioning from yellow to green. Surrounding the tube and stand are stylized floral elements in various shades of pink, purple, blue, and yellow, set against a pastel teal background. There's also a golden hoop that frames the ensemble, adding a touch of elegance and highlighting the product as the focal point. The overall aesthetic suggests a fresh, clean, and luxurious feel, typical for high-end skincare or makeup products."
  },
  {
    key: 2,
    src: "/assets/images/functype/AIProductImage/inspiration_2.webp",
    text: "Arrange the product on a rustic wooden table adorned with a delicate lace cloth. Capture it under a canopy of vibrant cherry blossoms, with soft pink petals drifting in the air. The sunlight should filter through the branches, creating a warm glow that highlights the product features"
  },
  {
    key: 3,
    src: "/assets/images/functype/AIProductImage/inspiration_3.webp",
    text: "placed on a rock in the middle of a body of water. In the background, there is a picturesque sunset with vibrant colors ranging from orange to purple in the sky, reflecting onto the water's surface. Silhouettes of trees and a lighthouse can be seen against the horizon, contributing to a serene and tranquil ambiance. The foreground shows ripples around the rock, suggesting movement in the water. A palm leaf is also visible at the top right corn"
  },
])
const inspText = ref("placed on a cylindrical stand in the center of the composition. The stand has a gradient color transitioning from yellow to green. Surrounding the tube and stand are stylized floral elements in various shades of pink, purple, blue, and yellow, set against a pastel teal background. There's also a golden hoop that frames the ensemble, adding a touch of elegance and highlighting the product as the focal point. The overall aesthetic suggests a fresh, clean, and luxurious feel, typical for high-end skincare or makeup products.")
const inspIndex = ref(1)
function onTipClick() {
  prompt.value = inspText.value
}
function onInspirationClick(params) {
  inspIndex.value = params.key;
  inspText.value = inspirationList.value[params.key - 1].text;
}
const selectedImageIndex = ref(styleList.value[0]);

const enable = computed(() => {
  if (step.value == StepStatus.edit && props.applyEnabled) {
    if (!isCustom.value) {
      return true;
    } else if (prompt.value) {
      return true;
    }
  }
  return false;
});
onMounted(() => {
  // const images = [...uploadStore.images];
  // console.log(images);

  // if (images.length) {
  //   initData();
  // }
  // 生命周期原因，加nextTick
  nextTick(() => {
    // 其他功能处理好的图
    if (props.fileList.length && props.fileList[0].enhanceUrl) {
      updateFileListEvent([
        {
          status: uploadStatus.uploadSuccess,
          originUrl: props.fileList[0].enhanceUrl,
          preImage: props.fileList[0].enhanceUrl,
          enhanceUrl: props.fileList[0].enhanceUrl,
          isEvaluate: true,
        },
      ]);
      initData()
    } else if (props.fileList.length && props.fileList[0].file) {
      updateFileListEvent([
        {
          ...props.fileList[0],
          status: uploadStatus.none,
          isEvaluate: true,
        },
      ]);
      initData()
    }
    // if (props.commonData.enhance) {
    //   updateFileListEvent([
    //     {
    //       status: uploadStatus.uploadSuccess,
    //       originUrl: props.commonData.enhance,
    //       preImage: props.commonData.enhance,
    //     },
    //   ]);
    //   initData()
    // } else if (props.commonData.origin) {
    //   uploadLocalFilesSuccessEvent([props.commonData.origin]);
    //   initData()
    // }

  })
  window.addEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
  window.addEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
});
onUnmounted(() => {
  const route = useRoute();
  window.removeEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
  window.removeEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
  uploadStore.setImages([]);
  if (['ai_poster', 'ai_logo'].includes(route.query.id)) {
    return
  }
  if (step.value === StepStatus.edit) {
    console.log('onUnmounted edit');
    updateFileListEvent([
      {
        status: uploadStatus.uploadSuccess,
        originUrl: step1Data.value.enhanceUrl,
        preImage: step1Data.value.enhanceUrl,
        enhanceUrl: step1Data.value.enhanceUrl,
        isEvaluate: true,
      },
    ]);
    // updateECommerceCommonEvent({enhance: step1Data.value.enhanceUrl})
  }
  if (step.value === StepStatus.success) {
    console.log('onUnmounted finish');
    // updateECommerceCommonEvent({enhance: imgGeneratorList.value[indexImages.value].enhanceUrl})
    updateFileListEvent([
      {
        status: uploadStatus.uploadSuccess,
        originUrl: imgGeneratorList.value[indexImages.value].enhanceUrl,
        preImage: imgGeneratorList.value[indexImages.value].enhanceUrl,
        enhanceUrl: imgGeneratorList.value[indexImages.value].enhanceUrl,
        isEvaluate: true,
      },
    ]);
  }
});
watch(
  () => props.fileList,
  () => {
    const fileList = props.fileList;
    if (fileList.length) {
      if (step.value === StepStatus.none) {
        if (fileList[0].status === uploadStatus.uploadFail) {
          messageBox.error(t("enhance-upload-fail"), null);
          cleanFile();
        } else if (fileList[0].status === uploadStatus.fail) {
          messageBox.error(t("enhance-catch"), null);
          cleanFile();
        } else if (fileList[0].status === uploadStatus.lodingImg) {
          // 清除下载
          cleanMenuDownload();
          step1Data.value = fileList[0];
        } else if (fileList[0].status === uploadStatus.handle) {
          step1Data.value = fileList[0];
        } else if (fileList[0].status === uploadStatus.success) {
          step1Data.value = fileList[0];
          step.value = StepStatus.edit;

        }
      } else if (step.value === StepStatus.edit) {
        fileModel.value = fileList[0];
        if (fileList[0].imgList?.length) {
          // fileModel.value = fileList[0];
          imgGeneratorList.value = fileList[0]?.imgList.map((v) => {
            return {
              status: uploadStatus.lodingImg,
              enhanceUrl: decryptUrl(v.enhanceUrl),
              preImage: fileList[0].preImage,
            };
          });
          step.value = StepStatus.success;
        } else if (fileList[0].status === uploadStatus.lodingImg && fileList[0].enhanceUrl) {
          // fileModel.value = fileList[0];
          imgGeneratorList.value = [
            {
              status: uploadStatus.lodingImg,
              enhanceUrl: fileList[0].enhanceUrl,
              preImage: fileList[0].preImage,
            },
          ];
          step.value = StepStatus.success;
        }
      }
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);

// watch(
//   () => props.fileList,
//   () => {
//     if (props.fileList.length) {
//       fileModel.value = props.fileList[0];
//     } else {
//       fileModel.value = null;
//     }
//   },
//   { deep: true }
// );
function uploadLocalFilesSuccess(params) {
  initData();
}
const onTabChange = (val) => {
  isCustom.value = val;
  if (!isCustom.value) {
    picNum.value = 1
  } else {
    picNum.value = 4
  }
  prompt.value = "";
};
function initData() {
  cleanMenuDownload();
  imgGeneratorList.value = [];
  step.value = StepStatus.none;
  indexImages.value = 0;
  step1Data.value = null;
  step1();
}
function taskFailRetryEvent(e) {
  // 如果任务失败，重置数据
  setTimeout(() => {
    updateFileListEvent([{ ...e.detail, status: uploadStatus.uploadSuccess }]);
  }, 100);
}
function foldChange() {
  isFold.value = !isFold.value;
}
const baseControlsRef = ref(null);
function imageViewChange(scale) {
  baseControlsRef.value.setScale(scale);
}

const onClickImg = (item, index) => {
  selectedImageIndex.value = item;
  console.log(item.width, item.height);
};

function baseCtlScaleChange(scale) {
  if (imageViewRef.value) {
    imageViewRef.value.setScale(scale);
  }
}

function cleanFile() {
  updateFileListEvent([]);
  step.value = StepStatus.none;
  step1Data.value = null;
}

function onLoad() {
  console.log("抠图加载成功");
  updateFileListEvent([
    {
      ...props.fileList[0],
      status: uploadStatus.success,
      // originUrl: step1Data.value.enhanceUrl,
      // enhanceUrl: step1Data.value.enhanceUrl,
    },
  ]);
}
function onBgLoad() {
  console.log("bg加载成功");
  updateFileListEvent([
    {
      status: uploadStatus.success,
      originUrl: step1Data.enhanceUrl,
    },
  ]);
}
function decryptUrl(url) {
  let enhanceUrl = "";
  if (url) {
    $encryptor?.setPrivateKey(privateKey);
    let part1 = url.substring(0, url.length / 2);
    let part2 = url.substring(url.length / 2);
    // console.log("part1", part1);
    // console.log("part2", part2);
    // 解密
    const dec1 = $encryptor?.decrypt(part1);
    const dec2 = $encryptor?.decrypt(part2);
    // console.log("dec1", dec1);
    // console.log("dec2", dec2);
    // 打印解密后的明文
    enhanceUrl = dec1 + dec2;
  }
  return enhanceUrl;
}
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList);
}
function selectImg(index) {
  indexImages.value = index;
}
function delImg(index) {
  imgGeneratorList.value.splice(index, 1);
  if (imgGeneratorList.value.length === 0) {
    cleanFile();
  }
  indexImages.value = 0;
}
function downloadImg(index) {
  downloadImgEvent({ url: imgGeneratorList.value[index].enhanceUrl });
}
function enhanceLoad(index) {
  imgGeneratorList.value[index].status = uploadStatus.success;
}


function step1() {
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    cleanFile();
    return;
  }
  const timer = setInterval(() => {
    // 加载成功后进行处理,抠图
    if (
      props.fileList.length &&
      (props.fileList[0].status === uploadStatus.none || props.fileList[0].status === uploadStatus.uploadSuccess)
    ) {
      clearInterval(timer);
      applyEvent({
        enhanceCfgIds: [74],
        // enhanceCfgIds: [6],
      });
    } else if (
      props.fileList.length &&
      props.fileList[0].status === uploadStatus.imageLoadFail
    ) {
      clearInterval(timer);
      cleanFile();
      messageBox.error("load failed!", null);
    }
  }, 200);
}
async function onApply() {
  // if (textareaRef.value) {
  //   textareaRef.value.focus();
  // }
  console.warn(enable.value, count.value, imgGeneratorList.value)
  if (!enable.value) {
    return;
  }
  if (count.value !== 0) {
    return
  }
  if (isLogin.value) {
    count.value++
    if (imageBgEditRef.value) {
      const file = await imageBgEditRef.value.handleExport();
      if (!file) {
        count.value = 0;
        return;
      }
      updateFileListEvent([
        {
          ...step1Data.value,
          status: uploadStatus.none,
          originUrl: "",
          enhanceUrl: '',
          imgList: [],
          file,
        },
      ]);
    }

    setTimeout(() => {
      if (imgGeneratorList.value.length == 0) {
        //   counts.value = 0;
        imgGeneratorList.value = [];
        let aiType = [70];
        console.warn('star')
        if (isCustom.value) {
          applyEvent({
            enhanceCfgIds: [65],
            picNum: picNum.value,
            prompt: prompt.value,
            // stylePath: selectedImageIndex.value.id,
          });
        } else {
          applyEvent({
            enhanceCfgIds: aiType,
            stylePath: selectedImageIndex.value.id,
          });
        }
      }
      count.value = 0;
    }, 10);

    // isApply.value = false;
  } else {
    showLogin();
  }
}

function showLogin() {
  mainStore.setVisibleLogin(true);
}
const onDownload = async () => {
  const file = await imageBgEditRef.value.handleExport();
};
</script>
<style scoped>
.h-screen-minus-80 {
  height: calc(100vh - 80px);
}

.arco-textarea {
  background: #f1f1f1;
  border: 1px solid transparent;
  border-image: linear-gradient(45deg, #4a2dff, #c02cff) 1;
}

.selected {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px #5f45ff;
  border-radius: 8px;
}

textarea:focus {
  border: 1px solid #c02cff;
}

textarea {
  width: 100%;
  border: 2px solid #f1f1f1;
  border-radius: 0.5rem;
  text-align: left;
  line-height: normal;
  outline: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 3s linear infinite;
}

.selected-ins {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px #5F45FF;
  border-radius: 5px
}

.limited-lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  width: auto;
  word-break: break-all;
}

.required {
  color: red;
  margin-right: 5px;
}

.backgroundBtn {
  background-color: #E3E3EC;
}
</style>
