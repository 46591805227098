<template>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-[20px] min-h-[160px] items-stretch lg:gap-[30px] lg:min-h-[190px]" :class="[list.length===4?'lg:grid-cols-4':'lg:grid-cols-5']">
    <div
      class="group flex-1  flex  flex-col gap-y-3 rounded-[20px] p-[10px] shadow-[0_6px_12px_0px_rgba(94,94,108,0.1)] bg-white cursor-pointer hover:bg-text-deep hover:shadow-none lg:p-[30px]"
      v-for="item in list"
      :key="item.key"
      @click="toTag(item.key)"
    >
      <p>
        <img
          width="50"
          height="50"
          :src="item.icon"
          :alt="item.key"
          class="group-hover:hidden w-[30px] h-[30px] lg:w-[50px] lg:h-[50px]"
        />
        <img
          width="50"
          height="50"
          :src="item.selectIcon"
          :alt="item.key"
          class="hidden group-hover:inline-block h-[30px] lg:w-[50px] lg:h-[50px]"
        />
      </p>
      <p class="hyphens-auto font-Roboto-Bold font-bold  text-text-deep text-base leading-[20px] group-hover:text-white lg:text-[20px] lg:leading-[24px]">{{ item.name }}</p>
      <p  class="text-text-gray text-[12px] leading-[14px] line-clamp-3 lg:text-[14px] lg:leading-[16px]">{{ item.desc }}</p>
    </div>
  </div>
</template>

<script setup>
import {getTags} from '@/utils/support';
const { t } = useI18n();
const classification = getTags(t)
const router = useRouter();
const guide_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANXSURBVHgB7Zo/UhsxFMY/O9Q4dKnw5gRxunRZThA4AeQEkBMQVyljqqQLOUHgBCwnCD4Bhguw7lJBvhdkRrNIWv1bnGH8m9nZf1pJn96TniQbWLFihYseOqQg+v2MoCOyCWGdX/K0d3d3957nkTyyJL3gMev1eqc8V7nEJQuhgJKVP+RliTgqijqinhMkEC0kg4AmYqWtWAu9QCDiQuvr6194+R1294lBXPNgMBhgPp+fI5Agi0jnpRXEBd6gW4Kt4y1EiThDXiu4CBLjJWQJIhZ4i+m3JZA+sSQR/4pn2b98ErYKub29lZGpwPIYbW5ufm1L5HQtWmPEFvmNQOgOFU9TqOCH+4aQ1t1FZKMoF6us710fD4fDy8CCxac/ugpk4+yp+FMgjOrq6mrL9rLXUuAP+OPdMWMHD5dVXELOWFgJ/0Je6yKksuxfB/1+f8DznOdJ432pxIRgtYpRiGqxS3giE0DWcbvxfbPFxWJvma5ePKDr3uA+onvDPDb0PBbYRq0SYVT6DS3wGY/dRu739AcUGzwVaeaxwCiEFdlGGA8tJHGHrbaL7jBOj4xCWJGguRRbdl9bRO1bks14nKSUo8oqTc/XLOkLhCHx5pI+X/Ns8nnpHzuNzi6jYoFwCtPDRxZpLk8DsYmQYfNCL0PFkihMdex7ViaWR7GlqwmoSUiNTDyVCGENHUERxwYRMm/LYXGvOJLLIqf6jbJEFrf1Cogq0Qzp6LGlRD53ujA9NMYRtt4UiTColtrtCJmgyxqFGPsIJ3gVxXxAWoGHXBANeTlX65BcGKc1tkmjLG9v8B/SnGUvMLqW9BO1ykst9IjHJ1j8OiK/U9t6xzX8jpGwiygCWOZE3U5C1zcWJrYX1s0HWYmlWEUTseAn0qhcS+i2XZQxIlG78zpJMUT2AlzvnXu/dV3PNggv3yEQutErfnrOPP6oqP4NkWL47ZhLXOdufetOo7ZBFxMLanUUiGdKEa1lt27QqRFsB3HRXixQIB6ZPXutVp/NJnarRR5yJZIx8szD2ph29rOCDo0zkXU6OoD5Hl1fXx8gkJSf3rK6mopZY1escH6PRKhHOuN+bNROFfCQDzKhNgRKHjJrFmvZhsyZtlt/bFokxZBNiImn/MPAihUr3PwFRdWuwV5ExtsAAAAASUVORK5CYII=";
const guide_select_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ3SURBVHgB7ZqLUdwwEIb/y6QAdxCnglwqiFNB6IBLB0kFRyqAVAAl5CqwU8GZCuQODipYVmMBRiedvXrcAeNvZscPNLJ+Sbsr6QBmZmYOsUBGiKh88bHFokMmkgnhRhd8WbF9Y1uylZ6iLVvHtmFrcooTwQIqtprCqdnOcCoSCLBRZE3F3AIKtkvKxxq50T3G1lJ+FOUaHSNC0fFQycWcQIRYzGj4pT6sbuEPp7lpOUR/HSv0AeNo5ytxOpbcmZdjhQ6OCFegE9sWchq2Wzwnv9LYOcI75TuPTIMQSO4Xiq0aqXNFYf5WIwTzQamIcmLdocGjghSSZ+3S0dgrtmtztf9ekRzZqJhGSPiH8R7Xz4VVbkdyClebfVGrgozGer7AvlPr55X17j/krFwvfUKkq9G7xxvTY+fIx5fJJUnuiFsyPsDXtaeMon0/USRHTdUBCsc35xX1OWn4DWlUfGKqCKmjj6HIHdEUhVPa7Xb5SIF0dOgzcjcUwRcdRkskxCXkDuk4igjNR+TjxiFCr9tSjPheZ+cckY31rEciybTlDhoXYgp1iGeYWyqkm06t66UvId4inmpwv0Q6nEJ8PtKw/UAcax6JT3y9R9pM71zWODdW1C8zdnidfHadTjqnlvGTBvH8ZfsNz3QIYCM+YqWw/cKQX1Z9NcVTIYSYjzvqCl5bGQ5uqsZOUf4gENrfAMXmkJ+IgfptagjXj2IofpF4gVioP7TeUhg7ij+hTBUo3saR6WsWo5KLOIGYNpsIS1BoAJjCFY4JpR+dmkITXiJBZxT/Y2iFSFL+PF2iX7rrVbO+9y3dOzyf1t+4NkkhvJt/GJiZmTnMAzfIvpsj8K2DAAAAAElFTkSuQmCC";
const payments_icon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIxSURBVHgB7ZmNbcIwEIWPqAMwAaQTNGwAE3QEYIKWCYAJSico3YANyAZlhGSCZoP0HXElFBHbic8QJH+SZSu5/Lzc2efYRIFAIBC4AwOTQQzKsvxCM0EZ0m0p8OxDFEXbDOgMtUKUiB+6vYA6xWAwmOjERKSHPXFvEcxQRUUjWiG4eEr9IdGdNHmkT2gj45GEaAlCbMFoc0KVkWe8CoGIA0bMSZ7nz2in5BHfHlldtLfkEW9C4IH9ZQJDM2UPkSd8euSaB1bkiSeSI1MdO0c5XZtO8DHMepZUJbcXqjJ2QgJo51rj8bgkPfzySw4bcgDiEgg6kiHpYdBofF/X0CpcRTC4x0l5szOuQpLRaLQmR+CRN9d5nXNnx5fcuIiBiDVE7MgRkVGrqxhc8wERGxJAbPhlMfi677b2bItrrO1NSOeRrIVtQYLcU0hKgogK4WG0hW1Ggl6RzOxp/QD6wRSd+TwIoD9sr+ScjAy/sLZIeiTBi58zMwvArOCosvWUC7f5GJ9TNmwrtrDhOkWpk1EVLqavzCEYU0shuimKZGgxsaWdSDhdEv7Z+0YQ0jeCEBt4CQhlpkpKHpEefs/wCgqq71omT3mbAvUGyXFOwpgS4i/ZJy3ew/hEvTdtynQVpEuIpo2eo8Uv6L+AHd6/1SRQCVooQbHBPIWQWdNJkxDd6kZnAQ3PWqgJZtzwLO2OldUeIlVh8Ip6qDotx/+ePMCCUM1VJBRqdWVpCtdAIBB4LP4AL0zjUs8QktMAAAAASUVORK5CYII=";

const payments_select_icon =
"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHUSURBVHgB7ZmNbYMwEIUfVQdIN0gmaLpBukk7QbNBu0FHICNkA9ig2QA6QdjgeiecJkJg/AtE8iedjIjt85Nt7mIDiUQikVgiRLRmK9jOND3iM5cxjI0zGxPBxQ/bCvPSsL1kWVYPVXiAnhzzi4AaQ66rMDYjhOXQ8Iw8Df14T0LAQgbHO7a07oYkxIITW43IxBZy5GUtn80NP5eISOzNvrl8+7mrHRcFPJhrsx9uAxg/l1wcEYmYM7LpRmKVKVRwRDcjjwhHjXZj/0rZl07IOxbzzo9btme0EXuL2BgkdZVa+75+tmSQlOr68N0jjVr7XnAfMpMnxILM+IQn3MeHiSO4QuY4i5G2pk7gCtlhLYbbfNs4gCtkz96i7z1ZousvdECsLeo2CMicQkoEJGhk10Xegf7PsPgrPVWuVXZf8EB31J7AiO162tSYArJDIvOqI6DLvyCpS21mEGSzh04aa7SbeCx/kii+huUJjW5ppcOHpZGELI0kZGnEFlKyvSorMRfkTk49kZzau5YDOQJXyO5yR+p+kcGljKsguEL9acaQAOt7FCVI2lYGftwP90h/uuEsYMDXm0aQ+FrDB7oug4ugQpwiEkpQcSOg8BaRSCQSi+MPzUbX1hOgH6sAAAAASUVORK5CYII=";
const tech_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALzSURBVHgB7VmLUeMwEF1nroBUkJgKLldBTAXkKjio4HIVcB0cVHCmg9CB6cBUgJMKQgMJbx2JEUJO9NkwgfGb0UiW7Mw+62n15BD16NGjx2dCRkfCaDT6l2XZJZpDa6hC/1UDkCAGdATkeT5HsHN6T4JRbLfb/ySMoxBBoL8P3FKAbEGC+EbC4ABBJNfXmJk/qNbcVgQn3N5sNgWqioQgPiMI8FK3QeIeS+EGpcTlAkQqY+wXCUJ8sY/H4ydUubpsEPAdCExpNxNv1gzGzkGyIgGISsuWFXfh+rrrfkl5iUrLlJUPJOUlSgSBTQMfyaWylxgRFVBOgVDySoYYkQBZcQKo9YWUvMSI7JHVGmML3k9QzpbL5Rky1Q9OzWpcRF4iWcuxCVaoHlCqPen1BuWCGxLZS4QIywrBt23UJYK/8niM5cU7/lDJ6y8lQERalqxufZ4B2bWxVpLllUzEylbszmvf5yDHib5OzV7J0jJlhcDuu+7jV45qpuwKk7DtSpK8kolYslrrBuLmQAt+07jnwrIuLrTyivVeSUQc2eoapvE7mkP0F6rP9+eSslfSGunYBGcoBUUgZXNMIhLhrQ4hOntFE4n1VocQm72iiYRadgfYc92i/OSvKrozVl7RJ0TrJOiD1nOhfkRZ2J+DMMMlEkRLIubkGJW1HCdBF/TOzXtL5bFRligtkZjsFUXE3ARNqMDZLPKbr9mGkD+SvFeUtExZKad7Rzu5hAT+DinyCp4RS1Ys9XOSQ6MbofIKzlpmttrnrUKQ7zDXs8EIzV7BM2JugoPBoKQIePqwIO8VRMQhKy/Lrp9F4FMEXvj6sBB5BRHxtewMBM5nDQ6aj7MTtu0hBpIRkr2CiHRZdoaSCxvGKYKe2eeNSHjLKzvwK6//cegDkTmOvhLVM+ucjuC7FBqefazHWn0Md6KTiPrHaU4nBH5xq9XK+WGjkwg2vS2dIPBdzBnzvn3EOyN9IJqugU4iylo3dDqo2fJTjx49enwJvACZgWtr71VeFgAAAABJRU5ErkJggg==";
const tech_select_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIwSURBVHgB7ZiNbdswEIWfig7gETxCNgg7QZMJmkzQdoJqg6YTxBs4nUDZoNnA2sDNBC/HmEZoWj8kRdlMwA84WJBJ4kg9Hp8EFAqFQkEg+Vtiy2MaiSXeA5LoDw7T4D0giW44jkJCPiMxJsGldeunxH9z/V3iwlzrdo/IFZnIylr1B+v+wuybPRvkjCMrfV2bDb6dW17J0IkxjBo54sjKhzzlRb9q5aKQgE9IBI+rlS8KOREgq43Ev2zlxX5Z6Wq15u60X1rtH1LLazI8rlYNd2VXefapkQOOrO49+yz4drbkIS9HVhcB/Zps5OVIxHtlTb9tNvJyZHU30G5pNvya3XZlkrxSuN9L63rvcl/3AHZnhI6vGD9j9ERVVVWPODXs9lZrR/sh1DgHDPdWY0TLa6pFuURaXuWFCKInwnhvNYZCBFOeyA2m0Ur8kbiWuLXuf8MpYbhl1yX3no7nssaz95vCKaDfm6BOvDGJj572PIf3Yn+10vb8ziS1CBzz9N6Lh7JqJG5CE+8ZN1pewSc7D6tVKyfxF6Sjta4V5vzuRU9vFTjm3ofZT3peeTHSsjtj6P1wZfbThv0ozAEjLbvV9xfDfFiNOQiRlX5aRi4Nu227D96LFbrZOy27SVxXrSvTRv9OrmIIsPbV0J96Ra2EdILKabKSeIbf+0YsrcRfiSeZ0Aqh8PDLeS70ftioBiZCZIg8lc6ch9zvE/Kj7ftjaCK3Qx3PgF7YaxQKhcKH4AWAOdJONQtppAAAAABJRU5ErkJggg==";
const api_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJASURBVHgB7ZjvcYIwFMAftAPoBLJB3aB0gpYN6gZ+7afaCXp+6zfdwDqBOEHtBuAEmUD6Xo94gA9IyFFiL787zkD+mB+E5AUAh8PhcAyAV5cRBME0y7J3TE7xGMHwxJ7nzRKEy2RFUCJAiS+wQ6CIQJkHdDlUM/yaCiuwT4IY5aPkAlYEC4dgL1Puog/XBztSrlGExYnYxl+JJLQG4PEJPWEq8o2di9o6mC9kazwiPBXFPJwhl9QGJg9ggIkISYTYOZKYNZQTWCYunJdmnePxOKc2aKEDA5muIlJC3t2wriCW28s0BgwX5eQ1astEpotISSKPyVYN5c/D7nQ6PVczse6G2qC0iYyuCCexg+ZwJpYJrHvP5FPYsTOV0RHpJCGj1byjQU05YxlVEdFBgp7AtnAaQjN1MgIU6HsdiQvpR9BHOQJXFZF367dh2g8o3K1E7huonkJEXdpr5HVoolCS0XkiUx0ZzIsLp0/QDCdB9e9AEd2hpSNzfj9w2g0b2jSWILq8I5xMxJSLZaJm2pV5kakE0fVlL8kgSSVfFGa4EOqnXcJYgmA/PkwmkwzUoKexxA7QjFR6D/Da2vf9Pf6+QrMIxVlbLDcHRYk0TS/6bSoyCJyI21jZxv8WqSxmtsEGknVP5A0shWZJ7voNd1EIkYzH4xTs+YBNUATwguvTB5fptdXmtqcDILgP1w6baR1aVfLd4Qb6Q+TfwbSG0i1ogiH5Av8ogH5ZQPsepoT2goiBYAz9QlHzGhwOh8NxDfwAwKc48b7AjAoAAAAASUVORK5CYII=";
const api_select_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHLSURBVHgB7ZiBdYIwEIYPJ6AbOIIj4AR1BN3AFTpB2wl0A91AO4F0AroBbHC9a/G9KyQhsYHGvvveu9dK/oR8hgcRAEVRFCUlEHFBdaKqMQ1OVHPbfDOLBHe4UOWQFg3VMsuystsws3TYQXoSDM/p2dRgWxGEdGloRR66B+9RBEikN+8Z/BNUJDWmEvmg2lAdYUoCHlIl1YrqMJArxNjdB+xLO8YFPYktwhJ5m88dudo1tjie+8qY5nzrpfVOVdBdsGk/F47sm5hsL3c91o61pCohFui5Em2W92Su/dhaZPeGdu67EJnBlQFfMJ4EMxf5ypIJkgFfMJ7EqZN34S0DvlhOEirBbEWfrUfeJNM7j2nOYz9HzuL/Rwjndztwy7d1CVyVSmRdt2fXapSmIPjiOFmIzE7k1hhJIpZIiMxKZPYYSSKmiEmmMGRke+UYqwiRiC3yQ4b+zjtt9YCkJA+RGEPkKsPXv2nTuGvbKvcQX33XvhKMac76Uzc1VCQ1bCJnSBfj7xWbyBOky2tQ2vPWOSW8A9ja5pt5CBXw9zSmF9dKygxeWl3we6d6gPHgtymb0S8lEjni+AS/kbzlgXiGceEV2YOiKIpyD3wCVpQYn/T355EAAAAASUVORK5CYII=";
const privacy_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ2SURBVHgB7ZkxUttAFIZ/OzkA7tJZ3MC5gdKli1OmItwgOUFMmSrOCeKcgNClQyUlJR2yK+hEB43N/+DBqLC12tU+sGf2m9FISCuzn55292kXSCQSiV2iBwOyLNvj7utqtfrEfc6t5HHR7/f/lmVZwIBoIlr5MSt8gMfKb8JEqpOIVn7E7YCVG3O/5/cL8aSCRCiQL5fLca/Xk6fvW/lNiNQJpWaUOocnrUWMKr8JbymnCAUy/ugfNL/3lhR8eIcUKpsK9eGAUZjg9SSEXB9kI04RfZVem5GrgFNkS3C2yV0RcZJEto23MIQdhYwBD+MAe56cuwxGmIlQ4ju7/mn9HIekbxT6BQNMXq11EoKck2swwEKkWidRYyZlEBkLkcbciJIiUSIyFiLWCeVaLERGkmhuuqjXnCmHLyaNvaln2qlei4yHw+EpH/7zk5fvGTkn12CA5YAo6fcx9/vyB49PYUjKtbaNNiLRBy8L2nwh/kY4UcYU1mHmLOMqIHNXbKiXCK+UjPQS1RyBUGTfNfnwBg6qqrodDAZ3PPyIMN6hQ/rOh3g0n8//ucr5zGsd62zii8FIFAzEhzZlfXqtQxgkew2U+j9b4TVlKiO1DmzmiSGj8d5n6tTZRuqwvVyxvVzDKM14QmcW//vc4yUiUOacMjcIb/yNaOOe+t7nLSJQ5owy8lrmiIhILBaLCQIIEhEoU8SMjLxOIZF4vh8d0Q5AstwMYZSU+ByyJlInOCJPaAdwwsoM4PnlJ+OESlygI1EXQxkdWQD9AXd0KgocOWZbvOgckTraozVFRwR+cvsSe3XXZHla0EmGia7yVppFT3U6KJFIvDD3NFkId5gaookAAAAASUVORK5CYII=";
const privacy_select_icon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGXSURBVHgB7dntbYMwEAbgo+oAdAOyQUZgFEZgBDZoO0GzQUfwCtnAbFA2eHuW+YGq8nX4MCR+JCs/El3uDWCDQ5QkSfL0AOQ8ah4GnuXxxaOko+ubrwbNjzleqL75sm/sB+vZqKH65j+EzU+FcjWvpEmp+f1CcbEC8+e9JuN6oK24yA3xmbk+swVBQPF1WZa9TX3gLEGIg0z2+kIPIgU5mlfSde+HU/IoKBbI1f/UqiFEW0GmnqgnCjPXp8b0Oznnc7mcXyyPnFaIMf3ep97kfjp+aSkwjSCrfulQtFb2C//y7Ui9gvyptUqslf1d+J4eyBkMnifgn2cMhOb61L5pbPmMuASok24aT2dJkI5OYEmQT5ILtabc5j6w5GIX3VIMuJXeHdWS5EbXpVWw4a41gIZC4oLf2J+h0OC3Ry32YxFiP2skzBX77DQ66lunFfRVtAfoXvwN7cl9IcJrKAaEPTIVxQQ/AVjIWWhf2EvB//Ug2bU30Jpit4Cf0eyCAG4Kr+nIZo6OC+AmiSgbFCJ/Ap0vQJI8mF/PFDS8a/c23wAAAABJRU5ErkJggg==";
const props = defineProps({
  tags: {
    type: Array,
    default: ["guide", "payments", "tech", "api", "privacy"],
  },
});
const dataList = {
  guide: {
    name: classification["guide"].name,
    desc: classification["guide"].desc,
    icon: guide_icon,
    selectIcon: guide_select_icon,
    key: classification["guide"].key,
  },
  payments: {
    name: classification["payments"].name,
    desc: classification["payments"].desc,
    icon: payments_icon,
    selectIcon: payments_select_icon,
    key: classification["payments"].key,
  },
  tech: {
    name: classification["tech"].name,
    desc: classification["tech"].desc,
    icon: tech_icon,
    selectIcon: tech_select_icon,
    key: classification["tech"].key,
  },

  api: {
    name: classification["api"].name,
    desc: classification["api"].desc,
    icon: api_icon,
    selectIcon: api_select_icon,
    key: classification["api"].key,
  },
  privacy: {
    name: classification["privacy"].name,
    desc: classification["privacy"].desc,
    icon: privacy_icon,
    selectIcon: privacy_select_icon,
    key: classification["privacy"].key,
  },
};
const list = ref(props.tags.map((v) => dataList[v]));
function toTag(path) {
    const localePath = useLocalePath();
    router.push(
      localePath({
        name: "support-tag",
        params: {tag: path}
      })
    );
  
}
</script>
