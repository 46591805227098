<template>
  <div
    class=" w-full h-full  m-auto relative select-none"
    ref="canvasRef"
    @mouseleave="mouseleave"
    @mouseenter="isEnter = true"
    @wheel="handleMouseWheel"
    @touchmove.prevent
    @wheel.prevent
  >
    <div class=" overflow-hidden flex items-center justify-center relative h-full" @mousedown="onmouseDown2">
      <div
        ref="img-contain"
        class="w-full h-full relative  flex justify-center items-center scale-[1] "
      >
        <div
        class="w-full h-full bg-no-repeat bg-contain bg-center"
          :style="`background-image: url(${origin});transform: scale(${scaleZoom/100}) translate(${translate.x}px,${translate.y}px)`"
        ></div>
      </div>
      <div
        class="w-full h-full  absolute left-0 top-0 right-0 bottom-0"
        :style="`clip: rect(0px,10000px,9000px,${imgLeft}px);transform: scale(${1});`"
      >
        <div
        class="w-full h-full bg-no-repeat bg-contain bg-center"
          :style="`background-image: url(${enhance});transform: scale(${scaleZoom/100}) translate(${translate.x}px,${translate.y}px)`"
        ></div>
      </div>
    </div>
    <div
      v-show="isDown || isImgDown"
      class=" absolute w-full h-full top-0 left-0 z-[5] cursor-grab"
      @mousemove="mousemove"
      @mouseup="onmouseup"
    />

    <div
      ref="move"
      class=" absolute top-0 bottom-0 z-[4] cursor-grab select-none w-[40px] flex flex-col items-center"
      :style="{ left: pointer + 'px' }"
      @mousedown="onmouseDownMove"
    >
      <div class=" relative top-0 left-0 h-full w-[2px] bg-[#F7F7F8]">
        <!-- <div 
        class=" absolute bottom-[5%] flex flex-col items-center ml-px z-[5] -translate-x-1/2 -translate-y-[38px] w-max"
        v-if="showMoveTip"
        >
          <div class=" text-text-deep text-sm bg-white rounded-[10px]  py-3 px-4 ">{{ $t("try-move") }}</div>
          <img src="@/assets/images/enhance/sanjiao.png" alt="icon" width="30px" />
        </div> -->
        <!-- <svg class=" absolute bottom-[50%] -translate-x-1/2 -translate-y-1/2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="white"/>
          <path d="M20 12L24 16L20 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 12L8 16L12 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> -->
          <svg class=" absolute bottom-[50%] -translate-x-1/2 -translate-y-1/2" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#C4C4C4" fill-opacity="0.6" style="fill:#C4C4C4;fill:color(display-p3 0.7699 0.7699 0.7699);fill-opacity:0.6;"/>
<rect x="0.294118" y="0.294118" width="39.4118" height="39.4118" rx="19.7059" stroke="white" stroke-opacity="0.4" style="stroke:white;stroke-opacity:0.4;" stroke-width="0.588235"/>
<path d="M24.7059 14.361C24.7059 13.837 25.3395 13.5745 25.7101 13.9451L31.3488 19.5838C31.5785 19.8135 31.5785 20.186 31.3488 20.4157L25.7101 26.0544C25.3395 26.425 24.7059 26.1625 24.7059 25.6384V14.361Z" fill="white" style="fill:white;fill-opacity:1;"/>
<path d="M15.2941 14.361C15.2941 13.837 14.6605 13.5745 14.2899 13.9451L8.65123 19.5838C8.42151 19.8135 8.42151 20.186 8.65123 20.4157L14.2899 26.0544C14.6605 26.425 15.2941 26.1625 15.2941 25.6384V14.361Z" fill="white" style="fill:white;fill-opacity:1;"/>
</svg>


        <div class=" absolute top-5 bg-[#1f1f1f98] rounded-lg h-8 leading-8 px-3 text-white text-base w-max right-4">{{ $t("before") }}</div>
        <div class="absolute top-5 bg-[#1f1f1f98] rounded-lg h-8 leading-8 px-3 text-white text-base w-max left-4">{{ $t("after") }}</div>
      </div>
    </div>
    <!-- <div 
    v-if="showMoveTip" 
    class=" absolute bottom-[5%] flex flex-col items-center ml-px z-[5] -translate-x-1/2 -translate-y-[38px]"
     :style="{ left: pointer + 2 + 'px' }"
     >
      <div class=" text-text-deep text-sm bg-white rounded-[10px]  py-3 px-4 ">{{ $t("try-move") }}</div>
      <img src="@/assets/images/enhance/sanjiao.png" alt="icon" width="30px" />
    </div> -->
  </div>
  <!-- <div v-else class="canvas" ref="canvasRef">
    <div class="compare-img">
      <div
        ref="img-contain"
        class="img-contain img-contain-origin"
        :style="`transform: scale(${1});`"
      >
        <div
          :style="`background-image: url(${origin});transform: scale(${scaleZoom/100}) translate(${translate.x}px,${translate.y}px)`"
        ></div>
      </div>
      <div
        class="img-contain img-contain-enhance"
        :style="`clip: rect(0px,10000px,9000px,${imgLeft}px);transform: scale(${1});`"
      >
        <div
          :style="`background-image: url(${enhance});transform: scale(${scaleZoom/100}) translate(${translate.x}px,${translate.y}px)`"
        ></div>
      </div>
    </div>
    <div
      class="mask m-mask"
      @touchmove="touchmove"
      @touchstart="masktouchstart"
    >
      <div
        ref="move"
        class="handle m-handle"
        :style="{
          left: pointer + 'px',
        }"
        @touchstart="touchstart"
        @touchend="touchend"
      >
        <div class="line">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="white"/>
          <path d="M20 12L24 16L20 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 12L8 16L12 20" stroke="#1F1F1F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="before">{{ $t("before") }}</div>
          <div class="after">{{ $t("after") }}</div>
        </div>
      </div>
      <div
        class="try-move"
        v-if="showMoveTip"
        :style="{ left: pointer + 'px' }"
      >
        <div class=" font-Roboto-Bold font-bold  text-xs text-text-deep bg-white rounded-xl">{{ $t("try-move") }}</div>
        <img
          src="@/assets/images/enhance/sanjiao.png"
          alt="icon"
          width="30px"
        />
      </div>
    </div>
  </div> -->
</template>
<script setup>

const device = useDevice();
const props = defineProps({
  origin: {
    type: String,
    default: "",
  },
  enhance: {
    type: String,
    default: "",
  },
  minScale: {
    type: Number,
    default: 50,
  },
  maxScale: {
    type: Number,
    default: 250,
  },
  step: {
    type: Number,
    default: 10,
  },
});
const pointer = ref(0);
const imgLeft = ref(0);
const isDown = ref(false);
const showMoveTip = ref(false);
const isMobile = ref(false);
const moveHalfWidth = ref(0);
const clientWidth = ref(0);
const translate = ref({ x: 0, y: 0 });
const scaleZoom = ref(100);
const moveX = ref(0);
const moveY = ref(0);
const isImgDown = ref(false);
const lastTouchEnd = ref(0);
const isEnter = ref(false);
const canvasRef = ref(null);
const emit = defineEmits(["scaleChange"]);
defineExpose({setScale})
onMounted(async () => {
  init();
  showMoveTip.value = !(localStorage.getItem("showMoveTip") === "1");
  await nextTick()
  // 禁止右键，防止直接复制图片地址
  const canvas = canvasRef.value
  if (!device.isMobile) {
    if(canvas){
      // console.log('canvas',canvas);
      canvasRef.value.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }
    
  }
});
const offset = computed(() => {
  const offsetWidth = canvasRef.value.offsetWidth;
  return (clientWidth.value - offsetWidth) / 2;
});
function init() {
  clientWidth.value = document.documentElement.clientWidth;
  moveHalfWidth.value = 16;
  imgLeft.value = canvasRef.value.offsetWidth / 2;
  // posX.value = imgLeft.value;
  // canvas.value = canvas.value.offsetWidth / 2 - moveHalfWidth.value;
  pointer.value = canvasRef.value.offsetWidth / 2 - moveHalfWidth.value;;
}
function mousemove(e) {
  if (isDown.value) {
    if (
      e.offsetX < moveHalfWidth.value ||
      e.offsetX + moveHalfWidth.value > canvasRef.value.offsetWidth
    ) {
      console.log(e.offsetX, moveHalfWidth.value);
      isDown.value = false;

      return;
    }
    // canvas.value = e.offsetX - moveHalfWidth.value;
    pointer.value = e.offsetX - moveHalfWidth.value;
    imgLeft.value = e.offsetX;

    if (showMoveTip.value) {
      showMoveTip.value = false;
      localStorage.setItem("showMoveTip", "1");
    }
  } else if (isImgDown.value) {
    const posX = e.offsetX - moveX.value;
    const posY = e.offsetY - moveY.value;
    translate.value = {
      x: posX,
      y: posY,
    };
  }
}

function touchmove(e) {
  // const clientWidth = canvas.value.offsetWidth
  const offsetX = e.touches[0].clientX - offset.value;
  const offsetY = e.touches[0].clientY;
  // console.log(123, isDown.value, isImgDown.value);
  if (isDown.value) {
    if (
      offsetX < moveHalfWidth.value ||
      offsetX + moveHalfWidth.value > canvasRef.value.offsetWidth
    ) {
      isDown.value = false;
      return;
    }
    pointer.value = offsetX;
    imgLeft.value = offsetX;

    if (showMoveTip.value) {
      showMoveTip.value = false;
      localStorage.setItem("showMoveTip", "1");
    }
  } else if (isImgDown.value) {
    const posX = offsetX - moveX.value;
    const posY = offsetY - moveY.value;
    translate.value = {
      x: posX,
      y: posY,
    };
  }
}
function onmouseDownMove(e) {
  e.preventDefault();
  isDown.value = true;
}
function onmouseDown2(e) {
  e.preventDefault();
  isImgDown.value = true;

  moveX.value = e.offsetX;
  moveY.value = e.offsetY;
}
function onmouseup(e) {
  e.preventDefault();
  isDown.value = false;
  isImgDown.value = false;
}
function touchstart(e) {
  e.preventDefault();
  isDown.value = true;
}
function touchend(e) {
  e.preventDefault();
  isDown.value = false;
  isImgDown.value = false;
}
function masktouchstart(e) {
  e.preventDefault();
  const now = Date.now();
  if (now - lastTouchEnd.value <= 300) {
    // 计算双击事件的逻辑
    let scale = scaleZoom.value;
    scale += props.step;
    if (scale > props.maxScale) {
      scale = props.step;
    }
    checkScale(scale);
  } else {
    isImgDown.value = true;
    lastTouchEnd.value = now;
    moveX.value = e.touches[0].clientX;
    moveY.value = e.touches[0].clientY;
  }
}

function dblclick(e) {
  let scale = scaleZoom.value;
  scale += 1;
  if (scale > 4) {
    scale = 1;
  }
  checkScale(scale);
}

function setScale(scale) {
  scaleZoom.value = scale;
  if (scale == 100) {
    translate.value = {
      x: 0,
      y: 0,
    };
    init();
  }
}
function mouseleave() {
  console.log('mouseleave');

  isDown.value = false;
  isImgDown.value = false;
  isEnter.value = false;
}
function handleMouseWheel(e) {
  if (!window.scrollY) {
    if (!isEnter.value) return;
    // 禁止页面滚动
    // e.preventDefault()
    let scale = scaleZoom.value;
    console.log(e.wheelDelta);

    if (e.wheelDelta) {
      // 判断浏览器IE，谷歌滑轮事件
      if (e.wheelDelta > 0) {
        scale += props.step;
        checkScale(scale);
      }
      if (e.wheelDelta < 0) {
        // 当滑轮向下滚动时
        scale -= props.step;
        checkScale(scale);
      }
    } else if (e.detail) {
      // Firefox滑轮事件
      if (e.detail > 0) {
        // 当滑轮向下滚动时
        scale -= props.step;
        checkScale(scale);
      }
      if (e.detail < 0) {
        // 当滑轮向上滚动时
        scale += props.step;
        checkScale(scale);
      }
    }
  }
}
function checkScale(scale) {
  console.log('scale',scale);
  if (scale >= props.minScale && scale <= props.maxScale) {
    scaleZoom.value = scale;
    emit("scaleChange", scaleZoom.value);
  }
}
</script>

<style scoped lang="scss">
// @media screen and (min-width: 767px) {
//   .canvas {
//     width: 100%;
//     height: 100%;
//     margin: auto auto;
//     background: #fff;

//     position: relative;
//     transform: translate3d(0, 0, 0);
//     -webkit-user-select: none; /* Chrome, Opera, Safari */
//     -moz-user-select: none; /* Firefox */
//     -ms-user-select: none; /* Internet Explorer */
//     user-select: none;
//     // .m-mask,
//     // .m-handle {
//     //   display: none;
//     // }

//     .web-mask,
//     .web-handle {
//       display: block;
//     }

//     .mask {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       z-index: 5;
//       cursor: grab;
//     }

//     .compare-img {
//       overflow: hidden;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       position: relative;
//       height: 100%;

//       .img-contain {
//         width: 100%;
//         height: 100%;
//         position: relative;
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: contain;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         transform: scale(1);

//         .original {
//           position: absolute;
//           top: 20px;
//           left: 25px;
//           width: 56px;
//           height: 29px;
//           background: #eeeeee88;
//           border-radius: 2px;
//           display: inline-block;
//           text-align: center;
//           line-height: 29px;
//           color: #000;
//           padding: 0 7px;
//         }

//         .enhance {
//           position: absolute;
//           top: 20px;
//           right: 25px;
//           width: 56px;
//           height: 29px;
//           background: #eeeeee88;
//           border-radius: 2px;
//           display: inline-block;
//           text-align: center;
//           line-height: 29px;
//           color: #000;
//           padding: 0 7px;
//         }

//         img {
//           object-fit: contain;
//           vertical-align: middle;
//         }
//       }

//       .img-contain-origin {
//         // position: absolute;
//         // left: 0;
//         // right: 0;
//         // top: 0;
//         // bottom: 0;
//         div {
//           width: 100%;
//           height: 100%;
//           background-position: center;
//           background-repeat: no-repeat;
//           background-size: contain;
//         }
//       }

//       .img-contain-enhance {
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;

//         div {
//           width: 100%;
//           height: 100%;
//           background-position: center;
//           background-repeat: no-repeat;
//           background-size: contain;
//         }
//       }
//     }

//     .handle {
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       // width: 4px;
//       // background: #ffffff88;

//       z-index: 4;
//       // background-color: #FFFFFF;
//       cursor: grab;
//       // padding: 20px;

//       -webkit-touch-callout: none;
//       /* iOS Safari */
//       -webkit-user-select: none;
//       /* Chrome/Safari/Opera */
//       -khtml-user-select: none;
//       /* Konqueror */
//       -moz-user-select: none;
//       /* Firefox */
//       -ms-user-select: none;
//       /* Internet Explorer/Edge */
//       user-select: none;
//       /* Non-prefixed version, currently not supported by any browser */

//       .line {
//         position: relative;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 4px;
//         background: #f7f7f8;
//         // box-shadow: 0px 0.04rem 0.1rem 0px rgba(109, 109, 109, 0.54);
//         img {
//           position: absolute;
//           bottom: 20%;
//           left: -18px;
//         }

//         .before,
//         .after {
//           position: absolute;
//           top: 20px;
//           background: #ffffffb3;
//           border-radius: 20px;
//           height: 30px;
//           line-height: 30px;
//           padding: 0 20px;
//           color: $text-deep;
//           font-size: 12px;
//           width: max-content;
//         }

//         .before {
//           right: 20px;
//         }

//         .after {
//           left: 20px;
//         }
//       }
//     }

//     .try-move {
//       position: absolute;
//       bottom: 16%;

//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       transform: translate(-50%, -68px);
//       margin-left: 1px;
//       z-index: 5;
//       & > div {
//         color: $text-deep;
//         font-size: 14px;
//         background-color: #fff;
//         border-radius: 10px;
//         padding: 11px 15px;
//       }
//     }
//   }
// }
// @media screen and (max-width: 767px) {
//   .canvas {
//     width: 100%;
//     height: 100%;
//     margin: auto auto;
//     background: #fff;

//     position: relative;

//     .m-mask,
//     .m-handle {
//       display: block;
//     }

//     // .web-mask,
//     // .web-handle {
//     //   display: block;
//     // }

//     .mask {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       z-index: 5;
//     }

//     .compare-img {
//       overflow: hidden;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       position: relative;
//       height: 100%;

//       .img-contain {
//         width: 100%;
//         height: 100%;
//         position: relative;
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: contain;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         transform: scale(1);

//         .original {
//           position: absolute;
//           top: 20px;
//           left: 25px;
//           width: 56px;
//           height: 29px;
//           background: #eeeeee88;
//           border-radius: 2px;
//           display: inline-block;
//           text-align: center;
//           line-height: 29px;
//           color: #000;
//           padding: 0 7px;
//         }

//         .enhance {
//           position: absolute;
//           top: 20px;
//           right: 25px;
//           width: 56px;
//           height: 29px;
//           background: #eeeeee88;
//           border-radius: 2px;
//           display: inline-block;
//           text-align: center;
//           line-height: 29px;
//           color: #000;
//           padding: 0 7px;
//         }

//         img {
//           object-fit: contain;
//           vertical-align: middle;
//         }
//       }

//       .img-contain-origin {
//         // position: absolute;
//         // left: 0;
//         // right: 0;
//         // top: 0;
//         // bottom: 0;
//         div {
//           width: 100%;
//           height: 100%;
//           background-position: center;
//           background-repeat: no-repeat;
//           background-size: contain;
//         }
//       }

//       .img-contain-enhance {
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;

//         div {
//           width: 100%;
//           height: 100%;
//           background-position: center;
//           background-repeat: no-repeat;
//           background-size: contain;
//         }
//       }
//     }

//     .handle {
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       // width: 4px;
//       // background: #ffffff88;

//       z-index: 6;
//       // background-color: #FFFFFF;
//       cursor: ew-resize;
//       // padding: 20px;

//       -webkit-touch-callout: none;
//       /* iOS Safari */
//       -webkit-user-select: none;
//       /* Chrome/Safari/Opera */
//       -khtml-user-select: none;
//       /* Konqueror */
//       -moz-user-select: none;
//       /* Firefox */
//       -ms-user-select: none;
//       /* Internet Explorer/Edge */
//       user-select: none;
//       /* Non-prefixed version, currently not supported by any browser */

//       .line {
//         position: relative;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 2px;
//         background: #f7f7f8;
//         // box-shadow: 0px 0.04rem 0.1rem 0px rgba(109, 109, 109, 0.54);
//         img {
//           position: absolute;
//           bottom: 20%;
//           left: -20px;
//         }
//         .before,
//         .after {
//           position: absolute;
//           top: 20px;
//           background: rgba(255, 255, 255, 0.7);
//           border-radius: 20px;
//           height: 30px;
//           line-height: 30px;
//           padding: 0 20px;
//           color: $text-deep;
//           font-size: 12px;
//           width: max-content;
//         }

//         .before {
//           right: 20px;
//         }

//         .after {
//           left: 20px;
//         }
//       }
//     }

//     .try-move {
//       position: absolute;
//       bottom: 16%;

//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       transform: translate(-50%, -68px);
//       margin-left: 1px;
//       z-index: 6;
//       & > div {
//         color: $text-deep;
//         font-size: 14px;
//         background-color: #fff;
//         border-radius: 10px;
//         padding: 11px 15px;
//       }
//     }
//   }
// }
</style>
