<template>
  <div class="Roboto-12 rounded-lg border border-transparent" :class="[focus ? 'bg-gradient' : '']">
    <div class="w-full h-full flex flex-col rounded-lg bg-light-gray-bg px-3 pt-2">
      <textarea ref="textareaRef" @input="onInput"
        style=" font-size: 12px; font-weight: 400; resize: none;line-height: 20px" :value="modelValue"
        class="flex-1 text-text-deep relative  Roboto-12 w-full h-36    outline-none bg-light-gray-bg" type="text"
        :placeholder="placeholder" @blur="onBlur" @focus="onFocus"></textarea>
      <div class="flex items-center justify-between py-2  w-full Roboto-12 bg-light-gray-bg rounded-b-lg">
        <div>
          <a-tooltip :contentStyle="{
            'border-radius': '15px',
            'background-color': '#1F1F1FE5',
            'margin-left': '20px',
          }" v-if="enhance && modelValue.length <= 200 && !loading" :content="modelValue == '' ? $t('prompt-words') : $t('Enhance-Prompt')
            " class="rounded-full">
            <img class="w-6 h-6 left-6 cursor-pointer hover:scale-110" @click="onClickprompt()"
              src="/assets/images/functype/AIGenerator/WordPro.png" alt="" />
          </a-tooltip>
          <img v-if="loading" class="w-6 h-6 left-6 cursor-pointer animate-rotate"
            src="/assets/images/functype/AIGenerator/loading.svg" />
        </div>
        <div class="flex items-center gap-2">
          <div class="mt-[2px]" :class="remainingChars != props.maxLength
            ? 'text-text-gray'
            : 'text-red-600'
            ">
            {{ remainingChars }}/{{ props.maxLength }}
          </div>
          <img v-show="modelValue.length" @click="onClean" class="w-4 h-4 cursor-pointer hover:scale-110"
            src="@/assets/images/enhance/ClearAll.png" alt="" />
          <div v-show="!modelValue.length" class="w-4 h-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const { isLogin } = useUserInfo();
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  maxLength: {
    type: Number,
    default: 3000,
  },
  placeholder: {
    type: String,
    default: "",
  },
  enhance: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const focus = ref(false);
const loading = ref(false);
const textareaRef = ref(null);
const remainingChars = computed(() => props.modelValue.length);
const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement; // 类型断言
  if (target.value.length > props.maxLength) {
    const str = target.value.slice(0, props.maxLength);
    emit("update:modelValue", str);
    return;
  }
  if (target.value) {
    target.value = target.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '');
  }
  emit("update:modelValue", target.value);
};
function onClickprompt() {
  if (isLogin.value) {
    if (/^\s*$/.test(props.modelValue)) {
      return null;
    }
    if (props.modelValue == "" || props.modelValue.length > 200) {
      return null;
    }
    loading.value = true;
    useApi
      .postOptimizePrompts({ prompt: props.modelValue })
      .then((res) => {
        if (res.code == 0) {
          // props.modelValue = res.data;
          emit("update:modelValue", res.data);
          loading.value = false;
        } else if (res.code == 401) {
        }
      })
      .catch((e) => {
        loading.value = false;
      });
  } else {
    mainStore.setVisibleLogin(true);
  }
}
function onClean() {
  if (!props.modelValue.length) {
    return;
  }
  emit("update:modelValue", "");
}
function onBlur() {
  focus.value = false;
}
function onFocus() {
  focus.value = true;
}
</script>
<style lang="scss" scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 3s linear infinite;
}
</style>