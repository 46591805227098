<template>
  <div>
    <div class="mt-14 text-center font-Montserrat text-3xl">
      {{ $t("Index-MovingFigures-title") }}
    </div>
    <Vue3Marquee>
      <NuxtImg
        class="h-[100px] w-auto mt-10 mx-20"
        v-for="img in imgArray"
        :key="img.src"
        :src="img.src"
        height="100"
        :alt="$t('AI_Enhance')"
        loading="lazy"
        :width="img.width"
      />
    </Vue3Marquee>
  </div>
</template>

<script setup>
import { Vue3Marquee } from "vue3-marquee";

const imgArray = [
  { src: "/assets/images/home/image1.png", width: 148, height: 244 },
  { src: "/assets/images/home/image2.png", width: 69, height: 244 },
  { src: "/assets/images/home/image3.png", width: 77, height: 244 },
  { src: "/assets/images/home/image4.png", width: 128, height: 244 },
  { src: "/assets/images/home/image5.png", width: 136, height: 244 },
  { src: "/assets/images/home/image6.png", width: 144, height: 244 },
  { src: "/assets/images/home/image7.png", width: 144, height: 244 },
  { src: "/assets/images/home/image8.png", width: 144, height: 244 },
];
</script>
