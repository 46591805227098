<template>
  <div v-if="isShowLoading" class="loading-container">
    <div class="loading-box">
      <Loading />
      <!-- <div id="lottie"></div> -->

      <img
        class="logo"
        src="@/assets/images/logo-text.png"
        alt="logo"
        height="40"
        width="234"
      />
      <span v-if="title"  class="loading-txt">{{ title }}</span>
      <span v-if="subText" class="loading-txt">{{ subText }}</span>
      <div v-if="closeCB" class="close" @click="onClose">
        <img src="@/assets/images/close.png" alt="close" width="20" />
      </div>
    </div>
  </div>
</template>
<script setup>
const isShowLoading = ref(true);
defineExpose({updateText})
const props= defineProps({
  title: {
    type: String,
    default: "",
  },
  subText: {
    type: String,
    default: "",
  },
  closeCB: {
    type: Function,
  },
});
const title = ref(props.title)
const subText = ref(props.subText)
function onClose() {
  isShowLoading.value = false;
  if (props.closeCB) props.closeCB();
}
function updateText({loadTitle=null,loadSubText=null}) {
  if(loadTitle!== null){
    title.value = loadTitle
  }
  
  if(loadSubText !== null){
    subText.value = loadSubText
  }
}
</script>

<style lang="scss">
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.loading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 300px;
  border-radius: 10px;
  // background: #e7e7e7;
  .logo {
    margin-top: 40px;
  }
  .loading-txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    color: #fff;
    text-align: center;
    padding: 0 10px;
  }
  .close {
    position: fixed;
    right: 20px;
    top: 20px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $gray-middle;
    img {
      display: block;
    }
  }
}

// #lottie {
//   background-color: #ffffff;
//   width: 80px;
//   height: 80px;
//   display: block;
//   overflow: hidden;
//   transform: translate3d(0, 0, 0);
//   text-align: center;
//   opacity: 1;
// }
</style>
