<template>
  <div class="relative rounded-full bg-cover w-12 h-12 flex items-center">
    <NuxtImg :src="mainStore.userInfo?.logoUrl ?? '/assets/images/avatar.webp'" alt="avatar"
      class="rounded-full min-w-9 min-h-9  max-w-9 max-h-9 object-cover" loading="lazy" width="60" height="60"/>
    <img v-show="isVip" class="absolute bottom-0 right-[5px] w-12 h-6" src="/assets/images/vipBottom.webp" width="48" height="24" loading="lazy"/>
    <!-- <div
      
      class="absolute bottom-0 -right-[2px] bg-text-deep h-4 w-7 rounded-tl-[8px] rounded-br-[8px] flex items-center justify-center"
    >
      <span
        class="bg-vip bg-clip-text text-transparent font-Roboto-Bold font-bold text-[10px] leading-4"
        >VIP</span
      >
    </div> -->
  </div>
</template>
<script setup>
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const { isVip } = useUserInfo();
</script>
