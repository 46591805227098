<template>
    <div class="flex gap-1 justify-center items-center">
        <div v-for="i in starList" :key="i">
            <NuxtImg v-if="i == 1" src="/assets/images/home/star.svg" width="26" height="26" loading="lazy" alt="star"/>
            <NuxtImg v-else src="/assets/images/home/starhafl.svg" width="26" height="26" loading="lazy" alt="star"/>
        </div>
        <span v-if="props.showScore" class="mx-4 Roboto-Bold-24">{{ props.score }} </span>
    </div>
</template>

<script setup>
// score 评分 ， starNum 几颗星星 ，showScore 是否显示分数 ， halfStar 是否最后一颗半星显示
const props = defineProps({
    score: {
        type: Number,
        default: 4.7,
    },
    starNum: {
        type: Number,
        default: 5,
    },
    showScore: {
        type: Boolean,
        default: false,
    },
    halfStar: {
        type: Boolean,
        default: false,
    },
});
const starList = computed((e) => {
    const nums = []
    for (let index = 0; index < props.starNum; index++) {
        if (props.halfStar && (index == props.starNum - 1)) {
            nums.push(0)
        } else {
            nums.push(1)
        }
    }
    return nums
})
</script>