<template>
  <div
    v-if="show"
    class="fixed z-[20] rounded-[32px] border border-[#FFFFFF4D] box-shadow"
    :style="{ top: position.top + 'px', left: position.left + 'px','transform': `translateX(-100%) scale(${scale})` }"
  >
    <div class="triangle-up relative"></div>

    <div
      class="content-gradient relative pt-6 rounded-t-[32px] rounded-b-[34px] -ml-px -mr-px -mb-px"
    >
      <NuxtImg
        class="absolute right-0 top-0"
        src="/assets/images/dialog/login/3.webp"
        :alt="$t('AI_Enhance')"
        width="194"
        height="153"
        loading="lazy"
      />
      <div class="px-6 font-semibold text-base text-white">
        {{$t('GuideLogin-title1')}}
      </div>
      <div class="px-6 mb-6 relative z-[3] w-[80%]">
        <span class="Roboto-Bold-24 text-gradient">{{$t('GuideLogin-title2')}}</span
        ><span class="font-semibold text-white text-2xl"
          >{{$t('GuideLogin-title3')}}</span
        >
      </div>
      <div
        class="rounded-[32px] bg-white px-8 py-6 flex gap-x-10 relative z-[2] overflow-hidden"
      >
        <div class="w-[200px]">
          <NuxtImg
            src="/assets/images/dialog/login/1.webp"
            :alt="$t('AI_Enhance')"
            class="rounded-[10px] mb-3"
            width="200"
            height="100"
            loading="lazy"
          />
          <p class="Roboto-12 text-[#343434]">
            {{$t('GuideLogin-item-1')}}
          </p>
        </div>
        <div class="w-[200px]">
          <NuxtImg
            src="/assets/images/dialog/login/2.webp"
            :alt="$t('AI_Enhance')"
            class="rounded-[10px] mb-3"
            width="200"
            height="100"
            loading="lazy"
          />
          <p class="Roboto-12 text-[#343434]">
            {{$t('GuideLogin-item-2')}}
          </p>
        </div>
      </div>
      <div
        class="absolute top-3 right-3 flex items-center justify-center rounded-full w-8 h-8 bg-[#FFFFFF4D] cursor-pointer"
        @click="onClose"
      >
        <NuxtImg
          src="/assets/images/dialog/close.svg"
          :alt="$t('AI_Enhance')"
          width="16"
          height="16"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { customEventNames } = useCustomEvent();
const { isLogin } = useUserInfo();
const show = ref(false);
const position = reactive({ left: 0, top: 0 });
const scale = ref(1)
const debounce = useDebounceFn(updatePos,300)
const timer = ref()
onMounted(() => {
  const guideDialog = localStorage.getItem("guideDialog");
  if (guideDialog === '1' && !isLogin.value) {
    timer.value = setTimeout(()=>{
      show.value = true;
    },2000)
    updatePos();
  }
  document.addEventListener("scroll", debounce, { passive: true });
  window.addEventListener("resize", debounce, { passive: true });
  window.addEventListener(customEventNames.guideLoginEvent, guideLoginEvent);
});
onUnmounted(() => {
  document.removeEventListener("scroll", debounce);
  window.removeEventListener("resize", debounce);
  window.removeEventListener(customEventNames.guideLoginEvent, guideLoginEvent);
});

function guideLoginEvent(e: any) {
  const isshow = e.detail;
  if(isshow){
    updatePos();
    show.value = true;
  }else {
    show.value = false;
    if(timer.value){
      clearTimeout(timer.value)
    }
  }
}
function updatePos() {
  const element: HTMLDivElement = document.getElementById(
    "sign-up"
  ) as HTMLDivElement;
  const rect: DOMRect = element.getBoundingClientRect();
  // position.left = rect.right - rect.width / 2 + 40;
  if(window.innerHeight>650) {
    position.left = rect.right - rect.width / 2 + 40;
    position.top = rect.top + 90;
    scale.value = 1
  }else {
    position.left = rect.right - (rect.width *0.8) / 2 + 80;
    position.top = rect.top + 60;
    scale.value = 0.8
  }
}
function onClose() {
  show.value = false;
  useLogEvent({ eventName: "guideLogin-close" });
}
</script>
<style lang="scss" scoped>
.content-gradient {
  background: linear-gradient(90deg, #4f2bff 0%, #d10dff 100%);
}
.text-gradient {
  background: linear-gradient(90deg, #ffd529 0%, #00ffdb 100%);
  /* 背景仅作用于文字区域 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 文字颜色透明以显示背景 */
  color: transparent;
  /* 兼容旧版WebKit浏览器 */
  -webkit-text-fill-color: transparent;
}
.triangle-up::before {
  content: "";
  position: absolute;
  right: 36px;
  top: -20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #b52cff;
}
.box-shadow {
  box-shadow: 0px 8px 24px 0px #67677840;
}
</style>
