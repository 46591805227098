// const app = useNuxtApp()
export function useFuncType() {
  const { locale, t } = useI18n();
  function getFuncType() {
    const FuncType = {
      enhance_pro: {
        path: "enhance_pro",
        name: t("enhance_pro"),
      },
      stable_motion_ai: {
        path: "stable_motion_ai",
        name: t("stable_motion_ai"),
      },
      color_master: {
        path: "color_master",
        name: t("color_master"),
      },
      descratch: {
        path: "descratch",
        name: t("descratch"),
      },
      anime_enhance: {
        path: "anime_enhance",
        name: t("anime_enhance"),
      },
      word_hd: {
        path: "word_hd",
        name: t("word_hd"),
      },
      video_enhance: {
        path: "video_enhance",
        name: t("video_enhance"),
      },
      // AI Removal
      image_background_remover: {
        path: "image_background_remover",
        name: t("image_background_remover"),
      },
      video_background_remover: {
        path: "video_background_remover",
        name: t("video_background_remover"),
      },
      object_remover: {
        path: "object_remover",
        name: t("object_remover"),
      },
      face_cutout: {
        path: "face_cutout",
        name: t("face_cutout"),
      },
      watermark_remover: {
        path: "watermark_remover",
        name: t("watermark_remover"),
      },
      // AI Editing
      image_compressor: {
        path: "image_compressor",
        name: t("image_compressor"),
      },
      video_compressor: {
        path: "video_compressor",
        name: t("video_compressor"),
      },
      image_converter: {
        path: "image_converter",
        name: t("image_converter"),
      },
      video_converter: {
        path: "video_converter",
        name: t("video_converter"),
      },
      ai_e_commerce: {
        path: "ai_e_commerce",
        name: t("ai_e_commerce"),
      },
      background_remover: {
        path: "background_remover",
        name: t("background_remover"),
      },
      ai_poster: {
        path: "ai_poster",
        name: t("ai_poster"),
      },
      ai_logo: {
        path: "ai_logo",
        name: t("ai_logo"),
      },
      partial_redraw: {
        path: "partial_redraw",
        name: t("partial_redraw"),
      },
      ai_product_image: {
        path: "ai_product_image",
        name: t("ai_product_image"),
      },
      ai_uncrop: {
        path: "ai_uncrop",
        name: t("ai_uncrop"),
      },
      // AI Generation
      ai_background_generator: {
        path: "ai_background_generator",
        name: t("ai_background_generator"),
      },
      ai_art_generator: {
        path: "ai_art_generator",
        name: t("ai_art_generator"),
      },
    };
    return FuncType;
  }
  function checkIsProFunc(funcType: string) {
    return [
      "video_enhance",
      "video_background_remover",
      "video_compressor",
      "video_converter",
    ].includes(funcType);
  }
  function checkIsBaseFunc(funcType: string) {
    return [
      "enhance_pro",
      "stable_motion_ai",
      "color_master",
      "descratch",
      "word_hd",
      "anime_enhance"
    ].includes(funcType);
  }
  return { getFuncType, checkIsProFunc,checkIsBaseFunc };
}
