<template>
  <div class="relative w-full h-full flex items-center justify-center">
    <div class="h-full flex-1 flex items-center justify-center relative">
      <div class="h-4/5 w-[90%] max-w-[1224px] relative -translate-y-[10%] shadow-1 bg-white rounded-xl overflow-hidden" v-if="fileModel">
        <div class="w-full h-full overflow-hidden">
          <EnhancePreLoad ref="preLoadRef" v-if="
            fileModel.status == uploadStatus.handle ||
            fileModel.status == uploadStatus.lodingImg
          " :fileModel="fileModel"></EnhancePreLoad>
          <div v-else-if="fileModel.status == uploadStatus.success" class="w-full h-full">
            <EnhanceCompareImg ref="compareImgRef" :origin="fileModel.preImage" :enhance="isVip
              ? fileModel.enhanceUrl
              : fileModel.watermark || fileModel.enhanceUrl
              " :minScale="minScale" :maxScale="maxScale" @scaleChange="CompareImgScaleChange" />
            <EnhanceGiveALike class="absolute right-4 bottom-4" v-if="isLogin"
              v-show="fileModel.enhanceUrl && !fileModel.isEvaluate" :id="fileModel.enhanceRecordId"
              @onEvaluate="onEvaluate"></EnhanceGiveALike>
          </div>
          <EnhanceImageView ref="imageViewRef" v-else :minScale="minScale" :maxScale="maxScale"
            :url="fileModel.preImage" @scaleChange="imageViewChange"></EnhanceImageView>
        </div>
      </div>
      <div v-else class="flex items-center justify-center">
        <FuncTypeUpload class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block xl:max-w-[1080px] lg:h-[280px]"
          :acceptCount="acceptCount" :funcType="funcType" :acceptList="acceptList"></FuncTypeUpload>
      </div>
      <div v-if="fileModel"
        class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max">
        <div class="relative h-full flex items-center justify-between gap-x-4">
          <EnhanceBaseControls class="flex-1 flex justify-center" ref="baseControlsRef" :minScale="minScale"
            :maxScale="maxScale" @change="baseCtlScaleChange"></EnhanceBaseControls>
          <div v-if="funcType === FuncType.enhance_pro.path" class="bg-gray-line w-px h-[30px]"></div>
          <div v-if="funcType === FuncType.enhance_pro.path" class="flex gap-x-3 items-center">
            <span class="text-black Roboto-14">{{ $t("color_master") }}</span>
            <Switch v-model="ColorMaster"></Switch>
          </div>
          <div v-if="funcType === FuncType.enhance_pro.path" class="bg-gray-line w-px h-[30px]"></div>
          <div v-if="funcType === FuncType.enhance_pro.path" class="flex gap-x-3 items-center">
            <span class="text-black Roboto-14">{{ $t("descratch") }}</span>
            <Switch v-model="descratch"></Switch>
          </div>
          <div v-if="funcType === FuncType.enhance_pro.path" class="bg-gray-line w-px h-[30px]"></div>
          <EnhanceApply @click="onApply" :enable="applyEnabled"></EnhanceApply>
        </div>
        <!-- 遮罩，部分流程禁止用户操作 -->
        <div class="absolute left-0 top-0 bottom-0 right-0 z-[2] cursor-not-allowed" v-if="
          !(
            fileModel.status === uploadStatus.success ||
            fileModel.status === uploadStatus.none ||
            fileModel.status === uploadStatus.fail ||
            fileModel.status === uploadStatus.uploadFail||
            fileModel.status === uploadStatus.enhanceErr
          )
        "></div>
      </div>
    </div>
    <EnhanceFold :isFold="isFold" @change="foldChange">
      <div class="w-full h-full py-8 flex flex-col overflow-hidden transition-all delay-150"
        :class="isFold ? 'pl-6 pr-2' : 'opacity-0'">
        <div class="flex items-center justify-between w-full mb-6 pr-4">
          <span class="Roboto-Bold-20 text-black">{{
            $t("enhance-All-Photo")
          }}</span>
          <span class="text-gray">{{ fileList.length }} / {{ acceptCount }}</span>
        </div>
        <div id="image-box" class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4"
          style="max-height: calc(100vh - 196px)">
          <template v-if="fileList.length">
            <EnhanceImgItem class="mb-5 last:mb-0" v-for="(item, index) in fileList" :key="item.id" :imgInfo="item"
              :isSelect="currIndex === index" @click="selectImg(index)" @delImg="() => delImg(index)"
              @downloadImg="() => downloadImg(index)" :isFinish="props.isFinish" @load="() => enhanceLoad(index)">
            </EnhanceImgItem>
            <div
              class="relative items-center justify-center gap-4 h-[52px] w-[264px] border border-gray-line rounded-xl cursor-pointer mt-5"
              :class="acceptCount === fileList.length&&isVipPro ? 'hidden' : 'flex'">
              <img src="/assets/images/enhance/add.svg" alt="add file" width="20" height="20" />
              <span class="Roboto-Bold-12 text-text-deep">{{
                $t("enhance-add")
              }}</span>
              <EnhanceUpload :acceptList="acceptList" :acceptCount="acceptCount"
                class="absolute inset-0 h-full opacity-0" @onSuccess="onAddFiles" :eventEnable="false"></EnhanceUpload>
                <div class="absolute inset-0 h-full opacity-0 z-[2]" v-if="acceptCount === fileList.length&&!isVipPro" @click="onOpenVip"></div>
            </div>
          </template>
          <template v-else>
            <div class="h-full w-full flex justify-center items-center">
              <EnhanceNoRecords></EnhanceNoRecords>
            </div>
          </template>
        </div>
      </div>
    </EnhanceFold>
  </div>
</template>
<script setup>
import {
  minScale,
  maxScale,
  imageAccept,
  maxUploadCount,
} from "@/utils/config";
import { uploadStatus } from "@/utils/type";
import { useMainStore } from "@/store/index";
const {
  customEventNames,
  cleanMenuDownload,
  downloadImgEvent,
  addFileListEvent,
  downloadUrlEvent,
  applyEvent,
  selectImgEvent,
  delImgEvent,
  updateFileListEvent
} = useCustomEvent();
const props = defineProps({
  funcType: {
    type: String,
    default: "",
  },
  fileList: {
    type: Array,
    default: [],
  },
  currIndex: {
    type: Number,
    default: 0,
  },
  isFinish: {
    type: Boolean,
    default: false,
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
});
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const mainStore = useMainStore();
const fileModel = ref(null);
const { isLogin, isVip, isVipPro } = useUserInfo();
const isFold = ref(true);
const acceptCount = computed(() => {
  return isVipPro.value ? maxUploadCount : 1;
});
const ColorMaster = ref(false);
const descratch = ref(false);
// const preLoadRef = ref();
const tryFlag = ref(true);
watch(
  () => props.fileList,
  () => {
    console.log("数据");
    if (props.fileList.length) {
      fileModel.value = props.fileList[props.currIndex];
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);
watch(
  () => props.currIndex,
  () => {
    console.log("数据");
    if (props.fileList.length) {
      fileModel.value = props.fileList[props.currIndex];
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);
const compareImgRef = ref();

const baseControlsRef = ref(null);
const imageViewRef = ref(null);
const smearRef = ref(null);
const acceptList = ref(imageAccept);
onMounted(() => {
  window.addEventListener(customEventNames.uploadLocalFilesSuccessEvent, uploadLocalFilesSuccess);
});
onUnmounted(() => {
  window.removeEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
});
function uploadLocalFilesSuccess(e) {
  console.log(e.detail);
  const files = e.detail;
  if (files.length) {
    cleanMenuDownload()
  }
}
function baseCtlScaleChange(scale) {
  if (fileModel.value.status == uploadStatus.success) {
    compareImgRef.value.setScale(scale);
  } else {
    if (imageViewRef.value) {
      imageViewRef.value.setScale(scale);
    }
  }
}

function CompareImgScaleChange(scale) {
  baseControlsRef.value.setScale(scale);
}
function imageViewChange(scale) {
  baseControlsRef.value.setScale(scale);
}
function onApply() {
  let aiType = [6];
  switch (props.funcType) {
    case FuncType.value.enhance_pro.path:
      aiType = [6];
      if (ColorMaster.value) {
        aiType.push(53);
      }
      if (descratch.value) {
        aiType.push(14);
      }
      break;
    case FuncType.value.stable_motion_ai.path:
      aiType = [57];
      break;
    case FuncType.value.color_master.path:
      aiType = [53];
      break;
    case FuncType.value.descratch.path:
      aiType = [14];
      break;
    case FuncType.value.anime_enhance.path:
      aiType = [72];
      break;
    case FuncType.value.word_hd.path:
      aiType = [58];
      break;
    default:
      break;
  }
  applyEvent({
        enhanceCfgIds: aiType,
      })
}

function onAddFiles(files) {
  addFileListEvent(files)
}
function selectImg(index) {
  baseControlsRef.value.setScale(100);
  if (imageViewRef.value) {
    imageViewRef.value.setScale(100);
  }
  if (compareImgRef.value) {
    compareImgRef.value.setScale(100);
  }
  if (index === props.currIndex) {
    return;
  }
  // nextTick(() => {
  //   if (preLoadRef.value) {
  //     preLoadRef.value.removeEventListener("load", enhanceLoad);
  //   }
  //   setTimeout(() => {
  //     if (preLoadRef.value) {
  //       preLoadRef.value.addEventListener("load", enhanceLoad);
  //     }
  //   });
  // });

  // currIndex.value = index;
  // setTimeout(()=>{
  //   if(preLoadRef.value) {
  //     preLoadRef.value.addEventListener('load',enhanceLoad)
  //   }
  // })
  selectImgEvent(index)
}
function delImg(index) {
  delImgEvent(index)
}
function downloadImg(index) {
  downloadImgEvent({ url: props.fileList[index].enhanceUrl })
}
function foldChange() {
  isFold.value = !isFold.value;
}
function enhanceLoad(index) {
  const fileList = [...props.fileList];
  fileList[index].status = uploadStatus.success;
  console.log("加载成功");
  updateFileListEvent(fileList)
}
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[props.currIndex].isEvaluate = true;
  updateFileListEvent(fileList)
}
function enhanceError(error) {
  // console.log("加载错误", error);
  if (tryCount.value < 3) {
    tryCount.value = tryCount.value + 1;
    console.log("加载失败，重试", tryCount.value);
    tryFlag.value = false;
    setTimeout(() => {
      tryFlag.value = true;
    }, 1000);
  }
}
function reloadImg() {
  tryCount.value = 0;
  enhanceError();
}
function onOpenVip() {
  mainStore.setVisibleVipDialog(true)
}
</script>
