<template>
  <div class="relative w-full h-full flex items-center justify-center px-6">
    <div class="h-4/5 w-full max-w-[1224px] overflow-hidden relative -translate-y-[10%] shadow-1 bg-white rounded-xl" v-if="fileModel">
      <div class="w-full h-full overflow-hidden">
        <EnhancePreLoad v-if="
          fileModel.status == uploadStatus.handle ||
          fileModel.status == uploadStatus.lodingImg
        " :fileModel="fileModel" @load="onImgLoad"></EnhancePreLoad>
        <div v-else-if="fileModel.status == uploadStatus.success" class="w-full h-full">
          <div class="mosaic relative h-full w-full bg-auto select-none">
            <img class="absolute object-contain w-full h-full" :src="fileModel.enhanceUrl" alt="enhance" />
          </div>
          <!-- <EnhanceCompareImg
            ref="compareImgRef"
            :origin="fileModel.preImage"
            :enhance="
              isVip
                ? fileModel.enhanceUrl
                : fileModel.watermark || fileModel.enhanceUrl
            "
            :minScale="minScale"
            :maxScale="maxScale"
            @scaleChange="CompareImgScaleChange"
          /> -->
          <EnhanceGiveALike class="absolute right-4 bottom-4" v-if="isLogin"
            v-show="fileModel.enhanceUrl && !fileModel.isEvaluate" :id="fileModel.enhanceRecordId"
            @onEvaluate="onEvaluate"></EnhanceGiveALike>
        </div>
        <EnhanceImageView ref="imageViewRef" v-else :minScale="minScale" :maxScale="maxScale" :url="fileModel.preImage"
          @scaleChange="imageViewChange"></EnhanceImageView>
      </div>
    </div>
    <div v-else class="flex items-center justify-center">
      <FuncTypeUpload class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
        :funcType="route.params.funcType"></FuncTypeUpload>
    </div>
    <div v-if="fileModel" v-show="!(fileModel.status >= uploadStatus.lodingImg)"
      class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4">
      <div class="relative h-full flex items-center justify-between gap-x-8">
        <EnhanceBaseControls class="flex-1 flex justify-center" ref="baseControlsRef" :minScale="minScale"
          :maxScale="maxScale" @change="baseCtlScaleChange"></EnhanceBaseControls>
        <EnhanceApply @click="onApply" :enable="applyEnabled && fileModel.status !== uploadStatus.success">
        </EnhanceApply>
      </div>
      <!-- 遮罩，部分流程禁止用户操作 -->
      <div class="absolute left-0 top-0 bottom-0 right-0 z-[2] cursor-not-allowed" v-if="
        !(
          fileModel.status === uploadStatus.success ||
          fileModel.status === uploadStatus.none ||
          fileModel.status === uploadStatus.fail ||
          fileModel.status === uploadStatus.uploadFail ||
          fileModel.status === uploadStatus.enhanceErr
        )
      "></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { minScale, maxScale } from "@/utils/config";
import { uploadStatus } from "@/utils/type";
import type { EnhanceModel } from "~/types";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
const {
  customEventNames,
  cleanMenuDownload,
  applyEvent,
  updateFileListEvent,
  updateECommerceCommonEvent,
  uploadLocalFilesSuccessEvent,
} = useCustomEvent();
const uploadStore = useUploadStore();
const route = useRoute();
const props = defineProps({
  fileList: {
    type: Array<EnhanceModel>,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
  // 默认背景移除
  enhanceCfgIds: {
    type: Array,
    default: [75],
  },
  // commonData: {
  //   type: Object,
  //   default: { origin: null, enhance: null },
  // },
});
const fileModel = ref<EnhanceModel | null>(null);
const { isLogin, isVip, isVipPro } = useUserInfo();
const mainStore = useMainStore();
watch(
  () => props.fileList,
  () => {
    console.log("数据fileList:", props.fileList);
    if (props.fileList.length) {
      console.log("数据", props.fileList[0]);
      fileModel.value = props.fileList[0] as EnhanceModel;
      // if (fileModel.value.status === uploadStatus.none) {
      //   updateECommerceCommonEvent({ origin: fileModel.value.file });
      // }
      // else if (fileModel.value.status === uploadStatus.lodingImg) {
      //   updateECommerceCommonEvent({ enhance: fileModel.value.enhanceUrl });
      // }
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);

const compareImgRef = ref();
const baseControlsRef = ref(null);
const imageViewRef = ref(null);
onMounted(() => {
  // 生命周期原因，加nextTick
  nextTick(() => {
    // 其他功能处理好的图
    if (props.fileList.length && props.fileList[0].enhanceUrl) {
      updateFileListEvent([
        {
          status: uploadStatus.none,
          originUrl: props.fileList[0].enhanceUrl,
          preImage: props.fileList[0].enhanceUrl,
          enhanceUrl: props.fileList[0].enhanceUrl,
          isEvaluate: true,
        },
      ]);
    }
    else if (props.fileList.length && props.fileList[0].file) {
      fileModel.value = props.fileList[0]
    }
  })

});
onUnmounted(() => {
  uploadStore.setImages([]);
  cleanMenuDownload();
  // let p = {}
  // if (fileModel.value?.enhanceUrl) {
  //   p = { origin: null, enhance: fileModel.value?.enhanceUrl }
  //   updateECommerceCommonEvent(p);
  // }
});
function baseCtlScaleChange(scale: number) {
  if (imageViewRef.value) {
    imageViewRef.value?.setScale(scale);
  }
}

// function CompareImgScaleChange(scale) {
//   baseControlsRef.value.setScale(scale);
// }
function imageViewChange(scale: number) {
  baseControlsRef.value?.setScale(scale);
}
function onApply() {
  if (isLogin.value) {
    if (fileModel.value?.status == uploadStatus.success) {
      return;
    }
    window.dispatchEvent(
      new CustomEvent("applyEvent", {
        // 提交相关处理参数，如相关表单
        detail: {
          enhanceCfgIds: props.enhanceCfgIds,
        },
      })
    );
  } else {
    mainStore.setVisibleLogin(true);
  }
}

function onImgLoad() {
  console.log("加载成功");

  const fileList = [...props.fileList];
  fileList[0].status = uploadStatus.success;
  updateFileListEvent(fileList);
}
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList);
}
</script>
