<template>
  <div class="relative w-full h-full flex items-center justify-center">
    <div class="h-full flex-1 flex items-center justify-center relative px-6">
      <div
        class="h-4/5 w-full max-w-[1224px] relative -translate-y-[10%] shadow-1 bg-white rounded-xl"
        v-if="fileModel"
      >
        <div class="h-full w-full relative flex flex-wrap overflow-hidden">
          <div
            class="h-full w-full relative items-center justify-center"
            id="main-area"
            :class="isCompare ? 'hidden' : 'flex'"
          >
            <canvas
              id="origin-canvas-area"
              oncontextmenu="return false"
              width="300"
              height="300"
              :style="leftBackgStr"
              >This function is not supported by the current Tourer. Try another
              one!</canvas
            >
            <canvas
              @mousedown="rightCanvasDown"
              id="top-canvas-area"
              oncontextmenu="return false"
              width="300"
              height="300"
              :style="leftBackgStr"
              class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 opacity-50"
              >This function is not supported by the current Tourer. Try another
              one!</canvas
            >
            <div
              class="absolute bg-[#FF711899] rounded-full -translate-y-1/2 -translate-x-1/2 pointer-events-none"
              :style="{
                width: penSize + 'px',
                height: penSize + 'px',
                left: arcXy.x + 'px',
                top: arcXy.y + 'px',
                display: arcXy.display,
              }"
            ></div>
          </div>
          <div
            class="h-full w-full relative items-center justify-center"
            :class="isCompare ? 'flex' : 'hidden'"
          >
            <canvas
              id="canvas-compare"
              oncontextmenu="return false"
              width="300"
              height="300"
              >This function is not supported by the current Tourer. Try another
              one!</canvas
            >
            <div
                class="absolute top-4 left-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white">
                {{ $t("before") }}
            </div>
          </div>
          <div
            v-if="
              fileModel.status == uploadStatus.handle ||
              fileModel.status == uploadStatus.lodingImg
            "
            class="absolute top-0 w-full h-full flex flex-col items-center justify-center bg-black-60"
          >
            <Loading />
            <p
              v-if="fileModel.status == uploadStatus.handle"
              class="mt-10 max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center"
              v-html="$t('enhance-process')"
            ></p>
          </div>
        </div>
        <div
          class="w-full h-full absolute left-0 top-0 flex items-center justify-center flex-col bg-[#00000088]"
          v-if="loadImgFail"
        >
          <p class="mt-10 max-w-[50%] text-center Roboto-16 text-[#FFFFFF99]">
            {{ $t("loading-err")
            }}<a
              @click="reloadImg"
              class="cursor-pointer underline ml-1 text-text-gray"
            >
              {{ $t("reload") }}?</a
            >
          </p>
          <div
            class="absolute right-2 top-2 close-btn"
            @click.stop="loadImgFail = false"
          />
        </div>
        <EnhanceGiveALike
          class="absolute right-4 bottom-4"
          v-if="isLogin"
          v-show="fileModel.enhanceUrl && !fileModel.isEvaluate"
          :id="fileModel.enhanceRecordId"
          @onEvaluate="onEvaluate"
        ></EnhanceGiveALike>
      </div>
      <div v-else class="flex items-center justify-center">
        <FuncTypeUpload
          class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
          :funcType="route.params.funcType"
        ></FuncTypeUpload>
      </div>
      <div
        v-if="fileModel"
        class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-6 w-max"
      >
        <div class="relative h-full flex items-center justify-between gap-x-3">
          <!-- 撤销 -->
          <a-tooltip content-class="!rounded-md">
            <div
              class="h-14 w-14 flex items-center justify-center cursor-pointer rounded-lg hover:bg-light-gray-bg"
              @click="preHistory"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.10912 7.31278C1.95997 7.43579 1.96441 7.66568 2.11819 7.78285L7.26689 11.7056C7.46436 11.8561 7.7487 11.7153 7.7487 11.467L7.7487 8.37844H13.7095C15.1245 8.3932 16.4757 8.96821 17.4658 9.97698C18.4559 10.9857 19.0038 12.3456 18.989 13.7575C18.9742 15.1694 18.398 16.5175 17.3869 17.5054C16.3759 18.4933 15.013 19.04 13.598 19.0253H6.52977C6.43733 19.0253 6.34868 19.0619 6.28332 19.1271C6.21796 19.1923 6.18123 19.2808 6.18123 19.373V20.764C6.18123 20.8563 6.21796 20.9447 6.28332 21.0099C6.34868 21.0752 6.43733 21.1118 6.52977 21.1118H13.598L13.7207 21.1104C15.6687 21.0783 17.5261 20.2836 18.8924 18.8978C20.2586 17.5119 21.0244 15.6458 21.0245 13.7019V13.5795C20.9926 11.6353 20.1962 9.78146 18.8069 8.41789C17.4176 7.05432 15.5468 6.29027 13.598 6.29053H7.7487L7.7487 3.29764C7.7487 3.044 7.45349 2.90482 7.25781 3.0662L2.10912 7.31278Z"
                  :fill="historyIndex > 0 ? '#1F1F1F' : '#DEE0EC'"
                />
              </svg>
            </div>
            <template #content>
              <div class="flex items-center gap-1">
                {{ $t('enhance-Undo') }}:<span
                  class="Roboto-12 py-[3] px-[6px] bg-white rounded-md text-text-deep"
                  >Ctrl</span
                >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="5"
                    width="10"
                    height="2"
                    rx="1"
                    fill="white"
                    style="fill: white; fill-opacity: 1"
                  />
                  <rect
                    x="7"
                    y="1"
                    width="10"
                    height="2"
                    rx="1"
                    transform="rotate(90 7 1)"
                    fill="white"
                    style="fill: white; fill-opacity: 1"
                  />
                </svg>
                <span
                  class="Roboto-12 py-[3] px-[6px] bg-white rounded-md text-text-deep"
                  >Z</span
                >
              </div>
            </template>
          </a-tooltip>
          <a-tooltip content-class="!rounded-md">
            <div
              class="h-14 w-14 flex items-center justify-center cursor-pointer rounded-lg hover:bg-light-gray-bg"
              @click="nextHistory"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.9153 7.31278C21.0644 7.43579 21.06 7.66568 20.9062 7.78285L15.7575 11.7056C15.5601 11.8561 15.2757 11.7153 15.2757 11.467L15.2757 8.37844H9.31492C7.8999 8.3932 6.54872 8.96821 5.55862 9.97698C4.56851 10.9857 4.02059 12.3456 4.03538 13.7575C4.05017 15.1694 4.62646 16.5175 5.63748 17.5054C6.6485 18.4933 8.01143 19.04 9.42645 19.0253H16.4946C16.5871 19.0253 16.6757 19.0619 16.7411 19.1271C16.8065 19.1924 16.8432 19.2808 16.8432 19.373V20.764C16.8432 20.8563 16.8065 20.9447 16.7411 21.0099C16.6757 21.0752 16.5871 21.1118 16.4946 21.1118H9.42645L9.30376 21.1104C7.35571 21.0783 5.49833 20.2836 4.13206 18.8978C2.76579 17.5119 2.00006 15.6458 1.99996 13.7019V13.5795C2.03178 11.6353 2.82824 9.78146 4.21755 8.41789C5.60685 7.05432 7.47765 6.29027 9.42645 6.29053H15.2757L15.2757 3.29764C15.2757 3.044 15.5709 2.90482 15.7666 3.0662L20.9153 7.31278Z"
                  :fill="
                    historyIndex < historyList.length - 1
                      ? '#1F1F1F'
                      : '#DEE0EC'
                  "
                />
              </svg>
            </div>
            <template #content>
              <div class="flex items-center gap-1">
                {{ $t("enhance-Redo") }}：<span
                  class="Roboto-12 py-[3] px-[6px] bg-white rounded-md text-text-deep"
                  >Ctrl</span
                >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="5"
                    width="10"
                    height="2"
                    rx="1"
                    fill="white"
                    style="fill: white; fill-opacity: 1"
                  />
                  <rect
                    x="7"
                    y="1"
                    width="10"
                    height="2"
                    rx="1"
                    transform="rotate(90 7 1)"
                    fill="white"
                    style="fill: white; fill-opacity: 1"
                  />
                </svg>
                <span
                  class="Roboto-12 py-[3] px-[6px] bg-white rounded-md text-text-deep"
                  >Y</span
                >
              </div>
            </template>
          </a-tooltip>
          <div class="bg-gray-line w-px h-[30px]"></div>
          <!-- <EnhanceBaseControls
            class="flex-1 flex justify-center border-none"
            ref="baseControlsRef"
            :minScale="minScale"
            :maxScale="maxScale"
            @change="setScale"
          ></EnhanceBaseControls>
          <div class="bg-gray-line w-px h-[30px]"></div> -->

          <div class="base-controls h-10 flex items-center gap-x-3">
            <!-- 拖动 -->
            <!-- <div
              class="h-12 w-12 flex items-center justify-center cursor-pointer rounded-lg hover:bg-light-gray-bg"
              @click="changeMove"
              :class="isMove ? 'bg-mainColor-deep-10' : ''"
            >
              <img
                src="/assets/images/enhance/hand.svg"
                alt="drop"
                width="24"
                height="24"
              />
            </div> -->
            <!-- 对比 -->
            <div
              class="h-12 w-12 flex items-center justify-center cursor-pointer rounded-lg hover:bg-light-gray-bg"
              @mousedown="compareMousedown"
              @mouseup="compareMouseup"
              @mouseleave="compareMouseup"
            >
              <img
                v-if="fileModel.enhanceUrl"
                src="/assets/images/enhance/compare.svg"
                alt="compare"
                width="24"
                height="24"
              />
              <img
                v-else
                src="/assets/images/enhance/compare-disable.svg"
                alt="compare"
                width="24"
                height="24"
              />
            </div>
            <!-- 涂抹 -->
            <a-tooltip
              content-class="!rounded-md"
              :content="$t('enhance-Brush')"
            >
              <div
                class="h-12 w-12 flex items-center justify-center cursor-pointer rounded-lg hover:bg-light-gray-bg"
                @click="changeSmear"
                :class="isSmear ? 'bg-mainColor-deep-10' : ''"
              >
                <img
                  src="/assets/images/enhance/brush.svg"
                  alt="brush"
                  width="24"
                  height="24"
                />
              </div>
            </a-tooltip>
            <a-slider
              :min="10"
              :max="100"
              show-tooltip
              class="mx-1"
              style="width: 100px"
              :step="10"
              v-model="penSize"
            />
          </div>
          <EnhanceApply
            @click="onApply"
            :enable="applyEnabled && historyList.length > 1"
          ></EnhanceApply>
        </div>
        <!-- 遮罩，部分流程禁止用户操作 -->
        <div
          class="absolute left-0 top-0 bottom-0 right-0 z-[2] cursor-not-allowed"
          v-if="
            !(
              fileModel.status === uploadStatus.success ||
              fileModel.status === uploadStatus.none ||
              fileModel.status === uploadStatus.fail ||
              fileModel.status === uploadStatus.uploadFail||
              fileModel.status === uploadStatus.enhanceErr
            )
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { uploadStatus } from "@/utils/type";
import type { EnhanceModel } from "~/types";
import { useMainStore } from "@/store/index";
const {
  customEventNames,
  cleanMenuDownload,
  downloadImgEvent,
  addFileListEvent,
  downloadUrlEvent,
  applyEvent,
  selectImgEvent,
  delImgEvent,
  updateFileListEvent
} = useCustomEvent();
const mainStore = useMainStore();
const route = useRoute();
const { t } = useI18n();
const { isLogin, isVip, isVipPro } = useUserInfo();
const fullLoading = useFullLoading();
const props = defineProps({
  fileList: {
    type: Array<EnhanceModel>,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
});

const fileModel = ref<EnhanceModel | null>(null);
const minScale = 50;
const maxScale = 250;
const baseControlsRef = ref(null);
const step = ref(10);
const historyList = ref<any>([]);
const historyIndex = ref(0);

const leftBackgStr = ref("cursor: none;");
const penSize = ref(40);
const hideCan = ref();
const hideCanTxt = ref();
const isSmear = ref(true);
const isMove = ref(false);
const scale = ref(100);
const originalScale = ref(0);
const arcXy = ref({ x: -100, y: -100, display: "" });
const rightCansTxt = ref();
const rightOriginCans = ref();
const rightOriginCansTxt = ref();
const topOriginCans = ref();
const topOriginCansTxt = ref();
const originalObj = ref();
const rightCans = ref();
const topCans = ref();
const topCansTxt = ref();
const cutoutBorderWidth = ref(0);
const cutoutBorderHeight = ref(0);
const cutoutBorderLeft = ref(0);
const cutoutBorderTop = ref(0);
const isCompare = ref(false);
const compareTimer = ref();
const mouseupTime = ref(0);
const loadImgFail = ref(false);
const lastX = ref(0);
const lastY = ref(0);
// 重试图片地址
const loadUrl = ref("");
const isInit = ref(true);
watch(
  () => props.fileList,
  () => {
    console.log("数据");
    if (props.fileList.length) {
      fileModel.value = props.fileList[0];
      if (fileModel.value.status === uploadStatus.none) {
        if (isInit.value) {
          isInit.value = false;
        loadUrl.value = fileModel.value.localUrl;
        init(fileModel.value.localUrl, true);
        }
      } else if (fileModel.value.status == uploadStatus.lodingImg) {
        initData();
        loadUrl.value = fileModel.value.enhanceUrl!;
        init(fileModel.value.enhanceUrl!);
      }
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);
watch(
  () => scale.value,
  (n: number, o: number) => {
    // console.log("scale", n);
    if (!hideCan.value) {
      return;
    }
    isMove.value
      ? 100 == n
        ? ((cutoutBorderLeft.value =
            (hideCan.value.width - (100 * hideCan.value.width) / n) / 2),
          (cutoutBorderTop.value =
            (hideCan.value.height - (100 * hideCan.value.height) / n) / 2))
        : ((cutoutBorderLeft.value =
            (cutoutBorderLeft.value * o + (hideCan.value.width * (n - o)) / 2) /
            n),
          (cutoutBorderTop.value =
            (cutoutBorderTop.value * o + (hideCan.value.height * (n - o)) / 2) /
            n))
      : ((cutoutBorderLeft.value =
          (hideCan.value.width - (100 * hideCan.value.width) / n) / 2),
        (cutoutBorderTop.value =
          (hideCan.value.height - (100 * hideCan.value.height) / n) / 2));
    cutoutBorderWidth.value = (100 * hideCan.value.width) / n;
    cutoutBorderHeight.value = (100 * hideCan.value.height) / n;
    drawCanvas();
  }
);
onMounted(() => {
  document.addEventListener("mousemove", leftCanvasMouseMove);
  window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
  window.addEventListener("keydown", handleKeyDown);
});
onUnmounted(() => {
  document.removeEventListener("mousemove", leftCanvasMouseMove);
  window.removeEventListener(
    "uploadLocalFilesSuccess",
    uploadLocalFilesSuccess
  );
  window.removeEventListener("keydown", handleKeyDown);
});
function uploadLocalFilesSuccess(e: any) {
  console.log(e.detail);
  const files = e.detail as File[];
  if (files.length) {
    isInit.value = true;
    initData();
  }
}
function initData() {
  historyIndex.value = 0;
  historyList.value = [];
  loadImgFail.value = false;
  reset();
}

function onApply() {
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  if (historyList.value.length <= 1) {
    return;
  }

  const tempCans = document.createElement("canvas") as HTMLCanvasElement;
  const tempCtx = tempCans.getContext("2d");
  tempCans.width = originalObj.value.width + hideCan.value.width;
  tempCans.height = originalObj.value.height;
  tempCtx?.drawImage(originalObj.value, 0, 0);
  tempCtx?.drawImage(hideCan.value, originalObj.value.width, 0);
  tempCans.toBlob((blob) => {
    // emit("apply",URL.createObjectURL(n))
    const fileName = Math.random() + ".png";
    if (blob) {
      // var a = document.createElement("a");
      // a.href = URL.createObjectURL(blob);
      // a.download = fileName; // 自定义保存的文件名及类型
      // a.click();
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });
      const list = [...props.fileList];
      list[0].file = file;
      list[0].status = uploadStatus.none;
      list[0].enhanceUrl = "";
      list[0].originUrl = "";
      list[0].isEvaluate = false;
      list[0].enhanceRecordId = null;
      updateFileListEvent(list)
      cleanMenuDownload()

      nextTick(() => {
        applyEvent({
              enhanceCfgIds: [62],
              isMask: 1
            })
      });
    }
  }, "image/png");
}
const handleKeyDown = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.code === "KeyZ") {
    event.preventDefault(); // 阻止默认行为（例如浏览器的撤销）
    preHistory();
  }
  if (event.ctrlKey && event.code === "KeyY") {
    event.preventDefault(); // 阻止默认行为（例如浏览器的撤销）
    nextHistory();
  }
};
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList)
}

function init(url: string, isFirst: boolean = false) {
  console.log("初始化");
  setTimeout(() => {
    console.log(123);
    rightCans.value = document.getElementById("origin-canvas-area");
    rightCansTxt.value = rightCans.value.getContext("2d");
    topCans.value = document.getElementById("top-canvas-area");
    topCansTxt.value = topCans.value.getContext("2d");
    // topCans.value?.addEventListener("wheel", wheelFun);
    document.removeEventListener("mousemove", leftCanvasMouseMove);
    document.addEventListener("mousemove", leftCanvasMouseMove);
    const e = document.getElementById("main-area")?.clientWidth || 0;
    const n = document.getElementById("main-area")?.clientHeight || 0;
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      fullLoading.hide();
      loadImgFail.value = false;
      originalObj.value = img;
      let w = e;
      let h = Math.floor((w * img.height) / img.width);
      h > n && ((h = n), (w = Math.floor((h * img.width) / img.height)));
      if (isFirst) {
        const CompareCans = document.getElementById(
          "canvas-compare"
        ) as HTMLCanvasElement;
        const compareCtx = CompareCans.getContext("2d");
        CompareCans.width = w;
        CompareCans.height = h;
        compareCtx?.clearRect(0, 0, CompareCans.width, CompareCans.height);
        compareCtx?.drawImage(img, 0, 0, CompareCans.width, CompareCans.height);
      }
      // canWidth.value = w;
      // canHeight.value = h;
      originalScale.value = img.width / w;
      rightOriginCans.value = document.createElement("canvas");
      rightOriginCansTxt.value = rightOriginCans.value.getContext("2d");
      rightOriginCans.value.width = img.width;
      rightOriginCans.value.height = img.height;
      rightOriginCansTxt.value.drawImage(
        img,
        0,
        0,
        rightOriginCans.value.width,
        rightOriginCans.value.height
      );
      rightCans.value.width = w;
      rightCans.value.height = h;

      topCans.value.width = w;
      topCans.value.height = h;
      // topCansTxt.value.fillStyle = '#ff000055'
      // topCansTxt.value.fillRect(0,0,w,h)

      topOriginCans.value = document.createElement("canvas");
      topOriginCansTxt.value = topOriginCans.value.getContext("2d");
      topOriginCans.value.width = img.width;
      topOriginCans.value.height = img.height;
      // topOriginCansTxt.value.fillStyle = "#ff000055";
      // topOriginCansTxt.value.fillRect(
      //   0,
      //   0,
      //   topOriginCans.value.width,
      //   topOriginCans.value.height
      // );

      hideCan.value = document.createElement("canvas");
      hideCanTxt.value = hideCan.value.getContext("2d");
      hideCan.value.width = img.width;
      hideCan.value.height = img.height;
      hideCanTxt.value.fillStyle = "#000";
      hideCanTxt.value.fillRect(
        0,
        0,
        hideCan.value.width,
        hideCan.value.height
      );
      cutoutBorderWidth.value = img.width;
      cutoutBorderHeight.value = img.height;
      drawCanvas();
      // changePenSize();
      if (baseControlsRef.value) {
        baseControlsRef.value?.setScale(100);
        scale.value = 100;
      }
      addToHistory();

      if (fileModel.value?.status === uploadStatus.lodingImg) {
        const list = [...props.fileList];
        list[0].status = uploadStatus.success;
        updateFileListEvent(list)
      }

      //   historyIndex.value++;
    };
    img.onerror = function () {
      // messageBox.error(t("network-err"), t("loading-err"));
      fullLoading.hide();
      console.log("img load err");
      loadImgFail.value = true;
    };
  }, 0);
}
//鼠标涂抹位置
function leftCanvasMouseMove(t: MouseEvent) {
  if (!isMove.value && isSmear.value && fileModel.value) {
    const e = document
      .getElementById("main-area")
      ?.getBoundingClientRect() as DOMRect;
    const n = document
      .getElementById("top-canvas-area")
      ?.getBoundingClientRect() as DOMRect;
    const arcXyTmp = { x: 0, y: 0, display: "" };
    arcXyTmp.x = t.clientX - e.left;
    arcXyTmp.y = t.clientY - e.top;
    arcXyTmp.display = "block";
    // console.log("leftCanvasMouseMove", arcXyTmp);

    if (
      arcXyTmp.x < n.left - e.left ||
      arcXyTmp.x > n.left + n.width - e.left ||
      arcXyTmp.y < n.top - e.top ||
      arcXyTmp.y > n.top + n.height - e.top
    ) {
      arcXyTmp.display = "none";
    }
    arcXy.value = arcXyTmp;
  }
}
async function changeSmear() {
  isSmear.value = true;
  isMove.value = false;
  leftBackgStr.value = "cursor: none;";
  // changePenSize();
  await nextTick();
}
function changeMove() {
  isSmear.value = false;
  isMove.value = true;
  leftBackgStr.value = "cursor: grab;";
}

function wheelFun(t: WheelEvent) {
  const n = t || window.event;
  n.preventDefault && n.preventDefault();
  if (
    (t.deltaY > 0 && scale.value > minScale) ||
    (t.deltaY < 0 && scale.value < maxScale)
  ) {
    //放大
    if (t.deltaY < 0) {
      scale.value += step.value;
      if (scale.value > maxScale) {
        scale.value = maxScale;
      }
    } else {
      scale.value -= step.value;
      if (scale.value < minScale) {
        scale.value = minScale;
      }
    }
    if (baseControlsRef.value) {
      baseControlsRef.value?.setScale(scale.value);
    }
    // emit("scaleChange", scale.value);
  }
}
function setScale(value: number) {
  scale.value = value;
  if (value == 100) {
  }
}
function reset() {
  scale.value = 100;
  penSize.value = 40;
  if (baseControlsRef.value) {
    baseControlsRef.value?.setScale(scale.value);
  }
}
function rightCanvasDown(t: MouseEvent) {
  isMove.value ? rightCanvasMove(t) : rightCanvasSmear2(t);
}
// 拖动
function rightCanvasMove(t: MouseEvent) {
  leftBackgStr.value = "cursor: grabbing;";
  var n = cutoutBorderLeft.value,
    r = cutoutBorderTop.value;
  document.getElementById("top-canvas-area")!.onmousemove = function (o) {
    if (100 != scale.value) {
      var h =
        (100 * (o.clientX - t.clientX) * originalScale.value) / scale.value;
      var c =
        (100 * (o.clientY - t.clientY) * originalScale.value) / scale.value;
      cutoutBorderLeft.value = n - h;
      cutoutBorderTop.value = r - c;
      isMove.value = true;
      drawCanvas();
    }
  };
  document.onmouseup = function () {
    leftBackgStr.value = "cursor: grab;";
    document.getElementById("top-canvas-area")!.onmousemove = null;
    document.onmouseup = null;
  };
}

function rightCanvasSmear2(t: MouseEvent) {
  // rightRepairCanvas 用于绘制每次的点
  // rightOriginCans 用于绘制最终显示的图像，显示在rightcans
  // hideCan 用于绘制经过的轨迹
  // originRestoreCursorCans 画笔
  // if (!isLogin.value) {
  //   mainStore.setVisibleLogin(true);
  //   return;
  // }
  if(historyIndex.value !== historyList.value.length -1){
    console.log('裁剪');
    
    historyList.value =historyList.value.slice(0,historyIndex.value+1)
  }
  console.log('长度：',historyList.value.length,historyIndex.value);
  
  topCansTxt.value.clearRect(
    0,
    0,
    topCansTxt.value.width,
    topCansTxt.value.height
  );
  const penSizeTmp =
    (penSize.value / (scale.value / 100)) * originalScale.value;
  let n = document
    .getElementById("top-canvas-area")
    ?.getBoundingClientRect() as DOMRect;
  let r =
    (100 * (t.clientX - n.left) * originalScale.value) / scale.value +
    cutoutBorderLeft.value;
  let o =
    (100 * (t.clientY - n.top) * originalScale.value) / scale.value +
    cutoutBorderTop.value;
  lastX.value = r;
  lastY.value = o;

  topCansTxt.value.beginPath();
  topCansTxt.value.moveTo(lastX.value, lastY.value);
  topCansTxt.value.lineTo(r, o);
  topCansTxt.value.lineCap = "round";
  topCansTxt.value.lineJoin = "round";
  topCansTxt.value.lineWidth = penSizeTmp;
  topCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // Brush color
  // topCansTxt.value.fillStyle = "#598eeecc"; // Brush color
  topCansTxt.value.stroke();

  topOriginCansTxt.value.beginPath();
  topOriginCansTxt.value.moveTo(lastX.value, lastY.value);
  topOriginCansTxt.value.lineTo(r, o);
  topOriginCansTxt.value.lineCap = "round";
  topOriginCansTxt.value.lineJoin = "round";
  topOriginCansTxt.value.lineWidth = penSizeTmp;
  topOriginCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // Brush color
  topOriginCansTxt.value.stroke();

  hideCanTxt.value.beginPath();
  hideCanTxt.value.moveTo(lastX.value, lastY.value);
  hideCanTxt.value.lineTo(r, o);
  hideCanTxt.value.lineCap = "round";
  hideCanTxt.value.lineJoin = "round";
  hideCanTxt.value.lineWidth = penSizeTmp;
  hideCanTxt.value.strokeStyle = "#fff"; // Brush color
  hideCanTxt.value.stroke();

  drawCanvas();
  document.getElementById("top-canvas-area")!.onmousemove = function (t) {
    r =
      (100 * (t.clientX - n.left) * originalScale.value) / scale.value +
      cutoutBorderLeft.value;
    o =
      (100 * (t.clientY - n.top) * originalScale.value) / scale.value +
      cutoutBorderTop.value;

    topCansTxt.value.beginPath();
    topCansTxt.value.lineCap = "round";
    topCansTxt.value.lineJoin = "round";
    topCansTxt.value.lineWidth = penSizeTmp;
    topCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // Brush color
    topCansTxt.value.moveTo(lastX.value, lastY.value);
    topCansTxt.value.lineTo(r, o);
    topCansTxt.value.stroke();

    topOriginCansTxt.value.beginPath();
    topOriginCansTxt.value.moveTo(lastX.value, lastY.value);
    topOriginCansTxt.value.lineTo(r, o);
    topOriginCansTxt.value.lineCap = "round";
    topOriginCansTxt.value.lineJoin = "round";
    topOriginCansTxt.value.lineWidth = penSizeTmp;
    topOriginCansTxt.value.strokeStyle = "rgba(255,113,24,0.6)"; // Brush color
    topOriginCansTxt.value.stroke();

    hideCanTxt.value.beginPath();
    hideCanTxt.value.moveTo(lastX.value, lastY.value);
    hideCanTxt.value.lineTo(r, o);
    hideCanTxt.value.lineCap = "round";
    hideCanTxt.value.lineJoin = "round";
    hideCanTxt.value.lineWidth = penSizeTmp;
    hideCanTxt.value.strokeStyle = "#fff"; // Brush color
    hideCanTxt.value.stroke();
    lastX.value = r;
    lastY.value = o;
    drawCanvas();
  };
  document.onmouseup = function () {
    document.getElementById("top-canvas-area")!.onmousemove = null;
    document.onmouseup = null;
    // 防止个别鼠标异常触发两次抬起事件
    let time = new Date().getTime();
    console.log("鼠标抬起", time);

    if (mouseupTime.value == 0 || time - mouseupTime.value > 500) {
      mouseupTime.value = time;
      //   repairImg();
      addToHistory();
      historyIndex.value++;
    }
  };
}
function drawCanvas() {
  rightCansTxt.value.clearRect(
    0,
    0,
    rightCans.value.width,
    rightCans.value.height
  );
  rightCansTxt.value.drawImage(
    rightOriginCans.value,
    cutoutBorderLeft.value,
    cutoutBorderTop.value,
    cutoutBorderWidth.value,
    cutoutBorderHeight.value,
    0,
    0,
    rightCans.value.width,
    rightCans.value.height
  );

  topCansTxt.value.clearRect(0, 0, topCans.value.width, topCans.value.height);
  topCansTxt.value.drawImage(
    topOriginCans.value,
    cutoutBorderLeft.value,
    cutoutBorderTop.value,
    cutoutBorderWidth.value,
    cutoutBorderHeight.value,
    0,
    0,
    topCans.value.width,
    topCans.value.height
  );
}
function repairImg() {
  const tempCans = document.createElement("canvas") as HTMLCanvasElement;
  const tempCtx = tempCans.getContext("2d");
  tempCans.width = originalObj.value.width + hideCan.value.width;
  tempCans.height = originalObj.value.height;
  tempCtx?.drawImage(originalObj.value, 0, 0);
  tempCtx?.drawImage(hideCan.value, originalObj.value.width, 0);
  tempCans.toBlob((blob) => {
    // emit("apply",URL.createObjectURL(n))
    const fileName = Math.random() + ".png";
    if (blob) {
      //     var a = document.createElement("a");
      //   a.href = URL.createObjectURL(blob);
      //   a.download = fileName; // 自定义保存的文件名及类型
      //   a.click();
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });

      //   onApply(file);
    }
  }, "image/png");
}
function addToHistory() {
  console.log('添加历史');
  
  const topImageData = topCansTxt.value.getImageData(
    0,
    0,
    topCans.value.width,
    topCans.value.height
  );
  const hideImageData = hideCanTxt.value.getImageData(
    0,
    0,
    hideCan.value.width,
    hideCan.value.height
  );
  const topOriginData = topOriginCansTxt.value.getImageData(
    0,
    0,
    topOriginCans.value.width,
    topOriginCans.value.height
  );
  historyList.value.push({
    topImageData,
    hideImageData,
    topOriginData
  });
}
function restoreCanvas(index: number) {
  console.log('restoreCanvas长度：',historyList.value.length,index);
  const data = historyList.value[index];
  topCansTxt.value.putImageData(data.topImageData, 0, 0);
  hideCanTxt.value.putImageData(data.hideImageData, 0, 0);
  topOriginCansTxt.value.putImageData(data.topOriginData, 0, 0);
}
function compareMousedown() {
  if ( !(props.fileList.length && props.fileList[0].enhanceUrl)) {
    return;
  }
  compareTimer.value = setTimeout(() => {
    scale.value = 100;
    baseControlsRef.value?.setScale(scale.value);
    isCompare.value = true;
    console.log("长按");
  }, 300);
}
function compareMouseup() {
  clearTimeout(compareTimer.value);
  isCompare.value = false;
  console.log("抬起");
}
/**
 * 上一张
 */
function preHistory() {
  console.log(historyIndex.value, historyList.value);
  if (historyIndex.value > 0) {
    historyIndex.value -= 1;
    restoreCanvas(historyIndex.value);
  }
}
/**
 * 下一张
 */
function nextHistory() {
  console.log(historyIndex.value, historyList.value);

  if (historyIndex.value < historyList.value.length - 1) {
    historyIndex.value += 1;
    restoreCanvas(historyIndex.value);
  }
}
/**
 * 绘制上一张图
 */
function drawLastImg(url: string) {
  const CompareCans = document.getElementById(
    "canvas-compare"
  ) as HTMLCanvasElement;
  const compareCtx = CompareCans.getContext("2d");
  const e = document.getElementById("main-area")?.clientWidth || 0;
  const n = document.getElementById("main-area")?.clientHeight || 0;
  const img = new Image();
  img.crossOrigin = "";
  img.src = url;
  img.onload = function () {
    let w = e;
    let h = Math.floor((w * img.height) / img.width);
    h > n && ((h = n), (w = Math.floor((h * img.width) / img.height)));
    CompareCans.width = w;
    CompareCans.height = h;
    compareCtx?.clearRect(0, 0, CompareCans.width, CompareCans.height);
    compareCtx?.drawImage(img, 0, 0, CompareCans.width, CompareCans.height);
  };
  img.onerror = function () {
    console.log("对比图加载异常");
  };
}

function reloadImg() {
  console.log("reloadImg");

  loadImgFail.value = false;
  fullLoading.show("", null);
  if (fileModel.value) {
    init(loadUrl.value);
  }
}
</script>
