<template>
  <div class="relative h-full w-full flex items-center justify-center">
    <div
      v-show="originVideo"
      class="relative h-2/3 max-w-[1224px] -translate-y-[20%] w-full shadow-1 bg-white"
    >
      <div v-show="preViewAbled" class="relative w-full h-full">
        <video
          ref="videoPlayer"
          object-fill="contain"
          class="absolute shadow-1 left-1/2 -translate-x-1/2"
          style="height: 100%"
          @loadedmetadata="loadedmetadata"
          @timeupdate="updateProgress"
        ></video>
        <div
          v-show="process === 1"
          class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white"
        >
          {{ $t("after") }}
        </div>
      </div>
      <div
        v-if="!preViewAbled"
        class="w-full h-full flex flex-col items-center justify-center gap-6 bg-text-deep"
      >
        <img
          src="/assets/images/enhance/video-empty.png"
          alt="empty"
          width="255"
          height="255"
        />
        <div class="max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center">
          <span v-if="process === 1"
            >{{ $t("enhance-video-nosupport-down1") }}
            <a
              @click="onDownload"
              class="underline text-mainColor cursor-pointer"
              >{{ $t("enhance-video-nosupport-down2") }}</a
            >
            {{ $t("enhance-video-nosupport-down3") }}</span
          >
          <span v-else>{{ $t("enhance-video-nosupport") }}</span>
        </div>
      </div>
    </div>
    <div v-if="!originVideo" class="flex items-center justify-center">
      <FuncTypeUpload
        class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
        :funcType="route.params.funcType"
      ></FuncTypeUpload>
    </div>
    <div
      class="absolute left-1/2 bottom-10 -translate-x-1/2 w-[888px] flex flex-col items-center gap-6"
      v-if="originVideo"
    >
      <EnhanceVideoProcessCtr
        ref="videoProcessCtrRef"
        v-if="preViewAbled && (originVideo || outputVideo)"
        @onPlay="onPlay"
        @onPause="onPause"
        @updateSeed="updateSeed"
        :totalDuration="totalDuration"
        :currentTime="currentTime"
      ></EnhanceVideoProcessCtr>
      <div
        class="bg-white shadow-6 h-[80px] rounded-[20px] px-4 flex items-center gap-8"
      >
        <div class="flex items-center gap-3">
          <img
            v-if="process === 1"
            src="/assets/images/enhance/check.svg"
            alt="check"
          />
          <span
            >{{ $t("enhance-Compressed") }}
            {{ (process * 100).toFixed(1) }}%</span
          >
          <!-- <span>{{$t('Compressed',{process:(process * 100).toFixed(1)})  }}%</span> -->
        </div>
        <div
          class="h-12 rounded-lg border border-gray-line px-3 flex items-center gap-3 bg-light-gray-bg"
        >
          <img src="/assets/images/enhance/video.svg" alt="video" />
          <span
            class="max-w-[180px] text-ellipsis overflow-hidden whitespace-nowrap Roboto-bold-14"
            >{{ truncateString(originVideo?.name ?? "", 20) }}</span
          >
          <span>{{ formatFileSize(originVideo?.size ?? 0) }}</span>
          <img
            v-if="process === 1"
            src="/assets/images/enhance/to.svg"
            alt="check"
          />
          <span v-if="process === 1" class="Roboto-bold-14 text-text-gray">{{
            formatFileSize(outputVideo?.size || 0)
          }}</span>
        </div>
        <EnhanceApply
          class="w-max"
          @click="onApply"
          :enable="applyEnabled"
        ></EnhanceApply>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUploadStore } from "@/store/upload";
import { useMainStore } from "@/store/index";
import messageBox from "@/composables/messageBox";
import { getObjectURL, formatFileSize, truncateString } from "@/utils";
const mainStore = useMainStore();
const uploadStore = useUploadStore();

const {
  public: { MODE },
} = useRuntimeConfig();
const { isLogin, isVip, isVipPro } = useUserInfo();
const { t } = useI18n();
const videoPlayer = ref<HTMLVideoElement | null>(null);
const originVideo = ref<File | null>(null);
const outputVideo = ref<File | null>(null);
const process = ref(0);
const totalDuration = ref(0);
const currentTime = ref(0);
const route = useRoute();
const applyEnabled = computed(() => {
  return originVideo.value && process.value == 0;
});
// 支持预览
const preViewAbled = ref(true);
const ffmpegRef = ref();
const outputUrl = ref("");
const videoProcessCtrRef = ref(null);
onMounted(async () => {
  const { createFFmpeg } = await import("@ffmpeg/ffmpeg");

  console.log("videos", uploadStore.videos);
  const videos = [...uploadStore.videos];
  uploadStore.setVideos([]);
  if (videos.length > 0) {
    initFileList(videos);
  }
  window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);

  const ffmpeg = createFFmpeg({
    // ffmpeg路径
    corePath: "/ffmpeg/ffmpeg-core.js",
    // 日志
    log: true,
    // 进度
    progress: ({ ratio }) => {
      process.value = ratio;
    },
  });
  ffmpegRef.value = ffmpeg;
  await ffmpeg.load();
});
onUnmounted(() => {
  uploadStore.setVideos([]);
  window.removeEventListener(
    "uploadLocalFilesSuccess",
    uploadLocalFilesSuccess
  );
});
function uploadLocalFilesSuccess(params: any) {
  console.log(params.detail);
  initFileList(params.detail);
}
function initFileList(fileList: File[]) {
  console.log("fileList", fileList);
  if (fileList.length) {
    process.value = 0;
    originVideo.value = fileList[0];
    outputVideo.value = null;
    outputUrl.value = "";
    currentTime.value = 0;
    totalDuration.value = 0;
    const suffix = originVideo.value?.name.split(".")[1].toLocaleLowerCase();
    preViewAbled.value = ["mp4", "webm"].includes(suffix);
    const videoUrl = getObjectURL(fileList[0]);
    if (videoPlayer.value) {
      videoPlayer.value.src = videoUrl;
    }
  }
}

async function onApply() {
  const { fetchFile } = await import("@ffmpeg/ffmpeg");
  if (!applyEnabled.value || !originVideo.value) {
    return;
  }
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  // pro会员专属的功能
  if (!isVipPro.value) {
    messageBox.error(null, t("errCode-300601"));
    return;
  }
  const suffix = originVideo.value?.name.split(".")[1];
  const tt = new Date().getTime();
  const outputFullName = `${tt}.${suffix}`;

  const ffmpeg = ffmpegRef.value;
  let bitRate = "2000k";
  console.log("开始压缩");
  try {
    ffmpeg.FS(
      "writeFile",
      originVideo.value.name,
      await fetchFile(originVideo.value)
    );
    if (videoPlayer.value) {
      const min =
        videoPlayer.value.videoWidth > videoPlayer.value.videoHeight
          ? videoPlayer.value.videoHeight
          : videoPlayer.value.videoWidth;
      bitRate = getResolutionCategory(min);
    }
    console.log("bitRate:", bitRate);
    // 开始压缩视频
    await ffmpeg.run(
      "-i",
      originVideo.value.name,
      "-b",
      bitRate,
      "-crf",
      "23",
      outputFullName
    );
    console.log("压缩完成");
    // 压缩所完成，   读文件  压缩后的文件名称为 put.mp4
    const data = ffmpeg.FS("readFile", outputFullName);
    // 转换压缩后的视频格式  当前为 blob 格式
    outputVideo.value = await transToFile(data, outputFullName, suffix);
    if (outputVideo.value) {
      outputUrl.value = getObjectURL(outputVideo.value);
      console.log(outputUrl.value);
      if (videoPlayer.value && preViewAbled) {
        videoPlayer.value.src = outputUrl.value;
      }
      // 更新使用次数
      // useApi.updateCount().then((result) => {
      //   useMainStore
      // });
      window.dispatchEvent(
        new CustomEvent("downloadUrlEvent", {
          detail: outputUrl.value,
        })
      );
    }
  } catch (err) {
    console.log("处理异常");
  }
}
async function transToFile(
  data: Uint8Array,
  fileFullName: string,
  fileType: string = "mp4"
) {
  console.log(data);

  // 转换bolb类型
  const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  // 这么写是因为文件转换是异步任务
  const blobToFile = async (blob: Blob, fileName: string, type: string) => {
    return new window.File([blob], fileName, { type });
  };
  return blobToFile(blob, fileFullName, getMIME(fileType));
}
function onDownload() {
  if (outputUrl.value) {
    window.dispatchEvent(
      new CustomEvent("downloadImgEvent", {
        detail: {url:outputUrl.value},
      })
    );
  }
}
function onPlay() {
  videoPlayer.value?.play();
}
function onPause() {
  videoPlayer.value?.pause();
}
function updateSeed(val: number) {
  if (videoPlayer.value) {
    videoPlayer.value.currentTime = totalDuration.value * val;
  }
}
function updateProgress() {
  if (videoPlayer.value) {
    currentTime.value = videoPlayer.value.currentTime;
  }
}
function loadedmetadata() {
  if (videoPlayer.value) {
    totalDuration.value = videoPlayer.value.duration; // 获取视频总时长
    console.log(videoPlayer.value.videoWidth, videoPlayer.value.videoHeight);
  }
}
function getResolutionCategory(resolution: number) {
  if (resolution <= 360) {
    return "400k";
  } else if (resolution <= 480) {
    return "500k";
  } else if (resolution <= 720) {
    return "1500k";
  } else if (resolution <= 1080) {
    return "3000k";
  } else if (resolution <= 1440) {
    return "10000k";
  } else if (resolution <= 2160) {
    return "20000k";
  } else if (resolution <= 4320) {
    return "40000";
  } else {
    return "3000k";
  }
}
</script>
