<template>
  <NuxtImg src="assets/images/functype/allTools/newImgs/top-white.webp" width="482" height="20" class="absolute top-0"
    loading="lazy" alt="top-w" style="z-index: 2">
  </NuxtImg>
  <Vue3Marquee :duration="80" :pauseOnHover="false" style="z-index: 1;">
    <NuxtImg v-for="(i, index) in imgArray" :key="index" :src="i.src" :width="i.width" :height="i.height" alt="Remover"
      class="mx-2" loading="lazy"></NuxtImg>
    <div class="h-[72px]"></div>
  </Vue3Marquee>
  <NuxtImg src="assets/images/functype/allTools/newImgs/bottom-white.webp" width="482" height="72" alt="bottom-w"
    loading="lazy" class="absolute bottom-0" style="z-index: 2"></NuxtImg>
  <NuxtImg src="assets/images/functype/allTools/newImgs/bottom-women.webp" width="168" height="320" alt="bottom-w"
    loading="lazy" class="absolute left-[33%] bottom-0 blur-bottom" style="z-index: 3"></NuxtImg>
</template>

<script setup>
import { Vue3Marquee } from 'vue3-marquee';
const imgArray = [
  { src: "/assets/images/functype/allTools/newImgs/Remover-1.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-2.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-3.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-4.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-5.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-6.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-7.webp", width: 160, height: 268 },
  { src: "/assets/images/functype/allTools/newImgs/Remover-8.webp", width: 160, height: 268 },
];
</script>
<style>
.blur-bottom {
  -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 10%);
  mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}
</style>