<template>
  <div
    class="h-10  hover:-translate-y-1 duration-500"

  >
  <div
  class="h-full rounded-full flex items-center justify-center relative border border-solid px-7 "

  :class="
      props.enable
        ? 'bg-mainColor-deep group cursor-pointer border-mainColor-deep'
        : 'bg-[#cec7ff] cursor-not-allowed border-mainColor-deep-10'
    "
  >
    <img src="/assets/images/header/download.svg" alt="download" width="20" height="20" loading="lazy"/>
    <div class="ml-3 flex flex-col text-white">
      <span class="Roboto-Bold-12 leading-[14px]">{{
        $t("enhance-save")
      }}</span>
      <span class="Roboto-9 leading-3">{{ $t("enhance-remove") }}</span>
    </div>
  </div>

  </div>
</template>

<script setup>
import { isObjectURL, downloadNetwork } from "@/utils/index";
import messageBox from "@/composables/messageBox";
import { useUploadStore } from "@/store/upload";

const uploadStore = useUploadStore();
const { isLogin, isVip, isVipPro } = useUserInfo();
const { locale, t } = useI18n();
const dayjs = useDayjs();
const emit = defineEmits("download");
const fullLoading = useFullLoading();
const loading = ref(false);
const props = defineProps({
  // url: {
  //   type: String,
  //   default: '',
  // },
  enable: {
    type: Boolean,
    default: false,
  },
  // customEvent:{
  //   type: Boolean,
  //   default: false,
  // }
});

function download() {
  emit("download");
}
async function onDownloadNetwork(url) {
  console.log("下载", url);
  if (await downloadNetwork(url)) {
    //     fullLoading.hide();
    //     loading.value = false;
  } else {
    messageBox.error(t("network-err"), t("network-err-try"));
  }
  fullLoading.hide();
  loading.value = false;
  // fetch(url, {
  //   mode: "cors",
  // })
  //   .then(async (res) => {
  //     const e = await res.blob();
  //     return e;
  //   })
  //   .then(async (blob) => {
  //     let url = URL.createObjectURL(blob);
  //     createLocalDownload(url);
  //     fullLoading.hide();
  //     loading.value = false;
  //   })
  //   .catch((e) => {
  //     messageBox.error(t("network-err"), t("network-err-try"));
  //     loading.value = false;
  //   });
}
</script>
