<template>
  <div class="fixed top-[40%] right-5 z-20 flex flex-col gap-5 lg:right-[5%]">
    <span class="group cursor-pointer" @click="share('twtter')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="group-hover:hidden">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E9E9EB" />
        <path
          d="M25.1708 11.875H27.9275L21.905 18.7583L28.99 28.125H23.4416L19.0966 22.4442L14.125 28.125H11.3666L17.8083 20.7625L11.0125 11.875H16.7L20.6275 17.0675L25.1708 11.875ZM24.2033 26.475H25.7308L15.8708 13.4383H14.2316L24.2033 26.475Z"
          fill="#1F1F1F" />
      </svg>
      <svg class="hidden group-hover:inline-block" width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#1F1F1F" />
        <path
          d="M25.1708 11.875H27.9275L21.905 18.7583L28.99 28.125H23.4416L19.0966 22.4442L14.125 28.125H11.3666L17.8083 20.7625L11.0125 11.875H16.7L20.6275 17.0675L25.1708 11.875ZM24.2033 26.475H25.7308L15.8708 13.4383H14.2316L24.2033 26.475Z"
          fill="#1F1F1F" />
      </svg>
    </span>
    <span class="group cursor-pointer" @click="share('fb')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="group-hover:hidden">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E9E9EB" />
        <path
          d="M24.1667 12.9167C24.1667 12.862 24.1559 12.8078 24.135 12.7573C24.114 12.7067 24.0833 12.6608 24.0446 12.6221C24.0059 12.5834 23.96 12.5527 23.9095 12.5318C23.8589 12.5108 23.8047 12.5 23.75 12.5H21.6667C20.6176 12.4478 19.5905 12.8129 18.8098 13.5155C18.029 14.2182 17.5582 15.2013 17.5 16.25V18.5H15.4167C15.3062 18.5 15.2002 18.5439 15.122 18.6221C15.0439 18.7002 15 18.8062 15 18.9167V21.0834C15 21.1939 15.0439 21.2999 15.122 21.378C15.2002 21.4561 15.3062 21.5 15.4167 21.5H17.5V27.0834C17.5 27.1939 17.5439 27.2999 17.622 27.378C17.7002 27.4561 17.8062 27.5 17.9167 27.5H20.4167C20.4714 27.5 20.5256 27.4893 20.5761 27.4683C20.6267 27.4474 20.6726 27.4167 20.7113 27.378C20.75 27.3393 20.7807 27.2934 20.8016 27.2428C20.8226 27.1923 20.8333 27.1381 20.8333 27.0834V21.5H23.0167C23.1093 21.5014 23.1998 21.4718 23.2737 21.4159C23.3477 21.3601 23.4009 21.2812 23.425 21.1917L24.025 19.025C24.0416 18.9635 24.0438 18.8989 24.0315 18.8364C24.0192 18.7738 23.9927 18.7149 23.9541 18.6642C23.9155 18.6135 23.8657 18.5723 23.8086 18.5438C23.7516 18.5154 23.6888 18.5004 23.625 18.5H20.8333V16.25C20.8541 16.0438 20.9509 15.8526 21.105 15.714C21.2591 15.5753 21.4594 15.499 21.6667 15.5H23.75C23.8605 15.5 23.9665 15.4561 24.0446 15.378C24.1228 15.2999 24.1667 15.1939 24.1667 15.0834V12.9167Z"
          fill="#1F1F1F" />
      </svg>
      <svg class="hidden group-hover:inline-block" width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#1F1F1F" />
        <path
          d="M24.1667 12.9167C24.1667 12.862 24.1559 12.8078 24.135 12.7573C24.114 12.7067 24.0833 12.6608 24.0446 12.6221C24.0059 12.5834 23.96 12.5527 23.9095 12.5318C23.8589 12.5108 23.8047 12.5 23.75 12.5H21.6667C20.6176 12.4478 19.5905 12.8129 18.8098 13.5155C18.029 14.2182 17.5582 15.2013 17.5 16.25V18.5H15.4167C15.3062 18.5 15.2002 18.5439 15.122 18.6221C15.0439 18.7002 15 18.8062 15 18.9167V21.0834C15 21.1939 15.0439 21.2999 15.122 21.378C15.2002 21.4561 15.3062 21.5 15.4167 21.5H17.5V27.0834C17.5 27.1939 17.5439 27.2999 17.622 27.378C17.7002 27.4561 17.8062 27.5 17.9167 27.5H20.4167C20.4714 27.5 20.5256 27.4893 20.5761 27.4683C20.6267 27.4474 20.6726 27.4167 20.7113 27.378C20.75 27.3393 20.7807 27.2934 20.8016 27.2428C20.8226 27.1923 20.8333 27.1381 20.8333 27.0834V21.5H23.0167C23.1093 21.5014 23.1998 21.4718 23.2737 21.4159C23.3477 21.3601 23.4009 21.2812 23.425 21.1917L24.025 19.025C24.0416 18.9635 24.0438 18.8989 24.0315 18.8364C24.0192 18.7738 23.9927 18.7149 23.9541 18.6642C23.9155 18.6135 23.8657 18.5723 23.8086 18.5438C23.7516 18.5154 23.6888 18.5004 23.625 18.5H20.8333V16.25C20.8541 16.0438 20.9509 15.8526 21.105 15.714C21.2591 15.5753 21.4594 15.499 21.6667 15.5H23.75C23.8605 15.5 23.9665 15.4561 24.0446 15.378C24.1228 15.2999 24.1667 15.1939 24.1667 15.0834V12.9167Z"
          fill="#1F1F1F" />
      </svg>
    </span>
    <span class="group cursor-pointer" @click="share('LinkedIn')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="group-hover:hidden">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E9E9EB" />
        <path
          d="M15.7833 14.1665C15.7831 14.6086 15.6073 15.0324 15.2945 15.3448C14.9818 15.6572 14.5578 15.8326 14.1158 15.8324C13.6738 15.8321 13.2499 15.6563 12.9375 15.3436C12.6251 15.0309 12.4497 14.6069 12.45 14.1649C12.4502 13.7228 12.626 13.299 12.9387 12.9866C13.2514 12.6742 13.6754 12.4988 14.1175 12.499C14.5595 12.4992 14.9833 12.6751 15.2957 12.9878C15.6081 13.3005 15.7835 13.7245 15.7833 14.1665ZM15.8333 17.0665H12.5V27.4999H15.8333V17.0665ZM21.1 17.0665H17.7833V27.4999H21.0666V22.0249C21.0666 18.9749 25.0416 18.6915 25.0416 22.0249V27.4999H28.3333V20.8915C28.3333 15.7499 22.45 15.9415 21.0666 18.4665L21.1 17.0665Z"
          fill="#1F1F1F" />
      </svg>
      <svg class="hidden group-hover:inline-block" width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#1F1F1F" />
        <path
          d="M15.7833 14.1665C15.7831 14.6086 15.6073 15.0324 15.2945 15.3448C14.9818 15.6572 14.5578 15.8326 14.1158 15.8324C13.6738 15.8321 13.2499 15.6563 12.9375 15.3436C12.6251 15.0309 12.4497 14.6069 12.45 14.1649C12.4502 13.7228 12.626 13.299 12.9387 12.9866C13.2514 12.6742 13.6754 12.4988 14.1175 12.499C14.5595 12.4992 14.9833 12.6751 15.2957 12.9878C15.6081 13.3005 15.7835 13.7245 15.7833 14.1665ZM15.8333 17.0665H12.5V27.4999H15.8333V17.0665ZM21.1 17.0665H17.7833V27.4999H21.0666V22.0249C21.0666 18.9749 25.0416 18.6915 25.0416 22.0249V27.4999H28.3333V20.8915C28.3333 15.7499 22.45 15.9415 21.0666 18.4665L21.1 17.0665Z"
          fill="#1F1F1F" />
      </svg>
    </span>
    <span class="group cursor-pointer" @click="share('Reddit')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="group-hover:hidden">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E9E9EB" />
        <path
          d="M20 16C22.648 16 25.028 16.826 26.675 18.14C27.2384 17.9425 27.8539 17.9532 28.4102 18.1701C28.9664 18.3869 29.4267 18.7958 29.7077 19.3225C29.9888 19.8493 30.072 20.4592 29.9424 21.042C29.8127 21.6248 29.4788 22.142 29.001 22.5C29.001 26.09 24.971 29 20.001 29C15.126 29 11.156 26.2 11.001 22.706L10.001 22.5C9.52319 22.142 9.18927 21.6248 9.05964 21.042C8.93001 20.4592 9.01324 19.8493 9.29426 19.3225C9.57529 18.7958 10.0356 18.3869 10.5918 18.1701C11.1481 17.9532 11.7636 17.9425 12.327 18.14C13.973 16.827 16.353 16 19.001 16H20ZM20 16L21 11L27 12"
          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M26 12C26 12.2652 26.1054 12.5196 26.2929 12.7071C26.4804 12.8946 26.7348 13 27 13C27.2652 13 27.5196 12.8946 27.7071 12.7071C27.8946 12.5196 28 12.2652 28 12C28 11.7348 27.8946 11.4804 27.7071 11.2929C27.5196 11.1054 27.2652 11 27 11C26.7348 11 26.4804 11.1054 26.2929 11.2929C26.1054 11.4804 26 11.7348 26 12Z"
          fill="#1F1F1F" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M17 21.5C17.2761 21.5 17.5 21.2761 17.5 21C17.5 20.7239 17.2761 20.5 17 20.5C16.7239 20.5 16.5 20.7239 16.5 21C16.5 21.2761 16.7239 21.5 17 21.5Z"
          fill="black" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M23 21.5C23.2761 21.5 23.5 21.2761 23.5 21C23.5 20.7239 23.2761 20.5 23 20.5C22.7239 20.5 22.5 20.7239 22.5 21C22.5 21.2761 22.7239 21.5 23 21.5Z"
          fill="black" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18 25C18.667 25.333 19.333 25.5 20 25.5C20.667 25.5 21.333 25.333 22 25" stroke="black"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg class="hidden group-hover:inline-block" width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#1F1F1F" />
        <path
          d="M20 16C22.648 16 25.028 16.826 26.675 18.14C27.2384 17.9425 27.8539 17.9532 28.4102 18.1701C28.9664 18.3869 29.4267 18.7958 29.7077 19.3225C29.9888 19.8493 30.072 20.4592 29.9424 21.042C29.8127 21.6248 29.4788 22.142 29.001 22.5C29.001 26.09 24.971 29 20.001 29C15.126 29 11.156 26.2 11.001 22.706L10.001 22.5C9.52319 22.142 9.18927 21.6248 9.05964 21.042C8.93001 20.4592 9.01324 19.8493 9.29426 19.3225C9.57529 18.7958 10.0356 18.3869 10.5918 18.1701C11.1481 17.9532 11.7636 17.9425 12.327 18.14C13.973 16.827 16.353 16 19.001 16H20ZM20 16L21 11L27 12"
          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M26 12C26 12.2652 26.1054 12.5196 26.2929 12.7071C26.4804 12.8946 26.7348 13 27 13C27.2652 13 27.5196 12.8946 27.7071 12.7071C27.8946 12.5196 28 12.2652 28 12C28 11.7348 27.8946 11.4804 27.7071 11.2929C27.5196 11.1054 27.2652 11 27 11C26.7348 11 26.4804 11.1054 26.2929 11.2929C26.1054 11.4804 26 11.7348 26 12Z"
          fill="#1F1F1F" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M17 21.5C17.2761 21.5 17.5 21.2761 17.5 21C17.5 20.7239 17.2761 20.5 17 20.5C16.7239 20.5 16.5 20.7239 16.5 21C16.5 21.2761 16.7239 21.5 17 21.5Z"
          fill="black" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M23 21.5C23.2761 21.5 23.5 21.2761 23.5 21C23.5 20.7239 23.2761 20.5 23 20.5C22.7239 20.5 22.5 20.7239 22.5 21C22.5 21.2761 22.7239 21.5 23 21.5Z"
          fill="black" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18 25C18.667 25.333 19.333 25.5 20 25.5C20.667 25.5 21.333 25.333 22 25" stroke="black"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
    <span class="group cursor-pointer mt-6" @click="GoTop()">
      <div class="flex items-center content-center justify-center w-[39px] h-[39px] rounded-lg border bg-white">
        <icon-up />
      </div>
    </span>
  </div>
</template>
<script setup>
const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
});
function share(type) {
  let text = props.text || "Picma Studio";
  var url = props.url || window.location.href;
  let hashtags = "PicMa Studio";
  let intentUrl = "";

  if (type === "twtter") {
    useLogEvent({ eventName: "share-twtter" });
    intentUrl =
      "https://twitter.com/intent/tweet?url=" +
      encodeURIComponent(url) +
      "&text=" +
      encodeURIComponent(text) +
      "&hashtags=" +
      encodeURIComponent(hashtags);
  } else if (type === "fb") {
    useLogEvent({ eventName: "share-facebook" });
    intentUrl =
      `http://www.facebook.com/sharer/sharer.php?u= ` + encodeURIComponent(url);
  } else if (type === "LinkedIn") {
    useLogEvent({ eventName: "share-LinkedIn" });
    intentUrl =
      `https://www.linkedin.com/shareArticle?mini=true&url=` +
      encodeURIComponent(url) +
      "&title=" +
      text;
    // intentUrl = `https://www.linkedin.com/sharing/share-offsite/?url=`+encodeURIComponent(url)
  } else if (type === "Reddit") {
    useLogEvent({ eventName: "share-Reddit" });
    intentUrl =
      `http://www.reddit.com/submit?url=` +
      encodeURIComponent(url) +
      "&title=" +
      text;
  }
  window.open(intentUrl, "_blank", "width=650,height=650");
}
function GoTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
</script>

<style lang="scss">
.share-list {
  position: fixed;
  right: 16px;
  top: 40%;
  z-index: 20;

  span {
    display: block;
    margin-bottom: 2px;
    color: #333;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #6b57ff;
    }
  }
}
</style>
