<template>
  <div
    class="relative h-full bg-white transition-all duration-1000"
    :class="isFold ? 'w-[312px]' : 'w-0'"
  >
    <slot></slot>
    <div
      class="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-full w-8 h-[126px] flex items-center justify-center bg-white rounded-tl-2xl rounded-bl-2xl cursor-pointer"
      @click="change"
    >
      <img
        src="/assets/images/enhance/fold.svg"
        alt="fold"
        width="20"
        height="20"
        :class="isFold ? '' : 'rotate-180'"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
    isFold: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['change'])
// const isFold = ref(true);
// onMounted(() => {
//   isFold.value = props.initFold;
// });
const change = ()=>{
    emit("change")
}
</script>
