<template>
  <div
    class="h-12 px-4 gap-4 items-center justify-end flex fadeOut bg-[#1F1F1FB2] rounded-lg"
  >
    <svg
      @click="onGood"
      class="cursor-pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.09465 10.3366C2.04385 9.75235 2.50442 9.25 3.09089 9.25H5.00016C5.55246 9.25 6.00016 9.6977 6.00016 10.25V19.75C6.00016 20.3023 5.55246 20.75 5.00016 20.75H3.91698C3.39827 20.75 2.96567 20.3534 2.92074 19.8367L2.09465 10.3366Z"
          fill="white"
          stroke="white"
          style="fill: white; fill-opacity: 1; stroke: white; stroke-opacity: 1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 9.93725C9 9.5194 9.2597 9.1454 9.63765 8.96725C10.4619 8.5787 11.8665 7.7969 12.5 6.74025C13.3166 5.37845 13.4706 2.91798 13.4956 2.35439C13.4991 2.2755 13.4969 2.19651 13.5077 2.11828C13.6431 1.14223 15.5272 2.28246 16.25 3.48806C16.6423 4.14236 16.6926 5.002 16.6514 5.67315C16.6072 6.39125 16.3967 7.08495 16.1901 7.77415L15.75 9.24225H21.1785C21.8416 9.24225 22.3211 9.8759 22.1408 10.514L19.4557 20.0218C19.334 20.4525 18.9409 20.75 18.4933 20.75H10C9.4477 20.75 9 20.3023 9 19.75V9.93725Z"
          fill="white"
          stroke="white"
          style="fill: white; fill-opacity: 1; stroke: white; stroke-opacity: 1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
    <div class="w-px h-6 bg-gray-line opacity-30"></div>
    <svg
      @click="onBad"
      class="cursor-pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.09465 13.6634C2.04385 14.2477 2.50442 14.75 3.09089 14.75H5.00016C5.55246 14.75 6.00016 14.3023 6.00016 13.75V4.25C6.00016 3.6977 5.55246 3.25 5.00016 3.25H3.91698C3.39827 3.25 2.96567 3.6466 2.92074 4.16335L2.09465 13.6634Z"
          fill="white"
          stroke="white"
          style="fill: white; fill-opacity: 1; stroke: white; stroke-opacity: 1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14.0627C9 14.4806 9.2597 14.8546 9.63765 15.0327C10.4619 15.4213 11.8665 16.2031 12.5 17.2597C13.3166 18.6215 13.4706 21.082 13.4956 21.6456C13.4991 21.7245 13.4969 21.8035 13.5077 21.8817C13.6431 22.8578 15.5272 21.7175 16.25 20.5119C16.6423 19.8576 16.6926 18.998 16.6514 18.3268C16.6072 17.6087 16.3967 16.915 16.1901 16.2258L15.75 14.7577H21.1785C21.8416 14.7577 22.3211 14.1241 22.1408 13.486L19.4557 3.9782C19.334 3.54745 18.9409 3.25 18.4933 3.25H10C9.4477 3.25 9 3.6977 9 4.25V14.0627Z"
          fill="white"
          stroke="white"
          style="fill: white; fill-opacity: 1; stroke: white; stroke-opacity: 1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
const emit = defineEmits("onEvaluate");
const props = defineProps({
  id: {
    type: Number,
    default: null,
  },
});
function onBad() {
  imgEvaluate(2);
}
function onGood() {
  imgEvaluate(1);
}
function imgEvaluate(type) {
  if (!props.id) return;
  useApi
    .imgEvaluate({ enhanceRecordId: props.id, evaluate: type })
    .then((result) => {
      if (result.code == 0) {
        emit("onEvaluate");
      }
    });
}
</script>
<style lang="scss"></style>
