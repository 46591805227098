<template>
    <div id="ai" class="bg_expore relative">
        <div class="font-Montserrat flex  font-bold text-5xl pt-24 mt-14 text-white justify-center">
            <div>
                <NuxtImg class="transform -translate-y-[40px] -translate-x-[30px] w-12 h-12"
                    src="/assets/images/home/Start_1.png" :alt="$t('AI_Enhance')" loading="lazy" width="48"
                    heigth="48" />
            </div>
            <div>
                {{ $t("Index-Title2-Des-1") }}
            </div>
            <div>
                <NuxtImg class="translate-x-[30px] translate-y-[40px] w-6 h-6 rotate-45"
                    src="/assets/images/home/Start_1.png" :alt="$t('AI_Enhance')" loading="lazy" width="24"
                    heigth="24" />
            </div>
        </div>
        <div class="text-base font-normal text-white mt-10">

            {{ $t("Index-Title2-Des-2") }}
            <NuxtImg class="absolute transform bottom-2/4 left-1/2 -translate-x-[450px] translate-y-[150px] w-32 h-32 "
                src="/assets/images/home/Start_2.png" :alt="$t('AI_Enhance')" loading="lazy" width="128" heigth="128" />
        </div>
        <div class="relative">
            <div class="absolute w-full h-full mt-56" ref="imageContainer">
                <NuxtImg style="z-index: 1;"
                    class="h-[390px] mt-64 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    src="/assets/images/home/book.webp" :alt="$t('AI_Enhance')" loading="lazy" heigth="390" />
                <NuxtImg style="z-index: 2;"
                    class="h-[340px] mx-auto absolute top-1/2 left-1/2 transform translate-x-[200px] translate-y-1/2 floating-effect1"
                    src="/assets/images/home/phone.webp" :alt="$t('AI_Enhance')" loading="lazy" heigth="80" />
                <img style="z-index: 2" id="floating-img" @animationend="handleAnimationEnd" :class="{
                    'animate-spread-out': isInView && !animationEnded,
                    'floating-effect': animationEnded
                }" class="h-auto mt-64 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    src="/assets/images/home/icon_1.webp" :alt="$t('AI_Enhance')" height="290" loading="lazy" />
                <NuxtImg
                    class="h-[500px] w-full mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20"
                    src="/assets/images/home/Vector_2.webp" height="500" :alt="$t('AI_Enhance')" loading="lazy" />
            </div>
            <div class="mt-20 absolute w-full h-full z-10 flex flex-col items-center justify-center">
                <div class="flex ml-20">
                    <NuxtLink @click="onClickBtn('startfree')" :to="localePath({ name: 'master_center' })">
                        <button @mouseenter="floatFunction(0)" @mouseleave="floatFunction(1)"
                            class="max-w-fit min-w-64 hover:scale-105 h-[64px] bg-white rounded-full Roboto-Bold-18 content-center items-center gradient px-4 mt-20 z-10 shadow-lg">
                            <div class="text-button Roboto-Bold-20 flex items-center justify-center px-4">
                                {{ $t("Index_Btn_StartFree") }}
                                <NuxtImg class="w-4 h-4 ml-2" :class="{ 'arrow-move': isMoving }"
                                    src="/assets/images/home/arrow_1.png" width="16" height="16" :alt="$t('AI_Enhance')"
                                    loading="lazy" />
                            </div>
                        </button>
                    </NuxtLink>
                    <NuxtImg class="w-20 h-20 transform translate-y-1/2" src="/assets/images/home/arrow.png"
                        :alt="$t('AI_Enhance')" loading="lazy" width="80" heigth="80" />
                </div>
                <div class="mt-16 z-10">
                    <a href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406" target="_blank"
                        @click="onClickBtn('appstore')">
                        <button
                            class="rounded-full bg-black text-2xl content-center items-center text-white hover:scale-110 z-10">
                            <NuxtImg class="rounded-full w-[200px]  mx-auto" src="/assets/images/index/appStore.webp"
                                width="200" height="62" :alt="$t('AI_Enhance')" loading="lazy" />
                        </button>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma" target="_blank"
                        @click="onClickBtn('google')">
                        <button
                            class="ml-10 rounded-full bg-black text-2xl content-center items-center text-white hover:scale-110 z-10">
                            <NuxtImg class="rounded-full w-[200px]  mx-auto" width="200" height="62"
                                src="/assets/images/index/googlePlay.webp" :alt="$t('AI_Enhance')" loading="lazy" />
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n();
const localePath = useLocalePath();
const animationEnded = ref(false);
const isInView = ref(false);
const isMoving = ref(false);
const imageContainer = ref(null);

// 监听滚动
const handleScroll = () => {
    if (!imageContainer.value) return;
    const rect = imageContainer.value.getBoundingClientRect();
    isInView.value = rect.top < window.innerHeight && rect.bottom > 0;
};


const handleAnimationEnd = () => {
    animationEnded.value = true; // 标记动画已结束
    isInView.value = false; // 移除动画类名
};

function floatFunction(type) {
    if (type == 0) {
        isMoving.value = true;
    } else if (type == 1) {
        isMoving.value = false;
    }
}

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
});

function onClickBtn(params) {
    useLogEvent({ eventName: `index-${params}` });
}
</script>

<style scoped>
.bg_expore {
    background-image: linear-gradient(to bottom,
            #4A2DFF 0%,
            #ffffff 100%);
    width: 100%;
    height: 872px;
    background-size: cover;
    /* 背景图片覆盖整个区域 */
    background-position: center;
}

.text-button {
    background-image: linear-gradient(to right, #4a2dff, #ca4dff);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: Roboto-Bold;
    font-weight: bold;
}

.floating-effect {
    animation: floatings 2s ease-in-out infinite;
}

/* 浮动效果的动画 */
@keyframes floatings {

    0%,
    100% {
        transform: translate(-50%, -50%);
    }

    50% {
        transform: translate(-50%, -60%);
        /* 上下浮动 */
    }
}

.animate-spread-out {
    animation: spread-out 0.8s ease-out forwards;
}

@keyframes spread-out {
    0% {
        transform: translate(-50%, -100%) scale(0);
        opacity: 0.01;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

.floating-effect1 {
    margin-left: 200px;
    margin-top: 200px;
    animation: floatings1 2s ease-in-out infinite;
    /* 无限循环浮动 */
}

/* 浮动效果的动画 */
@keyframes floatings1 {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(5px, 0);
    }
}
</style>