<template>
  <div
    class="w-full h-full flex items-center justify-center bg-no-repeat bg-center bg-contain"
    :style="`background-image: url(${fileModel.preImage});`"
  >
    <div
      class="w-full h-full absolute flex items-center justify-center flex-col bg-[#00000088]"
    >
      <Loading v-show="tryCount < 3" />
      <p
        class="mt-10 text-white max-w-[50%] text-center text-xs"
        v-html="$t('enhance-process')"
        v-show="fileModel.status == uploadStatus.handle"
      ></p>
      <p
        class="mt-10 text-white max-w-[50%] text-center text-xs"
        v-if="fileModel.status == uploadStatus.lodingImg && tryCount >= 3"
      >
        {{ $t("loading-err")
        }}<a @click="reloadImg" class="cursor-pointer underline ml-1 text-text-gray">
          {{ $t("reload") }}?</a
        >
      </p>
      <img
        v-if="tryFlag && fileModel.status == uploadStatus.lodingImg"
        ref="preLoadRef"
        id="preLoadImg"
        class="hidden"
        :src="
          isVip
            ? fileModel.enhanceUrl
            : fileModel.watermark || fileModel.enhanceUrl
        "
        @load="load"
        alt="temp"
        @error="enhanceError"
      />
    </div>
  </div>
</template>
<script setup>
const { isVip } = useUserInfo();
const props = defineProps({
  fileModel: {
    type: Object,
    default: {},
  },
});
const tryCount = ref(0);
const tryFlag = ref(true);
const preLoadRef = ref()
const emit = defineEmits(['load'])
function enhanceError(error) {
  // console.log("加载错误", error);
  if (tryCount.value < 3) {
    tryCount.value = tryCount.value + 1;
    console.log("加载失败，重试", tryCount.value);
    tryFlag.value = false;
    setTimeout(() => {
      tryFlag.value = true;
    }, 1000);
  }
}
function reloadImg() {
  tryCount.value = 0;
  enhanceError();
}
function load() {
  console.log('加载成功');
  emit('load');
}
</script>
