<template>
  <div
    class="h-9 bg-mainColor-deep px-2 flex items-center gap-1 rounded-full text-white cursor-pointer hover:-translate-y-1 duration-500"
    @click="onClick"
  >
    <img
      src="/assets/images/header/start.svg"
      alt="start"
      width="20"
      height="20"
      class="mt-[2px]"
    />
    <span class="mr-2 leading-4 Roboto-Bold-14">{{ weekCount }}</span>
    <img
      src="/assets/images/header/add.svg"
      alt="start"
      width="20"
      height="20"
    />
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
const { isNormalVip } = useUserInfo();
const mainStore = useMainStore();
const weekCount = computed(() => {
  if (isNormalVip.value) {
    return mainStore.weekCount > 40 ? 0 : 40 - mainStore.weekCount;
  }
  if (mainStore.userInfo) {
    const num =10 - (mainStore.userInfo.removalCount ?? 0);
    return num<=0?0:num;
  }
  return 0;
});
const emit = defineEmits(["onClick"]);

function onClick() {
  emit("onClick");
  mainStore.setVisibleVipDialog(true);
}
</script>
