export const getFileSuffix = (name: string) => {
  return name.substring(name.lastIndexOf(".") + 1).toLowerCase();
};
export const getFileName = (name: string) => {
  return name.substring(0, name.lastIndexOf("."));
};

export const drawWaterMark = (
  imgUrl: string,
  str: Array<string> = ["PicMa"],
  density: number = 4
) => {
  // 创建一个Image对象
  var img = new Image();

  // 设置Image对象的src属性
  img.src = imgUrl;
  img.setAttribute("crossOrigin", "Anonymous");
  // 创建一个Promise对象
  var promise = new Promise(function (resolve, reject) {
    // 图片加载完成后执行
    img.onload = function () {
      // 将base64编码作为Promise对象的返回值
      resolve({
        base64: addWaterMark(img, str, density),
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    // 图片加载失败后执行
    img.onerror = function () {
      reject(new Error("image load failed!"));
    };
  });

  return promise;
};
export const addWaterMark = (
  img: HTMLImageElement,
  str: Array<string> = ["PicMa"],
  density: number = 6,
  fontSize: number = 12
) => {
  const canvas = <HTMLCanvasElement>document.createElement("canvas");

  // 设置Canvas的宽高为图片的真实尺寸
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  console.log(canvas.width, canvas.height);
  // 将图片绘制到Canvas上
  const ctx = <CanvasRenderingContext2D>canvas.getContext("2d");
  if (ctx === null) return;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  // var rotate = -45;
  //绘制水印
  //   if (objmsg.rotate != undefined && objmsg.rotate != null) {//旋转角度[默认20]
  //     ctx.rotate((Math.PI / 120) * -objmsg.rotate);
  // } else {
  //     ctx.rotate((Math.PI / 120) * -20);
  // }
  // ctx.rotate((Math.PI / 180) * rotate);
  // var fontSize = 14;

  let imgWidth = canvas.width;
  let imgHeight = canvas.height;
  if (imgWidth >= 3456) {
    fontSize = 190;
  } else if (imgWidth >= 2700) {
    fontSize = 140;
  } else if (imgWidth >= 2000) {
    //
    fontSize = 110;
  } else if (imgWidth >= 1436) {
    fontSize = 90;
    density = 5;
  } else if (imgWidth >= 1080) {
    //
    fontSize = 50;
  } else if (imgWidth >= 800) {
    fontSize = 40;
  } else if (imgWidth >= 500) {
    //
    fontSize = 34;
  } else {
    fontSize = 14;
  }
  // console.log("fontSize", fontSize);
  // if (objmsg.fontsize != undefined && objmsg.fontsize != null) {//字体大小[默认20px]
  //     fontsize = objmsg.fontsize;
  // }
  ctx.font = fontSize + "px Roboto";
  var fontcolor = "255, 255, 255, 0.2";
  // if (objmsg.fontcolor != undefined && objmsg.fontcolor != null) {//字体颜色透明度[默认白色]
  //     fontcolor = objmsg.fontcolor;
  // }
  ctx.fillStyle = "rgba(" + fontcolor + ")";
  ctx.textAlign = "left";
  ctx.textBaseline = "middle";
  // var density = 3;
  // if (objmsg.density != undefined && objmsg.density != null) {//稠密度[默认3]
  //     density = objmsg.density
  // }
  // for (var i = -2000; i < img.height; i += img.width / density) {
  //   for (var k = 0; k < img.height; k += img.width / density) {
  //     // var str = objmsg.str;
  //     if (str.length == 1) {
  //       ctx.fillText(str[0], i, k);
  //     } else if (str.length == 2) {
  //       ctx.fillText(str[0], i, k);
  //       ctx.fillText(str[1], i, k + (fontSize - 0 + 5)); //多行
  //     } else if (str.length == 3 || str.length > 3) {
  //       ctx.fillText(str[0], i, k);
  //       ctx.fillText(str[1], i, k + (fontSize - 0 + 5)); //多行
  //       ctx.fillText(str[2], i, k + (fontSize * 2 - 0 + 5)); //多行
  //     }
  //   }
  // }

  //文字坐标

  const maxPx = Math.max(imgWidth, imgHeight);

  const stepPx = Math.floor(maxPx / density);

  let arrayX = [0]; //初始水印位置 canvas坐标 0 0 点
  while (arrayX[arrayX.length - 1] < maxPx / 2) {
    arrayX.push(arrayX[arrayX.length - 1] + stepPx);
  }
  arrayX.push(
    ...arrayX.slice(1, arrayX.length).map((el) => {
      return -el;
    })
  );

  // console.log(arrayX);

  for (let i = 0; i < arrayX.length; i++) {
    for (let j = 0; j < arrayX.length; j++) {
      ctx.save();
      ctx.translate(imgWidth / 2, imgHeight / 2); ///画布旋转原点 移到 图片中心
      ctx.rotate(-Math.PI / 5);
      if (str.length > 3) {
        str = str.slice(0, 3);
      }
      str.forEach((el, index) => {
        let offsetY = fontSize * index + 2;
        ctx.fillText(el, arrayX[i], arrayX[j] + offsetY);
      });
      ctx.restore();
    }
  }

  // 获取图片的base64编码
  return ctx.canvas.toDataURL("image/png");
};
// 获取本地地址
export const getObjectURL = (file: File): string => {
  let url = "";
  window.URL = window.URL || window.webkitURL;
  if (window.URL) {
    url = window.URL.createObjectURL(file);
  } else {
    url = URL.createObjectURL(file);
  }
  return url;
};
export const createLocalDownload = (url: string,suffix:string) => {
  if (!url) return;
  const dayjs = useDayjs();
  // let suffix = url.split(".")[1];
  const tt = dayjs().format("YYYYMMDDHHmmss");

  let name = "Picma_" + tt;
  if (suffix) {
    name += "" + suffix;
  }
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  if(url.includes('blob:')){
    URL.revokeObjectURL(a.href);
  }

  // fullLoading.hide();
  // loading.value = false;
  // downActived.value = false;
  setTimeout(() => {
    a.remove();
  }, 2000);
};
export const isObjectURL = (url: string) => {
  try {
    // 使用 URL 对象解析 URL
    const parsedUrl = new URL(url);
    // 检查协议是否为 blob:
    return parsedUrl.protocol === "blob:";
  } catch (e) {
    // 如果 URL 解析失败，则不是一个有效的 URL
    return false;
  }
};
export const downloadNetwork = (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      mode: "cors",
    })
      .then(async (res) => {
        const e = await res.blob();
        return e;
      })
      .then(async (blob) => {
        let link = URL.createObjectURL(blob);
        let suffix = ''
        if(url.includes('?')) {
          let path = url.split("?")[0]
          suffix = path.substring(path.lastIndexOf('.'))
        }else {
          if(url.includes('@1m')){
            suffix = url.substring(url.lastIndexOf('.'),url.lastIndexOf('@1m'))
          }else {
          suffix = url.substring(url.lastIndexOf('.'))
          }
        }
        createLocalDownload(link,suffix);
        resolve(true);
      })
      .catch((e) => {
        reject(false);
      });
  });
};
// 类型转换 blob 转换 file
export const transToFile = (
  data: Uint8Array,
  fileFullName: string,
  fileType: string = "mp4"
) => {
  console.log(data);
  const file: File[] = [];
  // 转换bolb类型
  const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  // 这么写是因为文件转换是异步任务
  const blobToFile = async (blob: Blob, fileName: string, type: string) => {
    return new window.File([blob], fileName, { type });
  };
  const textContain = blobToFile(blob, fileFullName, getMIME(fileType));
  // 转换完成后可以将file对象传给接口
  textContain.then((res) => {
    file.push(res);
    console.log("res", res);
  });
  return file;
};
export const getMIME = (fileType: string): string => {
  const type = fileType.toLocaleLowerCase();
  switch (type) {
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "mov":
      return "video/quicktime";
    case "avi":
      return "video/x-msvideo";
    default:
      return "";
  }
};
// 格式化文件大小
export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 B";
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const formattedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2)); // 保留两位小数
  return `${formattedSize} ${sizes[i]}`;
};
export const truncateString = (input: string, maxLength: number): string => {
  // 如果字符串长度没有超过限制，直接返回
  if (input.length <= maxLength) {
    return input;
  }

  // 计算保留的字符
  const start = input.slice(0, 4); // 保留前3位
  const end = input.slice(-6); // 保留后6位

  // 返回拼接后的字符串
  return `${start}...${end}`;
};
export const funcTypeAcceptList = (funcType: string): string[] => {
  switch (funcType) {
    case "video_enhance":
    case "video_background_remover":
    case "video_compressor":
    case "video_converter":
      return ["mp4", "mov", "avi", "webm"];
    case "image_converter":
      return ["bmp", "jpeg", "jpg", "png"];
      // return ["bmp", "jpeg", "jpg", "png", "tiff"];
    default:
      break;
  }
  return ["bmp", "jpeg", "jpg", "png"];
};
