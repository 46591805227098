<template>
  <Dialog visible @close="onClose">
    <div class="login">
      <img class="logo" src="@/assets/images/logo-icon.png" alt="logo" />
      <img
        class="logo-txt"
        src="@/assets/images/logo-text-white.png"
        alt="white logo"
      />
      <div class="iv-form">
        <div class="iv-form-item">
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                type="email"
                :placeholder="$t('email')"
                v-model="form.email"
              />
            </div>
          </div>
          <div class="iv-err-tip" v-if="error.email">！{{ error.email }}</div>
        </div>
        <div class="iv-form-item">
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                type="password"
                :placeholder="$t('password')"
                v-model="form.password"
                :maxlength="25"
                @keyup.enter="submit"
              />
            </div>
          </div>
          <!-- <div class="iv-err-tip" v-if="error.password">{{ error.password }}</div> -->
        </div>
      </div>
      <div class="other">
        <span @click="onSignUp">{{ $t("login-sign-up") }}</span>
        <span @click="onForgot">{{ $t("login-forgot-txt") }}</span>
      </div>
      <div class="dialog-footer">
          <a-button
            class="btn-normal-2"
            :class="{ disable: disable }"
            @click="submit"
            :loading="loading"
          >
            {{ $t("login-login") }}
          </a-button>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { publicKey } from "@/utils/config";
import { verifyEmail, verifyPw } from "@/utils/verify";
import { useMainStore } from "@/store/index";
import messageBox from "@/composables/messageBox";
const { $encryptor } = useNuxtApp();
const mainStore = useMainStore();
const { t } = useI18n();
const router = useRouter()
const localePath = useLocalePath();
const form = ref({ email: "", password: "" });
const error = ref({ email: "", password: "" });
const loading = ref(false);
// const visibleEmailLogin = computed(() => mainStore.visibleEmailLogin);
// const vipToLogin = computed(() => mainStore.vipToLogin);
const disable = computed(() => {
  if (!form.value.password.trim() || !form.value.email.trim()) {
    return true;
  }
  return false;
});

watch(mainStore.visibleEmailLogin, (n, o) => {
  if (n) {
    form.value.email = "";
    form.value.password = "";
    error.value.email = "";
    error.value.password = "";
  }
});
function onClose() {
  useLogEvent({ eventName: "login-close" });
  mainStore.setVisibleEmailLogin(false);
  mainStore.setVisibleLogin(true);
}
function onSignUp() {
  mainStore.setVisibleEmailLogin(false);
  mainStore.setVisibleSignUp(true);
}
function onForgot() {
  mainStore.setVisibleEmailLogin(false);
  mainStore.setVisibleForgot(true);
}
function submit() {
  if (disable.value) {
    return;
  }
  switch (verifyEmail(form.value.email)) {
    case -1:
    // error.value.email = t("login-tip-empty-pw");
    // return;
    case -2:
      error.value.email = t("login-tip-invalid-email");
      return;
    default:
      error.value.email = "";
      break;
  }
  if (!form.value.password.trim()) {
    error.value.password = t("login-tip-empty-pw");
    return;
  }
  error.value.password = "";
  if (loading.value) {
    return;
  }
  // console.log(values);
  loading.value = true;
  $encryptor.setPublicKey(publicKey);
  const pwd = $encryptor.encrypt(form.value.password);
  useApi
    .loginByEmail({ username: form.value.email, password: pwd })
    .then((res) => {
      const { data, code } = res;

      if (code === 0) {
        useLogEvent({
          eventName: "login-success",
          eventParams: { type: "email" },
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(data.user));
        messageBox.success(t("login"), t("login-success"));
        mainStore.setUserInfo(data.user);
        // const visitorLoginEvent = new CustomEvent("visitorLoginEvent", {
        //   detail: data.user,
        // });
        // window.dispatchEvent(visitorLoginEvent);
        mainStore.setVisibleLogin(false);
        mainStore.setVisibleEmailLogin(false);
        //如果会员页过来登录的，登录完，非会员返回会员页
        if (mainStore.vipToLogin && data.user.memberType == 0) {
          mainStore.setVisibleVipDialog(true);
        }
        router.push(localePath({name: 'master_center'}));
        mainStore.setVipToLogin(false);
      }
      loading.value = false;
    })
    .catch((e) => {
      loading.value = false;
      console.log("登录异常:", e);
      useLogEvent({ eventName: "login-fail", eventParams: { type: "email" } });
      messageBox.error(t("login"), t("login-err"));
    });
}
</script>

<style scoped lang="scss">
@import '~/assets/css/iv-form.scss';
.login {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logo {
  margin-top: 40px;
  width: 54px;
  height: 54px;
}
.logo-txt {
  margin-top: 16px;
  margin-bottom: 40px;
  height: 148px;
  height: 24px;
}

.btn {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 36px;
  // width: 440px;
  margin-bottom: 16px;

  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  // background-image: linear-gradient(to right, #fff), @linear-gradient-vip;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(to right, #ffffff, #ffffff),
      $linear-gradient-vip;
  }
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 700;
    margin-left: 16px;
    font-size: 14px;
    color: $text-deep;
  }
}

.other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;

  span {
    color: $main-text-color;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }
  span:nth-child(2) {
    color: $text-deep;
  }
}
.disable {
  background-color: $color-bg;
  &:hover {
    cursor: not-allowed;
    background-color: $color-bg;
  }
}
.dialog-footer {
  margin-top: 40px;
  // width: 100%;
  .btn-normal-2 {
    margin: 0 auto;
    // width: 300px;
    height: 43px;
    border-radius: 44px;
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-left: 60px;
    padding-right: 60px;
    color: #fff;
    background: $main-btn-color-hover;
  }
}
@media screen and (max-width: 767px) {
}
.spinning {
  border-radius: 100px;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .login {
    width: 100%;
  }
  .dialog-footer {
    margin-top: 40px;
    width: 100%;
    .btn-normal-2 {
      margin: 0 auto;
      width: 100%;
      // height: 51px;
      // border-radius: 44px;
      // font-family: "Roboto";
      // font-style: normal;
      // font-weight: 700;
      // font-size: 16px;
      // line-height: 19px;
    }
  }
}
</style>
