<template>
  <div v-show="preViewAbled" class="relative h-full w-full">
    <video
      ref="videoPlayer"
      object-fill="contain"
      class="absolute shadow-1 left-1/2 -translate-x-1/2"
      style="height: 100%"
      @loadedmetadata="loadedmetadata"
      @timeupdate="timeupdate"
      @error="error"
    ></video>
    <!-- <div
        v-show="process === 1"
        class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white"
      >
        {{ $t("after") }}
      </div> -->
  </div>
  <div
    v-if="!preViewAbled"
    class="relative h-full shadow-1 flex flex-col items-center justify-center gap-6 bg-text-deep"
  >
    <img
      src="/assets/images/enhance/video-empty.png"
      alt="empty"
      width="255"
      height="255"
    />
    <div class="max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center">
      <span v-if="isFinish"
      >{{ $t('enhance-video-nosupport-down1') }}
          <a @click="onDownload" class="underline text-mainColor cursor-pointer"
            >{{ $t('enhance-video-nosupport-down2') }}</a
          >
          {{ $t('enhance-video-nosupport-down3') }}</span
        >
      <span v-else>{{ $t('enhance-video-nosupport') }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
// enum Status {
//   load,
//   fail,
//   success
// };
const props = defineProps({
  preViewAbled: {
    type: Boolean,
    default: false,
  },
  isFinish: {
    type: Boolean,
    default: false,
  }
});
// const loadStatus = ref(Status.load)
const videoPlayer = ref<HTMLVideoElement | null>(null);
const { t } = useI18n();
defineExpose({ videoPlayer });
const emit = defineEmits(['onDownload','loadedmetadata','timeupdate','error'])
const onDownload = ()=>{
    emit('onDownload')
}
const loadedmetadata = ()=>{
    emit('loadedmetadata')
}
const timeupdate = ()=>{
    emit('timeupdate')
}
const error = (e: Event)=>{
    // loadStatus.value = Status.fail
    console.log('video err:',e);
    messageBox.error(null, t('loading-err'));
    emit('error',e)
}
</script>
