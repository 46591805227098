<template>
    <div class="relative h-full w-full flex items-center justify-center">
        <div v-show="files" class="relative h-4/5 w-[1224px] -translate-y-[10%] shadow-1 rounded-xl bg-white">
            <!-- <img ref="ImgRef" object-fill="contain" style="height: 600px; width: 100%" :src="imgSrc" /> -->
            <div ref="ImgRef" class="image-container" object-fill="contain"
                :style="{ backgroundImage: `url(${imgSrc})` }"></div>
            <div v-show="process === 1"
                class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white">
                {{ $t("after") }}
            </div>
        </div>
        <div v-show="!files" class="flex items-center justify-center">
            <FuncTypeUpload class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
                :funcType="route.params.funcType"></FuncTypeUpload>
        </div>
        <canvas ref="canvasRef" style="display: none"></canvas>
        <div v-if="files" class="absolute left-1/2 bottom-10 -translate-x-1/2 w-[888px] flex flex-col items-center">
            <div class="bg-white shadow-6 h-[80px] rounded-[20px] flex items-center gap-4">
                <div class="flex items-center gap-3 ml-8 mr-3">
                    <img v-if="process > 1" src="/assets/images/enhance/check.svg" alt="check" />
                    <span class="Roboto-Bold-16" v-if="process > 1">{{ $t('enhance-Conversion-complete') }}</span>
                </div>
                <div class="h-12 rounded-lg border border-gray-line px-3 flex items-center gap-3 bg-light-gray-bg">
                    <img class="h-6" src="/assets/images/enhance/ImageIcon.png" alt="img" />
                    <span class="max-w-[140px] text-ellipsis overflow-hidden whitespace-nowrap Roboto-Bold-14">{{
                        truncateString(fileName, 20) }}</span>
                    <span class="text-text-gray Roboto-14">{{ formatFileSize(fileSize) }}</span>
                </div>
                <img src="/assets/images/enhance/to.png" alt="converter" width="24" height="24" />
                <a-select class="h-12 rounded-lg border border-gray-line px-3 flex items-center gap-3 bg-light-gray-bg"
                    style="width: 100px;border-radius:10px;border:1px solid rgb(224, 224, 224)" placeholder=""
                    v-model="typePicture">
                    <a-option :disabled="extension == 'png'" value="png">png</a-option>
                    <a-option :disabled="extension == 'jpg'" value="jpg">jpg</a-option>
                    <a-option :disabled="extension == 'bmp'" value="bmp">bmp</a-option>
                </a-select>
                <canvas ref="canvasRef" style="display: none"></canvas>
                <EnhanceApply class="ml-4 mx-2 w-max" @click="onApply" :enable="applyEnabled"></EnhanceApply>
                <a id="downloadLink" href="#" download="image.png"></a>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
// import UTIF from 'utif';
import { useUploadStore } from "@/store/upload";
import { useMainStore } from "@/store/index";
const uploadStore = useUploadStore();
const mainStore = useMainStore();
const { isLogin, isVip, isNormalVip, userInfo, checkWeekCount } = useUserInfo();
const typePicture = ref('png')
const canvasRef: any = ref(null);
const process = ref(0);
const imgSrc: any = ref(null);
const fileName = ref('')
const fileSize = ref(0)
const files: any = ref(null)
const route = useRoute();
const blobData = ref<Blob>();
const applyEnabled = computed(() => {
    return process.value == 0 && imgSrc.value
});
onMounted(() => {
    const images = [...uploadStore.images];
    if (images[0]?.type == "image/png") {
        typePicture.value = 'jpg'
    }
    if (images[0]?.type == "image/bmp") {
        typePicture.value = 'png'
    }
    if (images[0]?.type == "image/jpg" || images[0]?.type == "image/jpeg") {
        typePicture.value = 'png'
    }
    uploadStore.setImages([]);
    if (images.length > 0) {
        handFileChange(images);
    }
    window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
    window.addEventListener("updateLinkEvent", updateLinkEvent);
});
onUnmounted(() => {
    window.removeEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
    window.removeEventListener("updateLinkEvent", updateLinkEvent);
});
function uploadLocalFilesSuccess(params: any) {
    isConverter.value = false
    window.dispatchEvent(
        new CustomEvent("downloadUrlEvent", {
            detail: "",
        })
    );
    if (params.detail[0].type == "image/png") {
        typePicture.value = 'jpg'
    }
    if (params.detail[0].type == "image/bmp") {
        typePicture.value = 'png'
    }
    if (params.detail[0].type == "image/jpg" || params.detail[0].type == "image/jpeg") {
        typePicture.value = 'png'
    }
    handFileChange(params.detail);
}
const extension = ref('')
function handFileChange(imgFile: any) {
    process.value = 0;
    const file = imgFile[0];
    files.value = file;
    fileName.value = file.name;
    fileSize.value = file.size
    const mimeType = file.type;
    switch (mimeType) {
        case 'image/png':
            extension.value = 'png';
            break;
        case 'image/bmp':
            extension.value = 'bmp';
            break;
        case 'image/jpeg':
            extension.value = 'jpg';
            break;
        default:
            extension.value = 'jpg';
    }
    if (!file) return;
    const reader: any = new FileReader();
    reader.onload = (e: any) => {
        const img: any = new Image();
        img.onload = () => {
            const canvas: any = canvasRef.value;
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
        };
        img.src = e.target.result;
        imgSrc.value = e.target.result;
        window.dispatchEvent(
            new CustomEvent("updateFileListEvent", {
                detail: [file],
            })
        );
    };
    reader.readAsDataURL(file);

}
const isConverter = ref(false)
const { t } = useI18n();
const fullLoading = useFullLoading();
async function onApply() {

    if (!isConverter.value) {
        if (applyEnabled) {
            if (files.value) {
                if (!isLogin.value) {
                    mainStore.setVisibleLogin(true);
                    return;
                }
                if (!checkWeekCount()) {
                    return
                }
                if (['image/tiff', 'image/bmp', 'image/jpeg', 'image/jpg', 'image/png'].includes(files.value.type)) {
                    fullLoading.show(`${t("enhance-upload-process")}(0%)`, t("enhance-process"));
                    const canvas: any = canvasRef.value;
                    let mimeType = 'image/png';
                    let fileName = 'converted.png';
                    let quality = 0.8; // 默认压缩质量
                    const maxSize = 20 * 1024 * 1024; // 最大允许的文件大小 20MB
                    if (typePicture.value === 'png') {
                        mimeType = 'image/png';
                        fileName = 'converted.png';
                    } else if (typePicture.value === 'jpeg') {
                        mimeType = 'image/jpeg'; // 更正：MIME 类型应为 'image/jpg'
                        fileName = 'converted.jpeg';
                    } else if (typePicture.value === 'jpg') {
                        mimeType = 'image/jpeg'; // 更正：MIME 类型应为 'image/jpg'
                        fileName = 'converted.jpg';
                    } else if (typePicture.value === 'bmp') {
                        mimeType = 'image/bmp';
                        fileName = 'converted.bmp';
                    }
                    // else if (typePicture.value === 'tiff') {
                    //     mimeType = 'image/tiff';
                    //     fileName = 'converted.tiff';
                    // }
                    if (canvas) {
                        let dataUrl = canvas.toDataURL(mimeType, quality);
                        if (mimeType == 'image/bmp') {
                            await base64PNGtoBMP(dataUrl).then(e => {
                                dataUrl = 'data:image/bmp;base64,' + e
                            })
                        }
                        const blob: any = base64ToBlob(dataUrl);
                        blobData.value = blob
                        let url = URL.createObjectURL(blob);
                        // let fileSize = dataUrlToFileSize(dataUrl);
                        // if (fileSize > maxSize) {
                        //     while (fileSize > maxSize && quality > 0.1) {
                        //         quality -= 0.1;
                        //         url = canvas.toDataURL(mimeType, quality);
                        //         fileSize = dataUrlToFileSize(dataUrl);
                        //     }
                        // resultData.value.url = url
                        // if (fileSize > maxSize) {
                        //     let scaleFactor = 1;
                        //     while (fileSize > maxSize && scaleFactor > 0.5) {
                        //         scaleFactor -= 0.1;
                        //         const resizedCanvas = resizeCanvas(canvas, scaleFactor); // 自定义函数调整分辨率
                        //         dataUrl = resizedCanvas.toDataURL(mimeType, quality);
                        //         fileSize = dataUrlToFileSize(dataUrl);
                        //     }
                        // }
                        // }
                        const interval = setInterval(() => {
                            process.value += 0.02;
                            const percent = Number((process.value * 100).toFixed(2));
                            fullLoading.updateText({
                                title: `${t("enhance-upload-process")}(${percent}%)`,
                            });
                            if (process.value > 1) {
                                clearInterval(interval)
                                // 更新用户信息，同步点数
                                window.dispatchEvent(
                                    new CustomEvent("updateUseInfoEvent", {
                                        // 提交相关处理参数，如相关表单
                                        detail: '',
                                    })
                                );
                                fullLoading.hide()
                            }
                        }, 10);
                        isConverter.value = true

                        window.dispatchEvent(
                            new CustomEvent("downloadUrlEvent", {
                                // 提交相关处理参数，如相关表单
                                detail: { url, suffix: typePicture.value },
                            })
                        );
                    }
                }
            }
        }
    }
}

function base64PNGtoBMP(base64PNG: any) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx: any = canvas.getContext('2d');

            // 将 PNG 图像绘制到 canvas 上
            ctx.drawImage(img, 0, 0);

            // 获取图像的像素数据
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;

            // BMP 文件头和信息头
            const fileHeader = createBMPFileHeader(canvas.width, canvas.height);
            const infoHeader = createBMPInfoHeader(canvas.width, canvas.height);

            // BMP 像素数据
            const pixelData = createBMPPixelData(canvas.width, canvas.height, data);

            // 合并 BMP 文件
            const bmpData = new Uint8Array(fileHeader.length + infoHeader.length + pixelData.length);
            bmpData.set(fileHeader, 0);
            bmpData.set(infoHeader, fileHeader.length);
            bmpData.set(pixelData, fileHeader.length + infoHeader.length);

            // 将 BMP 数据转换为 Base64
            const base64BMP = arrayBufferToBase64(bmpData.buffer);
            resolve(base64BMP);
        };
        img.onerror = reject;
        img.src = base64PNG;
    });
}
// 创建 BMP 文件头
function createBMPFileHeader(width: number, height: number) {
    const fileSize = 54 + width * height * 3; // 54 是文件头和信息头的大小
    const header = new Uint8Array(14);
    header[0] = 0x42; // 'B'
    header[1] = 0x4d; // 'M'
    header[2] = fileSize & 0xff;
    header[3] = (fileSize >> 8) & 0xff;
    header[4] = (fileSize >> 16) & 0xff;
    header[5] = (fileSize >> 24) & 0xff;
    header[10] = 54; // 偏移量，文件头和信息头的大小
    return header;
}

// 创建 BMP 信息头
function createBMPInfoHeader(width: number, height: number) {
    const header = new Uint8Array(40);
    header[0] = 40; // 信息头大小
    header[4] = width & 0xff;
    header[5] = (width >> 8) & 0xff;
    header[6] = (width >> 16) & 0xff;
    header[7] = (width >> 24) & 0xff;
    header[8] = height & 0xff;
    header[9] = (height >> 8) & 0xff;
    header[10] = (height >> 16) & 0xff;
    header[11] = (height >> 24) & 0xff;
    header[12] = 1; // 颜色平面数
    header[14] = 24; // 每像素位数 (24 位 RGB)
    return header;
}

// 创建 BMP 像素数据
function createBMPPixelData(width: number, height: number, imageData: number[]) {
    const rowSize = Math.floor((24 * width + 31) / 32) * 4; // 每行字节数
    const pixelData = new Uint8Array(rowSize * height);
    let offset = 0;

    for (let y = height - 1; y >= 0; y--) {
        for (let x = 0; x < width; x++) {
            const index = (y * width + x) * 4;
            pixelData[offset++] = imageData[index + 2]; // 蓝色
            pixelData[offset++] = imageData[index + 1]; // 绿色
            pixelData[offset++] = imageData[index];     // 红色
        }
        offset += rowSize - width * 3; // 填充字节
    }
    return pixelData;
}

// 将 ArrayBuffer 转换为 Base64
function arrayBufferToBase64(buffer: any) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}
const base64ToArrayBuffer = (base64: any) => {
    const binaryString = atob(base64.split(',')[1]); // 去掉Base64前缀部分
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// function arrayBufferToBase64(buffer: any) {
//     const binary = new Uint8Array(buffer);
//     let base64 = '';
//     for (let i = 0; i < binary.byteLength; i++) {
//         base64 += String.fromCharCode(binary[i]);
//     }
//     return 'data:image/tiff;base64,' + btoa(base64);
// }

function base64ToBlob(base64Image: string) {
    const parts = base64Image.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

// 辅助函数：调整 Canvas 分辨率
const resizeCanvas = (canvas: HTMLCanvasElement, scaleFactor: number) => {
    const resizedCanvas = document.createElement('canvas');
    const resizedCtx = resizedCanvas.getContext('2d');
    resizedCanvas.width = canvas.width * scaleFactor;
    resizedCanvas.height = canvas.height * scaleFactor;
    resizedCtx?.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
    return resizedCanvas;
};
// 辅助函数：将 Data URL 转换为文件大小
const dataUrlToFileSize = (dataUrl: string) => {
    const base64String = dataUrl.split(',')[1];
    const padding = (base64String.length % 4) ? ('='.repeat(4 - (base64String.length % 4))) : '';
    const byteLength = (base64String.length / 4) * 3 - padding.length;
    return byteLength;
};
const updateLinkEvent = () => {
    if (blobData.value) {
        const url = URL.createObjectURL(blobData.value);
        window.dispatchEvent(
            new CustomEvent("downloadUrlEvent", {
                // 提交相关处理参数，如相关表单
                detail: { url, suffix: typePicture.value },
            })
        );
    }
}
</script>
<style>
.image-container {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>