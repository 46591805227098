<template>
  <Vue3Marquee :duration="40" :pauseOnHover="true">
    <div class="flex flex-wrap justify-center">
      <div
        class="shadow-xl shadow-orange-100 w-[380px] h-[350px] mb-10 border bg-white border-orange-100 rounded-[20px] mx-2 p-4 lg:p-[30px]"
        v-for="(item, index) in list" :key="index" :class="index > 3 ? 'hidden sm:inline-block' : 'inline-block'">
        <div class="flex gap-1">
          <IndexEvaluateStar :starNum="item.star" :showScore="false" :halfStar="item.halfStar">
          </IndexEvaluateStar>
        </div>
        <div class="mt-4 Roboto-Bold-24 text-left">
          {{ item.title }}
        </div>
        <p :title="item.review" class="mt-4 text-base text-gray-500 text-left line-clamp-6">{{
          item.review }}</p>
        <div class="flex items-center justify-between">
          <div class="flex items-center mt-4 absolute bottom-16">
            <NuxtImg class="w-12 h-12 rounded-lg" :src="item.icons" format="webp" :alt="$t('AI_Enhance')" loading="lazy" />
            <div class="ml-2 text-left">
              <div class="Roboto-16">{{ item.name }}</div>
              <div class="Roboto-14 mt-2 text-gray-500">{{ item.device === 'ios' ? "iOS user" : "Android user" }}</div>
            </div>
          </div>
          <div class="absolute bottom-16 transform  translate-x-[280px]">
            <NuxtImg class="w-10 h-10 mt-4" width="40" height="40" src="/assets/images/home/logo.png" alt="picma studio" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  </Vue3Marquee>
</template>

<script setup>
import { Vue3Marquee } from 'vue3-marquee';
const list = [
  { name: "Leo Hernandez", icons: "/assets/images/home/Rectangle1.png", title: "User-friendly", halfStar: true, star: 5, device: 'ios', review: `Never seen such a high-quality app that is free. I’m totally in love, it is easy to access and fun. I'll definitely recommend this app to everyone; it's the go-to app perfect for making your pictures high quality!! 💕` },
  { name: "Oliver Schmidt", icons: "/assets/images/home/Rectangle2.png", title: "Useful", halfStar: false, star: 5, device: 'android', review: `Don't think I've seen a better AI powered photo editing app. That's usable, howbeit with ads. Awesome job guys.` },
  { name: "Lucas Wilson", icons: "/assets/images/home/Rectangle3.png", title: "Highly recommended", halfStar: false, star: 5, device: 'ios', review: `This app does wonders! Ideal for dark photos. Doesn’t distort the look of the face. I love that it now has the option to choose between the original color and the auto color. Highly recommended!` },
  { name: "Olivia Brown", icons: "/assets/images/home/Rectangle4.png", title: "Amazing app", halfStar: false, star: 5, device: 'ios', review: `This app is amazing. I had an old photo of my grandmother when she was about 25 years old, and Picma has dramatically improved that. It was the only photo I had of her when she was young, and I'm delighted.` },
  { name: "Emma Garcia", icons: "/assets/images/home/Rectangle5.png", title: "Natural and realistic", halfStar: false, star: 4, device: 'android', review: `I have tried many AI photo enhancement applications. This one is the best. The result is very natural and realistic and very high quality. Other apps give paint-like results. Picma is perfect.` },
  { name: "Ava Müller", icons: "/assets/images/home/Rectangle6.png", title: "Astonishing", halfStar: false, star: 5, device: 'ios', review: `This is really amazing app. Looking at the old photos, it was difficult to identify who is in the album, the result is astonishing, and fine details have also been shown. ` },
  { name: "William Thompson", icons: "/assets/images/home/Rectangle7.png", title: "Efficient Processing", halfStar: true, star: 5, device: 'android', review: `Where there is blur or something, Picma is the quickest: you can do things that take me hours on Photoshop.` },
  { name: "JACK", icons: "/assets/images/home/Rectangle8.png", title: "Have great experience", halfStar: false, star: 4, device: 'android', review: `I have taken the premier membership of this app. Honestly it's a great app! I can definitely say from my experience that this app will benefit everyone. This app is especially good for those who can't do photo editing. All amazing paid features can be enjoyed for free in return for viewing ads. I really like this app. You can try it, hope you will have a great experience.` },
]
</script>
