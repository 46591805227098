<template>
  <div class="base-controls h-14 flex items-center  rounded-xl px-3">
    <span class=" min-w-10 mr-3">{{ scale }}%</span>
    <div
      class="p-1 cursor-pointer rounded-lg hover:bg-btn-bg-hover 2xl:p-2"
      @click="scaleDown"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="#1F1F1F" stroke-width="2" />
        <path
          d="M7 12H17"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <!-- <a-slider
      :min="props.minScale"
      :max="props.maxScale"
      :show-tooltip="false"
      @change="change"
      class=" mx-4"
      :step="props.step"
      v-model="scale"
      style="width: 100px;"
    /> -->
    <input class="mx-4" type="range" v-model="scaleTemp" style="width: 100px;" :min="props.minScale" :max="props.maxScale" @change="change" :step="props.step"/>
    <div
      class="p-1 cursor-pointer rounded-lg hover:bg-btn-bg-hover 2xl:p-2"
      @click="scaleUp"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="#1F1F1F" stroke-width="2" />
        <path
          d="M7 12H17"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 17V7"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div
      class="p-1 cursor-pointer rounded-lg hover:bg-btn-bg-hover ml-3 2xl:p-2 2xl:ml-6"
      @click="reset"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.11523 7.45117V16H6.42773V9.39648L4.40039 10.041V8.71094L7.93359 7.45117H8.11523ZM11.0273 15.1855C11.0273 14.9355 11.1133 14.7266 11.2852 14.5586C11.4609 14.3906 11.6934 14.3066 11.9824 14.3066C12.2715 14.3066 12.502 14.3906 12.6738 14.5586C12.8496 14.7266 12.9375 14.9355 12.9375 15.1855C12.9375 15.4355 12.8496 15.6445 12.6738 15.8125C12.502 15.9805 12.2715 16.0645 11.9824 16.0645C11.6934 16.0645 11.4609 15.9805 11.2852 15.8125C11.1133 15.6445 11.0273 15.4355 11.0273 15.1855ZM11.0273 10.3516C11.0273 10.1016 11.1133 9.89258 11.2852 9.72461C11.4609 9.55664 11.6934 9.47266 11.9824 9.47266C12.2715 9.47266 12.502 9.55664 12.6738 9.72461C12.8496 9.89258 12.9375 10.1016 12.9375 10.3516C12.9375 10.6016 12.8496 10.8105 12.6738 10.9785C12.502 11.1465 12.2715 11.2305 11.9824 11.2305C11.6934 11.2305 11.4609 11.1465 11.2852 10.9785C11.1133 10.8105 11.0273 10.6016 11.0273 10.3516ZM18.3926 7.45117V16H16.7051V9.39648L14.6777 10.041V8.71094L18.2109 7.45117H18.3926Z"
          fill="#1F1F1F"
        />
        <path
          d="M1 5V1H5"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 19V23H5"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 5V1H19"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 19V23H19"
          stroke="#1F1F1F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    
  </div>
</template>

<script setup>
const props = defineProps({
  showMove: {
    type: Boolean,
    default: false,
  },
  minScale: {
    type: Number,
    default: 50,
  },
  maxScale: {
    type: Number,
    default: 250,
  },
  step: {
    type: Number,
    default: 10,
  }
});
const scale = ref(100);
const scaleTemp = ref(100);
const emit = defineEmits(["change"]);
function change(value) {
  console.log(value.target.valueAsNumber);
  scale.value = value.target.valueAsNumber
  emit("change",value.target.valueAsNumber)
}
function setScale(value) {
  scale.value = value;
  scaleTemp.value = scale.value.toString();
}
function scaleUp() {
  let v = Number(scaleTemp.value)
  if(v<props.maxScale){
    scale.value += props.step
    scaleTemp.value = scale.value.toString()
    emit("change",scale.value)
  }
}
function scaleDown() {
  let v = Number(scaleTemp.value)
  if(v>props.minScale){
    scale.value -= props.step
    scaleTemp.value = scale.value.toString()
    emit("change",scale.value)
  }
}
function reset() {
  scale.value = 100;
  scaleTemp.value = scale.value.toString();
  emit("change",scale.value)
}
defineExpose({setScale})
</script>
<style lang="scss">

.base-controls .arco-slider-track {
  .arco-slider-bar {
  background-color: $text-deep;
}
  .arco-slider-btn::after {
  background-color: $text-deep;
  border-color: $text-deep;
}
&::before {
  background-color: #D9D9D9;
}
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: $text-deep;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: $text-deep;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: $text-deep;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}
input[type="range"]::-moz-range-track {
  height: 2px;
  border-radius: 5px;
  background-color: $text-deep;
}
</style>