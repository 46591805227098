<template>
  <div class="relative w-full h-full flex items-center justify-center">
    <div class="h-full flex-1 flex relative">
      <div class="h-full bg-white w-80 relative">
        <div class="h-full flex border flex-col items-center overflow-y-auto">
          <div
            class="grid grid-cols-2 gap-2 border bg-mainColor-deep-10 border-mainColor-deep-8 shadow rounded-lg w-72 h-10 mt-8 Roboto-Bold-16">
            <button :class="{
              'Roboto-Bold-12': !isCustom,
              'bg-bg-mainColor-deep': !isCustom,
            }" class="w-full h-10 rounded-lg Roboto-12" @click="onTabChange(false)">
              {{ $t("enhance-Template") }}
            </button>
            <button :class="{
              'Roboto-Bold-12': isCustom,
              'bg-bg-mainColor-deep': isCustom,
            }" class="w-full h-10 rounded-lg Roboto-12" @click="onTabChange(true)">
              {{ $t("enhance-Custom") }}
            </button>
            <div class="col-span-2 mt-2" v-if="!isCustom">
              <div class="Roboto-Bold-14 my-2"><span class="required">*</span>{{ $t("enhance-style") }}</div>
              <div class="grid grid-cols-3 gap-4 flex-1 flex mt-4 mb-28">
                <div class="relative inline-block hover:scale-110 h-[84px] w-[82px]" v-for="(item, index) in styleList"
                  :key="index" :value="item.id" :class="{ selected: selectedImageIndex.id === item.id }"
                  @click="onClickImg(item, index)">
                  <img class="cursor-pointer rounded-lg shadow border h-[80px] w-[82px] object-cover" :src="item.demo"
                    alt="AI Matching Image" />
                  <!-- <div style="background: linear-gradient(to bottom, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 100%)"
                    class="text-xs rounded-lg absolute bottom-0 left-0 right-0 text-white text-center h-[30px] w-full grid place-items-center">
                    {{ item.name }}
                  </div> -->
                </div>
              </div>
            </div>
            <div class="mt-4 col-span-2" v-else>
              <div class="Roboto-Bold-14"><span class="required">*</span>{{ $t("enhance-Description") }}</div>
              <EnhanceTextPrompt v-model="prompt" class="h-[170px] mt-2" enhance
                :placeholder="$t('enhance-Description-hp')"></EnhanceTextPrompt>
              <div>
                <div class="Roboto-Bold-12 text-text-gray my-4">
                  {{ $t('enhance-Art-Generator-No-inspiration') }}
                </div>
                <div class="flex items-center">
                  <img v-for="item in inspirationList" :value="item.key" :key="item.key"
                    @click="onInspirationClick(item)"
                    class="w-[40px] h-[60px] rounded-lg mr-4 cursor-pointer hover:scale-110"
                    :class="{ 'selected-ins': inspIndex === item.key }" :src="item.src" alt="img" />
                </div>
                <div :title="inspText" class="mt-5 Roboto-12 w-full h-[153px] border rounded-lg limited-lines px-4 pt-2"
                  style="font-size: 12px; font-weight: 400;line-height: 20px;background-color: white;color:#8A8EA1">
                  {{ inspText }}
                </div>
                <div class="w-[270px] h-4 transform -translate-y-[23px] translate-x-[4px]"
                  style="background-color: white;">
                  <a-tooltip :contentStyle="{ 'border-radius': '15px', 'background-color': '#1F1F1FE5' }"
                    :content="t('enhance-Art-Generator-Use-Prompt')" position="top">
                    <img class="float-right w-4 h-4 cursor-pointer hover:scale-110"
                      src="/assets/images/perCenter/edit.svg" alt="img" @click="onTipClick">
                  </a-tooltip>
                </div>
              </div>
              <div class="mt-4 col-span-2">
                <span class="Roboto-Bold-14">
                  {{ $t("enhance-output") }}
                </span>
                <a-input-number v-model="picNum" class="Roboto-Bold-12" :style="{ width: '90px', float: 'right' }"
                  mode="button" size="small" :max="4" :min="1" />
              </div>
            </div>

            <div class="h-12 bottom-10 left-16 fixed">
              <EnhanceGenerate @click="onApply" :enable="enable" :picNum="picNum">
              </EnhanceGenerate>
            </div>
          </div>
        </div>
      </div>
      <div class="relative h-full flex-1 flex items-center justify-center">
        <div class="relative h-2/3 w-[624px] flex items-center justify-center ">
          <template v-if="step === StepStatus.none">
            <EnhancePreLoad v-if="
              step1Data &&
              (step1Data.status == uploadStatus.handle ||
                step1Data.status == uploadStatus.lodingImg)
            " :fileModel="step1Data" @load="onLoad"></EnhancePreLoad>
            <FuncTypeUpload v-else class="h-[240px] mx-auto z-[2] hidden sm:block lg:h-[280px]"></FuncTypeUpload>
          </template>
          <template v-else-if="step === StepStatus.edit">
            <EnhanceImageBgEdit ref="imageBgEditRef" v-if="isCustom && step1Data.enhanceUrl"
              :url="isCustom && step1Data.enhanceUrl" @load="onBgLoad"></EnhanceImageBgEdit>
            <EnhanceImageBgEdit ref="imageBgEditRef" v-else-if="!isCustom && step1Data.enhanceUrl"
              :url="step1Data.enhanceUrl" :bgImgUrl="selectedImageIndex.demo" @load="onBgLoad"></EnhanceImageBgEdit>
            <div v-if="fileModel && fileModel.status == uploadStatus.handle"
              class="w-[472px] h-[720px] absolute flex items-center justify-center flex-col bg-[#00000088]">
              <Loading />
              <p class="mt-10 text-white max-w-[50%] text-center text-xs" v-html="$t('enhance-process')"></p>
            </div>
          </template>
          <template v-else-if="step === StepStatus.success">
            <EnhancePreLoad v-if="fileModel.status == uploadStatus.handle" :fileModel="fileModel"></EnhancePreLoad>
            <EnhancePreLoad v-else-if="
              imgGeneratorList[indexImages].status == uploadStatus.lodingImg
            " :fileModel="imgGeneratorList[indexImages]"></EnhancePreLoad>
            <div v-else-if="
              imgGeneratorList[indexImages].status == uploadStatus.success
            " class="w-full h-full shadow-1 rounded-xl -translate-y-14">
              <EnhanceImageView ref="imageViewRef" :minScale="minScale" :maxScale="maxScale"
                :url="imgGeneratorList[indexImages].enhanceUrl" @scaleChange="imageViewChange"></EnhanceImageView>
              <EnhanceGiveALike class="absolute right-4 bottom-4" v-if="isLogin" v-show="imgGeneratorList[indexImages].enhanceUrl &&
                !fileModel.isEvaluate
                " :id="fileModel.enhanceRecordId" @onEvaluate="onEvaluate">
              </EnhanceGiveALike>
            </div>
          </template>
        </div>
        <div v-if="fileModel && step === StepStatus.success"
          class="absolute left-1/2 bottom-10 -translate-x-1/2 bg-white shadow-6 h-[88px] rounded-[20px] px-4 w-max">
          <EnhanceBaseControls class="flex-1 flex justify-center mt-4" ref="baseControlsRef" :minScale="minScale"
            :maxScale="maxScale" @change="baseCtlScaleChange">
          </EnhanceBaseControls>
        </div>
      </div>
    </div>

    <EnhanceFold :isFold="isFold" @change="foldChange">
      <div class="w-full h-full py-8 flex flex-col overflow-hidden transition-all delay-150"
        :class="isFold ? 'pl-6 pr-2' : ' opacity-0'">
        <div class="flex items-center justify-between w-full mb-6 pr-4">
          <span class="Roboto-Bold-20 text-black">{{
            $t("enhance-All-Generate")
          }}</span>
          <span class="text-gray">{{ imgGeneratorList.length }}</span>
        </div>
        <div class="w-full flex-1 overflow-y-auto overflow-x-hidden pr-4" style="max-height: calc(100vh - 196px)">
          <EnhanceImgItem class="mb-5 last:mb-0" v-for="(item, index) in imgGeneratorList" :key="item" :imgInfo="item"
            :isSelect="indexImages === index" @click="selectImg(index)" @delImg="() => delImg(index)"
            @downloadImg="() => downloadImg(index)" @load="() => enhanceLoad(index)" :isFinish="false">
          </EnhanceImgItem>
          <div v-if="imgGeneratorList.length == 0" class="h-full w-full flex justify-center items-center">
            <EnhanceNoRecords></EnhanceNoRecords>
          </div>
        </div>
      </div>
    </EnhanceFold>
  </div>
</template>
<script setup>
import { uploadStatus } from "@/utils/type";
import {
  minScale,
  maxScale,
  imageAccept,
  maxUploadCount,
} from "@/utils/config";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
import messageBox from "@/composables/messageBox";

const StepStatus = {
  none: 0,
  edit: 1,
  success: 2,
};
const { $encryptor } = useNuxtApp();
const {
  customEventNames,
  cleanMenuDownload,
  applyEvent,
  updateFileListEvent,
  downloadImgEvent,
} = useCustomEvent();
const { isLogin, isVip, isVipPro } = useUserInfo();
const { locale, t } = useI18n();
const uploadStore = useUploadStore();
const mainStore = useMainStore();
const step = ref(StepStatus.none);
const step1Data = ref();
const imageBgEditRef = ref();
// const isApply = ref(true);
// const loading = ref(false);
const isFold = ref(true);
const isCustom = ref(false);
const fileModel = ref(null);
const imgGeneratorList = ref([]);
const imageViewRef = ref(null);

const prompt = ref(""); //文字描述
const picNum = ref(1); //图片数量1~4
const indexImages = ref(0);

// const counts = ref(0);

const props = defineProps({
  fileList: {
    type: Array,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
});

const styleList = ref([
  {
    id: 0,
    name: "Desert",
    demo: "/assets/images/functype/AIBGGenerator/origin/Desert.jpg",
  },
  {
    id: 1,
    name: "Garden",
    demo: "/assets/images/functype/AIBGGenerator/origin/Garden.jpg",
  },
  {
    id: 2,
    name: "Moonscape",
    demo: "/assets/images/functype/AIBGGenerator/origin/Moonscape.jpg",
  },
  {
    id: 3,
    name: "NightSky",
    demo: "/assets/images/functype/AIBGGenerator/origin/NightSky.jpg",
  },
  {
    id: 4,
    name: "Mountain",
    demo: "/assets/images/functype/AIBGGenerator/origin/Mountain.jpg",
  },
  {
    id: 5,
    name: "Surreal",
    demo: "/assets/images/functype/AIBGGenerator/origin/Surreal.jpg",
  },
  {
    id: 6,
    name: "Island",
    demo: "/assets/images/functype/AIBGGenerator/origin/Island.jpg",
  },
  {
    id: 7,
    name: "StarrySky",
    demo: "/assets/images/functype/AIBGGenerator/origin/StarrySky.jpg",
  },
  {
    id: 8,
    name: "StarrySkyLake",
    demo: "/assets/images/functype/AIBGGenerator/origin/StarrySkyLake.jpg",
  },
  {
    id: 9,
    name: "SeaSide",
    demo: "/assets/images/functype/AIBGGenerator/origin/SeaSide.jpg",
  },
  {
    id: 10,
    name: "NorthernLights",
    demo: "/assets/images/functype/AIBGGenerator/origin/NorthernLights.jpg",
  },
  {
    id: 11,
    name: "BlueTears",
    demo: "/assets/images/functype/AIBGGenerator/origin/BlueTears.jpg",
  },
]);

const inspirationList = ref([
  {
    key: 1,
    src: "/assets/images/functype/AIBGGenerator/inspiration_1.webp",
    text: "a breathtaking coastal scene during what appears to be sunset. The sky is painted in vibrant shades of pink, orange, and blue, with the clouds gently illuminated from below by the setting sun. The ocean reflects these colors beautifully, displaying gradients of turquoise to deep orange as it meets the shore.Prominent limestone stacks rise from the Southern Ocean, creating a natural monumental landscape. These stacks, eroded over time by the elements, stand alone and majestic against the backdrop of the vast sea and sky. Waves caress the beach, leaving behind patterns of foam and sand trails, hinting at the ebb and flow of the tide.The cliffs on the right are steep and rugged, covered with sparse vegetation that struggles to maintain life in such a harsh environment. The untouched beauty of this scene suggests that it is a remote part of the world, likely devoid of human presence except for perhaps the occasional adventurer or photographer seeking out such natural wonders.Overall, the image conveys a sense of tranquility, the enduring power of nature, and the awe-inspiring beauty of our planet's coastlines. It invites the viewer to reflect on the serenity and magnificence of the natural world and to consider the importance of protecting these environments for future generations."
  },
  {
    key: 2,
    src: "/assets/images/functype/AIBGGenerator/inspiration_2.webp",
    text: "a breathtaking view of the Aurora Borealis, also known as the Northern Lights. They are depicted in vivid shades of green and blue, dancing across a starry night sky. Below this magnificent display of nature's light show, there is a dense forest of coniferous trees, their branches heavy with fresh snow, indicating that the scene is set during the winter season. In the foreground, on a blanket of untouched snow, there is a tent illuminated from within. The glow spills out onto the snow, creating a warm contrast to the cold surroundings. It gives the impression of a solitary camper taking in the natural wonder above while seeking shelter from the elements below."
  },
  {
    key: 3,
    src: "/assets/images/functype/AIBGGenerator/inspiration_3.webp",
    text: "a breathtaking nighttime scene of a rocky coastline. The sky is filled with twinkling stars, adding to the serenity and beauty of the setting.The most striking feature of this image is the radiant blue glow emanating from the water near the rocks. This bioluminescent phenomenon creates an ethereal and otherworldly atmosphere, as if the ocean itself is illuminating from within. The luminescence clings to the rocks and ripples through the water, revealing the contours of the sea floor and highlighting the textures of the rocks.The composition of the photo is balanced, with the rugged texture of the cliffs on the left contrasting with the smooth, shimmering surface of the glowing water on the right. The dark sandy beach in between grounds the image and leads the viewer's eye across the natural wonder.In the distance, there appears to be a faint light source on the horizon, possibly a distant shore or a boat's light, which adds a touch of human presence to the otherwise wild and untamed landscape.Overall, this photograph evokes a sense of tranquility, awe, and the majesty of nature in its nocturnal glory."
  },
])
const inspText = ref("a breathtaking coastal scene during what appears to be sunset. The sky is painted in vibrant shades of pink, orange, and blue, with the clouds gently illuminated from below by the setting sun. The ocean reflects these colors beautifully, displaying gradients of turquoise to deep orange as it meets the shore.Prominent limestone stacks rise from the Southern Ocean, creating a natural monumental landscape. These stacks, eroded over time by the elements, stand alone and majestic against the backdrop of the vast sea and sky. Waves caress the beach, leaving behind patterns of foam and sand trails, hinting at the ebb and flow of the tide.The cliffs on the right are steep and rugged, covered with sparse vegetation that struggles to maintain life in such a harsh environment. The untouched beauty of this scene suggests that it is a remote part of the world, likely devoid of human presence except for perhaps the occasional adventurer or photographer seeking out such natural wonders.Overall, the image conveys a sense of tranquility, the enduring power of nature, and the awe-inspiring beauty of our planet's coastlines. It invites the viewer to reflect on the serenity and magnificence of the natural world and to consider the importance of protecting these environments for future generations.")
const inspIndex = ref(1)
function onTipClick() {
  prompt.value = inspText.value
}
function onInspirationClick(params) {
  inspIndex.value = params.key;
  inspText.value = inspirationList.value[params.key - 1].text;
}


const selectedImageIndex = ref(styleList.value[0]);
const stylePath = ref(styleList.value[0].id);
const enable = computed(() => {
  if (step.value == StepStatus.edit && props.applyEnabled) {
    if (!isCustom.value) {
      return true;
    } else if (prompt.value) {
      return true;
    }
  }
  return false;
});
onMounted(() => {
  const images = [...uploadStore.images];
  console.log(images);

  if (images.length) {
    initData();
  }
  window.addEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
  window.addEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
});
onUnmounted(() => {
  window.removeEventListener(
    customEventNames.uploadLocalFilesSuccessEvent,
    uploadLocalFilesSuccess
  );
  window.removeEventListener(
    customEventNames.taskFailRetryEvent,
    taskFailRetryEvent
  );
  cleanMenuDownload();
  uploadStore.setImages([]);
});
watch(
  () => props.fileList,
  () => {
    const fileList = props.fileList;
    if (fileList.length) {
      if (step.value === StepStatus.none) {
        if (fileList[0].status === uploadStatus.uploadFail) {
          messageBox.error(t("enhance-upload-fail"), null);
          cleanFile();
        } else if (fileList[0].status === uploadStatus.fail) {
          messageBox.error(t("enhance-catch"), null);
          cleanFile();
        } else if (fileList[0].status === uploadStatus.lodingImg) {
          // 清除下载
          cleanMenuDownload();
        } else if (fileList[0].status === uploadStatus.handle) {
          step1Data.value = fileList[0];
        } else if (fileList[0].status === uploadStatus.success) {
          step1Data.value = fileList[0];
          step.value = StepStatus.edit;

        }
      } else if (step.value === StepStatus.edit) {
        fileModel.value = fileList[0];
        if (fileList[0].imgList?.length) {
          // fileModel.value = fileList[0];
          imgGeneratorList.value = fileList[0]?.imgList.map((v) => {
            return {
              status: uploadStatus.lodingImg,
              enhanceUrl: decryptUrl(v.enhanceUrl),
              preImage: fileList[0].preImage,
            };
          });
          step.value = StepStatus.success;
        } else if (fileList[0].status === uploadStatus.lodingImg && fileList[0].enhanceUrl) {
          // fileModel.value = fileList[0];
          imgGeneratorList.value = [
            {
              status: uploadStatus.lodingImg,
              enhanceUrl: fileList[0].enhanceUrl,
              preImage: fileList[0].preImage,
            },
          ];
          step.value = StepStatus.success;
        }
      }
    } else {
      fileModel.value = null;
    }
  },
  { deep: true }
);

// watch(
//   () => props.fileList,
//   () => {
//     if (props.fileList.length) {
//       fileModel.value = props.fileList[0];
//     } else {
//       fileModel.value = null;
//     }
//   },
//   { deep: true }
// );
function uploadLocalFilesSuccess(params) {
  initData();
}
const onTabChange = (val) => {
  isCustom.value = val;
  if (!isCustom.value) {
    picNum.value = 1
  } else {
    picNum.value = 4
  }
  prompt.value = "";
};
function initData() {
  cleanMenuDownload();
  imgGeneratorList.value = [];
  step.value = StepStatus.none;
  indexImages.value = 0;
  step1Data.value = null;
  step1();
}
function taskFailRetryEvent(e) {
  // 如果任务失败，重置数据
  setTimeout(() => {
    updateFileListEvent([{ ...e.detail, status: uploadStatus.uploadSuccess }]);
  }, 100);
}
function foldChange() {
  isFold.value = !isFold.value;
}
const baseControlsRef = ref(null);
function imageViewChange(scale) {
  baseControlsRef.value.setScale(scale);
}

const onClickImg = (item, index) => {
  selectedImageIndex.value = item;
  stylePath.value = item.id;
};

function baseCtlScaleChange(scale) {
  if (imageViewRef.value) {
    imageViewRef.value.setScale(scale);
  }
}

function cleanFile() {
  updateFileListEvent([]);
  step.value = StepStatus.none;
  step1Data.value = null;
}
function onBgLoad() {
  console.log("bg加载成功");
  updateFileListEvent([
    {
      status: uploadStatus.success,
      originUrl: step1Data.enhanceUrl,
    },
  ]);
}
function decryptUrl(url) {
  let enhanceUrl = "";
  if (url) {
    $encryptor?.setPrivateKey(privateKey);
    let part1 = url.substring(0, url.length / 2);
    let part2 = url.substring(url.length / 2);
    // console.log("part1", part1);
    // console.log("part2", part2);
    // 解密
    const dec1 = $encryptor?.decrypt(part1);
    const dec2 = $encryptor?.decrypt(part2);
    // console.log("dec1", dec1);
    // console.log("dec2", dec2);
    // 打印解密后的明文
    enhanceUrl = dec1 + dec2;
  }
  return enhanceUrl;
}
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  updateFileListEvent(fileList);
}
function selectImg(index) {
  indexImages.value = index;
}
function delImg(index) {
  imgGeneratorList.value.splice(index, 1);
  if (imgGeneratorList.value.length === 0) {
    cleanFile();
  }
  indexImages.value = 0;
}
function downloadImg(index) {
  downloadImgEvent({ url: imgGeneratorList.value[index].enhanceUrl });
}
function enhanceLoad(index) {
  imgGeneratorList.value[index].status = uploadStatus.success;
}

function step1() {
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    cleanFile();
    return;
  }
  const timer = setInterval(() => {
    // 加载成功后进行处理,抠图
    if (
      props.fileList.length &&
      props.fileList[0].status === uploadStatus.none
    ) {
      clearInterval(timer);
      applyEvent({ enhanceCfgIds: [74] });
    } else if (
      props.fileList.length &&
      props.fileList[0].status === uploadStatus.imageLoadFail
    ) {
      clearInterval(timer);
      cleanFile();
      messageBox.error("load failed!", null);
    }
  }, 200);
}
const count = ref(0)
async function onApply() {
  if (!enable.value) {
    return;
  }
  if (count.value !== 0) {
    return
  }
  if (isLogin.value) {
    count.value++
    if (imageBgEditRef.value) {
      const file = await imageBgEditRef.value.handleExport();
      if (!file) {
        count.value = 0;
        return;
      }
      updateFileListEvent([
        {
          ...step1Data.value,
          status: uploadStatus.none,
          originUrl: "",
          enhanceUrl: '',
          imgList: [],
          file,
        },
      ]);
    }
    setTimeout(() => {
      if (imgGeneratorList.value.length == 0) {
        imgGeneratorList.value = [];
        let aiType = [65];
        if (isCustom.value) {
          applyEvent({
            enhanceCfgIds: aiType,
            picNum: picNum.value,
            prompt: prompt.value,
          });
        } else {
          applyEvent({
            enhanceCfgIds: aiType,
            stylePath: stylePath.value,
          });
        }
      }
      count.value = 0;
    }, 100);

    // isApply.value = false;
  } else {
    showLogin();
  }
}

function showLogin() {
  mainStore.setVisibleLogin(true);
}
function onLoad() {
  console.log("抠图加载成功");
  updateFileListEvent([
    {
      ...props.fileList[0],
      status: uploadStatus.success,
      // originUrl: step1Data.value.enhanceUrl,
      // enhanceUrl: step1Data.value.enhanceUrl,
    },
  ]);
}
</script>
<style scoped>
.h-screen-minus-80 {
  height: calc(100vh - 80px);
}

.arco-textarea {
  background: #F7F7F8;
  border: 1px solid transparent;
  border-image: linear-gradient(45deg, #4a2dff, #c02cff) 1;
}

.selected {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px #5f45ff;
  border-radius: 8px;
}

textarea:focus {
  border: 1px solid #c02cff;
}

.required {
  color: red;
  margin-right: 5px;
}

textarea {
  width: 100%;
  /* 16px */
  border: 2px solid #F7F7F8;
  background-color: #F7F7F8;
  /* 默认边框颜色 */
  border-radius: 0.5rem;
  text-align: left;
  line-height: normal;
  outline: none;
  /* 去掉默认的黑色外边框 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 3s linear infinite;
}

.selected-ins {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px #5F45FF;
  border-radius: 5px
}

.limited-lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  width: auto;
  word-break: break-all;
}
</style>
