import { useMainStore } from "@/store/index";
import { MemberType } from "~/utils/type";

export function useUserInfo() {
  const { t } = useI18n();
  const mainStore = useMainStore();
  const isLogin = computed(() => !!mainStore.userInfo?.userId);
  const isVip = computed(() => !!mainStore.userInfo?.userId && mainStore.userInfo?.memberType > MemberType.None);
  const isNormalVip = computed(() => !!mainStore.userInfo?.userId && (mainStore.userInfo?.memberType == MemberType.Week || mainStore.userInfo?.memberType == MemberType.Year || mainStore.userInfo?.memberType == MemberType.Month));
  const isVipPro = computed(() => !!mainStore.userInfo?.userId && (mainStore.userInfo?.memberType == MemberType.WeekPro || mainStore.userInfo?.memberType == MemberType.YearPro || mainStore.userInfo?.memberType == MemberType.MonthPro));
  const userInfo = computed(() => mainStore.userInfo);
  const checkWeekCount = () => {
    if (!isVip.value) {
      const num = 10 - (userInfo.value.removalCount ?? 0);
      if (num <= 0) {
          messageBox.error(t('errCode-use-limit'), null);
          mainStore.setVisibleVipDialog(true)
          return false;
      }
    } else if (isNormalVip.value) {
        const num = 10 - mainStore.weekCount > 40 ? 0 : 40 - mainStore.weekCount;
        if (num <= 0) {
            messageBox.error(t('errCode-use-limit-vip'), null);
            return false;
        }
    }
    return true
  }
  return { isLogin, isVip,isNormalVip,isVipPro,userInfo,checkWeekCount };
}
