<template>
  <div class="w-full sticky h-screen-minus-80 flex">
    <div
      class="w-32 bg-white border-r border-t border-solid border-t-gray-line border-r-gray-line flex flex-col px-4 py-[18px] gap-2">
      <div v-for="(item, index) in funcList" :key="item.id" @click="changeFunc(item.id)"
        class="w-full pt-2 pb-2 px-2 flex flex-col items-center gap-1 rounded-lg Roboto-10 text-center break-words hover:bg-mainColor-deep-8 cursor-pointer"
        :class="funcIndex === item.id ? 'bg-mainColor-deep-8' : ''">
        <img :src="`/assets/images/enhance/icons/${item.icon}.svg`" alt="Background Remover" />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="flex-1">
      <ClientOnly>
      <EnhancePagesAIECommerceBgRemover v-if="funcIndex === FuncType.background_remover.path" :fileList="props.fileList"
        :applyEnabled="applyEnabled">
      </EnhancePagesAIECommerceBgRemover>
      <EnhancePagesAIECommerceAIPoster v-if="funcIndex === FuncType.ai_poster.path" :fileList="props.fileList"
        :applyEnabled="applyEnabled">
      </EnhancePagesAIECommerceAIPoster>
      <EnhancePagesAIECommerceAILogo v-if="funcIndex === FuncType.ai_logo.path" :fileList="props.fileList"
        :applyEnabled="applyEnabled">
      </EnhancePagesAIECommerceAILogo>
      <EnhancePagesAIECommercePartialRedraw v-if="funcIndex === FuncType.partial_redraw.path" :fileList="props.fileList"
        :applyEnabled="applyEnabled"></EnhancePagesAIECommercePartialRedraw>
      <EnhancePagesAIECommerceAIProductImage v-if="funcIndex === FuncType.ai_product_image.path" :fileList="props.fileList"
        :applyEnabled="applyEnabled" :funcIndex="funcIndex"></EnhancePagesAIECommerceAIProductImage>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">

import { useUploadStore } from "@/store/upload";
// import type { ECCommonData } from "~/types";
const { getFuncType } = useFuncType();
const FuncType = ref(getFuncType());
const {
  customEventNames,
  cancelFuncTimerEvent,
  cleanMenuDownload,
  updateFileListEvent
} = useCustomEvent();
const { locale, t } = useI18n();

const funcList = ref([
  { id: FuncType.value.background_remover.path, name: t("background_remover"), icon: 'Background Remover' },
  { id: FuncType.value.ai_poster.path, name: t("ai_poster"), icon: 'AI Poster-2' },
  { id: FuncType.value.ai_logo.path, name: t("ai_logo"), icon: 'AI Logo-2' },
  { id: FuncType.value.partial_redraw.path, name: t("partial_redraw"), icon: 'Partial Redraw' },
  { id: FuncType.value.ai_product_image.path, name: t("ai_product_image"), icon: 'AI Product Image' },
]);
const funcIndex = ref('');
const route = useRoute();
const router = useRouter();
const uploadStore = useUploadStore();

// funcList.value.map(v => {
//   if(route.query.id){
//     if (v.id == (route.query.id as String).toLocaleLowerCase()) {
//       funcIndex.value = v.id
//     }
//   }
// })
// if(!funcIndex.value){
//   funcIndex.value = funcList.value[0].id
// }
const props = defineProps({
  fileList: {
    type: Array,
    default: [],
  },
  applyEnabled: {
    type: Boolean,
    default: false,
  },
});
onMounted(() => {
  funcList.value.map(v => {
    if(route.query.id){
      if (v.id == (route.query.id as String).toLocaleLowerCase()) {
        funcIndex.value = v.id
      }
    }
  })
  if(!funcIndex.value){
    funcIndex.value = funcList.value[0].id
  }
  // const images = [...uploadStore.images];
  // if(images.length) {
  //   commonData.value.origin = images[0]
  // }
  // window.addEventListener(customEventNames.uploadLocalFilesSuccessEvent, uploadLocalFilesSuccess);
  // window.addEventListener(customEventNames.updateECommerceCommonEvent, updateECommerceCommonEvent);
})
watch(()=>route.query.id,(n,o)=>{
  funcList.value.map(v => {
    if(n){
      if (v.id == (n as String).toLocaleLowerCase()) {
        funcIndex.value = v.id
        // console.log('n',n)
      }
    }
  })
})
onUnmounted(() => {
  // window.removeEventListener(customEventNames.updateECommerceCommonEvent, updateECommerceCommonEvent);
  // window.removeEventListener(customEventNames.uploadLocalFilesSuccessEvent, uploadLocalFilesSuccess);
  uploadStore.setImages([]);
})

function changeFunc(id: string) {
  if (funcIndex.value == id) {
    return
  }
  // 取消正在任务中的查询
  cancelFuncTimerEvent()
  cleanMenuDownload();
  // 其他功能进入，则清除数据
  if ([FuncType.value.ai_logo.path, FuncType.value.ai_poster.path].includes(id)) {
    // commonData.value = {origin:null,enhance: ''}
    updateFileListEvent([])
  }
  // 当前是AI_Poster/AI_Logo，进入其他的，则清除
  if ([FuncType.value.ai_logo.path, FuncType.value.ai_poster.path].includes(funcIndex.value)) {
    if ([FuncType.value.background_remover.path, FuncType.value.partial_redraw.path, FuncType.value.ai_product_image.path].includes(id)) {
      updateFileListEvent([])
    }
  }

  router.replace(route.path + '?id=' + id)
  setTimeout(() => {
    funcIndex.value = id;
    // router.replace(route.path+'?id='+id)
  }, 100)
}
</script>
<style scoped>
.h-screen-minus-80 {
  height: calc(100vh - 80px);
}
</style>
