<template>
  <div class="relative h-full w-full flex items-center justify-center">
    <div class="h-full" v-if="fileList.length">
      <div class="h-full flex items-center gap-12">
        <!-- <div class="h-2/3 w-[1224px] -translate-y-[20%]"> -->
        <div
          class="relative h-2/3 w-[856px] bg-white shadow-1 -translate-y-[20%]"
        >
          <EnhanceVideoPreview
            ref="originVideoRef"
            :preViewAbled="preViewAbled"
            :isFinish="false"
            @timeupdate="timeupdate"
            @loadedmetadata="loadedmetadata"
            @error="originError"
          ></EnhanceVideoPreview>
          <div
            v-show="fileList[0].status > uploadStatus.fail"
            class="absolute top-4 left-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white z-[2]"
          >
            {{ $t("before") }}
          </div>
          <div
            v-if="
              fileList[0].status == uploadStatus.handle ||
              fileList[0].status == uploadStatus.fail
            "
            class="absolute top-0 w-full h-full flex flex-col items-center justify-center bg-text-deep"
          >
            <template v-if="fileList[0].status == uploadStatus.handle">
              <Loading />
              <p
                class="mt-10 max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center"
                v-html="$t('enhance-process')"
                v-show="fileList[0].status == uploadStatus.handle"
              ></p>
            </template>
            <template v-else>
              <img
                src="/assets/images/enhance/video-empty.png"
                alt="empty"
                width="255"
                height="255"
              />
              <p
                class="mt-10 max-w-[317px] Roboto-16 text-[#FFFFFF99] text-center"
                v-show="fileList[0].status == uploadStatus.fail"
              >
                {{ $t("enhance-process-fail") }}
              </p>
            </template>
          </div>
        </div>
        <div
          v-show="fileList[0].status > uploadStatus.fail"
          class="relative h-2/3 w-[856px] bg-white shadow-1 -translate-y-[20%]"
        >
          <EnhanceVideoPreview
            ref="enhanceVideoRef"
            :preViewAbled="preViewAbled"
            @onDownload="onDownload"
            @loadedmetadata="enhanceLoadedmetadata"
            @error="enhanceError"
            isFinish
          ></EnhanceVideoPreview>
          <div
            class="absolute top-4 right-4 bg-black-60 Roboto-16 p-[6px] rounded-lg text-white z-[2]"
          >
            {{ $t("after") }}
          </div>
          <EnhanceGiveALike
            class="absolute right-4 bottom-4"
            v-if="isLogin"
            v-show="fileList[0].enhanceUrl && !fileList[0].isEvaluate"
            :id="fileList[0].enhanceRecordId"
            @onEvaluate="onEvaluate"
          ></EnhanceGiveALike>
        </div>
      </div>
    </div>
    <div v-else class="flex items-center justify-center">
      <FuncTypeUpload
        class="h-[240px] max-w-[90%] mx-auto z-[2] hidden sm:block lg:max-w-[1080px] lg:h-[280px]"
        :funcType="route.params.funcType"
      ></FuncTypeUpload>
    </div>
    <div
      class="absolute left-1/2 bottom-10 -translate-x-1/2 w-[888px] flex flex-col items-center gap-6"
      v-if="fileList.length"
    >
      <EnhanceVideoProcessCtr
        ref="videoProcessCtrRef"
        v-if="preViewAbled && originVideo"
        @onPlay="onPlay"
        @onPause="onPause"
        @updateSeed="updateSeed"
        :totalDuration="totalDuration"
        :currentTime="currentTime"
        :enable="
          fileList[0].status == uploadStatus.none ||
          fileList[0].status == uploadStatus.success
        "
      ></EnhanceVideoProcessCtr>
      <div
        class="bg-white shadow-6 py-4 rounded-[20px] px-4 flex items-center gap-8"
      >
        <!-- <div
          class="w-[695px] bg-light-gray-bg border border-gray-line rounded-lg flex items-center py-3"
        >
          <textarea
            v-model="describe"
            class="bg-light-gray-bg border border-gray-line placeholder:text-white-line outline-none h-full "
            placeholder="Please describe the objects that need to be retained to help make your video background removal more accurate."
            allow-clear
            :max-length="100"
            auto-size
          ></textarea>

        </div> -->
        <textarea
          v-model="describe"
          class="w-[695px] bg-light-gray-bg p-2 border text-xl border-gray-line placeholder:text-gray outline-none min-h-10 max-h-24 rounded-lg"
          :placeholder="$t('enhance-video-bg-remove-ph')"
          :maxLength="150"
          :rows="2"
        ></textarea>
        <EnhanceApply
          class="w-max"
          @click="onApply"
          :enable="applyEnabled"
        ></EnhanceApply>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUploadStore } from "@/store/upload";
import { uploadStatus } from "@/utils/type";
import { getObjectURL } from "@/utils";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const route = useRoute();
type VideoModel = {
  status: number;
  file: File;
  localUrl: string;
  width: number;
  height: number;
  enhanceUrl: string;
  originUrl: string;
  isEvaluate: boolean;
  enhanceRecordId: string;
};
enum Status {
  load,
  fail,
  success,
}
const uploadStore = useUploadStore();

const { isLogin, isVip } = useUserInfo();

const originVideoRef = ref<HTMLElement>();
const enhanceVideoRef = ref<HTMLElement>();
const originVideoEle = ref<HTMLVideoElement | null>(null);
const enhanceVideoEle = ref<HTMLVideoElement | null>(null);
const originVideo = ref<File | null>(null);
const totalDuration = ref(0);
const currentTime = ref(0);
const enhanceStatus = ref(Status.load);
const describe = ref("");
const applyEnabled = computed(() => {
  if (props.fileList.length) {
    return originVideo.value && props.fileList[0].status === uploadStatus.none;
  }
  return false;
});
// 支持预览
const preViewAbled = ref(true);

const videoProcessCtrRef = ref(null);
const props = defineProps({
  fileList: {
    type: Array<VideoModel>,
    default: [],
  },
});

watch(
  () => props.fileList,
  (n, o) => {
    console.log(n, o);

    if (n.length) {
      if (n[0].status > uploadStatus.fail) {
        nextTick(() => {
          if (enhanceVideoRef.value?.videoPlayer) {
            enhanceVideoEle.value = enhanceVideoRef.value.videoPlayer;
            if (enhanceVideoEle.value) {
              enhanceVideoEle.value.src = n[0].enhanceUrl;
            }
          }
        });
      }
    }
  },
  { deep: true }
);
onMounted(async () => {
  const videos = [...uploadStore.videos];
  uploadStore.setVideos([]);
  if (videos.length) {
    initFileList(videos[0]);
  }
  window.addEventListener("uploadLocalFilesSuccess", uploadLocalFilesSuccess);
});
onUnmounted(() => {
  
  window.removeEventListener(
    "uploadLocalFilesSuccess",
    uploadLocalFilesSuccess
  );
});
function uploadLocalFilesSuccess(params: any) {
  console.log(params.detail);
  if (params.detail.length) {
    initFileList(params.detail[0]);
  }
}
function initFileList(file: File) {
  originVideo.value = file;
  currentTime.value = 0;
  totalDuration.value = 0;
  const suffix = originVideo.value.name.split(".")[1].toLocaleLowerCase();
  preViewAbled.value = ["mp4", "webm"].includes(suffix);
  const videoUrl = getObjectURL(file);
  nextTick(() => {
    if (originVideoRef.value?.videoPlayer) {
      originVideoEle.value = originVideoRef.value.videoPlayer;
      if (originVideoEle.value) {
        originVideoEle.value.src = videoUrl;
      }
    }
  });
}

function onApply() {
  if (!applyEnabled.value) {
    return;
  }
  if (!isLogin.value) {
    mainStore.setVisibleLogin(true);
    return;
  }
  window.dispatchEvent(
    new CustomEvent("applyEvent", {
      // 提交相关处理参数，如相关表单
      detail: {
        enhanceCfgIds: [61],
      },
    })
  );
}

function onDownload() {
  if (props.fileList.length && props.fileList[0].enhanceUrl) {
    window.dispatchEvent(
      new CustomEvent("downloadImgEvent", {
        detail: {url:props.fileList[0].enhanceUrl},
      })
    );
  }
}
function onPlay() {
  originVideoEle.value?.play();
  enhanceVideoEle.value?.play();
}
function onPause() {
  originVideoEle.value?.pause();
  enhanceVideoEle.value?.pause();
}
function updateSeed(val: number) {
  if (originVideoEle.value) {
    originVideoEle.value.currentTime = totalDuration.value * val;
  }
  if (enhanceVideoEle.value) {
    enhanceVideoEle.value.currentTime = totalDuration.value * val;
  }
}
function timeupdate() {
  console.log("timeupdate");

  if (originVideoEle.value) {
    currentTime.value = originVideoEle.value.currentTime;
  }
}
function loadedmetadata() {
  console.log("loadedmetadata");

  if (originVideoEle.value) {
    totalDuration.value = originVideoEle.value.duration; // 获取视频总时长
    console.log(
      originVideoEle.value.videoWidth,
      originVideoEle.value.videoHeight
    );
  }
}
function onEvaluate() {
  const fileList = [...props.fileList];
  fileList[0].isEvaluate = true;
  window.dispatchEvent(
    new CustomEvent("updateFileListEvent", {
      detail: fileList,
    })
  );
}
function originError(e: Event) {}
function enhanceError(e: Event) {
  enhanceStatus.value = Status.fail;
}
function enhanceLoadedmetadata() {
  enhanceStatus.value = Status.success;
}
</script>
